import * as XLSX from "xlsx";

const getFileName = (name: string) => {
    let longDate = new Date();
    const shortDate = `${longDate.getDay()}_${longDate.toLocaleString('en-us', { month: 'short' })}_${longDate.getFullYear()}`;
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}_${shortDate}`;
    return {
        sheetName,
        fileName
    };
};
export class TableUtil {
    static setExcelColumnWidth(): any {
        //set excel column width
        const wscols = [
            { wch: 18 },
            { wch: 25 },
            { wch: 18 },
            { wch: 25 },
            { wch: 25 },
            { wch: 15 },
            { wch: 20 },
            { wch: 20 },
        ];
        return wscols;
    }

    static exportArrayToExcel(tableData: any[], tableHeader: any[], name?: string | any) {
        let { sheetName, fileName } = getFileName(name);
        let wb = XLSX.utils.book_new();

        // table th data
        let tableKeys = Object.keys(tableData[0]);
        let tableHeaderKeys = Object.keys(tableHeader);

        // convert camel case to normal string
        tableKeys = tableKeys.map((x) => this.getNormalText(x));
        tableHeaderKeys = tableHeaderKeys.map((x) => this.getNormalText(x));

        //Had to create a new workbook and then add the header
        const ws = XLSX.utils.book_new();

        // set excel cells width
        ws['!cols'] = this.setExcelColumnWidth();

        // write schedule data
        XLSX.utils.sheet_add_aoa(ws, [tableHeaderKeys], { origin: 'A1', cellStyles: true });
        XLSX.utils.sheet_add_json(ws, [tableHeader], { origin: 'A2', skipHeader: true });

        // write schedule report data
        XLSX.utils.sheet_add_aoa(ws, [tableKeys], { origin: 'A5', cellStyles: true });
        XLSX.utils.sheet_add_json(ws, tableData, { origin: 'A6', skipHeader: true });

        //
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }


    static getNormalText(camelCaseText): any {
        let changeCase = camelCaseText.replace(/([A-Z]+)/g, " $1")
        return changeCase.charAt(0).toUpperCase() + changeCase.slice(1);
    }
}
