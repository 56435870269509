import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MessageService } from 'src/app/message.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';
import { MatDialogRef } from '@angular/material/dialog';

// export function spaceValidator(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/^\s+|\s+$/g, '').length) {
//       control.setValue('');
//       return { required: true }
//   }
//   else {
//       return null;
//   }
//   }
@Component({
  selector: 'app-tests-create',
  templateUrl: './tests-create.component.html',
  styleUrls: ['./tests-create.component.scss'],
})
export class TestsCreateComponent implements OnInit {
  basicDetailsForm: FormGroup;
  globalcollectionuuid: any;
  duplicateDataFlag = false;
  collectionuuid: number;

  constructor(
    private testService: TestService,
    private fb: FormBuilder,
    private router: Router,
    private routerstate: ActivatedRoute,
    private messageService: MessageService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<TestsCreateComponent>

   
  ) {}

  ngOnInit(): void {
    this.globalcollectionuuid = JSON.parse(
      localStorage.getItem('defaultcollectionuuid')
    );
    // this.collectionuuid = this.globalcollectionuuid.replace(/['"]+/g,'')
    console.log(this.collectionuuid)


    this.basicDetailsForm = new FormGroup({
      testTitle: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(120),
        Validators.pattern(/^[ a-zA-Z.-]+([ ]?[a-z0-9 !#|^*()-_@$!%*?&]){0,}$/)
      ]),
      testDescription: new FormControl(
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(480),
        ]
      ),
    });
  }

  // This function used for validation purpose
  get f() {
    return this.basicDetailsForm.controls;
  }

  saveBasicTestDetails() {

  
    // console.log("hgasgdsagdhasvdnbdsavdabnsdvaswerwgafwghavdsnbdvsad", this.basicDetailsForm);
    if (this.basicDetailsForm.value.testDescription != null) {
      this.basicDetailsForm.value.testDescription =
        this.basicDetailsForm.value.testDescription.trim();
    } else {
      this.basicDetailsForm.value.testDescription =
        this.basicDetailsForm.value.testDescription;
    }
    const apiData = {
      collectionuuId: this.globalcollectionuuid,
      testTitle: this.basicDetailsForm.value.testTitle.trim(),
      testDescription: this.basicDetailsForm.value.testDescription,
    };

    this.testService.createTest(apiData).subscribe(
      (testData) => {
        console.log(testData);
        if (testData?.data.testId) {
        this.testService.getTests(this.globalcollectionuuid).subscribe(testData => { 
          console.log(testData)
          this.testService.newTestList(testData);
        });
          this.notificationService.success(testData?.message);
          // this.router.navigate(['../tests', testData?.data.testuuId], {
          //   relativeTo: this.routerstate,
          // });
          this.dialogRef.close();
        }
      },
      (error) => {
        this.notificationService.warn(error?.error?.message);
        // if (error.error.error == 'Conflict') {
        //   this.duplicateDataFlag = true;
        // }
      }
    );
  }

  onTestNameControlFocus(): void {
    this.duplicateDataFlag = false;
  }
}
