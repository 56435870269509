import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';
import { ResizeColumnDirective } from '../../../resize-column.directive';

export interface UserData {
  checkbox: any;
  name: string;
  email: string;
}

@Component({
  selector: 'app-add-recipient-dialogbox',
  templateUrl: './add-recipient-dialogbox.component.html',
  styleUrls: ['./add-recipient-dialogbox.component.scss']
})
export class AddRecipientDialogboxComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['checkbox', 'name', 'email'];
  dataSource: MatTableDataSource<UserData>;
  searchValue: string;
  examineeScehduleData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<AddRecipientDialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogboxData, private testService: TestService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    console.log(this.dialogboxData)
    this.dataSource = new MatTableDataSource(this.dialogboxData);

    // this.testService.getExamineetoSchedule().subscribe((resData) => {
    //   console.log(resData);
    //   this.examineeScehduleData = resData?.items;
    //   Array.from(this.dialogboxData).forEach((result: any) => {
    //     console.log(result)
    //     const index = this.examineeScehduleData.findIndex((id: any) => {
    //       console.log(id);
    //       return result?.examineeId === id?.examineeId;
    //     });
    //     resData.items.splice(index, 1);
    //   });
    //   this.dataSource = new MatTableDataSource<any>(resData?.items);
    // })

    // this.dataSource = new MatTableDataSource(this.examineeScehduleData);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyFilter() {
    const filterValue = this.searchValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    console.log(this.dataSource)
  }

  resetFilter() {
    this.searchValue = '';
    const filterValue = this.searchValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  addRecicipentToArray(event, row) {
    row['checked'] = event?.checked;
  }

  close(): void {
    
    const finalPayload = this.dialogboxData.filter((value => value?.checked === true));
    // console.log(finalPayload)
  
      // this.notificationService.success('Examinee(s) added to Schedule successfully');
      this.dialogRef.close(0);
    
  }

  sendRecipient(): void {
    const finalPayload = this.dialogboxData.filter((value => value?.checked === true));
    // console.log(finalPayload)
    if (finalPayload.length > 0) {
      this.notificationService.success('Examinee(s) added to Schedule successfully');
      this.dialogRef.close(finalPayload);
    } else {
      this.notificationService.warn('Please select Examinee(s)');
    }
  }
}
@NgModule({
  declarations: [
    AddRecipientDialogboxComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    FormsModule,
    MatDividerModule
  ]
})
class AddRecipientDialogboxComponentModule {

}
