<!--<div class="question-wrapper">
  <div class="question-container">
<div class="div-content sentence-align" [innerHTML]='questions?.questiondescription?.description'>
</div>

<mat-radio-group name="radioButtons">
<div class="div-options sentence-align" *ngFor="let o of questions?.options">
  <mat-radio-button [disabled]='isReadOnly' [value]="true" [checked]="o?.answerValid">
    <span class="" [innerHtml]="o?.answerDescription?.description"></span>
  </mat-radio-button>
</div>
</mat-radio-group>
</div>-->

<div class="question-wrapper">
  <!--<div class="question-container">
<div class="div-content sentence-align" [innerHTML]='questions?.questiondescription?.description'>
   </div>-->
  <div class="div-content sentence-align" >
    <!-- <h3 aria-label="Question"> <span [innerHTML]='questions?.questiondescription?.description'></span></h3> -->
    <form [formGroup]="form">
			<div class="editor">
				<ngx-editor [editor]="editor" [placeholder]="'Enter here...'" formControlName="editorContent">
				</ngx-editor>
			</div>
		</form>
</div>

<mat-radio-group name="radioButtons">
<div class="div-options sentence-align" *ngFor="let o of questions?.options">
  <mat-radio-button [disabled]='isReadOnly' [value]="true" [checked]="o?.answerValid" class="options">
    <!-- <span class="" [innerHtml]="o?.answerDescription?.description"></span> -->
    
    <div class="editor">
      <app-option-editor [optionValue]="o?.answerDescription?.description"></app-option-editor>
      <!-- <ngx-editor [editor]="editor" [value]="o?.answerDescription?.description">
      </ngx-editor> -->
    </div>

  </mat-radio-button>
</div>
</mat-radio-group>
</div>

