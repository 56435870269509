<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title>Update Test details</h2>
    <span class="spacer"></span>
    <div class="btn-close">
      <button mat-icon-button mat-dialog-close aria-label="Close Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="testDetailsForm" novalidate>
      <div class="form-wrapper">
        <div class="input-wrapper">
          <span class="input-label">
            <label>Test name</label><span class="required">*</span>
          </span>
          <span class="input-field">
            <textarea class="form-control textarea" rows="2" maxlength="121" type="text" formControlName="testTitle"
              [readonly]='isPublished'></textarea>
            <div *ngIf="f.testTitle.invalid && (f.testTitle.dirty || f.testTitle.touched)" class="alert alert-danger">
              <div *ngIf="f.testTitle.errors?.required"> Test name is required</div>
              <div *ngIf="f.testTitle.errors?.minlength">Minimum 1 characters required</div>
              <div *ngIf="f.testTitle.errors?.maxlength"> Maximum 120 characters allowed</div>
              <div *ngIf="f.testTitle.errors?.pattern">Test name is invalid</div>
            </div>
          </span>
        </div>
        <div class="input-wrapper">
          <span class="input-label">
            <label>Test description</label>
          </span>
          <span class="input-field">
            <textarea type="text" rows="8" maxlength="481" class="form-control textarea" type="text"
              formControlName="testDescription" [readonly]='isPublished'></textarea>
            <div *ngIf="f.testDescription.invalid && (f.testDescription.dirty || f.testDescription.touched)"
              class="alert alert-danger">
              <div *ngIf="f.testDescription.errors?.minlength"> Minimum 1 character required</div>
              <div *ngIf="f.testDescription.errors?.maxlength"> Maximum 480 characters allowed</div>

            </div>
          </span>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button mat-flat-button color="primary" class="btn-blue" aria-label="Save"  (click)='editBasicTestDetails()'
      [disabled]="isPublished || testDetailsForm.invalid">Save</button> -->
    <button mat-flat-button color="primary" class="btn-blue" aria-label="Save" (click)='editBasicTestDetails()'
      [disabled]="isPublished || f.testTitle.invalid">Save</button>
  </mat-dialog-actions>
</div>

<!-- <div class="main-div">
    <div class="col-md-10 text-center" [ngClass]="{'text-success': success,'text-danger': error}" *ngIf="messageFlag">{{messages}}</div>
    <div class="btn-wrapper">
        <button (click)='editBasicTestDetails()' mat-raised-button class="btn-bg-color"
            [disabled]="isPublished || testDetailsForm.invalid">Save</button>
    </div>
    <div class="frm-container">
        <form [formGroup]="testDetailsForm">
            <div class="input-class">
                <label>Test name<span class="red-asterisk">*</span></label>
                <input class="form-control" maxlength="121" type="text" formControlName="testTitle" [readonly]='isPublished'>
                <div *ngIf="f.testTitle.invalid && (f.testTitle.dirty || f.testTitle.touched)" class="alert alert-danger">
                    <div *ngIf="f.testTitle.errors?.required"> Test name is required. </div>
                    <div *ngIf="f.testTitle.errors?.minlength">Minimum 3 characters required. </div>
                    <div *ngIf="f.testTitle.errors?.maxlength"> Maximum 120 characters allowed. </div>
                </div>
            </div>
            <div class="input-class">
                <label>Test description</label>
                <textarea type="text" maxlength="481" class="form-control textarea" type="text" formControlName="testDescription" [readonly]='isPublished'></textarea>
                <div *ngIf="f.testDescription.invalid && (f.testDescription.dirty || f.testDescription.touched)" class="alert alert-danger">
                    <div *ngIf="f.testDescription.errors?.minlength"> Minimum 20 characters required. </div>
                    <div *ngIf="f.testDescription.errors?.maxlength"> Maximum 480 characters allowed. </div>
                </div>
            </div>
        </form>
    </div>
    </div> -->








<!-- <div class="filter-section title"> Details</div> -->
<!-- <div class="main-div">
<div class="col-md-10 text-center" [ngClass]="{'text-success': success,'text-danger': error}" *ngIf="messageFlag">{{messages}}</div>
<div class="btn-wrapper">
    <button (click)='editBasicTestDetails()' mat-raised-button class="btn-bg-color"
        [disabled]="isPublished || testDetailsForm.invalid">Save</button>
</div>
<div class="frm-container">
    <form [formGroup]="testDetailsForm">
        <div class="input-class">
            <label>Test name<span class="red-asterisk">*</span></label>
            <input class="form-control" maxlength="121" type="text" formControlName="testTitle" [readonly]='isPublished'>
            <div *ngIf="f.testTitle.invalid && (f.testTitle.dirty || f.testTitle.touched)" class="alert alert-danger">
                <div *ngIf="f.testTitle.errors?.required"> Test name is required. </div>
                <div *ngIf="f.testTitle.errors?.minlength">Minimum 3 characters required. </div>
                <div *ngIf="f.testTitle.errors?.maxlength"> Maximum 120 characters allowed. </div>
            </div>
        </div>
        <div class="input-class">
            <label>Test description</label>
            <textarea type="text" maxlength="481" class="form-control textarea" type="text" formControlName="testDescription" [readonly]='isPublished'></textarea>
            <div *ngIf="f.testDescription.invalid && (f.testDescription.dirty || f.testDescription.touched)" class="alert alert-danger">
                <div *ngIf="f.testDescription.errors?.minlength"> Minimum 20 characters required. </div>
                <div *ngIf="f.testDescription.errors?.maxlength"> Maximum 480 characters allowed. </div>
            </div>
        </div>
    </form>
</div>
</div> -->

<!-- <div class="filter-section title">Basic Details</div>
<div class="btn-wrapper">
    <button (click)='saveBasicItemDetails()' mat-raised-button class="btn-bg-color" [disabled]="isPublished">Save</button>
</div>
<div class="frm-container">
    <div class="input-class">
        <label>Test Name</label>
        <input class="form-control" type="text" [(ngModel)]='itemBasicDetails.testTitle'>
      </div>
      <div class="input-class">
          <label>Test Description</label>
          <input class="form-control" type="text" [(ngModel)]='itemBasicDetails.testDescription'>
      </div>
</div> -->