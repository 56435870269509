import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CollectionService } from 'src/app/shared/collection.service';
import { passwordValidator } from '../../shared/password.validator';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  timeZoneData$: Subscription | undefined;
  timeZoneArr = [];
  clientForm: FormGroup;
  hide = true;
  constructor(private collectionService: CollectionService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.timeZoneData$ = this.collectionService.getTimeZone()?.subscribe((timeZoneData: any) => {
      this.timeZoneArr = timeZoneData?.items;
    });
    this.clientForm = this.fb.group({
      clientName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z \-\']+')]],
      clientUrl: ['', [Validators.required, Validators.minLength(2)]],
      emailId: ['', [Validators.required, Validators.email]],
      timezone: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z \-\']+')]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z \-\']+')]],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])],
      confirmPassword: ['', [Validators.required, passwordValidator]],

    });
  }
  get clientFormControl(): any {
    return this.clientForm.controls;
  }

  registerClient(): void {
    delete this.clientForm.value.confirmPassword;
    console.log(this.clientForm.value);
    this.collectionService.registerClient(this.clientForm.value)?.subscribe(data => {
      alert('client registerd successfully');
    });
  }
}
