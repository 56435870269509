<div class="module-wrapper content-wrapper">
  <mat-card>
    <mat-card-content>
      <div class="head-container">
        <h1>Collection Management</h1>
        <span class="spacer"></span>
        <div>
          <div class="btn-wrapper">
            <button mat-flat-button color="warn" class="btn-orange" aria-label="Add New Item"
              (click)="openNewCollectionDialog()"> + New Collection</button>
          </div>
        </div>
      </div>
      <div class="input-container">
        <div class="input-fields-wrapper">
          <div class="input-wrapper">
            <input type="text" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model=""
              placeholder="Find by Collection name or Collection description or Language or Timezone" #input [(ngModel)]="searchValue">
          </div>
          <div class="btn-wrapper">
            <button mat-flat-button color="primary" class="btn-blue" aria-label="Search"
              (click)="applyFilter(input.value)">Search</button>
            <button mat-flat-button color="basic" class="btn-flat" aria-label="Reset"
              (click)='resetFilter(input.value)'>Reset</button>
          </div>
        </div>
      </div>
      <div *ngIf="isNoCollections" class="data-not-found-container table-container">
        <span>No Collections for this client</span>
      </div>
      <div *ngIf="!isNoCollections">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="collectionName">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Collection(s) </th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.collectionName?.length > 40 then sliceTitle else noSlice">
                </ng-container>
                <ng-template #sliceTitle>
                  <span class="text-bold" matTooltipClass="my-custom-tooltip" matTooltip="{{row?.collectionName}}"
                    matTooltipPosition="above">
                    {{row?.collectionName | slice: 0:40}}...</span>
                </ng-template>
                <ng-template #noSlice>
                  <span class="text-bold">{{row?.collectionName }}</span>
                </ng-template>

                <ng-container *ngIf="row?.description?.length > 40 then sliceDescription else nosliceDescription">
                </ng-container>
                <ng-template #sliceDescription>
                  <span class="description-text" matTooltipClass="my-custom-tooltip" matTooltip="{{row?.description}}"
                    matTooltipPosition="above">
                    {{row?.description | slice: 0 :40}}...
                  </span>
                </ng-template>
                <ng-template #nosliceDescription>
                  <span class="description-text"> {{row?.description }} </span>
                </ng-template>

              </td>
            </ng-container>

            <ng-container matColumnDef="language">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Language </th>
              <td mat-cell *matCellDef="let row">
                {{row?.language}}
              </td>
            </ng-container>

            <ng-container matColumnDef="timezone">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Timezone</th>
              <td mat-cell *matCellDef="let row"> {{row?.timezone}} </td>
            </ng-container>


            <ng-container matColumnDef="modifiedDate">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Updated </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-bold"> {{row?.modifiedBy}} </span>
                <span> {{row?.modifiedDateTime | dateAgo}} </span>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
              <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr class="pointer" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="collectioDetails(row)"></tr>


          </table>
          <div class="data-not-found-container" *ngIf="isFilterData">
            <p>Data not found</p>
          </div>
        </div>
        <div class="collection-paginator">
          <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>

