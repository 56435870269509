import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, throwError, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TableUtil } from "../shared/tableUtil";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }
  public routeToReportObs = new BehaviorSubject<any>(true);
  // this function is fetching time zones data from api
  getTimeZone(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'schedule/' + 'gettimezonelist';
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  // Schedule landing page api
  getSchedules(collectionId: any): Observable<any> | null {
    const fetchScheduleApiUrl = environment.apiUrl + 'report/' + 'schedule-landing?collectionId=' + collectionId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchScheduleApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }

  }

  // Schedule report (landing page) filter api
  scheduleReportFilter(collectionId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };

    const addTagApiUrl =
      environment.apiUrl + 'report/schedule-landing?collectionId=' + collectionId +
      '&fromDate=' + filterObj.fromDate + '&toDate=' + filterObj.todate +
      '&scheduleName=' + filterObj.scheduleName + '&testName=' + filterObj.testNameFilter;
    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }

  // Attendance report page api
  getAttendance(schIdId: any): Observable<any> | null {
    const fetchAttendanceApiUrl = environment.apiUrl + 'report/' + 'attendance?scheduleId=' + schIdId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchAttendanceApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }

  }

  //attendance report filter
  attendanceReportFilter(scheduleId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl =
      environment.apiUrl + 'report/' + 'attendance?scheduleId=' + scheduleId +
      '&examineeName=' + filterObj.examineeName +
      '&attendance=' + filterObj.attendance;

    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }


  // Examinee score report api
  getExamineeScoreReport(collectionId: any, scheduleId: any): Observable<any> | null {
    const fetchExamineescoreApiUrl = environment.apiUrl + 'report/' + 'examinee/' + 'score?collectionId=' + collectionId +
      // '&examineeId=' + examineeId +
      '&scheduleId=' + scheduleId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchExamineescoreApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }
  }

  //examinee score report filter
  examineeScoreReportFilter(scheduleId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl =
      environment.apiUrl + 'report/examinee/score?examineeName=' + filterObj.examineeName +
      '&scheduleId=' + scheduleId;
    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }

  // Examinee detailed score report api
  getExamineeDetailedScore(examId: any, schIdId: any): Observable<any> | null {

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      const globalcollectionid = JSON.parse(
        localStorage.getItem('defaultcollectionid')
      );
      const fetchAttendanceApiUrl = environment.apiUrl + 'report/examinee/score/' + 'details?scheduleId=' + schIdId + '&' + 'examineeId=' + examId + '&clientId=' + clientId + '&' + 'collectionId=' + globalcollectionid;

      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchAttendanceApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }
  }

  // Examinee landing page api
  //examinee detailed score report filter
  examineeDetailedScoreReportFilter(scheduleId, examineeId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );

    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const clientId = currentUserFromStorage.clientId;
    const globalcollectionid = JSON.parse(
      localStorage.getItem('defaultcollectionid')
    );
    const addTagApiUrl =
      environment.apiUrl + 'report/examinee/score/' + 'details?scheduleId=' + scheduleId + '&' + 'examineeId=' + examineeId +
      '&itemName=' + filterObj.itemName +
      '&answerResult=' + filterObj.answerResult + '&clientId=' + clientId + '&' + 'collectionId=' + globalcollectionid;

    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }

  // Examinee landing page api
  getExaminee(collectionId: any): Observable<any> | null {
    const fetchExamineeApiUrl = environment.apiUrl + 'report/' + 'examinee-landing?collectionId=' + collectionId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchExamineeApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }

  }
  // Examinee report (landing page) filter api

  examineeReportFilter(collectionId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl =
      environment.apiUrl + 'report/examinee-landing?collectionId=' + collectionId +
      '&emailId=' + filterObj.emailId + '&firstName=' + filterObj.firstName +
      '&lastName=' + filterObj.lastName;
    '&scheduleName=' + filterObj.scheduleName + '&testName=' + filterObj.testNameFilter;
    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }




  //Examinee Test Report api
  getExamineeTestReport(examId: any): Observable<any> | null {
    console.log(examId)
    const collectionId = localStorage.getItem('defaultcollectionid');
    const fetchAttendanceApiUrl = environment.apiUrl + 'report/examinee/' + 'test?examineeId=' + examId + '&collectionId=' + collectionId;
    console.log(fetchAttendanceApiUrl)
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchAttendanceApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }
  }
  //Examinee Test Report filter api
  examineeTestReportFilter(collectionId, examineeId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl =
      environment.apiUrl + 'report/examinee/' + 'test?collectionId=' + collectionId + '&' + 'examineeId=' + examineeId +
      '&fromDate=' + filterObj.scheduleStartdate + '&toDate=' + filterObj.scheduleEnddate +
      '&scheduleName=' + filterObj.scheduleName +
      '&attendance=' + filterObj.attendance +
      '&result=' + filterObj.result;
    ;
    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }

  //Examinee detailed report api
  getExamineeDetailedReport(schIdId: any, examId: any): Observable<any> | null {
    console.log(schIdId, examId);

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage?.clientId;
      const fetchAttendanceApiUrl = environment.apiUrl + 'report/examinee/test/' + 'details?clientId=' + clientId + '&' + 'scheduleId=' + schIdId
        + '&' + 'examineeId=' + examId;
      console.log(fetchAttendanceApiUrl)
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchAttendanceApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {

      return null;
    }
  }

  //Examinee detailed report filter api
  examineeDetailedReportFilter(scheduleId, examineeId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const clientId = currentUserFromStorage?.clientId;
    const addTagApiUrl =
      environment.apiUrl + 'report/examinee/test/' + 'details?clientId=' + clientId + '&' + 'scheduleId=' + scheduleId
      + '&' + 'examineeId=' + examineeId +
      '&itemName=' + filterObj.itemName +
      '&questionAnswerd=' + filterObj.questionAnswerd;
    console.log(addTagApiUrl);
    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    );
  }

  // This service method used to get item report list
  getItemReport(collectionId: string, schduleId: number): Observable<any> | null {
    console.log(collectionId);
    const fetchItemApiUrl = environment.apiUrl + 'report/items?collectionId=' + collectionId + '&schduleId=' + schduleId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchItemApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }

  // This service method used for item report filter purpose on item report page
  itemReportFilter(collectionId: string, schduleId: number, itemName: string): Observable<any> | null {
    console.log(collectionId);
    const fetchItemApiUrl = environment.apiUrl + 'report/items?collectionId=' + collectionId + '&schduleId=' + schduleId + '&itemName=' + itemName;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchItemApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }

  exportTable(eleReportName: any, tableData: any, headerData: any, filename: string) {

    // building report initial letters
    const buildReportName = eleReportName.nativeElement.childNodes[0].data.split(' ');
    let reportName = buildReportName.map(([v]) => v);
    reportName = reportName.splice(0, reportName.length - 1).join('').toUpperCase();

    // calling excel file building function
    TableUtil.exportArrayToExcel(tableData, headerData, `${reportName}_${filename}`);
  }
}
