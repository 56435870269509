<div class="module-wrapper content-wrapper">
  <div class="tab-container">
    <mat-card>
      <mat-card-content>
        <div class="head-container" >
          <div *ngIf="!buttonWasClicked && !isEdit">
            <h1>Item Management</h1>
            <div class="breadcrumb-container">
              <a [routerLink]="[]" class="link-active" (click)="navigatetoItem()">Item Management</a> /
              <span class="nav-link" *ngIf="tab1">{{tab}}</span>
              <span class="nav-link" *ngIf="!tab1">Questions</span>
          </div>

          </div>
        </div>
        <div class="item-info-container">
          <div class="itemdetail">
            <div class="item-name"><span>Item name: {{itemBankNameDisplay}}</span>
            </div>
            <div class="item-desc"><span>Item description: {{itemBankNameDiscription}}</span></div>
          </div>
          <span class="spacer"></span>
          <div  class="edit-btn-wrapper">
            <span class="item-active">
              <i-feather name="disc"></i-feather>
              <span>{{itemStatus}}</span>
            </span>
            <div class="btn-wrapper">
              <button mat-icon-button aria-label="Edit Item" matTooltip="Edit Item" [disabled]="itemStatus !== 'Unpublished'" matTooltipPosition="above" (click)="openNewItemDialog()" class="edit"><span class="menu-icon">
                  <i-feather name="edit"></i-feather>
                </span></button>
            </div>
          </div>
        </div>
        <div class="tab-container" >
         <!-- <span class="item-bank-name">Item name : {{itemBankNameDisplay}}</span>-->

          <mat-tab-group mat-align-tabs="end"  (selectedTabChange)="onChange($event)" [selectedIndex]="selectedTabIndex" animationDuration="0ms">

            <mat-tab label="Questions">
              <ng-template mat-tab-label>
                <span class="menu-icon">
                  <i-feather name="help-circle"></i-feather>
                </span>
                <span>Questions ({{numberOfQuestions}})</span>
              </ng-template>
              <div class="tab-content">
                <app-question-list></app-question-list>
              </div>
            </mat-tab>


          <!--  <mat-tab>
              <ng-template mat-tab-label>
                <span class="menu-icon">
                  <i-feather name="details"></i-feather>
                </span>
                <span>Details</span>
              </ng-template>
              <div class="tab-content">
                <app-item-basic-details [isPublished]='isPublished' [itemBasicDetails]='itemBasicDetails'>
                </app-item-basic-details>
              </div>
            </mat-tab>-->
            <mat-tab label="Tags">
              <ng-template mat-tab-label>
                <span class="menu-icon">
                  <i-feather name="tag"></i-feather>
                </span>
                <span>Tags</span>
              </ng-template>
              <div class="tab-content">
                <app-item-tags [isPublished]='isPublished'></app-item-tags>
              </div>
            </mat-tab>

            <mat-tab label="Settings">
              <ng-template mat-tab-label>
                <span class="menu-icon">
                  <i-feather name="settings"></i-feather>
                </span>
                <span>Settings</span>
              </ng-template>
              <div class="tab-content">
                <app-item-settings [isPublished]='isPublished' [itemId]='itemId' [item]='data' [testId]="testId">
                </app-item-settings>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
