import {
  Component,
  OnInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { toHTML } from 'ngx-editor';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { QuestionService } from 'src/app/shared/question.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { ItemService } from 'src/app/shared/item.service';
import {
  AddNewQuestion,
  QuestionDescription,
  Options,
} from 'src/app/shared/addnewquestion';
import { NotificationService } from 'src/app/shared/notification.service';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
export interface DialogData {
  animal: string;
  name1: string;
  optionIdDia: any;
  html: string;
  editor: Editor;
  editors: Editor[];
  option: any;
  editor1: Editor;
}

@Component({
  selector: 'app-item-add-checkbox-questions',
  templateUrl: './item-add-checkbox-questions.component.html',
  styleUrls: ['./item-add-checkbox-questions.component.scss'],
})
export class ItemAddCheckboxQuestionsComponent implements OnInit, OnDestroy {
  listDisplay;
  itemId: any;
  deleteOptionId: any;
  display: boolean = false;
  que: any;
  editor1: Editor;
  questionDescription: [];
  set_main_form = false;
  addOptionNew: Options[] = [];
  addedOptions: string[] = [];
  newOptionArray = [];
  moreSetting: [];
  opened = false;
  optionDiv = false;
  openedD = false;
  name = 'Angular 6';
  parentData = 1;
  i = 0;
  userForm: FormGroup;
  tabs = [];
  selected = new FormControl(0);
  editorform: FormGroup;
  editor: Editor;
  editors: Editor[] = [];
  option: [];
  question: [];
  fetchEditQuetion: any;
  fetchEditOption: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  panelOpenState: boolean = false;
  animal: string;
  name1: string;
  optionIdDia: any;
  html: string;
  questionhtml: any;
  fetchEditQuetion1: any;
  queId: any;
  seteditor = true;
  autoscoreSelectValue: any;
  allowChwckAnsSelectValue: any;
  scoringTypeExactMatchValue: any;
  shuffleSelectValue: any;
  autoscoring: boolean;
  checkansSelectValue: any;
  checkAnsmaintValue: any;

  miniscoretype: any;
  miniscoretypeMainvalue: any;

  penaltypointselectvalue: any;
  penaltypointMainvalue: any;
  scoringselectvalue: any;
  addnewquestion = new AddNewQuestion();
  addnewquestiondescription = new QuestionDescription();
  setAddOption = true;
  editMode = false;

  radioSelected: [];
  checkradiovalue = false;

  getscoringtype: any;
  getAllowCheckAns: any;

  updateOptionText: any;

  submitted = false;

  newArrayforAddOption: any;

  addOptionEditor = false;
  saveDataValidation = false;

  checkSelectionOption: any;
  itemBankQueId: any;
  addQuestionScore: any;
  newEvent = 0;
  newEvent1: any;
  scoreArray = [];
  questionscoreF = false;
  arrayForScoreadd: any;
  defultvalueScore = 0;
  defultvalue = 0;
  @Output() postCreated = new EventEmitter();
  @Output() oneMoreBack = new EventEmitter();
  addQuestionForm: FormGroup;
  saveDisable: boolean=false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routerstate: ActivatedRoute,
    public dialog: MatDialog,
    public questionService: QuestionService,
    public itemservice: ItemService,
    private notificationService: NotificationService
  ) {
    this.itemBankQueId = localStorage.getItem('itemId');
    // this.router.getCurrentNavigation()?.extras?.state?.iteBankQueId;
    this.routerstate?.parent?.params?.subscribe((data) => {
      this.itemId = data.id;
      // this.queId = this.router.getCurrentNavigation() ?.extras ?.state ?.queId;
      // console.log(this.queId);
      console.log('------------------------>', this.itemBankQueId);
    });

    if (localStorage.getItem('questionuuId')) {
      this.queId = localStorage.getItem('questionuuId');
    } else {
      this.queId = this.router.getCurrentNavigation()?.extras?.state?.queId;
    }

    // this.editMode = this.router.getCurrentNavigation() ?.extras ?.state ?.isEdit;
    this.userForm = fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
      autoscore: new FormControl(true),
      shuffle: [''],
      scoringTypeMatch: ['false'],
      allowCheckAnswer: ['false'],
      penaltyPoint: [''],
      minimumScoreIfAttempt: [''],
    });

    // this.editor = new Editor();
    // this.editor1 = new Editor();

    this.questionService.updateEditText.subscribe((data) => {
      // console.log("updta enmew one", data);
      if (this.editMode == true) {
        if (this.fetchEditOption) {
          this.fetchEditOption.forEach((a) => {
            // console.log("------>edit ",a);
            if (a.optionId == data.optionIdDia) {
              a.answerDescription.description = data.newValue;
              a.modified = true;

              // console.log('next subscribed value: ' + a.answerDescription.description);
            }
          });
          this.addOptionNew = this.fetchEditOption;
          // this.addedOptions=this.fetchEditOption;
        }
      } else {
        if (this.addOptionNew) {
          this.newArrayforAddOption = this.addOptionNew;
          this.newArrayforAddOption.forEach((a) => {
            console.log("------->new cjeckkhjhj", a);
            if (a.optionId == data.optionIdDia) {
              a.answerDescription.description = data.newValue;
              a.modified = true;

              // console.log('next subscribed value: ' + a.answerDescription.description);
            }
          });
          this.addOptionNew = this.newArrayforAddOption;
          // this.addedOptions=this.fetchEditOption;
        }
      }

      // console.log('next subscribed value: ' + data.newValue);
    });
  }

  createOptionControl(): FormGroup {
    return this.fb.group({
      value: [null, Validators.required],
    });
  }


  ngOnInit(): void {
    this.saveDisable=false;
    this.editor = new Editor();
    this.editor1 = new Editor();
    this.addQuestionForm = new FormGroup({
      question: new FormControl(
        { value: '', disabled: false },
        Validators.required
      )
    });
    if (this.queId) {
      this.seteditor = true;
      this.editMode = true;
      this.questionService
        .getQuestion(this.itemId, this.queId)
        .subscribe((resdata) => {
          console.log('data get questions', resdata);
          // this.question = resdata.data?.questiondescription?.description;
          this.addQuestionForm.controls['question'].setValue(resdata?.data?.questiondescription?.description);
          this.fetchEditOption = resdata.data?.options;
          this.addOptionNew = this.fetchEditOption;
          this.checkSelectionOption = this.addOptionNew;
          this.newEvent = resdata.data?.correctPoints;
          // this.fetchEditQuetion1 = data ?.questions[0] ?.questionid;
          // console.log
          console.log('fetchEditQuetion', this.newEvent);
          this.arrayForScoreadd = this.fetchEditOption;
          this.autoscoring = resdata.data?.autoScoring;
          // this.fetchEditQuetion1 = data ?.questions[0] ?.questionid;
          // console.log
          // console.log("fetchEditQuetion", this.addedOptions);

          this.userForm.setValue({
            hideRequired: this.hideRequiredControl,
            floatLabel: this.floatLabelControl,
            autoscore: resdata.data.autoScoring,
            shuffle: resdata.data.shuffleOption,
            scoringTypeMatch: resdata.data.scoringTypeExactMatch,
            allowCheckAnswer: resdata.data.allowCheckAnswer,
            penaltyPoint: resdata.data.penaltyPoint,
            minimumScoreIfAttempt: resdata.data.minScoreIfAttempt,
          });
          this.getscoringtype = String(resdata.data.scoringTypeExactMatch);
          this.getAllowCheckAns = String(resdata.data.allowCheckAnswer);
        });
    }

    // console.log('this.itemId', this.itemId);
  }

  get optionrows(): FormArray {
    return <FormArray>this.userForm.get('optionrows');
  }

  get f() {
    return this.userForm.controls;
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      this.notificationService.warn('Cannot change Total question score');
      return false;
    } else {
      this.notificationService.warn('Cannot change Total question score');
      return false;
    }
  }

  addOptionRow(): void {
    this.editors.push(new Editor());
    this.optionrows.push(this.createOptionControl());
  }

  removeOptionRow(index: any) {
    (this.userForm.get('optionrows') as FormArray).removeAt(index);
    //this.editors.remove(index);
  }

  getRowFormControls(): AbstractControl[] {
    return (<FormArray>this.userForm.get('DyRow')).controls;
  }

  onCheckRadioOption(o, value) {
    console.log('valucheck', value);
    console.log('valucheck', o);
    this.checkSelectionOption.forEach((element) => {
      console.log("=---->", element);
      if (
        element.optionId == o.optionId
      ) {
        if (element.answerValid == false) {
          element.answerValid = true;
        } else {
          element.answerValid = false;
        }
        this.addScore(o);
      }
    });
    this.addOptionNew = this.checkSelectionOption;
    // console.log(" this.newArrayforAddOption", this.checkSelectionOption);
  }

  addOption() {
    this.questionscoreF = true;
    var i = this.addOptionNew.length + 1;
    // console.log("add", i);
    this.html = toHTML(this.option);
    let rmcharHtml = this.html.replace( /(<([^>]+)>)/ig, '');
    let rmCharSpace= rmcharHtml.replace(/^\s+/g, '');
    if (this.html != '<p></p>' && rmCharSpace.length !==0) {
      this.addedOptions.push(this.html);
      // this.addOptionNew=[];
      this.addOptionNew.push({
        answerDescription: {
          description: this.html,
          mediaURL: [''],
        },

        answerValid: false,
        arrangeOrder: 0,
        correctPoints: 0,
        modified: false,
        optionId: i,
        selected: false,
      });
      this.checkSelectionOption = this.addOptionNew;
      this.newArrayforAddOption = this.addedOptions;
    }

    // console.log("This Addedoptions", this.addOptionNew);
    this.editor1.setContent('');
    this.arrayForScoreadd = this.addOptionNew;
  }
  addScore(valuescore) {
    // with type info
    // console.log(" this.arrayForScoreadd", this.arrayForScoreadd);
    this.scoreArray = [];
    this.arrayForScoreadd.forEach((element) => {
      if (element.answerValid == true) {
        this.scoreArray.push(element.correctPoints);
      }
    });
    this.newEvent = this.scoreArray.reduce(add, 0);
    function add(accumulator, a) {
      // add score of option
      return accumulator + a;
    }
  }

  // addScore(valuescore) {
  //   console.log('valueScore', valuescore);
  //   this.scoreArray = [];
  //   if (this.arrayForScoreadd) {
  //     this.arrayForScoreadd.forEach((element) => {
  //       if (element.answerValid == true) {
  //         console.log('data bsbadasghds', element.answerValid);
  //         this.scoreArray.push(element.correctPoints);
  //       }
  //     });
  //   }

  //   console.log('element.correctPoints', this.scoreArray);
  //   this.newEvent = this.scoreArray.reduce(add, 0);
  //   function add(accumulator, a) {
  //     return accumulator + a;
  //   }

  //   // this.scoreArray = [];
  //   // this.arrayForScoreadd.forEach((element) => {
  //   //   this.scoreArray.push(element.correctPoints);
  //   // });
  //   // console.log("element.correctPoints",this.scoreArray)
  //   // this.newEvent = this.scoreArray.reduce(add, 0);
  //   // function add(accumulator, a) {
  //   //    return a;
  //   // }
  // }

  deleteOption(index: any) {
    // console.log("Delete data", this.addOptionNew);
    this.addOptionNew.splice(index, 1);
    this.scoreArray = [];
    this.arrayForScoreadd.forEach((element) => {
      if (element.answerValid == true) {
        this.scoreArray.push(element.correctPoints);
      }
    });
    this.newEvent = this.scoreArray.reduce(add, 0);
    function add(accumulator, a) {
      // add score of option
      return accumulator + a;
    }
  }

  deleteEditOption(index: any, deleteOption: any) {
    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to delete this option?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    // const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.fetchEditOption.splice(index, 1);
        // let id = deleteOption.id;
        this.deleteOptionId = deleteOption.id;
        // this.questionService.deleteOption(this.queId, id) .subscribe((data) => {});
        this.scoreArray = [];
        this.arrayForScoreadd.forEach((element) => {
          if (element.answerValid == true) {
            this.scoreArray.push(element.correctPoints);
          }
        });
        this.newEvent = this.scoreArray.reduce(add, 0);
        function add(accumulator, a) {
          return accumulator + a;
        }
        this.notificationService.success("Option deleted successfully");
      }
    });
  }

  saveQuestion() {
    if (this.addOptionNew.length <= 1) {
      this.notificationService.warn('There must be at least two options added');
    } else {
      this.addnewquestion = new AddNewQuestion();
      this.addnewquestion.questiondescription = new QuestionDescription();

      let selectetionOptionValue = this.addOptionNew.filter(
        (value) => value.answerValid == true
      );

      let selectioIndex = this.addOptionNew.findIndex(
        (value) => value.answerValid == true
      );
      this.addnewquestion.itemId = this.itemBankQueId;
      this.questionhtml = this.addQuestionForm.controls['question'].value;
      let rmcharHtml = this.questionhtml.replace( /(<([^>]+)>)/ig, '');
      let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
      if (rmCharSpace.length===0) {
        this.notificationService.warn("Question field is mandatory");  // Used to display alert message
      }
      else if (selectioIndex < 0) {
        this.notificationService.warn('Select at least one correct answer');
      } else {
        if (this.editMode == true) {
          // this.questionhtml = this.question;
          // this.addnewquestion.autoScoring = this.userForm.controls['autoscore'].value;
          // this.addnewquestion.allowCheckAnswer = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
          this.questionhtml = this.addQuestionForm.controls['question'].value;
          this.allowChwckAnsSelectValue = JSON.parse(
            this.userForm.controls['allowCheckAnswer'].value
          );
          this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;

          this.scoringTypeExactMatchValue = JSON.parse(
            this.userForm.controls['scoringTypeMatch'].value
          );
          this.addnewquestion.scoringTypeExactMatch =
            this.scoringTypeExactMatchValue;

          // this.addnewquestion.scoringTypeExactMatch = JSON.parse(this.userForm.controls['scoringTypeMatch'].value);
          this.addnewquestion.autoScoring = this.userForm.controls['autoscore'].value;
          console.log(
            "this.userForm.controls['shuffle'].value",
            this.userForm.controls['shuffle'].value
          );
          this.shuffleSelectValue = this.userForm.controls['shuffle'].value;
          this.addnewquestion.shuffleOption = this.shuffleSelectValue;
          // this.addnewquestion.allowCheckAnswer = true;
          this.fetchEditOption.forEach((element) => {
            element.modified = true;
          });
          this.addnewquestion.options = this.fetchEditOption;
          console.log(
            'this.addnewquestion.options',
            this.addnewquestion.options
          );
        } else {
          // this.questionhtml = toHTML(this.question);
          this.questionhtml = this.addQuestionForm.controls['question'].value;
          this.autoscoreSelectValue = JSON.parse(
            this.userForm.controls['autoscore'].value
          );
          this.addnewquestion.autoScoring = this.autoscoreSelectValue;

          // this.allowChwckAnsSelectValue = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
          // this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;

          this.shuffleSelectValue = this.userForm.controls['shuffle'].value;
          this.addnewquestion.shuffleOption = this.shuffleSelectValue;

          this.addnewquestion.options = this.addOptionNew;
        }
        // this.addnewquestion.options = this.addOptionNew;
        // console.log("question", this.addnewquestion.options);
        this.addnewquestion.correctPoints = this.newEvent;
        this.addnewquestion.questiondescription.description = this.questionhtml;
        // this.addnewquestion.scoringTypeExactMatch = true;

        // this.scoringTypeExactMatchValue = JSON.parse(this.userForm.controls['scoringTypeMatch'].value);
        // this.addnewquestion.scoringTypeExactMatch = this.scoringTypeExactMatchValue;

        this.addnewquestion.active = true;
        this.addnewquestion.ansRenderCount = 0;

        this.addnewquestion.questiondescription.mediaURL = ['String'];
        this.addnewquestion.questionOrder = 0;
        this.addnewquestion.questionSubTypeId = 2;
        this.addnewquestion.questionTypeId = 1;
        // this.addnewquestion.shuffleOption = true;
        // this.addnewquestion.shuffleOptions = true;
        // this.autoscoreSelectValue = JSON.parse(this.userForm.controls['autoscore'].value);
        // this.addnewquestion.autoScoring = this.autoscoreSelectValue;
        // console.log("this.userForm.controls['allowCheckAnswer'].value", this.userForm.controls['allowCheckAnswer'].value);
        if (this.userForm.controls['allowCheckAnswer'].value) {
          this.allowChwckAnsSelectValue = JSON.parse(
            this.userForm.controls['allowCheckAnswer'].value
          );
          this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;
        }
        // else{
        //   this.allowChwckAnsSelectValue = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
        //   this.addnewquestion.allowCheckAnswer =this.userForm.controls['allowCheckAnswer'].value;

        // }

        if (this.userForm.controls['scoringTypeMatch'].value) {
          console.log("---------------> this.scoringTypeExactMatchValue", this.scoringTypeExactMatchValue);
          this.scoringTypeExactMatchValue = JSON.parse(
            this.userForm.controls['scoringTypeMatch'].value
          );
          this.addnewquestion.scoringTypeExactMatch =
            this.scoringTypeExactMatchValue;
        }
        // else{
        //   // this.scoringTypeExactMatchValue = JSON.parse(this.userForm.controls['scoringTypeMatch'].value);
        //   this.addnewquestion.scoringTypeExactMatch = this.userForm.controls['scoringTypeMatch'].value;
        // }

        // this.checkansSelectValue = this.userForm.controls['checkAnswerAttempt'].value;
        // this.checkAnsmaintValue = +this.checkansSelectValue;
        // this.addnewquestion.checkAnswerAttempt = this.checkAnsmaintValue;

        this.miniscoretype =
          this.userForm.controls['minimumScoreIfAttempt'].value;
        this.miniscoretypeMainvalue = +this.miniscoretype;
        this.addnewquestion.minScoreIfAttempt = this.miniscoretypeMainvalue;

        // this.addnewquestion.options = this.addOptionNew;

        this.penaltypointselectvalue =
          this.userForm.controls['penaltyPoint'].value;
        this.penaltypointMainvalue = +this.penaltypointselectvalue;
        this.addnewquestion.penaltyPoints = this.penaltypointMainvalue;

        // console.log("this.editMode", this.addnewquestion);
        if (this.editMode == false) {
          if (this.questionhtml == '<p></p>') {
            this.notificationService.warn('Question field is mandatory');
          } else {
            if (this.itemId) {
              this.questionService
                .addQuestion(this.addnewquestion, this.itemId)
                .subscribe(
                  (resData) => {
                    //  console.log("We are in Edit question",resData);
                    this.saveDisable=true;
                    this.notificationService.success(resData?.message); // This line used to display message
                    this.itemservice.questionCountUpdate.next(true);
                    this.listDisplay = true;

                    this.back();
                  },
                  (error) => {
                    this.notificationService.warn(error?.error?.message);
                  }
                );
            }
          }
        }

        // for Edit Quetion
        if (this.editMode == true) {
          if (this.questionhtml == '<p></p>') {
            this.notificationService.warn('Question field is mandatory');
          } else {
            this.questionService.deleteOption(this.queId, this.deleteOptionId).subscribe((data) => { });
            this.questionService
              .updateQuestion(this.addnewquestion, this.itemId, this.queId)
              .subscribe(
                (resData) => {
                  console.log("We are in Edit question",resData);
                  this.itemservice.editedGetItem(resData.data);
                  this.notificationService.success(resData?.message); // This line used to display message
                  this.itemservice.questionCountUpdate.next(true);
                  this.listDisplay = true;

                  this.back();
                },
                (error) => {
                  this.notificationService.warn(error?.error?.message);
                }
              );
          }
        }
      }
    }
  }

  clickData() {
    this.set_main_form = true;
  }

  openOptionDiv() {
    this.questionhtml =
          this.addQuestionForm.controls['question'].value;
    let rmcharHtml = this.questionhtml.replace( /(<([^>]+)>)/ig, '');
    let rmCharSpace= rmcharHtml.replace(/^\s+/g, '');
    if (this.questionhtml != '<p></p>' && rmCharSpace.length !==0) {
      this.setAddOption = false;
      this.optionDiv = true;
      this.addOptionEditor = false;
    } else {
      this.addOptionEditor = true;
    }
  }

  // deleteOption(index1) {
  //   this.addedOptions.splice(index,1);
  // }

  ngOnDestroy(): void {
    if (localStorage.getItem('questionuuId')) {
      this.queId = localStorage.removeItem('questionuuId');
      localStorage.removeItem('questionId');
      localStorage.removeItem('questionuuId');

    }
    // this.editors.forEach(item => {
    //   item.destroy();
    // });
  }

  changeFromChild(): void {
    this.parentData = +1;
  }

  data: any;

  openDynamic(index: any) {
    this.data = index;
    if (this.data == index) {
      this.openedD = true;
    } else {
      this.openedD = false;
    }
  }

  send(values: any) {
    // console.log(values);
    // console.log("data call send", this.userForm.value);
  }



  back() {
    this.postCreated.emit('false');
    this.display = true;
    this.editor.destroy();
  }



  openDialog(a: any, optionIdDia): void {
    // console.log(a);
    const dialogRef = this.dialog.open(DialogOverview, {
      width: '950px',
      height: '350px',
      panelClass: 'edit-dialog-container',
      // console.log(a);
      data: { name1: a, animal1: 'test', optionIdDia: optionIdDia },
      // data: { name1: this.html ,animal1 :'test'}
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      this.animal = result;
    });
  }
}

export class OptionUpdateText {
  oldValue: any;
  newValue: any;
  optionIdDia: any;
}
@Component({
  selector: 'dialog-overview',
  templateUrl: 'dialog-overview.html',
  styleUrls: ['dialog-overview.scss'],
})
export class DialogOverview {
  fetchEditQuetion1: any;
  editor: Editor;
  editors: Editor[] = [];
  option: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  oldOptionName: any;
  optionIdDia: any;
  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public questionService: QuestionService,
    private notificationService: NotificationService
  ) {
    this.editor = new Editor();
  }
  ngOnInit(): void {
    // console.log("Option editor", this.data.name1);
    this.option = this.data.name1;
    this.oldOptionName = this.option;
    this.optionIdDia = this.data.optionIdDia;
  }

  updateOption(): void {
    var optionUpdateText = new OptionUpdateText();
    optionUpdateText.oldValue = this.oldOptionName;
    optionUpdateText.newValue = this.option;
    optionUpdateText.optionIdDia = this.data.optionIdDia
    let rmcharHtml = optionUpdateText.newValue.replace( /(<([^>]+)>)/ig, '');
    let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
    if (rmCharSpace.length === 0) {
      this.notificationService.warn('Option field is mandatory');
    } else {
      this.questionService.getUpdateEditValue(optionUpdateText);
      this.notificationService.success("Option updated successfully");
      this.dialogRef.close();
    }
  }
}
