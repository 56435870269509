import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  // variable declaration
  private errorResponseSubject: BehaviorSubject<any>;
  errorResponseSubjectObservable: Observable<any>;

  private newUser = new BehaviorSubject<any>('');
  public getNewData = this.newUser.asObservable();

  constructor(private http: HttpClient) {
    this.errorResponseSubject = new BehaviorSubject<any>('');
    this.errorResponseSubjectObservable =
      this.errorResponseSubject.asObservable();
  }

  newUserList(user: any){
    // console.log(user);
    this.newUser.next(user);
  }

  getusers(): Observable<any> | null {
    // console.log(collectionId);

    if (localStorage.getItem('currentUser')) {
      const collectionuuId = JSON.parse(localStorage.getItem('defaultcollectionuuid'));
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      const fetchUsersApiUrl = environment.apiUrl + 'users/'  + collectionuuId;
      // console.log(accessTokenFromStoarge);
      console.log(fetchUsersApiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchUsersApiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }


  // This service is  used on Users landing page
  getusersLanding(): Observable<any> | null {
    // console.log(collectionId);

    if (localStorage.getItem('currentUser')) {
      const collectionId = localStorage.getItem('defaultcollectionid');
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      const clientuuId = currentUserFromStorage.clientuuId;
      const fetchUsersApiUrl = environment.apiUrl + 'users/';
      // console.log(accessTokenFromStoarge);
      console.log(fetchUsersApiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchUsersApiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getUsersByID(useruuId: number): Observable<any> | null {
    // console.log(useruuId);

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const clientId = currentUserFromStorage.clientId;
      const fetchUserApiUrl = environment.apiUrl + 'userdetails/' + useruuId;
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchUserApiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getUsersFilter(options: any): Observable<any> | null {
    console.log(options);
    // const fetchItemapiUrl = environment.apiUrl + 'items?collectionId=' + filterData.collectionId + '&itemBankName=' + item.itemName + '&itemDescription=' + item.itemDescription + '&itemStatus' + item.itemStaus;
    // const fetchTestApiUrl = environment.apiUrl
    //   + 'tests?collectionId=' + filterData.collectionId
    //   + '&testName=' + filterData.testName
    //   + '&testDescription=' + filterData.testDescription
    //   + '&tagdescription=' + filterData.tagdescription
    //   + '&testStatus=' + filterData.testStatus;
    // '&itemBankName=' + item.itemName + '&itemDescription=' + item.itemDescription + '&itemStatus=' + item.itemStatus + '&tag=' + item.tag;
    if (localStorage.getItem('currentUser')) {
      const collectionuuId = JSON.parse(localStorage.getItem('defaultcollectionuuid'));
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      // const fetchUsersApiUrl = environment.apiUrl + 'users/' + clientId + '?userfirstName=' + options.userfirstName + '&userlastName=' + options.userlastName + '&userActiveStatus=' + options.userActiveStatus;
      const fetchUsersApiUrl =
        environment.apiUrl +
        'users/' +


        collectionuuId +
        '?' +
        options;
      // console.log(accessTokenFromStoarge);
      console.log(fetchUsersApiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchUsersApiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  // THis service used on users landing page as user filter function
  UsersFilter(options: any): Observable<any> | null {
    console.log(options);
    // const fetchItemapiUrl = environment.apiUrl + 'items?collectionId=' + filterData.collectionId + '&itemBankName=' + item.itemName + '&itemDescription=' + item.itemDescription + '&itemStatus' + item.itemStaus;
    // const fetchTestApiUrl = environment.apiUrl
    //   + 'tests?collectionId=' + filterData.collectionId
    //   + '&testName=' + filterData.testName
    //   + '&testDescription=' + filterData.testDescription
    //   + '&tagdescription=' + filterData.tagdescription
    //   + '&testStatus=' + filterData.testStatus;
    // '&itemBankName=' + item.itemName + '&itemDescription=' + item.itemDescription + '&itemStatus=' + item.itemStatus + '&tag=' + item.tag;
    if (localStorage.getItem('currentUser')) {
      const collectionId = localStorage.getItem('defaultcollectionid');
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      const clientuuId = currentUserFromStorage.clientuuId;
      // const fetchUsersApiUrl = environment.apiUrl + 'users/' + clientId + '?userfirstName=' + options.userfirstName + '&userlastName=' + options.userlastName + '&userActiveStatus=' + options.userActiveStatus;
      // const fetchUsersApiUrl = environment.apiUrl + 'users/' + clientId + '/' + collectionId + '?' + options;
      const fetchUsersApiUrl =
        environment.apiUrl + 'users/' + '?' + options;
      // console.log(accessTokenFromStoarge);
      console.log(fetchUsersApiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchUsersApiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  createNewUser(dataToApi: any) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const clientId = currentUserFromStorage.clientId;
    // const clientuuId = currentUserFromStorage.clientuuId;
    const addUserApiUrl = environment.apiUrl + 'users';
    console.log('add-user api url', addUserApiUrl);
    dataToApi.clientId = clientId;
    return this.http
      .post<any>(addUserApiUrl, JSON.stringify(dataToApi), { headers: headers })
      .pipe(
        tap((data) => data),
        catchError((error) => {
          this.errorResponseSubject.next(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
  }

  editUser(dataToApi: any, useruuId: number) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    // const clientId = currentUserFromStorage.clientId;
    const editUserApiUrl = environment.apiUrl + 'users/' + useruuId;
    // dataToApi.clientId=clientId;
    return this.http
      .put<any>(editUserApiUrl, JSON.stringify(dataToApi), { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }
}
