import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { tap, catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AddNewQuestion } from "src/app/shared/addnewquestion";
import { throwError, Subject } from "rxjs";
import { OptionUpdateText } from "src/app/items/item-add-question-type/item-add-question-type.component";


@Injectable({
    providedIn: 'root'
})
export class QuestionService {
    public updateEditText = new Subject<any>();
    backButtonObs = new Subject<any>();

    constructor(private http: HttpClient) { }


    getUpdateEditValue(value: any) {
        console.log("service calling", value)
        this.updateEditText.next(value);

    }

    addQuestion(question: AddNewQuestion, itemuuId: any): Observable<any> {
        console.log("service call", itemuuId);
        const questionapiUrl = environment.apiUrl + 'itembanks/' + itemuuId + '/questions';
        const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
        // const headers = { 'content-type': 'application/json'}
        const headers = { 'token': accessTokenFromStoarge }
        // console.log(headers);

        console.log("questionapiUrl", questionapiUrl);
        console.log('addquestions', question);
        const questionDataToApi = JSON.stringify({ question });
        console.log("question api", questionDataToApi);
        return this.http.post<any>(questionapiUrl, question, { 'headers': headers }).pipe(
            tap((resData: any) => resData?.data),
            catchError(this.handleError)
        )
    };



    getQuestion(itemId: any, queId: any): Observable<any> {
        console.log("service call", itemId);
        const questionapiUrl = environment.apiUrl + 'itembanks/' + itemId + '/questions/' + queId;
        const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
        // const headers = { 'content-type': 'application/json'}
        const headers = { 'token': accessTokenFromStoarge }
        console.log(headers);

        console.log("questionapiUrl", questionapiUrl);

        return this.http.get<any>(questionapiUrl, { 'headers': headers }).pipe(
            tap((resData: any) => resData?.data),
            catchError(this.handleError)
        )
    };

    updateQuestion(questionEdit: AddNewQuestion, itemId: any, queId: any): Observable<any> {
        console.log("Uodate service call", itemId);
        console.log("question in service", queId);
        const questionapiUrl = environment.apiUrl + 'itembanks/' + itemId + '/questions/' + queId;
        const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
        // const headers = { 'content-type': 'application/json'}
        const headers = { 'token': accessTokenFromStoarge }
        console.log(headers);

        console.log("questionapiUrl", questionapiUrl);
        const questionDataToApi = { questionEdit };
        console.log(questionDataToApi, "question api");
        return this.http.put<any>(questionapiUrl, questionEdit, { 'headers': headers }).pipe(
            tap((resData: any) => resData?.data),
            catchError(this.handleError)
        )
    };

    deleteQuestion(questionId: any, itemId: any): Observable<any> {
        // console.log("service call", environment.apiUrl);
        // environment.apiUrl + 'items/'+ itemId+ '/tags/' +  itemtagId
        const DeletequestionapiUrl = environment.apiUrl + 'itembanks/' + itemId + '/questions/' + questionId;
        const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
        // const headers = { 'content-type': 'application/json'}
        const headers = { 'token': accessTokenFromStoarge }
        console.log(headers);

        console.log("DeletequestionapiUrl", DeletequestionapiUrl);
        const deleteQuestionDataToApi = JSON.stringify({ questionId, itemId });
        console.log(deleteQuestionDataToApi, "question api");
        return this.http.delete<any>(DeletequestionapiUrl, { 'headers': headers }).pipe(
            // tap(data=>console.log(data)),
            tap((resData: any) => resData?.data),
            catchError(this.handleError)
        )
    };

    deleteOption(questionId: any, Id: any): Observable<any> {
        // console.log("service call", environment.apiUrl);
        // environment.apiUrl + 'items/'+ itemId+ '/tags/' +  itemtagId
        const DeletequestionapiUrl = environment.apiUrl + 'itembanks/' + questionId + '/options/' + Id;
        const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
        // const headers = { 'content-type': 'application/json'}
        const headers = { 'token': accessTokenFromStoarge }
        console.log(headers);

        console.log("DeletequestionapiUrl", DeletequestionapiUrl);
        const deleteQuestionDataToApi = JSON.stringify({ questionId, Id });
        console.log(deleteQuestionDataToApi, "question api");
        return this.http.delete<any>(DeletequestionapiUrl, { 'headers': headers }).pipe(
            // tap(data=>console.log(data)),
            tap((resData: any) => resData?.data),
            catchError(this.handleError)
        )
    };
    private handleError(error: any) {
        console.error(error);
        return throwError(error);
    }

}