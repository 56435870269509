import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, NgModule, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectFilterModule } from 'mat-select-filter';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';

export interface Examinee {
  fullName: string;
}


@Component({
  selector: 'app-edit-public-schedule',
  templateUrl: './edit-public-schedule.component.html',
  styleUrls: ['./edit-public-schedule.component.scss']
})
export class EditPublicScheduleComponent implements OnInit {

  // public schedule form
  publicScheduleForm: FormGroup;

  // date initialization
  minDate: Date;
  minDate1: Date;
  d = new Date();
  timeZoneArr = [];
  zone: any;
  convertStartTime: any;
  minutesToAdd: any;
  setDefaultStartTime: any;
  setDefaultEndTime: any;
  convertEndTime: any;

  // editor initialization
  editor: Editor;
  editor1: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  // mat chip and autocomplete 
  public allExaminees: Examinee[] = [];

  public chipSelectedExaminee: Examinee[] = [];

  public filteredExaminee: Observable<String[]>;

  private allowFreeTextAddExaminee = false;

  public examineeControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('examineeInput') examineeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  setPenaltymsg: boolean;
  setTestScoremsg: boolean;
  isExamineeExist = false;
  getMarksDetails: any;
  scheduleuuId: string;
  scheduleInfo: any;
  isLive = false;
  isCompleted = false;
  isStartTimeValid: boolean;
  isEndTimeValid: boolean;

  // search dropdown
  searchOptions = [];
  selected:any;
  filteredList:any;

  constructor(private fb: FormBuilder, private testService: TestService, private notificationService: NotificationService, private datePipe: DatePipe, private dialog: MatDialog,private cdr: ChangeDetectorRef
  ) {

    // /////////////////////////////////////// date logic ////////////////////////////////////////
    // this.minDate = new Date(Date.now() - (3600 * 1000 * 24));
    // this.minDate1 = new Date(Date.now() - (3600 * 1000 * 24));
    // //  convert time to current time to plus 30 minute
    // this.convertStartTime = new Date();
    // this.minutesToAdd = new Date(
    //   this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    // );

    // this.setDefaultStartTime =
    //   this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    // this.convertEndTime = this.convertStartTime.setMinutes(
    //   this.convertStartTime.getMinutes() + 60
    // );
    // this.setDefaultEndTime =
    //   this.convertStartTime.getHours() +
    //   ':' +
    //   this.convertStartTime.getMinutes();
    // ////////////////////////////////////////////////////////////////////////////////////////////

    // editor logic
    this.editor = new Editor();
    this.editor1 = new Editor();
  }

  ngOnInit(): void {
    this.scheduleInfo = JSON.parse(localStorage.getItem('scheduleInfo'));
    this.testService.editTestSchedule(this.scheduleInfo?.scheduleuuId).subscribe((selectedSchedule) => {
      if (selectedSchedule?.data[0]?.scheduleStatus.includes('Live')) {
        this.isLive = true;
      } else if (selectedSchedule?.data[0]?.scheduleStatus.includes('Completed')) {
        this.isCompleted = true;
      }
      else {
        this.isLive = false;
      }
      this.buildPublicScheduleForm(selectedSchedule?.data[0]);
      selectedSchedule?.data[0].publicScheduleParameters.parameters.forEach(publicExaminee => {
        this.chipSelectedExaminee.push({ fullName: publicExaminee });
      });
    });
    console.log('public ng oninit')
    /////////////////////////////////////// date logic ////////////////////////////////////////
    this.minDate = new Date(Date.now() - (3600 * 1000 * 24));
    this.minDate1 = new Date(Date.now() - (3600 * 1000 * 24));
    //  convert time to current time to plus 30 minute
    this.convertStartTime = new Date();
    this.minutesToAdd = new Date(
      this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    );

    this.setDefaultStartTime =
      this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    this.convertEndTime = this.convertStartTime.setMinutes(
      this.convertStartTime.getMinutes() + 60
    );
    this.setDefaultEndTime =
      this.convertStartTime.getHours() +
      ':' +
      this.convertStartTime.getMinutes();
    ////////////////////////////////////////////////////////////////////////////////////////////
    this.getTimeZone();
    // this.getMarks();
    this.filteredExaminee = this.examineeControl.valueChanges.pipe(
      map(examineeName => this.filterOnValueChange(examineeName))
    );
  }

  // form validation
  get f() {
    this.publicScheduleForm.valueChanges.subscribe(valueChange => {
      this.compareDateAndTime(valueChange);
    });
    return this.publicScheduleForm.controls;
  }

  compareDateAndTime(valueChange): any {
    if (valueChange.startTime == '' || valueChange.startTime == '00:00') {
      this.isStartTimeValid = true;
    } else {
      this.isStartTimeValid = false;
    }
    if (valueChange.endTime == '' || valueChange.endTime == '00:00') {
      this.isEndTimeValid = true;
    } else {
      this.isEndTimeValid = false;
    }
  }
  
  // create public schedule form
  buildPublicScheduleForm(selectedSchedule) {
// console.log(selectedSchedule);

    this.publicScheduleForm = this.fb.group({
      schName: new FormControl({ value: selectedSchedule?.title.trim(), disabled: this.isCompleted || this.isLive }, [Validators.required, Validators.minLength(3), Validators.maxLength(120)]),
      schDescription: new FormControl({ value: selectedSchedule?.description.trim(), disabled: this.isCompleted || this.isLive }, [ Validators.minLength(3), Validators.maxLength(480)]),
      startDate: new FormControl({ value: selectedSchedule?.startDate, disabled: this.isCompleted || this.isLive }, Validators.required),
      startTime: new FormControl({ value: `${selectedSchedule.startDateHours}:${selectedSchedule.startDateMinutes}`, disabled: this.isCompleted || this.isLive }, Validators.required),
      endDate: new FormControl({ value: selectedSchedule?.endDate, disabled: this.isCompleted || this.isLive }, Validators.required),
      endTime: new FormControl({ value: `${selectedSchedule.endDateHours}:${selectedSchedule.endDateMinutes}`, disabled: this.isCompleted || this.isLive }, Validators.required),
      timeZone: new FormControl({ value: selectedSchedule?.timeZoneName, disabled: this.isCompleted || this.isLive }, Validators.required),
      testDuration: new FormControl({ value: selectedSchedule?.testDuration, disabled: this.isCompleted || this.isLive }, [Validators.required, Validators.pattern(/^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/)] ),
      testTotalScore: new FormControl({ value: selectedSchedule?.testScore, disabled: this.isCompleted || this.isLive }, Validators.required),
      testPassingScore: new FormControl({ value: selectedSchedule?.testPassingScore, disabled: this.isCompleted || this.isLive }, [Validators.required, Validators.pattern(/^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/)]),
      penaltyPoints: new FormControl({ value: selectedSchedule?.penaltyPoint, disabled: this.isCompleted || this.isLive }, Validators.required),
      preMessageText: new FormControl({ value: selectedSchedule?.preTestMessage?.description.trim(), disabled: this.isCompleted || this.isLive }, [Validators.required, Validators.minLength(8), Validators.maxLength(1000)]),
      postMessageText: new FormControl({ value: selectedSchedule?.postTestMessage?.description.trim(), disabled: this.isCompleted || this.isLive }, [Validators.required, Validators.minLength(8), Validators.maxLength(1000)]),
      // ExamineeRejoinTest: new FormControl({ value: selectedSchedule?.examineeRejoin, disabled: true }, Validators.required),
      // showResultAfterTest: new FormControl({ value: selectedSchedule?.showResultAfterTest, disabled: true }, Validators.required),
      // shuffleItems: new FormControl({ value: selectedSchedule?.shuffleItem, disabled: true }, Validators.required),
      // shuffleQuestions: new FormControl({ value: selectedSchedule?.shuffleQuestion, disabled: true }, Validators.required),
      // shuffleOptions: new FormControl({ value: selectedSchedule?.shuffleOption, disabled: true }, Validators.required),
    });
  }

  // merge fname and emailid
  getFnameNemailId(firstName, emailId) {
    return `${firstName} (${emailId})`;
  }


  /////////////////////////////////////// start add and remove recipient logic //////////////////////////////////////////////
  // add new chip
  public addExaminee(event: MatChipInputEvent): void {
    // if (!this.allowFreeTextAddExaminee) {
    //   // only allowed to select from the filtered autocomplete list
    //   // console.log('allowFreeTextAddExaminee is false');
    //   return;
    // }

    // //
    // // Only add when MatAutocomplete is not open
    // // To make sure this does not conflict with OptionSelected Event
    // //
    // if (this.matAutocomplete.isOpen) {
    //   return;
    // }

    // // Add our examinee
    // const value = event.value;
    // if ((value || '').trim()) {
    //   this.selectExamineeByName(value.trim());
    // }

    // this.resetInputs();
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.chipSelectedExaminee.push({ fullName: value });
      this.isExamineeExist = false;
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  // remove chip
  public removeExaminee(examinee: Examinee): void {
    const index = this.chipSelectedExaminee.indexOf(examinee);
    if (index >= 0) {
      this.chipSelectedExaminee.splice(index, 1);
      if (this.chipSelectedExaminee.length === 0) {
        this.isExamineeExist = true;
        return;
      }
      this.resetInputs();
    }
  }

  // selected examinee
  public selectedExaminee(event: MatAutocompleteSelectedEvent): void {
    this.selectExamineeByName(event.option.value);
    this.resetInputs();
  }

  // reset add recipient input box
  private resetInputs() {
    // clear input element
    this.examineeInput.nativeElement.value = '';
    // clear control value and trigger examineeControl.valueChanges event
    this.examineeControl.setValue(null);
  }

  // filter add recipient input box on change value
  private filterOnValueChange(examineeName: string | null): String[] {
    let result: String[] = [];
    //
    // Remove the examinees we have already selected from all examinees to
    // get a starting point for the autocomplete list.
    //
    let allExamineesLessSelected = this.allExaminees.filter(examinee => this.chipSelectedExaminee.indexOf(examinee) < 0);
    if (examineeName) {
      result = this.filterExaminee(allExamineesLessSelected, examineeName);
    } else {
      result = allExamineesLessSelected.map(examinee => examinee.fullName);
    }
    return result;
  }

  // filter examinee after detect any change
  private filterExaminee(examineeList: Examinee[], examineeName: String): String[] {
    let filteredExamineeList: Examinee[] = [];
    const filterValue = examineeName.toLowerCase();
    let examineesMatchingEngineerName = examineeList.filter(examinee => examinee.fullName.toLowerCase().indexOf(filterValue) === 0);
    if (examineesMatchingEngineerName.length || this.allowFreeTextAddExaminee) {
      //
      // either the examinee name matched some autocomplete options 
      // or the name didn't match but we're allowing 
      // non-autocomplete examinee names to be entered
      //
      filteredExamineeList = examineesMatchingEngineerName;
    } else {
      //
      // the examinee name didn't match the autocomplete list 
      // and we're only allowing examinees to be selected from the list
      // so we show the whjole list
      // 
      filteredExamineeList = examineeList;
    }
    //
    // Convert filtered list of examinee objects to list of examinee 
    // name strings and return it
    //
    return filteredExamineeList.map(examinee => examinee.fullName);
  }


  private selectExamineeByName(examineeName) {
    let foundExaminee = this.allExaminees.filter(examinee => examinee.fullName == examineeName);
    if (foundExaminee.length) {
      //
      // We found the examinee name in the allExaminees list
      //
      this.chipSelectedExaminee.push(foundExaminee[0]);
      if (this.chipSelectedExaminee.length > 0) {
        this.isExamineeExist = false;
        return;
      }
    }
  }

  /////////////////////////////////////// end add and remove recipient logic //////////////////////////////////////////////

  ////////////////////////////////////// not used anywhere in html /////////////////////////////////////////////////////////
  testScoreChange() {
    if (
      this.publicScheduleForm?.value?.testPassingScore + 1 >
      // this.scheduleForm.controls['testPassingScore'].value || this.scheduleForm.controls['penaltyPoint'].value >
      this.publicScheduleForm?.value?.testTotalScore
    ) {

      this.setTestScoremsg = true;
      setTimeout(() => {
        this.setTestScoremsg = false;
      }, 1000);
      // this.publicScheduleForm.value.testPassingScore.patchValue({ testPassingScore: '0' });
    } else {
      this.setTestScoremsg = false;
    }

  }


  checkTestValue() {
    // console.log(this.publicScheduleForm.value.penaltyPoints + 1 > this.publicScheduleForm?.value?.testTotalScore)
    if (
      this.publicScheduleForm.value.penaltyPoints + 1 >
      this.publicScheduleForm?.value?.testTotalScore
    ) {
      this.setPenaltymsg = true;
      // this.publicScheduleForm.patchValue({ penaltyPoints: '0' });
    } else {
      this.setPenaltymsg = false;
    }

  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // get all time zones
  getTimeZone(): void {
    this.testService.getTimeZone().subscribe((timezonedata) => {
      this.timeZoneArr = timezonedata?.data;

      // Dropdown Search filter
      this.searchOptions = this.timeZoneArr;
      this.selected = this.searchOptions[0]?.name;
      this.filteredList = this.searchOptions.slice();

      this.cdr.detectChanges();
    });
  }

  // get marks 
  getMarks(): void {
    const testuuid = localStorage.getItem('testuuid');
    const marks = this.testService.getTestToltalScore(testuuid).subscribe((resData) => {
      this.publicScheduleForm.patchValue({
        testTotalScore: resData?.data?.totalMarks, testPassingScore: resData?.data?.passingCriteria, testDuration: resData?.data?.duration
      });
    });
  }

  // navigate to back
  back() {
    // console.log('back trigger')
    this.testService.scheduleBackBtnobs.next(false);
  }


  // submit public schedule form
  onSubmit(form) {
    if (this.chipSelectedExaminee.length <= 0) {
      this.isExamineeExist = true;
      return;
    }
    const finalPayload = this.getFinalPayload(form);
    console.log(finalPayload)
    this.testService.editTestScheduleForm(finalPayload).subscribe((resData) => {
      console.log(resData)
      if (resData.success) {
        this.notificationService.success(resData?.message);
        this.back();
      }
    }, handleError => {
      this.notificationService.warn(handleError.error.error[0].message);
    });
  }

  cancelTestSchedule() {
    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to cancel the Schedule?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log(confirmed)
        this.testService.cancleSchduel(this.scheduleInfo?.scheduleuuId).subscribe(
          (deldata) => {
            this.notificationService.success(deldata?.message); // this line used to display error message
            this.back();
          },
          (error) => {
            this.notificationService.warn(error?.error?.message); // this line used to display error message
          }
        );
      }
    });
  }


  // construct api payload
  getFinalPayload(payload): any {
    // console.log(payload);
    const examinneNames = this.chipSelectedExaminee.map((value) => value.fullName);
    return payload = {
      examinees: [],
      publicScheduleParameters: {
        parameters: examinneNames
      },
      clientId: JSON.parse(localStorage?.getItem('currentUser'))?.clientId,
      collectionId: localStorage.getItem('defaultcollectionid'),
      description: payload?.value?.schDescription || null,
      endDate: this.datePipe.transform(payload.value.endDate, 'yyyy-MM-dd'),
      endDateHours: payload.value.endTime.split(':')[0],
      endDateMinutes: payload.value.endTime.split(':')[1],
      postTestMessage: {
        description: payload?.value?.postMessageText,
        mediaURL: [
          "String"
        ]
      },
      preTestMessage: {
        description: payload?.value?.preMessageText,
        mediaURL: [
          "String"
        ]
      },
      scheduleStatus: this.scheduleInfo.scheduleStatus,
      scheduleType: this.scheduleInfo?.scheduleType,
      scheduleuuId: this.scheduleInfo?.scheduleuuId,
      testScheduleId: this.scheduleInfo?.testScheduleId,
      startDate: this.datePipe.transform(payload.value.startDate, 'yyyy-MM-dd'),
      startDateHours: payload.value.startTime.split(':')[0],
      startDateMinutes: payload.value.startTime.split(':')[1],
      testBankId: localStorage.getItem('testBankId'),
      testDuration: payload?.value?.testDuration,
      testPassingScore: JSON.parse(payload?.value?.testPassingScore),
      testScore: JSON.parse(payload?.value?.testTotalScore),
      penaltyPoint: payload?.value?.penaltyPoints,
      timeZoneName: payload?.value?.timeZone,
      title: payload.value.schName.trim(),
      showResultAfterTest: payload.value.showResultAfterTest,
      shuffleItem: payload.value.shuffleItems,
      shuffleOption: payload.value.shuffleOptions,
      shuffleQuestion: payload.value.shuffleQuestions,
    }
  }

}
@NgModule({
  declarations: [
    EditPublicScheduleComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    MatSelectFilterModule,
    NgxEditorModule,
    MatButtonModule,
    MatSelectModule
  ]
})
class EditPublicScheduleComponentModule {

} 
