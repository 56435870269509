import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/message.service';
import { ItemService } from 'src/app/shared/item.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';
import { FlipitemtabService } from 'src/app/tests/flipitemtab.service';
import { CreateNewItemComponent } from '../create-new-item/create-new-item.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-item-basic-details',
  templateUrl: './item-basic-details.component.html',
  styleUrls: ['./item-basic-details.component.scss'],
})
export class ItemBasicDetailsComponent implements OnInit, OnChanges {
  @Input() itemBasicDetails: any;
  @Input() isPublished: any;
  isNewItem = true;
  collectionId: any;
  itemBankName: any;
  itemDescription: any;
  action = '';
  duplicateDataFlag = false;
  basicDetailsForm: FormGroup;
  isPublishedItem = false;
  globalcollectionid: any;
  globalcollectionuuid: any;
  @Input() testId: any;
  display = false;
  itemUuid: any;

  constructor(
    private itemService: ItemService,
    private testService: TestService,
    private actRoute: ActivatedRoute,
    private messageService: MessageService,
    private routerstate: ActivatedRoute,
    private flipitemtabService: FlipitemtabService,
    private router: Router,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ItemBasicDetailsComponent>


  ) {

    this.collectionId = JSON.parse(localStorage.getItem('defaultcollectionuuid'));
    this.itemService.itemdata.subscribe((resData) => {
      this.itemUuid = resData;
    });
    this.itemService.updateStatus.subscribe((data) => {
    });
    if (this.itemBasicDetails) {
      this.isPublishedItem = this.itemBasicDetails.isPublished;
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('TestId')) {
      this.display = true;
    }
    this.globalcollectionid = JSON.parse(
      localStorage.getItem('defaultcollectionid')
    );

    this.globalcollectionuuid = JSON.parse(
      localStorage.getItem('defaultcollectionuuid')
    );

    if (this.itemBasicDetails) {
      this.isPublished = this.itemBasicDetails.isPublished;
    }

    this.basicDetailsForm = new FormGroup({
      itemTitle: new FormControl(this.itemBasicDetails?.itemBankName?.trim(), [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(120),
        Validators.pattern(/^[ a-zA-Z.-]+([ ]?[a-z0-9 !#|^*()-_@$!%*?&]){0,}$/)
      ]
      ),
      itemDes: new FormControl(
        this.itemBasicDetails?.itemDescription?.trim(),
        [
          Validators.minLength(1),
          Validators.maxLength(480),
        ]
      ),
    });


  }

  closeDialog() {
    this.dialogRef.close("noitem");
  }



  gotoTestItems() {
    this.testId = localStorage.getItem('TestId');
    this.router.navigate([
      '/collections',
      this.globalcollectionuuid,
      'tests',
      this.testId,
    ]);
    localStorage.removeItem('TestId');
  }

  // This function used for validation purpose
  get f() {
    return this.basicDetailsForm.controls;
  }
  ngOnChanges(): void {
    if (this.itemBasicDetails) {
      this.isNewItem = false;
    }

    if (this.itemBasicDetails) {
      this.isPublishedItem = this.itemBasicDetails.isPublished;
    }
  }

  saveBasicItemDetails(): void {
    console.log(this.itemDescription)
    if (this.isNewItem == true) {
      if (this.itemDescription != null && this.itemDescription != undefined) {
        this.itemDescription = this.itemDescription.trim();
      }
      this.itemService
        .addNewItem(
          this.globalcollectionuuid,
          this.basicDetailsForm?.value?.itemTitle,
          this.basicDetailsForm.value?.itemDes
        )
        .subscribe(
          (resData) => {

            this.itemService.getItem(this.globalcollectionuuid).subscribe(
              (resData) => {
                this.itemService.createdGetItem(resData);
              });
            if (resData?.data?.itemBankId) {

              this.itemService.updateItemBankName(resData?.data?.itemBankName);
              this.notificationService.success(resData.message); // This line used to display message
              // this.router.navigate(['./collections/' + this.collectionId + '/items'])
              this.dialogRef.close();

            }
          },
          (error) => {
            this.notificationService.warn(error?.error?.message); // This line used to display error message
          }
        );
    } else {
      if (this.basicDetailsForm.value.itemDes != null && this.basicDetailsForm.value.itemDes != undefined) {
        this.basicDetailsForm.value.itemDes =
          this.basicDetailsForm.value.itemDes.trim();
      }
      const apiData = {
        collectionuuId: this.globalcollectionuuid,
        itemBankName: this.basicDetailsForm.value.itemTitle.trim(),
        itemDescription: this.basicDetailsForm.value.itemDes,
        itemBankId: this.itemUuid,
      };
      this.itemService.updateItem(apiData).subscribe(
        (resData) => {
          console.log(resData);
          this.itemService.editedGetItem(resData);
          this.notificationService.success(resData.message); // This line used to display message
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.warn(error?.error?.message); // This line used to display error message
        }
      );
    }

  }
}
