<!-- <app-header></app-header> -->




<div class="users-container">


    <div class="filter-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10">
            <div class="input-fields-wrapper">
              <div class="input-wrapper">
                <input type="text" class="form-control" [(ngModel)]='firstName' placeholder="First name">
                <input type="text" class="form-control" [(ngModel)]='lastName' placeholder="Last name">
                <input type="text" class="form-control" [(ngModel)]='emailId' placeholder="Email ID">
                <select aria-placeholder="Status" class="form-control" [(ngModel)]='userStatus'>
                                                <option value="">Status</option>
                                                <option value="true">Published</option>
                                                <option value="false">Unpublished</option>
                                            </select>
              </div>
              <div class="btn-wrapper">

                <button class="btn-bg-color" mat-raised-button>                                                
                                            <span>Search</span>
                                        </button>

                <button class="btn-green-text" mat-raised-button>
                                            <span>Reset</span>
                                        </button>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="btn-wrapper">
              <button class="btn-orange" mat-flat-button (click)="saveItemsToTest()">
                                        <span> + New User</span>
                                    </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="table-container">
            <table mat-table [dataSource]="usersData" matSort class="mat-elevation-z8">


              <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>CheckUser</th>
                <td mat-cell *matCellDef="let row">
                  <div>
                    <mat-checkbox (change)="toggleCheckbox($event)" id="{{row.userid}}"></mat-checkbox>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef>First Name</th>
                <td mat-cell *matCellDef="let row">
                  <a (click)='navigateToUserDetails(row.userid)'>{{row?.firstName}}</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef>Last Name </th>
                <td mat-cell *matCellDef="let row">{{row.lastName}}</td>
              </ng-container>
              <ng-container matColumnDef="emailId">
                <th mat-header-cell *matHeaderCellDef>Email ID </th>
                <td mat-cell *matCellDef="let row">{{row.emailId}}</td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let row">
                  <i *ngIf="row?.active === true" class="fa fas fa-circle publish"></i>
                  <span *ngIf="row?.active === false" class="glyphicon glyphicon-adjust unpublish"></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
                <td mat-cell *matCellDef="let row">{{row.modifiedDateTime | dateAgo}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            </table>
            <div class="data-not-found-container" *ngIf='noDataFound'>
              <p>Data not found</p>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
 
</div>
