import { Component, Inject, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestsScheduleComponent, testUrlPass } from './tests-schedule.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';



@Component({
  selector: 'app-url-dialog',
  templateUrl: './url-dialog.html',
  styleUrls: ['./url-dialog.scss']
})
export class Urldialog implements OnInit {
  testUrltoCopy: any;

  constructor(public dialogRef: MatDialogRef<Urldialog>, @Inject(MAT_DIALOG_DATA)
   public data: testUrlPass,  private router: Router
   ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.testUrltoCopy = this.data?.testUrl;
  }



  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

}
