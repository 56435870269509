<app-loader></app-loader>

<div class="main-container" role="main"  onbeforeunload="handleClose()">

  <app-header *ngIf="userHeader == 1 && contentVisible"></app-header>

  <mat-sidenav-container class="example-container" autosize>
    <!-- <mat-sidenav #sidenav class="example-sidenav" mode="side" *ngIf="userHeader == 1" opened="true" (mouseenter)="mouseenter()"
      (mouseleave)="mouseleave()">
      <mat-nav-list>
        <mat-list-item class="parent">
          <span class="menu-icon"><i-feather name="grid"></i-feather></span>
          <span class="full-width" routerLink="dashboard" *ngIf="isExpanded || isShowing">Dashboard</span>
        </mat-list-item>
        <mat-list-item class="parent">
          <span class="menu-icon"><i-feather name="list"></i-feather></span>
          <span class="full-width" routerLink="item-landing" *ngIf="isExpanded || isShowing">Item</span>
        </mat-list-item>
        <mat-list-item class="parent">
          <span class="menu-icon"><i-feather name="file-text"></i-feather></span>
          <span class="full-width" *ngIf="isExpanded || isShowing">Tests</span>
        </mat-list-item>
        <mat-list-item class="parent">
          <span class="menu-icon"><i-feather name="tag"></i-feather></span>
          <span class="full-width" *ngIf="isExpanded || isShowing">Tags</span>
        </mat-list-item>
        <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent">
          <span class="menu-icon"><i-feather name="alert-octagon"></i-feather></span>
          <span class="full-width" *ngIf="isExpanded || isShowing">Reports</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
          <a mat-list-item href="...">Report 1</a>
          <a mat-list-item href="...">Report 2</a>
          <a mat-list-item href="...">Report 3</a>
          <a mat-list-item href="...">Report 4</a>
          <a mat-list-item href="...">Report 5</a>
          <a mat-list-item href="...">Report 6</a>
          <a mat-list-item href="...">Report 7</a>
        </div>
      </mat-nav-list>
      <mat-nav-list>
      </mat-nav-list>
    </mat-sidenav> -->

    <div class="example-sidenav-content">
      <div class="app-content-wrapper">
        <router-outlet></router-outlet>
      </div>
  
      <footer  *ngIf="userHeader == 1 && contentVisible">    
        <div class="footer-copyrights">
          © 2022 SimpliAssess, All Rights Reserved.
        </div>
      </footer>
    </div>

  </mat-sidenav-container>


  <!-- <app-userheader *ngIf="userHeader == 1"></app-userheader> -->


</div>