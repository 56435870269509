import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ItemService } from 'src/app/shared/item.service';
import { MatTabGroup, MatTabsModule, MatTabChangeEvent } from '@angular/material/tabs';
import { TestService } from 'src/app/shared/test.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TestsBasicDetailsComponent } from '../tests-basic-details/tests-basic-details.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface dialogData {
  tagtypeId: any;
  tagDetails: any;
  testuuId: number;
}

@Component({
  selector: 'app-tests-details',
  templateUrl: './tests-details.component.html',
  styleUrls: ['./tests-details.component.scss']
})
export class TestsDetailsComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  testServiceSubscription$: Subscription | undefined;
  displayedColumns: string[] = ['itemBankName', 'itemDescription'];
  dataSource: any;
  testuuId: any;
  testuuId1: any;
  questions: any;
  itemdetails: any;
  numberOfQuestions = 0;
  itemBasicDetails = {
    testTitle: "",
    testDescription: "",
    collectionId: "",
    testuuid: "",
    statusName: ""
  };
  testBankId: any;
  action = '';
  tab: any;
  tab1: boolean = false;
  isReadMore: boolean = true;
  display: boolean = false;
  loadItemsTab = false;
  isPublished: boolean;
  passTestDetailData: any;
  testItems: any;
  testTitle: any;
  testStatus: any;
  testDescription: any;
  @ViewChild('table') table: MatTable<any>;
  tabData: any;
  selectedIndex: any;
  isTestItemCount: any;
  constructor(private testService: TestService, private router: Router, private routerstate: ActivatedRoute, private dialog: MatDialog) {

    this.testService.isTestItemCount.subscribe(istestcount => {
      console.log("constructorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", istestcount)
      this.isTestItemCount = istestcount;
      console.log("constructorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", this.isTestItemCount);

    })


    this.testuuId = localStorage.getItem('TestId');
    if (localStorage.getItem('TestId')) {
      // this.selectedIndex = 1;

      this.testService.getItemByTestId(this.testuuId)?.subscribe(
        (data) => {
          // this.testItems = data?.items;
          // console.log('TestId', this.testId);
          // this.testItems = new MatTableDataSource(this.testItems);
          // this.testItems.sort = this.sort;
          // this.testItems.paginator = this.paginator;
          this.tabData = data;
        });

    }
  }

  //   ngOnChanges(): void{

  // }

  ngOnInit(): void {
    this.loadItemsTab = true;



    this.testService.getUpdatedTest.subscribe(resData => {
      console.log(resData)
      this.testTitle = resData?.data?.testTitle;
      this.testDescription = resData?.data?.testDescription;
    })




    if (this.routerstate.snapshot.paramMap.get('id') === null) {
      this.action = 'createTest';
    } else {
      this.action = 'editTest';
      this.routerstate.paramMap.pipe(
        switchMap((params: any) => {
          this.testuuId = params.get('id');
          console.log(this.testuuId)
          localStorage.setItem('testuuid', this.testuuId);
          return this.testService.getTestDetails(this.testuuId)
        }
        )).subscribe(
          resData => {
            console.log("------------------------------>data23232323", resData?.data);
            this.testuuId1 = resData?.data?.testuuId;
            this.testTitle = resData?.data?.testTitle;
            this.testDescription = resData?.data?.testDescription;
            this.testBankId = resData?.data?.testId;
            this.passTestDetailData = resData?.data;
            this.numberOfQuestions = resData?.data?.questions?.length;
            this.questions = new MatTableDataSource(resData?.data?.questions);
            this.itemBasicDetails['testTitle'] = resData?.data?.testTitle;
            this.itemBasicDetails.testDescription = resData?.data?.testDescription;
            // this.itemBasicDetails.collectionId = "10";
            this.itemBasicDetails.testuuid = this.testuuId;
            this.itemBasicDetails.statusName = resData?.data?.statusName;
            console.log("-------statusname", this.itemBasicDetails.statusName);
            if (this.itemBasicDetails.statusName == 'Published') {
              this.isPublished = true;
            } else {
              this.isPublished = false;
            }
            console.log('publish status =>', this.isPublished);
          }
        )
    }

    this.testService.updateTestStatus.subscribe((resData: any) => {


      if (resData == true) {
        this.itemBasicDetails.statusName = "Published";
      }
      else {
        // this.itemBasicDetails.statusName = "Unpublished";
      }
    });

    if (this.routerstate.snapshot.paramMap.get('id') === null) {

    } else {
      this.testuuId = this.routerstate.snapshot.paramMap.get('id');
      this.testServiceSubscription$ = this.testService.getItemByTestId(this.testuuId)?.subscribe(
        (data) => {
          console.log('--------->testId', data?.data);
          // this.testBankId = data ?.data[0].testId;
          this.testItems = data?.data;
          if (this.testItems?.length == 0) {
            this.isTestItemCount = "0";
          } else {
            console.log('TestId', this.testItems);
            console.log(this.testItems);
            this.testItems = new MatTableDataSource(this.testItems);
            this.isTestItemCount = this.testItems?.filteredData?.length;

            // this.testItems.sort = this.sort;
            // this.testItems.paginator = this.paginator;
            // this.isNoItem = false;
          }
        },
        (error) => {
          console.log(error);
          // this.router.navigate(["/login"]);
        },
        () => {
          console.log('item service subscription completed');
        }) || undefined;

    }

  }

  navigateToSelectQuestionsType() {
    this.router.navigate(["./selectquestiontype"], { relativeTo: this.routerstate });
  }
  navigateToItemLanding() {
    if (this.action === 'createTest') {
      this.router.navigate(["../test"], { relativeTo: this.routerstate });
    } else if (this.action === 'editTest') {
      this.router.navigate(["../../test"], { relativeTo: this.routerstate });
    } else {
      this.router.navigate(["../../test"], { relativeTo: this.routerstate });
    }

  }
  navigatetoTest() {
    this.router.navigate(["../../test"], { relativeTo: this.routerstate });
  }

  onChange(event: MatTabChangeEvent) {
    this.tab1 = true;
    this.tab = event.tab.textLabel;
    console.log(this.tab);
  }



  openEditTestDialog() {
    const dialogRef = this.dialog.open(TestsBasicDetailsComponent, {
      minWidth: '650px',
      data: {
        testuuId: this.testuuId1
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    // this.router.navigate(["../testsdetails"], { relativeTo: this.routerstate });
  }

}

