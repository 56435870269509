import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader$: BehaviorSubject<boolean>;

  private reqUrl = new BehaviorSubject<any>('');
  public getReqUrl = this.reqUrl.asObservable();

  constructor() {
    this.loader$ = new BehaviorSubject<boolean>(false);
  }

  sentReqUrl(url: any){
    // console.log(url);
    this.reqUrl.next(url);
  }

  public isLoading(): Observable<boolean> {
    return this.loader$;
  }

  public nextLoading(v: boolean): void {
    this.loader$.next(v);
  }
}
