<div class="close-button">
    <a>
      <mat-icon mat-dialog-close>close</mat-icon>
    </a>
</div>
<div class="mat-dialog" mat-dialog-content>
    <div class="main-heading">
    <label>Edit option</label>
    </div>
    
   
        <ngx-editor-menu  [editor]="editor" [toolbar]="toolbar">
          </ngx-editor-menu>
          <ngx-editor name='optionbox' [placeholder]="'Enter here...'" [(ngModel)]='option' [editor]="editor"></ngx-editor>

    
  
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" class="btn-blue btn-size" (click)="updateOption()">Save</button>
</div>
