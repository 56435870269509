<div class="settings-container" *ngIf="testSettingsForm">
  <div class="filter-section">
  </div>
  <!-- {{testSettingsForm.status}} -->
  <div class="frm-container">
    <form [formGroup]="testSettingsForm" novalidate>
      <div class="btn-wrapper">
        <button mat-flat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial class="btn-blue"
          aria-label="Save" (click)="finalSaveTestSettigs()"
          [disabled]="isPublished || testSettingsForm?.status === 'INVALID' ? true : false">Save</button>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <label> Test duration<span class="red-asterisk">*</span></label>
            <input [readonly]="isPublished" type="number" class="settingInput" formControlName="testDuration" min="0"
              max="9999" oninput="validity.valid||(value='')"
              [ngClass]="{'disabledInput':isPublished}"><span>min(s)</span>
            <div *ngIf="f.testDuration.invalid && (f.testDuration.dirty || f.testDuration.touched )"
              class="alert alert-danger">
              <div *ngIf="f.testDuration.errors?.required">Test duration is required</div>
              <div *ngIf="f.testDuration.errors?.min">Required fields cannot be non-zero</div>
            </div>
          </div>
          <div class="col-md-4">
            <label for=""> Passing score<span class="red-asterisk">*</span></label>
            <input [readonly]="isPublished" type="number" class="settingInput" formControlName="passingCriteria" min="0"
              max="9999" oninput="validity.valid||(value='')"
              [ngClass]="{'disabledInput':isPublished}"><span>mark(s)</span>
            <div *ngIf="f.passingCriteria.invalid && f.passingCriteria.touched" class="alert alert-danger">
              <div *ngIf="f.passingCriteria.errors?.required">Test passing score is required</div>
              <div *ngIf="f.passingCriteria.errors?.min">Required fields cannot be non-zero</div>
            </div>
          </div>
          <div class="col-md-4 status">
            <label for=""> Test status</label>
            <mat-form-field appearance="fill" class="test-status">
              <mat-select [(value)]="defaultStatus" [disabled]="isPublished"  >
                <mat-option *ngFor="let status of testStatusList" [value]="status?.itemstatus">
                  <p (click)="openConfirmationDialog(status)">{{status?.itemstatus}} </p>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-4">
            <div class="toggle-div">
              <label> Shuffle </label>
              <mat-slide-toggle formControlName="shuffle" class="example-margin" [disabled]="true">
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-md-4">
            <div class="toggle-div">
              <label> Calculator</label>
              <mat-slide-toggle formControlName="calculatorRequired" class="example-margin" [disabled]="true">
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="toggle-div">
              <label> Enable penalty points</label>
              <mat-slide-toggle formControlName="penaltyPoints" class="example-margin" [disabled]="true">
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-md-4">
            <div class="toggle-div">
              <label> Show item count</label>
              <mat-slide-toggle formControlName="showItemCount" class="example-margin" [disabled]="true">
              </mat-slide-toggle>
            </div>
          </div>
        </div> -->
      </div>
    </form>
  </div>

</div>