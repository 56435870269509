<!-- <div class="add-collection">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="collection-wrapper">
                    <div class="collection-data">
                        <div class="collection-actions">
                            <button class="btn-back" routerLink="/" mat-raised-button mat-flat-button color="basic">
                                <i class="fa fa-angle-left"></i>
                                <span>Back</span>
                            </button>
                        </div>
                        <div class="collection-tabs">
                            <mat-tab-group>
                                <mat-tab label="Details">
                                    <router-outlet></router-outlet>
                                </mat-tab>
                                <mat-tab label="Users"> Content 2 </mat-tab>
                                <mat-tab label="License"> Content 3 </mat-tab>
                                <mat-tab label="Other Options"> Content 3 </mat-tab>
                                <mat-tab label="Activity Log"> Content 3 </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

<router-outlet></router-outlet>