import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemService } from 'src/app/shared/item.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TestService } from 'src/app/shared/test.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Urldialog } from './url-dialog';
import { ScheduleCreateComponent } from '../schedule-create/schedule-create.component';
import { NewSchedulePopupComponent } from './new-schedule-popup/new-schedule-popup.component';
import { I } from '@angular/cdk/keycodes';

export interface testUrlPass {
  testUrl: string;
  schID: any;
}
@Component({
  selector: 'app-tests-schedule',
  templateUrl: './tests-schedule.component.html',
  styleUrls: ['./tests-schedule.component.scss'],
})
export class TestsScheduleComponent
  implements OnInit, OnDestroy {
  itemServiceSubscription$: Subscription | undefined;
  itemFilterServiceSubscription$: Subscription | undefined;
  schduleList: any;
  schedule: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'schedulename',
    'testType',
    'startdate',
    'enddate',
    'status',
    'examineecount',
    'examineeattempted',
    'testurl',
  ];
  //totalRecords: number;
  totalRecords: any;
  searchText: any;
  page: number = 1;
  itemName = '';
  itemDescription = '';
  itemStatus = '';
  isItemDescended = true;
  globalcollectionid: any;
  globalcollectionuuid: any;
  scheduleName = '';
  public config: any = {
    id: 'custom',
    itemsPerPage: 3,
    currentPage: 1,
  };
  testId: any;
  @Input() testuuId: any;
  @Input() testBankId: any;
  @Input() isPublished: any;

  setAddSchedule = false;
  pageOfItems: any;
  currentTestId: any;

  scheduleType = '';
  scheduleStatus = '';
  // scheduleName='';

  fromDate = '';
  toDate = '';
  filtertoDate = '';
  filterfromDate = '';
  isNoItems = false;
  schduletypeSearch = '';
  scheduleTestId: any;
  testIdArray: [];
  testUrl = 'http://tanaydemo.mundrisoftsolutions.com/';
  noDataFound: boolean = false;
  isEditEnabled = false;
  scheduleTypeInp: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private testService: TestService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {
    // console.log('------------------->ssssssssssss', this.isPublished);
    // this.testService.listMode.subscribe(data=>{
    //   if (data) {
    //     this.ngOnInit();
    //   }
    // })
    this.testService.scheduleBackBtnobs.subscribe((ResbackBtn) => {
      // console.log(ResbackBtn)
      this.setAddSchedule = ResbackBtn;
      this.isEditEnabled = ResbackBtn;
      // console.log(this.globalcollectionuuid)
      if (!ResbackBtn) {
        this.getTableData();
      }
    });
  }

  ngOnInit(): void {
    console.log('test schedule called many times');
    this.testId = this.testuuId;
    if (this.testId) {
      this.currentTestId = this.testuuId
    }
    this.globalcollectionid = JSON.parse(
      localStorage.getItem('defaultcollectionid')
    );
    this.globalcollectionuuid = JSON.parse(
      localStorage.getItem('defaultcollectionuuid')
    );

    if (this.currentTestId) {
      this.itemServiceSubscription$ =
        this.testService
          .getTestSchedule(this.globalcollectionuuid, this.currentTestId)
          ?.subscribe(
            (schedata) => {
              this.schedule = schedata?.data;
              console.log('data', schedata?.data);
              if (schedata?.data.length == 0) {
                this.isNoItems = true;
                console.log("yes");
              } else {
                console.log("No");
                this.schedule = new MatTableDataSource(schedata?.data);
                this.schedule.sort = this.sort;
                this.schedule.paginator = this.paginator;
                this.isNoItems = false;
                this.noDataFound = false
              }
            },
            (error) => {
              console.log('dj');
              console.log(error);
              this.router.navigate(['/login']);
            },
            () => {
              console.log('item service subscription completed');
            }
          ) || undefined;
    }
  }


  getTableData(): void {
    this.testId = localStorage.getItem('testuuid');
    this.globalcollectionuuid = JSON.parse(
      localStorage.getItem('defaultcollectionuuid')
    );
    this.itemServiceSubscription$ =
      this.testService
        .getTestSchedule(this.globalcollectionuuid, this.currentTestId)
        ?.subscribe(
          (schedata) => {
            this.schedule = schedata?.data;
            console.log('data', schedata?.data);
            if (schedata?.data.length == 0) {
              this.isNoItems = true;
              console.log("yes");
            } else {
              console.log("No");
              this.schedule = new MatTableDataSource(schedata?.data);
              this.schedule.sort = this.sort;
              this.schedule.paginator = this.paginator;
              this.isNoItems = false;
              this.noDataFound = false
            }
          },
          (error) => {
            console.log('dj');
            console.log(error);
            this.router.navigate(['/login']);
          },
          () => {
            console.log('item service subscription completed');
          }
        ) || undefined;
  }

  ngOnChanges(): void {



    this.testService.updateTestStatus.subscribe(isTestDisabled => {
      if (isTestDisabled == true) {
        this.isPublished = isTestDisabled;
      } else {
        this.isPublished = false;
      }
    });

    // if (this.tabData) {
    //   this.messageService.clearMessagesNext();
    //   this.messageFlag = false;
    // }

  }

  gotoExaminee(scheduleTestId: any): void {
    // localStorage.setItem('scheduleTestId',scheduleTestId)
    // this.router.navigate(['td/login/' , scheduleTestId]);
  }

  newSchedule(): void {
    this.testService.schedulUuid = '';
    this.isNoItems = false;
    this.setAddSchedule = true;

    // const dialogRef = this.dialog.open(ScheduleCreateComponent, {
    //   width: '60%',
    //   data: {
    //     schedule: null,
    //     type: 'add',
    //     isPublished: false
    //   },
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  closeNewTestSchedule(data: any): void {
    this.setAddSchedule = false;
    console.log("back button");
    this.ngOnInit();
  }

  newSchedulePOPUP() {
    const dialogRef = this.dialog.open(NewSchedulePopupComponent, {
      panelClass: 'dialog-container-custom',
      data: {
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

      }

      else {

      }
    });
  }
  statusChange(value) {
    alert(value);
  }

  filterSchedule(): void {
    this.filterfromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    if (this.filterfromDate == null) {
      this.filterfromDate = '';
    }

    this.filtertoDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
    if (this.filtertoDate == null) {
      this.filtertoDate = '';
    }
    if (this.scheduleType) {
      console.log("-------------------->", this.scheduleType);
      this.schduletypeSearch = this.scheduleType;
    }

    const filterObj = {
      scheduleType: this.scheduleType,
      scheduleStatus: this.scheduleStatus,
      scheduleName: this.scheduleName,
      fromDate: this.filterfromDate,
      todate: this.filtertoDate,
    };

    this.itemFilterServiceSubscription$ =
      this.testService
        .scheduleFilter(this.globalcollectionuuid, this.currentTestId, filterObj)
        ?.subscribe(
          (schedata) => {
            this.schedule = schedata?.data;



            if (schedata?.data == 0) {
              this.noDataFound = true;
              // this.schedule.paginator = 0;
              // console.log("Data Not FOund");
            }
            this.schedule = new MatTableDataSource(schedata?.data);
            this.schedule.sort = this.sort;
            this.schedule.paginator = this.paginator;

            /*  this.schduleList = new MatTableDataSource(this.schedule);
              this.schduleList.sort = this.sort;
              this.schduleList.paginator = this.paginator;*/
            // this.items = new MatTableDataSource(this.items);
            // this.items.sort = this.sort;
            // this.items.paginator = this.paginator;
          },
          (error) => {
            console.log('dj');
            console.log(error);
            this.router.navigate(['/login']);
          },
          () => {
            console.log('item service subscription completed');
          }
        ) || undefined;
    this.noDataFound = false;
  }
  // onChangePage(pageOfItems: Array<any>) {
  //   console.log('function call');
  //   this.totalRecords = this.items.length;
  //   console.log(this.totalRecords);
  // }

  // dateSort(): void {
  //   this.isItemDescended = !this.isItemDescended;
  //   this.items.sort(this.sortdate);
  //   console.log(this.items);
  // }

  sortdate(a: any, b: any) {
    //return new Date(a.updated).getTime() - new Date(b.updated).getTime();
    //return new Date(a.updated).getTime() - new Date(b.updated).getTime() ;
    return b.updated > a.updated;
  }

  resetFilter() {
    this.scheduleType = '';
    this.scheduleStatus = '';
    this.scheduleName = '';

    this.fromDate = '';
    this.toDate = '';
    this.filtertoDate = '';
    this.filterfromDate = '';

    this.schduletypeSearch = '';
    this.itemServiceSubscription$ =
      this.testService
        .getTestSchedule(this.globalcollectionuuid, this.currentTestId)
        .subscribe(
          (schedata) => {
            this.schedule = schedata?.data;
            this.schedule = new MatTableDataSource(schedata?.data);
            this.schedule.sort = this.sort;
            this.schedule.paginator = this.paginator;
            /* this.schduleList = new MatTableDataSource(this.schedule);
             this.schduleList.sort = this.sort;
             this.schduleList.paginator = this.paginator;*/
          },
          (error) => {
            console.log('dj');
            console.log(error);
            this.router.navigate(['/login']);
          },
          () => {
            console.log('item service subscription completed');
          }
        ) || undefined;
    this.noDataFound = false;
  }

  navigateToScheduleDetails(row) {
    // this.router.navigate(['/collections',this.globalcollectionid, 'scheduledetails',id]);
    // this.setAddSchedule = true;
    // this.testService.schedulUuid = id;
    this.setAddSchedule = false;
    this.isEditEnabled = true;
    const scheduleInfo = {
      scheduleuuId: row?.scheduleuuId,
      testScheduleId: row?.testScheduleId,
      scheduleType: row?.scheduleType,
      scheduleStatus: row?.scheduleStatus
    }
    localStorage.setItem('scheduleInfo', JSON.stringify(scheduleInfo));
  }

  openTestUrlDialog(testUrl: string) {
    // localStorage.setItem('scheduleTestId',scheduleTestId)

    console.log(testUrl);
    const dialogRef = this.dialog.open(Urldialog, {
      width: '60%',
      data: {
        testUrl: testUrl,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnDestroy() {
    if (this.itemServiceSubscription$) {
      this.itemServiceSubscription$.unsubscribe();
    }
    if (this.itemFilterServiceSubscription$) {
      this.itemFilterServiceSubscription$.unsubscribe();
    }
    this.isPublished = null;
  }
}
