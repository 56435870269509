<div class="module-container">
  

  <ng-container *ngIf="action==='createItem'">
     <app-item-basic-details [itemBasicDetails]='itemBasicDetails' [testId]="testId">
      </app-item-basic-details>
  </ng-container>

  <ng-container *ngIf="action==='editItem'">
      <app-item-basic-details [itemBasicDetails]='itemBasicDetails' [testId]="testId">
      </app-item-basic-details>
  </ng-container>
</div>



