import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { ItemsUploadService } from '../../items-upload.service';
import { saveAs } from "file-saver";
import { of, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/shared/notification.service';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

export interface ReportData {
  indexNo: number;
  rowNo: number;
  errorComment: string;
}
@Component({
  selector: 'app-add-multiple-item',
  templateUrl: './add-multiple-item.component.html',
  styleUrls: ['./add-multiple-item.component.scss']
})
export class AddMultipleItemComponent implements OnInit {

  dataSource: MatTableDataSource<ReportData>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) set content(sort: MatPaginator) {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = ['indexNo', 'rowNo', 'errorComment'];
  examineeData$: Subscription | undefined;
  isNoError = false

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef; files = [];
  uploadFileForm: FormGroup;
  uploadSuccess = false;
  uploadResponse: any = 'no status';
  eventData: any;
  uploadEmpty:boolean = false;
  emptyMsg: any;
  errorHeading: any;
  errorMsg:[];

  constructor(private location: Location, private itemUpload : ItemsUploadService,
    private notificationService : NotificationService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.uploadFileForm = this.fb.group({
      file: ['']

    })
  }

  // Back to item-management page
  goToPrevious(){
    this.location.back();
  }

  download(): void {
    let fileName = 'ItemsTemplate';
    let checkFileType = '.xml';
    var fileType = ""
    if (checkFileType == ".xml") {
      fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    }
    this.itemUpload.DownloadFile(fileName, fileType)
      .subscribe(
        success => {
          console.log(success);
          saveAs(success, fileName);
        },
        err => {
          alert("Server error while downloading file.");
        }
      );
  }

  uploadExcelFile(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push(file);
      }
      this.uploadFiles();
    };
  }

  private uploadFiles(): void {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  uploadFile(file): void {

    const formData = new FormData();
    formData.append('files', file);
    this.examineeData$ = this.itemUpload.upload(formData).subscribe(
      event => {
        console.log(this.eventData?.failedData,"data");
        this.eventData = event;
        if (this.eventData?.failedData?.length == 0 || this.eventData?.failedData?.length == null){
          console.log('no record found');
          this.isNoError = true;
          this.uploadSuccess = true;
          this.uploadEmpty =  true;
          this.uploadResponse = event;
          console.log(this.uploadResponse)
          this.dataSource = new MatTableDataSource(this.uploadResponse?.failedData);
        }
        else{
          console.log('record found');
          this.uploadSuccess = false;
          this.isNoError = false;
          this.uploadResponse = event;
          console.log(this.uploadResponse)
          this.dataSource = new MatTableDataSource(this.uploadResponse?.failedData);
          this.dataSource.paginator = this.paginator;
          if(this.uploadResponse?.totalNoOfRecords == 0){
            this.emptyMsg = "Error: " + this.uploadResponse?.failedData[0]?.message;
            this.errorHeading = "Error Report"
        }
      }
    },
      error => {
        this.notificationService.warn(error?.error?.message); 
      });
      //),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return of(`${file.data.name} upload failed.`);
      });
  }

}
