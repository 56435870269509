<div class="module-wrapper content-wrapper">
    <mat-card>
        <mat-card-content>
            <div class="head-container">
                <div>
                    <h1>Items Management</h1>

                    <div class="breadcrumb-container">
                        <a (click)="goToPrevious()" class="link-active">Item management</a> /
                        <span class="nav-link">Multiple items</span>
                    </div>
                </div>
            </div>
            <div class="main-content-wrapper item-bank-details-container">
                <div class="content-wrapper">
                    <div class="container-fluid">
                        <div class="row ">
                            <div class="col-md-12 border-top">
                                <div class="download-upload-class">
                                    <div class="download">
                                        <button class="btn-white" (click)="download()" mat-flat-button>
                                            <span> Download item list template</span>
                                        </button>

                                    </div>
                                    <div class="upload">
                                        <form [formGroup]="uploadFileForm" enctype="multipart/form-data">
                                            <button class="btn-orange" (click)="uploadExcelFile()"  mat-flat-button>
                                                <input type="file" #fileUpload multiple="multiple" formControlName="file" />
                                                <span> Upload Item(s)</span>
                                            </button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--  -->
                        <div class="row ">
                            <div class="col-12">

                                <ng-container *ngIf="dataSource && !emptyMsg then showTable else hideTable"></ng-container>
                                <ng-template #showTable>
                                    <div *ngIf="uploadSuccess == false">
                                        <div class="upload-status-false">
                                            <p>{{ uploadResponse?.uploadStatus | titlecase }}</p>
                                        </div>
                                        <p>Total No. of records: {{uploadResponse?.totalNoOfRecords}}</p>
                                        <p>Total No. of records imported successfully: {{uploadResponse?.totalNoOfSuccessfullRecords}}
                                        </p>
                                    </div>
                                    <div *ngIf="uploadSuccess== true">
                                        <div>
                                            <p class="upload-status">{{ uploadResponse?.uploadStatus | titlecase }}</p>

                                        </div>
                                        <p>Total No. of records: {{uploadResponse?.totalNoOfRecords}}</p>
                                        <p>Total No. of records imported successfully: {{uploadResponse?.totalNoOfSuccessfullRecords}}
                                        </p>
                                    </div>
                                    <div class="empty-msg" *ngIf="uploadSuccess== false">
                                        <p>Error Report</p>
                                    </div>
                                    <div  class="empty-msg" *ngIf="uploadEmpty== true && uploadSuccess== false">
                                        No records present for upload
                                    </div>


                                    <div *ngIf="!isNoError" class="report-table">
                                        <div class=" table-container">
                                            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                                                <ng-container matColumnDef="indexNo">
                                                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Serial number </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row?.rowNumber-1}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="rowNo">
                                                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Row number </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row?.rowNumber}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="errorComment">
                                                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Error comments</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <ul class="errMsg-wrapper">
                                                          <li *ngFor="let errMsg of row?.message?.split('.') let i=index" [class.empty]="errMsg.length < 1">
                                                            <span>{{ errMsg }}.</span>
                                                          </li>
                                                        </ul>
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-data">
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="examinee-paginator">
                                          <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
                                        </div>
                                    </div>

                                </ng-template>
                            </div>

                            <ng-template #hideTable>
                                <div *ngIf="uploadSuccess== false">
                                    <div class="upload-status-false">
                                        <p>{{ uploadResponse?.uploadStatus | titlecase }}</p>
                                    </div>
                                </div>
                                <div class="upload-status-msg">
                                    <div class="empty-msg">
                                        <p>{{errorHeading}}</p>
                                    </div>
                                    <p>{{emptyMsg}}</p>
                                </div>
                            </ng-template>

                        </div>
                        <!--  -->
                    </div>

                </div>

            </div>
        </mat-card-content>
    </mat-card>
</div>
