<div class="module-wrapper content-wrapper">
  <mat-card>
    <mat-card-content>
      <div class="head-container">
        <h1>Item Management</h1>
        <span class="spacer"></span>
        <div>
          <div class="btn-wrapper">
            <button mat-flat-button color="warn" aria-label="Add New Item" (click)="openNewItemDialog()"> + New Item</button>
              <button mat-flat-button aria-label="Add Multiple Items" (click)="addMultipleItems()"><span>+ Multiple Items</span></button>
          </div>
        </div>
      </div>
      <!-- <div class="input-container">
        <form class="input-fields-wrapper" [formGroup]="filterForm" (ngSubmit)="itemFilter()">
          <div class="input-wrapper">
            <input type="text" formControlName='itemName' placeholder="Find by Item name"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <input type="text" formControlName='itemDescription' placeholder="Find by Description"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <input type="text" formControlName='tag' placeholder="Find by Tag"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <select aria-placeholder="Status" formControlName='itemStatus'
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
              <option value="" ng-reflect-value="">Status</option>
              <option value="Published" ng-reflect-value="Published">Published</option>
              <option value="Unpublished" ng-reflect-value="Unpublished">Unpublished</option>
            </select>
          </div>
          <div class="btn-wrapper">
            <button mat-flat-button color="primary" class="btn-blue" (click)='itemFilter()'
              aria-label="Search">Search</button> 
            <button mat-flat-button color="basic" class="btn-flat" (click)='resetFilter()'
              aria-label="Reset">Reset</button>
          </div>
        </form>
      </div> -->

      <div class="input-container">
        <div class="input-fields-wrapper">
          <div class="input-wrapper">
            <input type="text"  placeholder="Find by Item name" [(ngModel)]="itemName" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <input type="text" placeholder="Find by description"[(ngModel)]="itemDescription" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <input type="text" placeholder="Find by Tag" [(ngModel)]="tag" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <select aria-placeholder="Status" [(ngModel)]="itemStatus" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
              <option value="">Status</option>
              <option *ngFor="let statusList of statusArray" value="{{ statusList.status }}">
                {{ statusList.status }}
              </option>
              <!-- <option value="" ng-reflect-value="">Status</option>
              <option value="Published" ng-reflect-value="Published">Published</option>
              <option value="Unpublished" ng-reflect-value="Unpublished">Unpublished</option> -->
            </select>
          </div>
          <div class="btn-wrapper">
            <button mat-flat-button color="primary"  class="btn-blue" (click)="itemFilter()" aria-label="Search">Search</button>
            <button mat-flat-button color="basic" class="btn-flat" (click)="resetFilter()" aria-label="Reset">Reset</button>
          </div>
        </div>
      </div>

      <div *ngIf="isNoItems" class="data-not-found-container table-container">
        <span>No Items in this Collection</span>
      </div>
      <div class="d-none" [class.d-block]="items"  *ngIf="!isNoItems" >
        <div class="table-container">
          <table mat-table [dataSource]="items" matSort class="mat-elevation-z8">
       
            <ng-container matColumnDef="itemBankName">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Item(s)</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.itemBankName?.length > 40 then sliceTitle else noSliceTitle">
                </ng-container>
                
                <ng-template #sliceTitle>
                  <span class="text-bold" matTooltipClass="my-custom-tooltip" matTooltip="{{row?.itemBankName}}"
                    matTooltipPosition="above">
                    {{row?.itemBankName | slice: 0:40}}...</span>
                </ng-template>
                <ng-template #noSliceTitle>
                  <span class="text-bold">{{row?.itemBankName }}</span>
                </ng-template>

                <ng-container *ngIf="row?.itemDescription?.length > 40 then sliceDescription else noSliceDescription">
                </ng-container>
                <ng-template #sliceDescription>
                  <span matTooltipClass="my-custom-tooltip" matTooltip="{{row?.itemDescription}}"
                    matTooltipPosition="above" class="description-text">
                    {{row?.itemDescription | slice: 0:40}}...</span>
                </ng-template>
                <ng-template #noSliceDescription>
                  <span class="description-text">{{row?.itemDescription }}</span>
                </ng-template>
              </td>
            </ng-container>

            <ng-container matColumnDef="questionTypes">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Question type(s)</th>
              <td mat-cell *matCellDef="let row">{{row.questionTypes}}
              </td>
            </ng-container>

            <ng-container matColumnDef="itemStatus">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row?.statusName === 'Published'" class="fa fas fa-circle publish" matTooltip="Published"
                  matTooltipPosition="above">
                </span>
                <span *ngIf="row?.statusName === 'Unpublished'" class="glyphicon glyphicon-adjust unpublish"
                  matTooltip="Unpublished" matTooltipPosition="above"></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="updated">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Updated</th>
              <td mat-cell *matCellDef="let row">
                <span class="text-bold"> {{row?.modifiedUserName }} </span> <span> {{row.updated | dateAgo}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag(s)</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.tagdescription?.length > 2; then Slicetag; else noSlicetag"></ng-container>
                <ng-template #Slicetag>
                  <div class="tag-data">
                    <table class="border-0">
                      <tr *ngFor="let ele of row.tagdescription">
                        <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:40)+'...'"
                          matTooltipPosition="above" matTooltip="{{ele}}" matTooltipClass="my-custom-tooltip"></td>
                        <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                      </tr>
                    </table>
                  </div>
                </ng-template>
                <ng-template #noSlicetag>
                  <table class="border-0">
                    <tr *ngFor="let ele of row?.tagdescription" >
                      <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:40)+'...'" matTooltip="{{ele}}"
                        matTooltipPosition="above" matTooltipClass="my-custom-tooltip"></td>
                      <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                    </tr>
                  </table>
                </ng-template>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
              <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item >
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="pointer" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToItemDetails(row.itemuuid,row.itemBankId)"></tr>
          </table>
          <div class="data-not-found-container" *ngIf='noDataFound'>
            <p>Data not found</p>
          </div>
        </div>
        <div class="item-paginator">
          <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
