import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CollectionService } from 'src/app/shared/collection.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShareDataService } from 'src/app/shared/share-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-collection',
  templateUrl: './add-collection.component.html',
  styleUrls: ['./add-collection.component.scss'],
})
export class AddCollectionComponent implements OnInit, OnChanges, OnDestroy {
  // variable declaration
  timeZone: string;
  language: string;
  languagesData$: Subscription | undefined;
  timeZoneData$: Subscription | undefined;
  collectionPostData$: Subscription | undefined;
  languageArr = [];
  timeZoneArr = [];
  userForm: FormGroup;
  selectedValue:Number;
  selectedTimezone;
  
  constructor(
    private router: Router,
    private collectionService: CollectionService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AddCollectionComponent>
  ) { }

  ngOnInit(): void {
    this.languagesData$ = this.collectionService
      .getLanguages()
      ?.subscribe((languagesData: any) => {
        this.languageArr = languagesData?.items;
        this.selectedValue = 1;
      });
    this.timeZoneData$ = this.collectionService
      .getTimeZone()
      ?.subscribe((timeZoneData: any) => {
        this.timeZoneArr = timeZoneData?.items;
        this.selectedTimezone = 'Asia/Kolkata';
        // this.selectedTimezone = 283;
        // console.log(this.timeZoneArr);
        
      });
    this.userForm = new FormGroup({
      collectionName: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(120),
        Validators.pattern(/^[ a-zA-Z.-]+([ ]?[a-z0-9 !#|^*()-_@$!%*?&]){0,}$/)
      ]),
      description: new FormControl('',[
         Validators.minLength(1),
         Validators.maxLength(480),
        ]),
      langid: new FormControl('', [Validators.required]),
      timeZoneName: new FormControl('', [Validators.required]),
    });
  }

  // This function used for validation purpose
  get f(): any {
    return this.userForm.controls;
  }

  ngOnChanges(): void { }

  saveBtnFn(): void {
    // console.log(this.userForm.value)
    if (this.userForm.value.description != null) {
      this.userForm.value.description = this.userForm.value.description.trim();
    } else {
      this.userForm.value.description = this.userForm.value.description;
    }
    const apiData = {
      collectionName: this.userForm.value.collectionName.trim(),
      description: this.userForm.value.description,
      langid: this.userForm.value.langid,
      timezoneName: this.userForm.value.timeZoneName,

    };
    this.collectionPostData$ = this.collectionService
      .postCollection(apiData)
      ?.subscribe(
        (resData) => {
          this.refershToken();
          // console.log('response form server', resData);
          this.notificationService.success(resData?.message);
          const id = JSON.stringify(resData?.data?.collectionuuId);
          localStorage.setItem('defaultcollectionuuid', id);
          localStorage.setItem('defaultcollectionid', resData?.data?.collectionid);
          this.dialogRef.close();
          // this.router.navigate([
          //   '/collections',
          //   resData?.data.collectionuuId,
          //   'edit',
          // ]);
        },
        (error) => {
          this.notificationService.warn(error?.error?.message);
          // this.userForm.reset();
        }
      );
  }
  refershToken() {
    this.collectionService.refershToken()?.subscribe(resData => {
      this.collectionService.getCollection()?.subscribe(resData => {
        this.collectionService.newCollectionList(resData);
      });
    });
  }
  ngOnDestroy(): void {
    this.languagesData$?.unsubscribe();
    this.timeZoneData$?.unsubscribe();
    this.collectionPostData$?.unsubscribe();
  }
}
