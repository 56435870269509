import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/message.service';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { ItemService } from 'src/app/shared/item.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';
import { FlipitemtabService } from 'src/app/tests/flipitemtab.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-item-settings',
  templateUrl: './item-settings.component.html',
  styleUrls: ['./item-settings.component.scss']
})
export class ItemSettingsComponent implements OnInit, OnChanges {

  selectLevels: any;
  selectStatus: any = [];
  collectionId: any;
  collectionuuId: any;
  selectedLevelID: any;
  itemStatusId: any;
  shuffle = false;
  cid: any;
  @Input() isPublished = false;
  defaultStatus: any;
  defaultLevel: any;
  @Input() itemId: any;
  @Input() item: any;
  @Input() testId;
  isPublishedStatus: any;
  isUnpublishedStatus: any;
  // testId: any;
  itemIds: any = [];
  itemJsonData: any = [];
  itemBankId: any;
  testId1: any;


  constructor(private itemService: ItemService, private testService: TestService,
    private flipitemtabService: FlipitemtabService, private messageService: MessageService,
    private router: Router,
    private routerstate: ActivatedRoute, private actRoute: ActivatedRoute,
    private notificationServices: NotificationService,
    private dialog: MatDialog,
  ) {

  }

  onChange(value: any) {
    if (value.checked == true) {
      this.shuffle = true;
    } else {
      this.shuffle = false;
    }
  }

  ngOnInit(): void {
    this.collectionuuId = JSON.parse(localStorage.getItem('defaultcollectionuuid'));
    if (this.testId) {
      localStorage.setItem('TestId', this.testId)
    }

  }
  ngOnChanges(): void {

    this.itemService.getDifficultyLevel().subscribe((getLevel) => {
      console.log(getLevel);
      this.selectLevels = getLevel?.items;
      this.selectLevels.sort(function (a, b) { return a.difficultyLevelId - b.difficultyLevelId });
      this.selectedLevelID = this.selectLevels[0]?.difficultyLevelId  // By default it is showing difficultylevel 1
    });

    this.itemService.getItemtatus().subscribe((getStatus) => {
      this.selectStatus = getStatus?.items;
      this.defaultStatus = getStatus?.items[1]?.itemstatus;  // By default it is showing Unpublished status
      this.itemStatusId = getStatus?.items[1]?.itemStatusId;
      this.isPublishedStatus = getStatus?.items[0]?.itemstatus;
      this.isUnpublishedStatus = getStatus?.items[1]?.itemstatus;
    });

    if (this.itemId != undefined) {
      this.itemService.getItemDetails(this.itemId).subscribe(resData => {
        this.selectedLevelID = resData?.data?.difficultylevelId;
        this.defaultStatus = resData?.data?.statusName;
        this.shuffle = resData?.data?.shuffle;
      })
    }
  }


  saveSettings() {
    console.log("i ANM HEREE");
    this.itemService.AddItemSetting(this.itemId, this.selectedLevelID, this.itemStatusId, this.shuffle, this.collectionuuId).subscribe((resData) => {
      if (this.testId) {
        return;
      } else {
        this.notificationServices.success(resData?.message);
      }
      this.selectStatus?.forEach((statusData: any) => {
        if (resData?.data?.statusId == statusData?.itemStatusId) {
          this.defaultStatus = statusData?.itemstatus // this is for selected status
        }

      });
      this.selectedLevelID = resData?.data?.difficultylevelId  // this is for selected difficulty level id
      if (this.defaultStatus == 'Published') {
        this.isPublished = true;
      } else {
        this.isPublished = false;
      }
      console.log(this.defaultStatus);
      this.itemService.updateItemStatus(this.isPublished);

    },
      error => {
        this.notificationServices.warn(error.error.message); // This is used to display error message.
      })

    // Redirect to test details when we create to new item from test details

    this.testId = localStorage.getItem('TestId');

    if (this.defaultStatus == this.isPublishedStatus) {
      if (this.testId) {
        console.log(this.testId)
        this.router.navigate(['/collections', this.collectionuuId, 'tests', this.testId]);

        this.itemService.getItemtatus().subscribe((getStatus) => {
          this.isPublishedStatus = getStatus?.items[0]?.itemstatus;
        })
        const itemID = this.actRoute.snapshot.paramMap.get('id');
        this.itemService.getItemDetails(itemID)?.subscribe(
          (data) => {
            console.log('ItemSetting', data)

            this.itemJsonData = [];
            const itemBankIds = this.itemId;
            this.itemJsonData.push({
              itemBankuuId: itemID,
              itemRenderOrder: 0
            });

            this.testService.addItemToTest1(this.testId, this.itemJsonData)?.subscribe(resData => {
              console.log('resdata', resData);
              if (resData?.data?.length > 0) {
                this.notificationServices.success(resData?.message);
                this.flipitemtabService.emit<boolean>(false);
              }
            }, (error) => {
              this.notificationServices.warn(error.error.message);
              console.log(error.error.message);
            });
          })
      }
      localStorage.removeItem('TestId');
    }


  }
  updateLevel(levelId: any): void {
    this.selectedLevelID = levelId
  }


  updateStatus(selectedStatus: any): void {
    console.log(selectedStatus.itemstatus);
    if (selectedStatus.itemstatus == 'Published') {
      const dialogRef = this.dialog.open(DelMessageComponent, {
        panelClass: 'dialog-container-custom',
        data: {
          message: 'Are you sure you want to publish this Item?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });
      selectedStatus = this.selectStatus;
      console.log(selectedStatus);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.itemStatusId = selectedStatus[0].itemStatusId
          console.log(selectedStatus?.itemstatus);
        } else {
          this.defaultStatus = selectedStatus[1].itemstatus
        }
      });
    } else {
      console.log(this.selectStatus);

      this.itemStatusId = this.selectStatus[1].itemStatusId
    }
  }
}
