<!-- <div *ngIf="display && isPublished">
  <app-tests-schedule [testuuId]='currentTestId' [testBankId]='testBankId' [isPublished]='isPublished'>
  </app-tests-schedule>
</div> -->

<div class="main-content-wrapper add-test-schedule-container" *ngIf="!display">
  <div class="content-wrapper">
    <div class="">

      <form [formGroup]="scheduleForm" *ngIf="loadLandingPage==false">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-md-12">
              <div class="btn-wrapper">
                <button mat-flat-button class="btn-back" color="warn" aria-label="Back"
                  (click)="gotoTestSchedule()"><span>Back</span></button>
                <span class="spacer"></span>
                <!-- <button mat-flat-button class="btn-bg-color" (click)="cancleTestSchedule()" *ngIf="action" [disabled]="scheduleStatus">Cancel Schedule</button>
                  <button mat-flat-button class="btn-bg-color" [disabled]="!scheduleForm.valid || scheduleStatus" (click)="saveTestSchedule()" >Save</button> -->
                <button mat-flat-button color="primary" (click)="cancleTestSchedule()" *ngIf="action"
                  [disabled]="scheduleStatus" class="btn-blue" aria-label="Cancel Schedule">Cancel Schedule</button>
                <button mat-flat-button color="primary"
                  [disabled]="(!scheduleForm.valid  ||  !(f.testType.value ? true : (getSelectedChallenges().length > 0 ?  true : false)) || scheduleStatus )"
                  (click)="saveTestSchedule()" class="btn-blue" aria-label="Save">Save</button>
              </div>
         
              <!-- [disabled]="!scheduleForm.valid || scheduleStatus ||  checkTypeData()" -->
              <div class="frm-container">
                <div class="input-class">
                  <label>Schedule name<span class="red-asterisk">*</span></label>
                  <input class="form-control" type="text" maxlength="121" formControlName="scheduleName"
                    (focus)='onTestNameControlFocus()' [ngClass]="{ 'is-invalid': submitted && f.scheduleName.errors }">
                  <div *ngIf="f.scheduleName.invalid && (f.scheduleName.dirty || f.scheduleName.touched)"
                    class="alert alert-danger">
                    <div *ngIf="f.scheduleName.errors?.required"> Schedule name is required</div>
                    <div *ngIf="f.scheduleName.errors?.minlength"> Minimum 3 characters required</div>
                    <div *ngIf="f.scheduleName.errors?.maxlength"> Maximum 120 characters allowed</div>
                  </div>



                </div>
                <div class="input-class">
                  <label>Schedule description</label>
                  <textarea class="form-control" type="text"  value="" name="testDescription"
                    formControlName="scheduleDesc"></textarea>
                  <!-- <div *ngIf="f.scheduleDesc.invalid && (f.scheduleDesc.dirty || f.scheduleDesc.touched )"
                    class="alert alert-danger">

                    <div *ngIf="f.scheduleDesc.errors?.minlength">Minimum 20 characters required</div>
                    <div *ngIf="f.scheduleDesc.errors?.maxlength">Maximum 480 characters allowed</div>
                  </div> -->
                </div>

              </div>
              <div class="filter-section title"> Details</div>
              <div class="type">

                <mat-radio-group formControlName="testType" (change)="changeSchduleType($event.value)"
                  [(ngModel)]="showExaminee" [disabled]="this.action">
                  <mat-radio-button [value]="true" [checked]="showExaminee? 'true':'false'"> Public</mat-radio-button>
                  <mat-radio-button [value]="false" [checked]="showExaminee? 'false':'true'">Private</mat-radio-button>

                </mat-radio-group>
                <!-- <div *ngIf="f.testType.invalid && (f.testType.dirty || f.testType.touched )" class="alert alert-danger">
                    <div *ngIf="f.testType.errors?.required">TestType is required.</div>

                  </div>  -->



              </div>
            </div>
          </div>

          <div class="row" [hidden]="showExaminee==true">
            <div class="col-md-12 showExaminee">
              <mat-form-field fxFlex appearance="standard">
                <mat-label>Add recipient <span class="red-asterisk">*</span></mat-label>
                <mat-chip-list #chipList aria-label="Challenge selection" [multiple]="false">
                  <mat-chip [value]="c" *ngFor="let c of getSelectedChallenges()" [removable]="true"
                    [selected]="chipSelection.isSelected(c)" [attr.aria-selected]="chipSelection.isSelected(c)"
                    disableRipple="true" (selectionChange)="chipListChanged($event, c)" (removed)="removeChallenge(c)"
                    (click)="chipSelection.toggle(c)" (keyup.enter)="chipSelection.toggle(c)">
                    {{ c.emailId }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>

                  <input type="text" aria-label="Challenges" autoActiveFirstOption [formControl]="challengeInput"
                    [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addChallenge($event)" value="">
                </mat-chip-list>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="onOptionSelected($event)">
                  <mat-option *ngFor="let option of filteredChallenges | async" [value]="getSelectedChallenges">
                    <div (click)="onChallengeSelected($event, option)">
                      <mat-checkbox [checked]="chipSelection.isSelected(option)"
                        (change)="handleCheckboxCheck($event, option)" (click)="$event.stopPropagation()">
                        {{ option.firstName }} ({{option.emailId}})
                      </mat-checkbox>
                    </div>
                  </mat-option>
                </mat-autocomplete>
                <!-- <div *ngIf="f.testType.invalid && (f.testType.dirty || f.testType.touched )" class="alert alert-danger">
                    <div *ngIf="f.testType.errors?.required">Select Examinee is required.</div>

                  </div> -->
              </mat-form-field>

            </div>
          </div>
          <div class="row date-time-field">
            <div class="col-md-2 date-class">
              <div class="input-class">
                <label for="">Start Date<span class="red-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="fill">
                  <!-- <mat-label>Start date</mat-label> -->
                  <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="startDate" />
                  <!-- <input matInput [matDatepicker]="picker" formControlName="startDate"> -->
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>

                  <mat-datepicker #picker></mat-datepicker>
                  <div *ngIf="f.startDate.invalid && (f.startDate.dirty || f.startDate.touched )"
                    class="alert alert-danger">
                    <div *ngIf="f.startDate.errors?.required">StartDate is required</div>

                  </div>
                </mat-form-field>


              </div>
            </div>
            <div class="col-md-2 date-class">
              <div class="input-time">
                <label class="timepicker" for="">Start Time <span class="red-asterisk">*</span></label>
                <ngx-timepicker-field [format]="24" aria-disabled="true" [defaultTime]="'00:00'"
                  formControlName="startTime">
                </ngx-timepicker-field>
                <div *ngIf="f.startTime.invalid && (f.startTime.dirty || f.startTime.touched ) "
                  class="alert alert-danger">
                  <div *ngIf="f.startTime.errors?.required">StartTime is required</div>

                </div>
              </div>
            </div>

            <div class="col-md-2 date-class">
              <div class="input-class">
                <label for="">End Date<span class="red-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="fill">
                  <!-- <mat-label>End date</mat-label> -->
                  <input matInput [matDatepicker]="picker1" [min]="minDate1" formControlName="endDate" />
                  <!-- <input matInput [matDatepicker]="picker1" formControlName="endDate"> -->
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <div *ngIf="f.endDate.invalid && (f.endDate.dirty || f.endDate.touched )" class="alert alert-danger">
                  <div *ngIf="f.endDate.errors?.required">EndDate is required</div>

                </div>
              </div>
            </div>
            <div class="col-md-2 date-class">
              <div class="input-time">
                <label class="timepicker" for="">End Time <span class="red-asterisk">*</span></label>
                <ngx-timepicker-field [format]="24" [defaultTime]="'00:00'" formControlName="endTime">
                </ngx-timepicker-field>
                <div *ngIf="f.endTime.invalid && (f.endTime.dirty || f.endTime.touched )" class="alert alert-danger">
                  <div *ngIf="f.endTime.errors?.required">EndTime is required</div>

                </div>
              </div>
            </div>
            <div class="col-md-3 date-class">
              <div class="input-class">
                <label class="timepicker mb-28" for="">Time Zone <span class="red-asterisk">*</span></label>
                <select class="form-control timeZ" name="timezone" formControlName="timezoneid" [value]="zone">
                  <option value="">Select Time Zone</option>
                 <!-- <option *ngFor="let option of timeZoneArr; let i = index" [value]="option.standardname">
                    {{option?.standardname}}
                  </option>-->
                  <option *ngFor="let option of timeZoneArr; let i = index" [value]="option.name">
                    {{option?.name}}
                  </option>
                </select>
                <div *ngIf="f.timezoneid.invalid && (f.timezoneid.dirty || f.timezoneid.touched )"
                  class="alert alert-danger">
                  <div *ngIf="f.timezoneid.errors?.required">TimeZone is required</div>

                </div>
              </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="input-class">
                  <label for="">Time Zone <span class="red-asterisk">*</span></label>
                  <select class="form-control" name="timezone" formControlName="timezoneid">
                    <option *ngFor="let option of timeZoneArr" [value]="addTestSchedule.timeZonesName">
                      {{addTestSchedule.timeZonesName}}
                    </option>
                  </select>
                </div>
              </div> -->


          </div>
          <div class="filter-section title"> Settings</div>
          <div class="row">
            <div class="col-md-2">
              <div class="setting-label">
                <label for=""> Test duration <span class="red-asterisk">*</span></label>

              </div>
            </div>
            <div class="col-md-2">
              <input type="number" class="settingInput" formControlName="testDuration" min="0" max="9999"
                oninput="validity.valid||(value='');"><span>mins</span>
              <div *ngIf="f.testDuration.invalid && (f.testDuration.dirty || f.testDuration.touched )"
                class="alert alert-danger">
                <div *ngIf="f.testDuration.errors?.required">Testduration is required</div>

              </div>

            </div>
            <div class="col-md-8">
              <div class="switch-op">
                <label for="">Examinee rejoin the test</label>
                <mat-slide-toggle ngDefaultControl [(ngModel)]="addTestSchedule.examineeRejoin"
                  (change)="testRejoinChange($event)" formControlName="rejoinTest" [disabled]="true">
                </mat-slide-toggle>

              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="setting-label">
                <label for=""> Total test score <span class="red-asterisk">*</span></label>
              </div>
            </div>
            <div class="col-md-2">
              <input type="number" class="settingInput" formControlName="totalTestScore" readonly><span>mark(s)</span>
              <div *ngIf="f.totalTestScore.invalid && (f.totalTestScore.dirty || f.totalTestScore.touched )"
                class="alert alert-danger">
                <div *ngIf="f.totalTestScore.errors?.required">Test score is required</div>

              </div>
            </div>
            <div class="col-md-8">
              <!-- <div class="switch-op">
                <label for="">Show result after the test</label>
                <mat-slide-toggle ngDefaultControl [(ngModel)]="addTestSchedule.showResultAfterTest"
                  (change)="resultAfterTest($event)" formControlName="showResultAfterTest" [disabled]="true"></mat-slide-toggle>

              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="setting-label">
                <label for="">Test passing score <span class="red-asterisk">*</span></label>

              </div>

            </div>
            <div class="col-md-2">
              <input type="number" class="settingInput" (change)="testScoreChange()" formControlName="totalPassingScore"
                min="0" max="9999" oninput="validity.valid||(value='');"><span>mark(s)</span>
              <div *ngIf="f.totalPassingScore.invalid && (f.totalPassingScore.dirty || f.totalPassingScore.touched )"
                class="alert alert-danger">
                <div *ngIf="f.totalPassingScore.errors?.required">Test passing score is required</div>

              </div>
              <div *ngIf="setTestScoremsg" class="alert alert-danger">
                <div>
                  Penalty Point Should be less than Testpassingscore and Totaltestscore

                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="switch-op">
                <label for="">Shuffle items</label>
                <mat-slide-toggle ngDefaultControl [(ngModel)]="addTestSchedule.shuffleItem"
                  (change)="itemShuffle($event)" formControlName="shuffleItem" [disabled]="true"></mat-slide-toggle>

              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="setting-label">
                <label for="">Penalty points <span class="red-asterisk">*</span></label>
              </div>
            </div>
            <div class="col-md-2">
              <input type="number" class="settingInput" formControlName="penaltyPoint" min="0" max="9999"
                oninput="validity.valid||(value='');" (change)="checkTestValue()"><span>mark(s)</span>
              <div *ngIf="f.penaltyPoint.invalid && (f.penaltyPoint.dirty || f.penaltyPoint.touched )"
                class="alert alert-danger">
                <div *ngIf="f.penaltyPoint.errors?.required">Test penalty score is required</div>

              </div>
              <div *ngIf="setPenaltymsg" class="alert alert-danger">
                <div>
                  Penalty Point Should be less than Testpassingscore and Totaltestscore

                </div>
              </div>

            </div>
            <div class="col-md-8">
              <div class="switch-op">
                <label for="">Shuffle questions</label>
                <mat-slide-toggle ngDefaultControl [(ngModel)]="addTestSchedule.shuffleQuestion"
                  (change)="questionShuffle($event)" formControlName="shuffleQuestion" [disabled]="true"></mat-slide-toggle>

              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <div class="switch-op">
                <label for="">Shuffle options</label>
                <mat-slide-toggle ngDefaultControl [(ngModel)]="addTestSchedule.shuffleOption"
                  (change)="optionShuffle($event)" formControlName="shuffleOption" [disabled]="true"></mat-slide-toggle>

              </div>
            </div>

          </div>
          <div class="filter-section title"> Message</div>
          <div class="filter-section "> <label>Enter pre-test message <span class="red-asterisk">*</span></label></div>
          <div class="row">
            <div class="col-md-12 editor-cls">
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor name='optionbox' [placeholder]="'Enter here...'" class="form-control textarea" [editor]="editor"
                formControlName="preMessageText" required>
              </ngx-editor>
              <div *ngIf="f.preMessageText.invalid && (f.preMessageText.dirty || f.preMessageText.touched )"
                class="alert alert-danger">
                <div *ngIf="f.preMessageText.errors?.minlength">Message is required</div>
                <!-- <div *ngIf="f.preMessageText.errors?.minlength">Minimum 3 characters required.</div> -->
                <div *ngIf="f.preMessageText.errors?.maxlength">Maximum 1000 characters allowed</div>
              </div>
            </div>
          </div>
          <div class="filter-section post-wrapper"> Enter post-test message </div>
          <div class="row" >
            <div class="col-md-12 editor-cls">
              <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor name='optionbox' [placeholder]="'Enter here...'" class="form-control" [editor]="editor1" maxLength="1001"
                formControlName="postMessageText" required>
              </ngx-editor>
              <div *ngIf="f.postMessageText.invalid && (f.postMessageText.dirty || f.postMessageText.touched )"
                class="alert alert-danger">

                <div *ngIf="f.postMessageText.errors?.minlength">Minimum 3 characters required</div>
                <div *ngIf="f.postMessageText.errors?.maxlength">Maximum 1000 characters allowed</div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
