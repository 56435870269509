<div class="component-container" *ngIf="isInstructionDisplayed == true">
    <div class="row">
        <div class="col-md-12">
            <!--  <h2 class="test-title">{{ testDetails?.scheduleName }}</h2>
            <p>{{ testDetails?.scheduleDescription }}</p>-->
            <h2 class="test-title">{{ testDetails?.testTitle }}</h2>
            <p>{{ testDetails?.testDescription }}</p>
            <hr>
        </div>
        <div class="col-md-12">
            <div class="pre-msg">
                <!-- <p [innerHtml]="testDetails?.preTestMessage?.description"></p> -->

                <!-- <form [formGroup]="form">
                    <div class="editor">
                        <ngx-editor [editor]="editor" [placeholder]="''" formControlName="editorContent">
                        </ngx-editor>
                    </div>
                </form> -->

            </div>

        </div>

        <div class="start-btn col-md-12">
            <hr>
            <div *ngIf="msg !=='Schedule time ended and Attempted questions submitted'">
                <h4 *ngIf=msg>{{msg}}</h4>
            </div>
            <div *ngIf="testDetails?.isScheduleExpired">
                <h4>Schedule time ended</h4>
            </div>
            <div *ngIf="!testDetails?.isScheduleExpired && testDetails?.examCompleted">
                <h4>Test duration is completed</h4>
            </div>
            <div *ngIf="testDetails?.isAutoSubmitted">
                <h4>Attempted questions submitted</h4>
            </div>
            <div *ngIf="testDetails?.examCompleted && !testDetails?.isAutoSubmitted">
                <h4>Test submitted successfully</h4>
            </div>
            <h4 *ngIf="msg ==='Schedule time ended and Attempted questions submitted'">Attempted questions submitted
            </h4>
            <button (click)='loadQuiz()' mat-raised-button class="btn-bg-color"
                [disabled]="!startEnable || !testDetails?.isScheduleStarted">
                Start
            </button>
        </div>
    </div>
    <!-- <div class="dialog-container" *ngIf="dialogEditor">
        <div>
            <div class="close" [align]="'end'">
                <button mat-mini-fab color="warn" (click)="closeDialog()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <h1>Notepad</h1>
            <form [formGroup]="form">
                <div class="editor">
                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                    </ngx-editor-menu>
                    <ngx-editor [editor]="editor" [placeholder]="'Enter here...'"></ngx-editor>
                </div>
            </form>
        </div>
    </div>
    <div class="dialog-container" *ngIf="dialogCalc">
        <div>
            <div class="close" [align]="'end'">
                <button mat-mini-fab color="warn" (click)="closeDialog()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <h1>Calculator</h1>
            <app-calculator></app-calculator>
        </div>
    </div> -->
    <!-- dialog -->
    <!-- <div class="additional-tools" *ngIf="tools">
        <button mat-fab color="primary" (click)="openEditor()">
            <mat-icon>note</mat-icon>
        </button>
        <button mat-fab color="primary" (click)="openCalculator()">
            <mat-icon>dialpad</mat-icon>
        </button>
    </div> -->
</div>

<ng-template #quiz></ng-template>