import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-question-type-checkbox',
  templateUrl: './question-type-checkbox.component.html',
  styleUrls: ['./question-type-checkbox.component.scss']
})
export class QuestionTypeCheckboxComponent implements OnInit {

  @Input() questions: any;
  @Input() isReadOnly: boolean;
  editor: Editor;
  html:any;
  form:FormGroup;
  constructor() { }


  ngOnInit(): void {
    this.editor = new Editor();
    this.form = new FormGroup({
      editorContent: new FormControl(
        { value: this.questions?.questiondescription?.description, disabled: true },
        Validators.required
      )
    });
  }


  get doc(): AbstractControl {
    return this.form.get("editorContent");
  }



  ngOnDestroy(): void {
    this.editor.destroy();
  }

}
