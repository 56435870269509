import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-option-editor',
  templateUrl: './option-editor.component.html',
  styleUrls: ['./option-editor.component.scss']
})
export class OptionEditorComponent implements OnInit {
  @Input() optionValue;
  form:FormGroup;
  editor: Editor;

  constructor() { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.form = new FormGroup({
      editorContent: new FormControl(
        { value: this.optionValue, disabled: true },
        Validators.required
      )
    });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.optionValue = null;
  }

}
