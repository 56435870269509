import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, throwError, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  itemStatus: any;
  public updateItemName = new Subject<any>();
  private updateStatusSource = new BehaviorSubject<any>('');
  public updateStatus = this.updateStatusSource.asObservable();
  private getItemUuid = new BehaviorSubject<any>('');
  public itemdata = this.getItemUuid.asObservable();
  private newCreatedItem = new BehaviorSubject<any>('');
  public getCretedItem = this.newCreatedItem.asObservable();
  private editCreatedItem = new BehaviorSubject<any>('');
  public getEditedItem = this.editCreatedItem.asObservable();
  questionCountUpdate = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }

  getItemuuid(item: any) {
    //console.log(item);
    this.getItemUuid.next(item);
  }

  createdGetItem(item1: any) {
    console.log(item1);
    this.newCreatedItem.next(item1);
  }

  editedGetItem(item2: any) {
    console.log(item2);
    this.editCreatedItem.next(item2);
  }
  updateItemBankName(value: any) {
    console.log("update status", value);
    this.updateItemName.next(value);
  }


  updateItemStatus(isPublishedStatus: any): void {
    console.log(isPublishedStatus);
    // console.log("selected tag data share service =>", selectedTagData);
    this.updateStatusSource.next(isPublishedStatus);
  }

  getItem(collectionuuId: string): Observable<any> | null {
    const fetchItemApiUrl = environment.apiUrl + 'items?collectionuuId=' + collectionuuId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchItemApiUrl, { 'headers': headers }).pipe(
        tap((data) => data),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }

  getItemByQuery(collectionId: string, testId: string, options: string = ''): Observable<any> | null {
    // console.log(itemId);
    const fetchItemApiUrl = environment.apiUrl + 'items/test?collectionuuId=' + collectionId + '&testuuId=' + testId + options;
    console.log(fetchItemApiUrl);
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemApiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchItemApiUrl, { 'headers': headers }).pipe(
        tap((data) => console.log(data)),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }


  getItemDetails(itemId: string) {
    const fetchItemDetailsapiUrl = environment.apiUrl + 'items/' + itemId;
    // if (localStorage.getItem('currentUser')) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // console.log(accessTokenFromStoarge);
    // console.log(fetchItemDetailsapiUrl);
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchItemDetailsapiUrl, { 'headers': headers }).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration
        return throwError(error);
      })
    )
    // }
    // else {
    //   console.log('please login in');
    //   console.log('later will navigate from here to login page ');
    //    //return Observable<null>;
    //    return null ;
    // }

  }

  getTags(itemuuId: string) {

    const fetchTagsapiUrl = environment.apiUrl + 'items/' + itemuuId + '/tags';

    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // console.log(accessTokenFromStoarge);
    console.log(fetchTagsapiUrl);
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTagsapiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }

        return throwError(error);
      })
    )

  }


  // THis service used for only search filter on item landing page.
  getItemFilter(item: any): Observable<any> | null {
    console.log("caaling filter", item);
    const fetchItemapiUrl = environment.apiUrl +
      'items?collectionuuId=' + item.collectionuuid +
      '&itemBankName=' + item.itemName +
      '&itemDescription=' + item.itemDescription +
      '&itemStatus=' + item.itemStatus +
      '&tag=' + item.tag;
    console.log(fetchItemapiUrl);

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchItemapiUrl, { 'headers': headers }).pipe(
        tap((data) => console.log("Show data", data)),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }


  // THis service used for only search filter test-existing-item page.
  getItemTestFilter(item: any): Observable<any> | null {
    console.log("caaling filter", item);
    const fetchItemapiUrl = environment.apiUrl +
      'items/test?collectionuuId=' + item.collectionuuid +
      '&testuuId=' + item.testuuId +
      '&itemBankName=' + item.itemName +
      '&itemDescription=' + item.itemDescription +
      '&itemStatus=' + item.itemStatus +
      '&tag=' + item.tag;
    console.log(fetchItemapiUrl);

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchItemapiUrl, { 'headers': headers }).pipe(
        tap((data) => console.log("Show data", data)),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }


  updateItem(Item: any) {
    console.log(Item);
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const updateItemApiUrl = environment.apiUrl + 'items/' + Item?.itemBankId;
    Object.defineProperty(Item, "itemBankId", { enumerable: false });


    const dataToApi = JSON.stringify(Item);

    return this.http.put<any>(updateItemApiUrl, dataToApi, { 'headers': headers }).pipe(
      map((resData: any) => resData),
      catchError(this.handleError)
    )
  }

  addNewItem(collectionId: any, itemBankName: any, itemDescription: any) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const createItemApiUrl = environment.apiUrl + 'items';
    const addNewItem = {
      "collectionuuId": collectionId,
      "itemBankName": itemBankName,
      "itemDescription": itemDescription

    }
    return this.http.post<any>(createItemApiUrl, JSON.stringify(addNewItem), { 'headers': headers }).pipe(
      tap(data => data),
      catchError(this.handleError)
    )
  }

  getAllTagsForItem(collectionuuId: any, itemuuId: any, keyword: string) {

    if (typeof keyword !== 'string') {
      keyword = " ";
    }
    const fetchTagsapiUrl = environment.apiUrl + 'tags/items?collectionuuId=' + collectionuuId + '&itemuuId=' + itemuuId + '&keyword=' + keyword;

    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTagsapiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }
        if (error.status === 0) {
          return throwError(null);
        }

        return throwError(error);
      })
    )


  }

  addTag(itemId: any, taguuId: any, tagOrder: any) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const addTagApiUrl = environment.apiUrl + 'items/' + itemId + "/tags";
    const dataToApi = {
      "taguuId": taguuId,
      "tagOrder": tagOrder
    }
    return this.http.post<any>(addTagApiUrl, JSON.stringify(dataToApi), { 'headers': headers }).pipe(
      tap((data: any) => data),
      catchError(this.handleError)
    )
  }

  getDifficultyLevel() {

    const fetchLevelApiUrl = environment.apiUrl + 'difficultylevel';

    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchLevelApiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }

        return throwError(error);
      })
    )

  }

  getItemtatus() {

    const fetchItemStatusApiUrl = environment.apiUrl + 'itemstatus';

    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    console.log(fetchItemStatusApiUrl);
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchItemStatusApiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }

        return throwError(error);
      })
    )

  }

  // updateItemSetting(Item: any) {
  //   const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
  //   const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
  //   const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
  //   const updateItemApiUrl = environment.apiUrl + 'items/settings/' + Item?.itemBankId;
  //   console.log(updateItemApiUrl);
  //   Object.defineProperty(Item, "itemBankId", { enumerable: false });
  //   const dataToApi = JSON.stringify(Item);
  //   console.log(dataToApi);

  //   return this.http.put<any>(updateItemApiUrl, dataToApi, { 'headers': headers }).pipe(
  //     map((data: any) => {
  //       console.log(data);
  //     }),
  //     catchError(this.handleError)
  //   )
  // }


  updateItemSetting(Item: any) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const updateItemApiUrl = environment.apiUrl + 'items/settings/' + Item;
    console.log(updateItemApiUrl);
    // Object.defineProperty(Item, "itemBankId", { enumerable: false });
    // const dataToApi = Item;
    // const dataToApi = {
    //   "Item": Item,
    // }
    // console.log(dataToApi);
    // Object.defineProperty(Item, "Item", { enumerable: false });

    const dataToApi = JSON.stringify(Item);
    console.log(dataToApi)
    return this.http.put<any>(updateItemApiUrl, dataToApi, { 'headers': headers }).pipe(
      tap(data => data),
      // map((data: any) => {
      //   console.log(data);
      // }),
      catchError(this.handleError)
    )
  }

  AddItemSetting(Item: any, level: any, status: any, shuffle: boolean, collectionuuId: any) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const updateItemApiUrl = environment.apiUrl + 'items/settings/' + Item;
    console.log(updateItemApiUrl);
    // Object.defineProperty(Item, "Item", { enumerable: false });
    const dataToApi = {
      "collectionuuId": collectionuuId,
      "difficultylevelId": level,
      "statusId": status,
      "shuffle": shuffle

    }
    console.log(dataToApi);

    return this.http.put<any>(updateItemApiUrl, JSON.stringify(dataToApi), { 'headers': headers }).pipe(
      tap(data => data),
      // map((data: any) => {
      //   console.log(data);
      // }),
      catchError(this.handleError)
    )
  }

  deleteTagOfItem(itemtagId: any, itemuuId: any) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const deleteItemTagApiUrl = environment.apiUrl + 'items/' + itemuuId + '/tags/' + itemtagId;
    console.log(deleteItemTagApiUrl);
    return this.http.delete<any>(deleteItemTagApiUrl, { 'headers': headers }).pipe(
      tap((data: any) => data
      ),
      catchError(this.handleError)
    )
  }

  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }
}
