<div class="add-recipient-container">
    <div class="btn-close">
        <h2 mat-dialog-title>Add Examinee(s)</h2>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div class="filter-wrapper">
        <input class="form-control" placeholder="Search" [(ngModel)]="searchValue" #input>
        <div class="btn-wrapper">
            <button mat-raised-button color="primary" (click)="applyFilter()">Search</button>
            <button mat-button (click)="resetFilter()">Reset</button>
        </div>
    </div>

    <div class="table-wrapper">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="checkbox-col"></th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox class="example-margin" [checked]="row?.checked"
                        (change)="addRecicipentToArray($event,row)"></mat-checkbox>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Examinee name </th>
                <td mat-cell *matCellDef="let row"><span class="font-bold">{{row?.firstName}} {{row?.lastName}} </span>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Examinee email </th>
                <td mat-cell *matCellDef="let row"> {{row?.emailId}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Data not found</td>
            </tr> -->
        </table>
        <div class="not-found" *ngIf="this.dataSource?.filteredData.length === 0">
            <p>Data not found</p>
        </div>
       
    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 15]" aria-label="Select page of users">
    </mat-paginator>
    <div class="send-recipient">
        <button mat-raised-button color="primary" (click)="sendRecipient()">Add</button>
    </div>
</div>