

<!-- <div class="table-content">
        <div class="example-table-container">
            <table mat-table [dataSource]="testsDataSource"  class="mat-elevation-z8">
                <ng-container matColumnDef="testTitle">
                    <th mat-header-cell *matHeaderCellDef>Test Title</th>
                    <td mat-cell *matCellDef="let row">
                        <a (click)='navigateToTestsDetails(row.testId, row.userTestId)'>{{row?.testTitle}}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="examCompleted">
                    <th mat-header-cell *matHeaderCellDef>Exam Completed</th>
                    <td mat-cell *matCellDef="let row" class="center-text">Yes</td>
                </ng-container>
        
                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef>Duration</th>
                    <td mat-cell *matCellDef="let row">{{row.duration}} {{row.durationDisplay}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

</div> -->
<!-- <app-landing></app-landing> -->
<router-outlet></router-outlet>

<!-- <app-register-examinee></app-register-examinee> -->

