import { AfterViewInit, Component, Inject, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TestService } from 'src/app/shared/test.service';
import { FlipitemtabService } from '../flipitemtab.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { MessageService } from 'src/app/message.service';
import { ItemService } from 'src/app/shared/item.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { DeliveryService } from 'src/app/shared/delivery.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { CreateNewItemComponent } from 'src/app/items/create-new-item/create-new-item.component';

export interface DialogData {
  name: any;
  testID: any;

}
export interface UserData {
  itemname: string;
  itemdescription: string;
  tags: any;
  isItemTag: boolean;
  // isTestTag: boolean;
  updatedDate: any;
  updatedBy: string;
}
@Component({
  selector: 'app-tests-items',
  templateUrl: './tests-items.component.html',
  styleUrls: ['./tests-items.component.scss']
})


export class TestsItemsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPublished: boolean;
  testServiceSubscription$: Subscription | undefined;
  testItemFilterServiceSubscription$: Subscription | undefined;
  testItems: any = null;
  displayedColumns: string[] = ['itemBankName', 'itemStatus', 'updated', 'tags', 'action'];
  totalRecords: any;
  searchText: any;
  page: number = 1;
  itemName = "";
  itemDescription = "";
  itemStatus = "";
  isItemDescended = true;
  testuuId: any;
  @Input() tabData: any;
  messageFlag = false;
  success = false;
  error = false;
  messages = '';
  items: any;
  itemId: any;
  isNoItem = true;

  globalcollectionid: any;
  collectionId: any;
  itemBankName: any;
  isTestItemCount: any;

  // pageOfItems: any;
  existingItemList = false;
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private testService: TestService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private flipitemtabService: FlipitemtabService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private itemservice: ItemService,
    private routerstate: ActivatedRoute,
    private deliveryservice: DeliveryService,
    private notificationServices: NotificationService
  ) {

    this.testService.isTestItemCount.subscribe(istestcount => {
      this.isTestItemCount = istestcount;
    })

    this.testuuId = this.actRoute.snapshot.paramMap.get('id');

    this.testService.testID.subscribe(tId => {
      this.testuuId = tId;
    })

    
  }

  ngOnInit(): void {

    this.isPublished = false;
    this.getItems();

    this.messageService.getMessageNext().subscribe(data => {
      this.messageFlag = true;
      this.success = true;
      this.error = false;
      this.messages = data?.text;
    });

   
    this.flipitemtabService.on<boolean>().subscribe(data => {
      this.existingItemList = data;
      this.resetDataItemsByTestId();
    });
  }
  ngAfterViewInit(): void {
    this.testItems.paginator = this.paginator;
    this.testItems.sort = this.sort;
    if (this.items.paginator.paginator) {
      this.items.paginator.paginator.firstPage();
    }
  }

  ngOnChanges(): void {

    this.testService.updateTestStatus.subscribe(isTestDisabled => {
      if (isTestDisabled == true) {
        this.isPublished = isTestDisabled;
      } else {
        this.isPublished = false;
      }
    });

    if (this.tabData) {
      this.messageService.clearMessagesNext();
      this.messageFlag = false;
    }


  }

  getItems() : void{
    if (this.actRoute.snapshot.paramMap.get('id') === null) {
    } 
    else {
      this.testuuId = this.actRoute.snapshot.paramMap.get('id');
      this.testServiceSubscription$ = this.testService.getItemByTestId(this.testuuId)?.subscribe(
        (resdata) => {
          this.testItems = resdata?.data;
          this.isTestItemCount = this.testItems?.length;
          this.testService.isTestItemCount.next(this.isTestItemCount);
          if (this.testItems.length == 0) {
            this.isNoItem = true;
          } else {

            this.testItems = new MatTableDataSource(this.testItems);
            this.testItems.sort = this.sort;
            this.testItems.paginator = this.paginator;
            this.isNoItem = false;
            
          }
        },
        (error) => {
          this.isNoItem = true;
          console.log(error);
        },
        () => {
          console.log('item service subscription completed');
        }) || undefined;

    }

  }

  addExistingItem() {
    this.existingItemList = true;
  }

  deleteItemFromTest(testBankuuId: number, itemBankuuId: number, itemBankName: any) {
    this.testService.deleteItemFromTest(testBankuuId, itemBankuuId).subscribe(resData => {
      this.notificationServices.success(resData.message);  // This line is used to display message
      this.resetDataItemsByTestId();


      if (this.actRoute.snapshot.paramMap.get('id') === null) {

      } else {
        this.testuuId = this.actRoute.snapshot.paramMap.get('id');
        this.testServiceSubscription$ = this.testService.getItemByTestId(this.testuuId)?.subscribe(
          (data) => {
            this.testItems = data?.data;
            if (this.testItems == 0) {
              this.isNoItem = true;
              this.isTestItemCount = 0;
              this.testService.isTestItemCount.next(this.isTestItemCount);
            } else {
              this.testItems = new MatTableDataSource(this.testItems);
              this.testItems.sort = this.sort;
              this.testItems.paginator = this.paginator;
              this.isTestItemCount = this.testItems?.filteredData?.length;
              this.testService.isTestItemCount.next(this.isTestItemCount);
              this.isNoItem = false;
            }
          },
          (error) => {
            console.log(error);
            // this.router.navigate(["/login"]);
          },
          () => {
            console.log('item service subscription completed');
          }) || undefined;

      }
    },
      error => {
        this.notificationServices.warn(error?.error?.message);  // This line is used to display message
      });
  }

  openConfirmationDialog(testBankuuId, itemBankuuId, itemBankName: any) {
    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to remove this Item from this Test?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteItemFromTest(testBankuuId, itemBankuuId, itemBankName);
      }
    });
  }

  resetDataItemsByTestId() {

    this.testServiceSubscription$ = this.testService.getItemByTestId(this.testuuId)?.subscribe(
      (resdata) => {
        if (resdata?.data?.length == 0) {
          this.isNoItem = true;
        } else {
          this.testItems = resdata?.data;
          this.testItems = new MatTableDataSource(this.testItems);
          this.testItems.sort = this.sort;
          this.isTestItemCount = this.testItems?.filteredData?.length;
          this.testService.isTestItemCount.next(this.isTestItemCount);
          this.isNoItem = false;
          this.testItems.paginator = this.paginator;
        }

      },
      (error) => {
        console.log(error);
        // this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

  }

  navigateToTestDetails(id: any) {
    this.router.navigate(["/tests-details", id]);
  }

  closeExistingItem(isClosed){
    this.existingItemList = false;
    this.getItems();
  }

  createNewItem(): void {

    const dialogRef = this.dialog.open(CreateNewItemComponent, {

      minWidth: '650px',
      data: {
        TestId: localStorage.setItem('TestId', this.testuuId)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != "noitem"){
        let collectionId = this.actRoute.parent.snapshot.paramMap.get('id');
        this.router.navigate(['/collections/' + collectionId + '/items']);
      }
     
    });
  }

  ngOnDestroy() {
    if (this.testServiceSubscription$) {
      this.testServiceSubscription$.unsubscribe();
    }
    if (this.testItemFilterServiceSubscription$) {
      this.testItemFilterServiceSubscription$.unsubscribe();
    }
    this.isPublished = null;
  }

  openDialogPreview(): void {
    const dialogRef = this.dialog.open(TestDialogPreview, {
      width: '1250px',
      height: '600px',
      panelClass: 'dialog-container',
      data: {
        name: this.testItems,
        testID: this.testuuId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

















@Component({
  selector: 'test-dialog-preview',
  templateUrl: 'test-dialog-preview.html',
  styleUrls: ['test-dialog-preview.scss'],
})
export class TestDialogPreview {
  testServiceSubscription$: Subscription | undefined;
  testId: any;
  testItems: any;
  itemIdArray: any[];
  itemId: any;
  displayedColumns: string[] = ['itemBankName', 'itemDescription'];
  isPublished = false;
  selectedTabIndex = 0;
  itemBasicDetails = {
    itemBankName: "",
    itemDescription: "",
    collectionId: "",
    itemBankId: "",
    isPublished: false
  };
  questions: any;
  numberOfQuestions = 0;
  itemsSubscription: any;
  isSelected: any;
  items: any;





  @ViewChild('table') table: MatTable<any>;
  constructor(public dialogRef: MatDialogRef<TestDialogPreview>,
    private itemservice: ItemService, private actRoute: ActivatedRoute,
    private testService: TestService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog) {

  }


  // ngOnchanges() {
  //   this.testItems[0]['active'] = 'selectedItem';
  // }




  ngOnInit() {
    const getIdArray = [];

    this.testId = this.data?.testID;
    this.testItems = this.data?.name?.filteredData;
    this.isSelected = this.testItems[0]?.itemBankuuId;

    this.itemIdArray = this.testItems;

    this.itemIdArray.forEach(ft => {
      getIdArray.push(ft.itemId);

    });


    this.itemservice.getItemDetails(this.isSelected).subscribe(resData => {
      this.questions = resData?.data?.questions;

    })













    // this.itemservice.getItemDetails(this.itemId).subscribe(data1 => {
    //   this.numberOfQuestions = data1?.questions?.length;
    //   this.questions = new MatTableDataSource(data1?.questions);
    //   this.itemBasicDetails['itemBankName'] = data1?.itemBankName;
    //   this.itemBasicDetails.itemDescription = data1?.itemDescription;
    //   // this.itemBasicDetails.collectionId = this.collectionId;
    //   this.itemBasicDetails.itemBankId = data1?.itemBankId;
    // })





  }




  getQuestions(itemBankuuId: any) {
    if (itemBankuuId !== this.isSelected) {
      this.itemservice.getItemDetails(itemBankuuId).subscribe(resData => {
        this.questions = resData?.data?.questions;
        this.isSelected = itemBankuuId;
      });
    }
  }

}
