
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ItemService } from 'src/app/shared/item.service';
import { QuestionService } from 'src/app/shared/question.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { CreateNewItemComponent } from '../create-new-item/create-new-item.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
// import { BoldTagPipe } from '../../shared/bold-tag.pipe';

export interface DialogData {
  name: any;
  id: number;
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface getItemUuid{
  itemuuid: any;
  itemBasicDetails: any;
}

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnInit, OnChanges ,OnDestroy{
  element1: void;
  flag: boolean=false;
  flag1:boolean=true;
 buttonWasClicked: boolean = false;
 displaydata: any;

  displayedColumns: string[] = ['itemBankName', 'itemDescription'];
  itemuuid:any;
  itemStatus:any;
  isEdit : boolean =false;
  tab: any;
  tab1 : boolean =false;
  getitemuuid:any;
  dataSource: any;
  itemId: any;
  questions: any;
  itemdetails: any;
  numberOfQuestions = 0;
  data: any;
  itemBasicData: any;
  isPublished = false;
  selectedTabIndex = 0;
  itemBasicDetails = {
    itemBankName: '',
    itemDescription: '',
    collectionId: '',
    itemBankId: '',
    isPublished: false,
  };
  setP: boolean;
  questionId: any;
  collectionId: any;
  testId: any;
  itemBankNameDisplay: any;
  itemBankNameDiscription: any;
  isNoQuestions = false;
  newItemname: any;
  ItemIdQue: any;
  @ViewChild('table') table: MatTable<any>;
  constructor(
    private itemservice: ItemService,
    private router: Router,
    private routerstate: ActivatedRoute,
    private questionService: QuestionService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService
  ) {
    this.itemservice.updateItemName.subscribe((resValue) => {

      this.itemBankNameDisplay = resValue;

   });
   this.itemservice.getEditedItem.subscribe((resData)=>
   {
     console.log(resData);
     console.log(resData?.data?.itemBankName);
    this.itemBankNameDisplay = resData?.data?.itemBankName;
    this.itemBankNameDiscription = resData?.data?.itemDescription;

   })
    this.testId = this.router.getCurrentNavigation()?.extras?.state?.testId;
    console.log('testId', this.testId);

    this.itemservice.questionCountUpdate.subscribe(es=>{
      this.ngOnInit();
    });
  }





  ngOnInit() {
    this.itemservice.updateStatus.subscribe((isItemDisable) => {
      console.log('dhananjay',isItemDisable);
      if (isItemDisable) {
        console.log(isItemDisable);
        this.isPublished = isItemDisable;
        this.itemStatus = "Published";
      }
      this.displaydata=localStorage.getItem("backbtnflag");
      console.log("---->display",this.displaydata);
    });


    // this.itemservice.getItemDetails(this.itemId).subscribe(data => {

    //   this.questions = data?.questions;
    //    console.log('preview', this.itemId)
    // });

    this.routerstate.paramMap
      .pipe(
        switchMap((params: any) => {
          this.itemId = params.get('id');
          this.collectionId = localStorage.getItem('defaultcollectionid');
          console.log(':this.itemId', this.itemId);
          return this.itemservice.getItemDetails(this.itemId);
        })
      )
      .subscribe((resdata) => {
        console.log('questions', resdata);
        this.getitemuuid= resdata?.data?.itemuuId;
        this.itemservice.getItemuuid(this.getitemuuid);
        console.log('questions', this.getitemuuid);
        this.itemBankNameDisplay = resdata?.data?.itemBankName;
        this.itemBankNameDiscription = resdata?.data?.itemDescription;
        this.itemStatus= resdata?.data?.statusName;
        this.getItemDetails(resdata);
        if (resdata?.data?.questions == 0) {
          this.isNoQuestions = true;
        } else {
          this.questions = resdata?.data?.questions;
        }
      });
  }

  openNewItemDialog(): void {
    console.log('itemmm',this.itemBasicDetails)
    //const dialogConfig: MatDialogConfig<CreateNewItemComponent> = {
      const dialogRef = this.dialog.open(CreateNewItemComponent,{

      minWidth: '650px',
      data :{
        itemuuid:this.getitemuuid,
        itemBasicDetails:this.itemBasicDetails
      }
    });
  //  const dialogRef = this.dialog.open(CreateNewItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  toggle(clicked: boolean) {​​​​​​
    this.buttonWasClicked = clicked;
    this.flag=true;
  }​​​​​​


  ngOnChanges() {}

   getItemDetails(itemBasicData: any) {
    // console.log('---------------------itembasicdata', itemBasicData);
    this.numberOfQuestions = itemBasicData?.data?.questions?.length;

    this.questions = new MatTableDataSource(itemBasicData?.data?.questions);
    this.itemBasicDetails['itemBankName'] = itemBasicData?.data?.itemBankName;
    this.itemBasicDetails.itemDescription =
      itemBasicData?.data?.itemDescription;
    this.itemBasicDetails.collectionId = this.collectionId;
    this.itemBasicDetails.itemBankId = itemBasicData?.data?.itemBankId;
    this.ItemIdQue = this.itemBasicDetails.itemBankId;
    console.log('---------------------itembasicdata', this.ItemIdQue);
    if (itemBasicData?.data?.statusName == 'Published') {
      this.isPublished = true;
      this.itemBasicDetails.isPublished = true;
    } else {
      this.isPublished = false;
      this.itemBasicDetails.isPublished = false;
    }
  }

  editQuestion(ele: any) {
    console.log('ele', ele);
    this.isEdit=true;
    this.element1 = ele;
    // itemid=
    console.log(' this.itemBasicDetails.itemBankId', this.itemId);
    localStorage.setItem('questionId', ele.questionid);
    localStorage.setItem('questionuuId', ele.questionuuId);
   /* if (ele.questionSubtypeId == 10) {
      // console.log("I M in Checkbox button");
      //  console.log(ele.questionid);
      this.router.navigate(['./addstandardquestioncheckbox'], {
        relativeTo: this.routerstate,
        state: { isEdit: true, queId: ele.questionuuId },
      });
      //  this.router.navigate(["./addstandardquestioncheckbox"],{ relativeTo: this.routerstate ,queryParams: { qurId:ele.questionid,itembankId:this.itemId}});
    }*/
    /*if (ele.questionSubtypeId =2) {
      console.log('I M in Radio button');
      // console.log("I M in Radio button");
      this.router.navigate(['./addstandardmcquestion'], {
        relativeTo: this.routerstate,
        state: { isEdit: true, queId: ele.questionuuId },
      });
      // this.router.navigate(["./addstandardmcquestion"],{ relativeTo: this.routerstate ,queryParams: { qurId:ele.questionid,itembankId:this.itemId}});
    }*/
    /*if (ele.questionSubtypeId == 13) {
      // console.log("I M in True False");
      // this.router.navigate(["./addstandardquestiontruefalse"],{ relativeTo: this.routerstate ,queryParams: { qurId:ele.questionid,itembankId:this.itemId}});
      this.router.navigate(['./addstandardquestiontruefalse'], {
        relativeTo: this.routerstate,
        state: { isEdit: true, queId: ele.questionid },
      });
    }*/
  }

  deleteQuestion(questionsP: any) {
    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to delete the Question?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    // const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log("-------->question delete",questionsP);
        this.questionId = questionsP.questionuuId;
        // console.log("Working delete", this.questionId);

        this.questionService
          .deleteQuestion(this.questionId, this.itemId)
          .subscribe((quesData) => {
            console.log('Delete Is working Ts', quesData);
            this.notificationService.success(quesData.message); // This line used to display message
            this.itemservice
              .getItemDetails(this.itemId)
              .subscribe((resData) => {
                if (resData?.data?.questions == 0) {
                  this.isNoQuestions = true;
                }
                this.numberOfQuestions = resData?.data?.questions?.length;
                this.questions = new MatTableDataSource(
                  resData?.data?.questions
                );
                this.itemBasicDetails['itemBankName'] =
                  resData?.data?.itemBankName;
                this.itemBasicDetails.itemDescription =
                  resData?.data?.itemDescription;
                this.itemBasicDetails.collectionId = this.collectionId;
                this.itemBasicDetails.itemBankId = resData?.data?.itemBankId;
              });
          });
      }
    });
  }

  navigateToSelectQuestionsType() {
    localStorage.setItem('ItemIdQue', this.ItemIdQue);
    // console.log(this.element1);
    this.router.navigate(['./selectquestiontype'], {
      relativeTo: this.routerstate,
      state: { itemId: this.itemId },
    });
  }
  navigateToItemLanding() {
    localStorage.removeItem('TestId');
    localStorage.removeItem('ItemIdQue');
    this.router.navigate(['../'], { relativeTo: this.routerstate });
  }

  navigatetoItem()
{
  this.router.navigate(['../'], { relativeTo: this.routerstate });
}


  onChange(event: MatTabChangeEvent) {
    this.tab1 = true;
    this.tab = event.tab.textLabel;
    console.log(this.tab);
  }

  openDialogPreview(): void {
    const dialogRef = this.dialog.open(DialogPreviewItem, {
      width: '1250px',
      height: '600px',
      panelClass: 'dialog-container',
      data: {
        name: this.questions,
        id: this.itemId,
      },
      // data:{ questions : new MatTableDataSource(this.data?.questions)}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  ngOnDestroy(): void {
      
  }
}
// function ngOnDestroy() {
//   if (localStorage.getItem('questionId')) {
//     localStorage.removeItem('questionId');
//   }
//   throw new Error('Function not implemented.');
// }
@Component({
  selector: 'dialog-preview-item',
  templateUrl: 'dialog-preview-item.html',
  styleUrls: ['dialog-preview-item.scss'],
})
export class DialogPreviewItem {
  numberOfQuestions = 0;
  displayedColumns: string[] = ['itemBankName', 'itemDescription'];

  Cdata: any;
  isPublished = false;
  selectedTabIndex = 0;
  itemBasicDetails = {
    itemBankName: '',
    itemDescription: '',
    collectionId: '',
    itemBankId: '',
    isPublished: false,
  };
  setP: boolean;
  questionId: any;
  collectionId: any;
  itemId: any;
  questions: any;
  dataSource: any;
  @ViewChild('table') table: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogPreviewItem>,
    private itemservice: ItemService,
    private routerstate: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.itemId = this.data?.id;
    console.log('itemID', this.itemId);

    this.routerstate.paramMap
      .pipe(
        switchMap((params: any) => {
          console.log('param', params);
          // this.itemId = params.get('id');
          this.collectionId = localStorage.getItem('defaultcollectionid');
          console.log(':this.itemid', this.itemId);
          return this.itemservice.getItemDetails(this.itemId);
        })
      )
      .subscribe((resData) => {
        //  console.log('data1',data1);
        // this.Cdata = data1;
        this.numberOfQuestions = resData?.data?.questions?.length;
        // console.log(data1);
        this.questions = new MatTableDataSource(resData?.data?.questions);
        this.itemBasicDetails['itemBankName'] = resData?.data?.itemBankName;
        this.itemBasicDetails.itemDescription = resData?.data?.itemDescription;
        this.itemBasicDetails.collectionId = this.collectionId;
        this.itemBasicDetails.itemBankId = resData?.data?.itemBankId;
        if (resData?.data?.statusName == 'Published') {
          this.isPublished = true;
          this.itemBasicDetails.isPublished = true;
        } else {
          this.isPublished = false;
          this.itemBasicDetails.isPublished = false;
        }

        //  console.log("this.questions", resData?.data?.statusName);
      });
  }

}
