import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../shared/app.service';
import { DeliveryService } from '../shared/delivery.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {


  userId: number;
  tests: any[] = [];
  testsSubscription$: Subscription | undefined;
  testsDataSource: any;
  displayedColumns: string[] = ['testTitle', 'examCompleted', 'duration'];


  constructor(private deliveryservice: DeliveryService, private appService: AppService, private router: Router) { }

  ngOnInit(): void {


    this.appService.changeUserHeader(0);

    if (localStorage.getItem('currentUser')) {
      this.userId = JSON.parse(localStorage.getItem('currentUser') || '{}').userId;
      console.log(this.userId);
      // this.testsSubscription$ = this.deliveryservice.getTest(this.userId).subscribe(
      //   (data: any) => {
      //     console.log(data);
      //     this.tests.push(data);
      //     this.testsDataSource = new MatTableDataSource(this.tests);
      //   },
      //   error => { console.log(error) },
      //   () => console.log('complete')
      // )
    }
  }

  navigateToTestsDetails(testId: any, userTestId: any): void {
    // update it to pass test id 
    this.router.navigate(['tests/start', this.userId], { state: { userTestId } });
    // A USER CAN HAVE MULTIPLE TEST
    // WE ARE SHOWING THAT IN ARRAY HERE 
    // FOR A TEST DETAIL, WE NEED TO PASS TESTID 
    // API SHOULD RETURN TEST DETAILS OF TEST ID
    // SINCE RIGHT NOW API ASSUMES THAT EVERY USER WILL HAVE ONLY ONE TEST AND RETURNS TEST DETIAL ON USER ID
    // SO WE ARE PASSING USERID
    // AFTER UPDATING API , WE SHOULD CHANAGE TO TEST ID 
    // this.router.navigate(["tests/start", testId ]);
  }

}
