import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/message.service';
import { ExamineesService } from '../examinees.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { ShareDataService } from 'src/app/shared/share-data.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-add-examinee',
  templateUrl: './add-examinee.component.html',
  styleUrls: ['./add-examinee.component.scss']
})
export class AddExamineeComponent implements OnInit {

  examineeDetailsForm: FormGroup;
  duplicateDataFlag = false;
  errorMessage: string;
  examineeData$: Subscription | undefined;
  action = 'createUser';
  emailRegex: any;

  constructor(
    private examineesService: ExamineesService,
    private router: Router,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private shareDataService: ShareDataService,
    public dialogRef: MatDialogRef<AddExamineeComponent>
  ) { }

  sendMessage(): void {
    // send message to subscribers via observable subject
    this.messageService.sendMessage('Message from Home Component to App Component!');
  }

  ngOnInit(): void {
    this.emailRegex = RegExp(this.shareDataService.globalEmailRegexPattern);

    this.examineeDetailsForm = new FormGroup({
        firstName: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/)
        ]),
        lastName: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/)
        ]),
        phone: new FormControl(null, [
          // Validators.required,
          Validators.minLength(5),
          Validators.maxLength(24),
          Validators.pattern(/^(?:\+?(\d{0,3}))?[- \(]*(\d{1,9})[-. \/)]*(\d{1,9})[-. \/]*(\d{1,9})*[-. \/]*(\d{1,9})(?: *x(\d))?$/)
        ]),
        emailId: new FormControl(null, [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(124),
          Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)+\.[A-Za-z]{2,4}$/)
          //Validators.pattern(this.emailRegex)
          //Validators.pattern(/^[a-zA-Z]+[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)*\.[A-Za-z]{1,3}$/)
          // Validators.pattern(/^[a-zA-Z0-9]+([.]?[_-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)\.[A-Za-z]{2,3}$/)
        ]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-_=+/\|";:<>,.`~])[A-Za-z\d@$!%*?&#^()-_=+/\|";:<>,.`~]{6,20}$/)
        ]),
        status: new FormControl('true', [Validators.required]),

      });
      // console.log(this.examineeDetailsForm.controls?.phone);  
  }

  get f(): any {
    return this.examineeDetailsForm.controls;
  }

  addNewExaminee(): void {
    const apiData = {
      // collectioId: this.collectionId,
      firstName: this.examineeDetailsForm.value.firstName.trim(),
      lastName: this.examineeDetailsForm.value.lastName.trim(),
      phone: this.examineeDetailsForm.value.phone,
      emailId: this.examineeDetailsForm.value.emailId.trim(),
      active: this.examineeDetailsForm.value.status,
      password: this.examineeDetailsForm.value.password,
    }


    this.examineeData$ = this.examineesService.createNewExaminee(apiData).subscribe((resData: any) => {
      console.log(resData);
      this.examineesService.getExaminees()?.subscribe(resData => {
        this.examineesService.newExamineeList(resData);
      });
      this.notificationService.success(resData.message);
      this.router.navigate(["/examinees"]);
      this.dialogRef.close();
      if (resData.examineeId) {
        // this.messageService.sendMessage('Examinee added successfully');
        // this.router.navigate(["/examinees"]);
      }
    },
      error => {
        this.notificationService.warn(error.error.message);
        if (error?.status == 409) {
          this.examineeDetailsForm.patchValue({ "emailId": null });
        }
        // if (error.error.error == 'Conflict') {
        //   this.duplicateDataFlag = true;
        //   this.notificationService.warn('Duplicate email id');
        // }
      });
  }

  onTestNameControlFocus(): void {

    this.duplicateDataFlag = false;

  }

  navigateToLandingPage(): void {
    this.router.navigate(["/examinees"]);
  }
}