import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  SimpleChanges,
  ViewContainerRef,
  ComponentFactoryResolver,
  AfterViewInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { CollectionService } from 'src/app/shared/collection.service';
import {
  NewTestSchedule,
  PostTestMessage,
  PretTestMessage,
  PublicScheduleParameters
} from 'src/app/shared/addtestschedule';
import { DatePipe, Time } from '@angular/common';


import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {
  MatChipInputEvent,
  MatChipList,
  MatChipSelectionChange,
} from '@angular/material/chips';
// import { DatePipe } from '@angular/common';
import { from, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  map,
  concatMap,
  catchError,
  startWith,
  debounceTime,
  tap,
  switchMap,
} from 'rxjs/operators';
import { TestService } from 'src/app/shared/test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NUM_CENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { TestsScheduleComponent } from '../tests-schedule/tests-schedule.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { ConfimationMessageComponent } from 'src/app/shared/confimation-message/confimation-message.component';
import { coerceStringArray } from '@angular/cdk/coercion';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CreatePublicScheduleComponent } from '../../tests/schedules/create-public-schedule/create-public-schedule.component';
import { CreatePrivateScheduleComponent } from '../../tests/schedules/create-private-schedule/create-private-schedule.component';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-tests-new-schedule',
  templateUrl: './add-tests-new-schedule.component.html',
  styleUrls: ['./add-tests-new-schedule.component.scss'],
})
export class AddTestsNewScheduleComponent implements OnInit, OnChanges, AfterViewInit {

  modeForm: FormGroup;
  @ViewChild('newscheduletemp', { read: ViewContainerRef, static:true }) private newscheduleviewref: ViewContainerRef;
  constructor(private fb: FormBuilder, private cfr: ComponentFactoryResolver) {
    this.modeForm = fb.group({
      mode: ["Public"]
    })
  }

  ngOnInit(): void {
    console.log('add test schedule oninit called')
    this.loadPublicScheudle();
    this.modeForm.get('mode').valueChanges.subscribe(value => {
      if (value == 'Public') {

        this.loadPublicScheudle();
      }
      else if (value == "Private") {

        this.loadPrivateSchedule();

      }
    })
  }

  ngAfterViewInit(): void {
    // this.loadPublicScheudle();
    // this.modeForm.get('mode').valueChanges.subscribe(value => {
    //   if (value == 'Public') {

    //     this.loadPublicScheudle();
    //   }
    //   else if (value == "Private") {

    //     this.loadPrivateSchedule();

    //   }
    // })
  }
  loadPublicScheudle() {

    this.newscheduleviewref.clear();
    // const { CreatePublicScheduleComponent } = await import('../../tests/schedules/create-public-schedule/create-public-schedule.component');
    // let greetcomp = this.newscheduleviewref.createComponent(
    //   this.cfr.resolveComponentFactory(CreatePublicScheduleComponent)
    // );
    const factUrl = this.cfr.resolveComponentFactory(CreatePublicScheduleComponent);
    const componentRef= this.newscheduleviewref.createComponent(factUrl);
  }

  loadPrivateSchedule() {

    this.newscheduleviewref.clear();
    // const { CreatePrivateScheduleComponent } = await import('../../tests/schedules/create-private-schedule/create-private-schedule.component');
    // let greetcomp = this.newscheduleviewref.createComponent(
    //   this.cfr.resolveComponentFactory(CreatePrivateScheduleComponent)
    // );
    const factUrl = this.cfr.resolveComponentFactory(CreatePrivateScheduleComponent);
    const componentRef = this.newscheduleviewref.createComponent(factUrl);
    // componentRef.instance.publictemp = 'abc';


  }

  ngOnChanges(changes: SimpleChanges): void {

  }

}