import { Component, OnInit } from "@angular/core";

const template = `
<router-outlet></router-outlet>
`
@Component({
 selector:'app-abc',
 template:template
})
export class AbcComponent implements OnInit{



    ngOnInit(){
        // alert('abc');
    }
}