<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title>Create new Collection</h2>
    <span class="spacer"></span>
    <div class="btn-close">
      <button mat-icon-button mat-dialog-close aria-label="Close Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="userForm">
      <div class="form-wrapper">
        <div class="input-wrapper">
          <span class="input-label">
            <label for="">Collection name</label><span class="required">*</span>
          </span>
          <span class="input-field">
            <input type="text" maxlength="121" class="form-control" placeholder="" formControlName="collectionName">
            <div *ngIf="f.collectionName.invalid && (f.collectionName.dirty || f.collectionName.touched)"
              class="alert alert-danger">
              <div *ngIf="f.collectionName.errors?.required"> Collection name is required</div>
              <div *ngIf="f.collectionName.errors?.minlength"> Minimum 1 character required</div>
              <div *ngIf="f.collectionName.errors?.maxlength"> Maximum 120 characters allowed</div>
              <div *ngIf="f.collectionName.errors?.pattern">Collection name is invalid</div>
            </div>
          </span>
        </div>
        <div class="input-wrapper">
          <span class="input-label">
            <label>Collection description {{f.description.errors?.maxLength}}</label>
          </span>
          <span class="input-field">
            <textarea type="text" class="form-control textarea" maxlength="481" placeholder=""
              formControlName="description"></textarea>
            <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)"
              class="alert alert-danger">
              <div *ngIf="f.description.errors?.minlength">Minimum 1 character allowed</div>
              <div *ngIf="f.description.errors?.maxlength">Maximum 480 characters allowed</div>
            </div>
          </span>
        </div>
        <div class="input-wrapper">
          <span class="input-label">
            <label for="">Language</label><span class="required">*</span>
          </span>
          <span class="input-field">
            <!-- <select aria-placeholder="language" class="form-control ng-untouched ng-pristine ng-valid" formControlName="langid">
          <option *ngFor="let option of languageArr">
            {{option?.language}}
          </option>
        </select> -->
            <select aria-placeholder="language" class="form-control ng-untouched ng-pristine ng-valid"
              ng-reflect-model="" formControlName="langid" [(ngModel)]="selectedValue">
              <option *ngFor="let option of languageArr" [value]="option?.langId">
                {{option?.language}}
              </option>
            </select>
            <div *ngIf="f.langid.invalid && (f.langid.dirty || f.langid.touched)" class="alert alert-danger">
              <div *ngIf="f.langid.errors?.required"> Language is required</div>
            </div>
          </span>
        </div>

        <div class="input-wrapper">
          <span class="input-label">
            <label for="">Timezone</label><span class="required">*</span>
          </span>
          <span class="input-field">
            <select aria-placeholder="timezone" [(ngModel)]="selectedTimezone"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="" formControlName="timeZoneName">
              <option *ngFor="let option of timeZoneArr" [value]="option?.standardname">
                {{option?.standardname}}
              </option>
            </select>

            <div *ngIf="f.timeZoneName.invalid && (f.timeZoneName.dirty || f.timeZoneName.touched)"
              class="alert alert-danger">
              <div *ngIf="f.timeZoneName.errors?.required"> Timezone is required</div>
            </div>
          </span>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button mat-flat-button color="primary" class="btn-blue"
    aria-label="Save" (click)="saveBtnFn()" [disabled]="userForm.invalid" >Save</button> -->
    <button mat-flat-button color="primary" class="btn-blue" aria-label="Save" (click)="saveBtnFn()"
      [disabled]="f.collectionName.invalid || f.langid.invalid || f.timeZoneName.invalid">Save</button>
  </mat-dialog-actions>
</div>