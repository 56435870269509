import { Injectable } from "@angular/core";
import { GlobalErrorEntity } from "./global-error.entity";


@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  constructor() { }

  logError(errorEntity : GlobalErrorEntity):string{
      console.log(errorEntity);
      return "error logged";
  }
}
