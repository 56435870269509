import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  transform(data: string): string {
    //convert string to array and remove whitespace
    let dataToArray = data.split(',').map(item => item.trim());
    //convert array to string replacing comma with new line
    return dataToArray.join("\n");
  }

}
