import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/message.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';

@Component({
  selector: 'app-new-schedule-popup',
  templateUrl: './new-schedule-popup.component.html',
  styleUrls: ['./new-schedule-popup.component.scss']
})
export class NewSchedulePopupComponent {

  scheduleDetailsForm: FormGroup;
  success = false;
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NewSchedulePopupComponent>,private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private messageService: MessageService,
    private notificationService: NotificationService,    private testService: TestService    ) {
    if (data) {
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }

    this.scheduleDetailsForm = this.fb.group({
      scheduleTitle: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(120),
      ]],
      scheduleDescription: [null],
    });
  }

  get f (){
    return this.scheduleDetailsForm.controls;
  }

  onConfirmClick(selectedValue): void {
    this.dialogRef.close(selectedValue);
  }

  saveBasicScheduleDetails() {
      if (this.scheduleDetailsForm.value.scheduleDescription != null) {
        this.scheduleDetailsForm.value.scheduleDescription =
          this.scheduleDetailsForm.value.scheduleDescription.trim();
      } else {
        this.scheduleDetailsForm.value.scheduleDescription =
          this.scheduleDetailsForm.value.scheduleDescription;
      }
      const apiData = {
        scheduleTitle: this.scheduleDetailsForm.value.scheduleTitle.trim(),
        scheduleDescription: this.scheduleDetailsForm.value.scheduleDescription,
      };
  
      this.testService.addTestSchedulePopup(apiData, apiData).subscribe(
        (resdata) => {
          console.log(resdata);
          // this.testService.updatedTestDetails(resdata);
          this.success = true;
          this.notificationService.success(resdata?.message);
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.warn(error?.error?.message);
        }
      );
    }

}