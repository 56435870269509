import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  imageSrc = "";
  constructor(public loader: LoaderService) {
    this.imageSrc = window.location.origin;
  }

  ngOnInit(): void { }
}
