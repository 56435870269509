
<!-- <app-header></app-header> -->
<div  *ngIf="!backbtnflag">
        <div class="content-wrapper">
          <div class="container-fluid">
              <div class="row"  *ngIf="displaybtn">
                  <div class="col-md-12">
                    <div class="filter-section">
                      <button mat-flat-button color="warn" class="btn-orange"  (click)='navigateToItemDetails(true)'>
                        <!--  <i class="fa fa-angle-left"></i>-->
                          <span>Back</span>
                      </button>
                      <div class="question-label">
                      <label>Choose Question Type</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="flag1 && flag && flag2">
                    <div class="col-md-3">
                      <div class="btn-selection" (click)="gotoMultipleChoice()"><button
                          [ngClass]="{'active1': tab=='tab1'}">Multiple Choice</button></div>
                      <div class="btn-selection"><button (click)="gotFillinthBlank()" [ngClass]="{'active1': tab=='tab2'}" > Fill in
                          the Blanks</button></div>
                      <div class="btn-selection"><button (click)="gotoMatchandOrders()" [ngClass]="{'active1': tab==='tab3'}" disabled >Match
                          & Order</button></div>
                      <div class="btn-selection"><button (click)="gotoFreeText()" [ngClass]="{'active1': tab==='tab4'}" disabled >Free
                          Text</button></div>
                      <div class="btn-selection"><button (click)="gotoHotSpot()" [ngClass]="{'active1': tab==='tab5'}" disabled >Hot
                          Spot</button></div>
                    </div>
                    <div class="col-md-9">
                      <div class="flex-container" *ngIf="setQuestionOption==true">
                        <div class="main-block" (click)='navigateToAddQuestionsTyperadio()'>
                          <div class="selection-heading"><span>Multiple Choice  -  Standard</span></div>
                          <!-- <div class="img-block">
                            <img src="../assets/1.png">
                            <div class="test">
                              <p>Multiple Choice-Standard</p>
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                          </div> -->
                          <div class="question-block">
                            <div class="block-layer"></div>
                          <label id="example-radio-group-label">Which is the largest country in the world?</label>
                          <mat-radio-group aria-labelledby="example-radio-group-label" class="option-group" *ngFor="let country of countries; let i = index">
                            <mat-radio-button class="option-button" [value]="i" [checked]="i === 2" readonly><span>{{country}}</span></mat-radio-button>
                          </mat-radio-group>
                          </div>
                        </div>
                        <div class="main-block" (click)='navigateToAddQuestionsTypecheckbox()'>
                          <div class="selection-heading"><span>Multiple Choice  -  Multiple Response</span>
                          </div>
                          <!-- <div class="img-block">
                                        <img src="../assets/2.png">
                                        <div class="test">
                                          <p>Multiple Choice-Multiple Response</p>
                                          <i class="fa fa-plus" aria-hidden="true"></i>
                                        </div>
                                      </div> -->
                                      <div class="question-block">
                                        <div class="block-layer"></div>
                                        <label id="example-radio-group-label">Which of the following are continents?</label>
                                        <div class="option-group">
                                          <mat-checkbox class="option-button" value="1" [checked]="true"  readonly><span>Australia</span></mat-checkbox>
                                          <mat-checkbox class="option-button" value="2" readonly><span>Austria</span></mat-checkbox>
                                          <mat-checkbox class="option-button" value="3" [checked]="true" 
                                          readonly><span>Europe</span></mat-checkbox>
                                          <mat-checkbox class="option-button" value="4" [checked]="true" 
                                           readonly><span>Asia</span></mat-checkbox>
                                        </div>
                                        </div>
                        </div>
                        <div class="main-block" (click)='navigateToAddQuestionsTypeTruFalse()'>
                          <div class="selection-heading">
                            <span>True Or False</span>
                          </div>
                          <!-- <div class="img-block">
                                        <img src="../assets/3.png">
                                        <div class="test">
                                          <p>True Or False</p>
                                          <i class="fa fa-plus" aria-hidden="true"></i>
                                        </div>
                                      </div> -->
                                      <div class="question-block">
                                        <div class="block-layer"></div>
                                        <label id="example-radio-group-label">About 71 percent of the Earth's surface is covered with water.</label>
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="option-group" *ngFor="let o of yesOrNo; let i = index">
                                          <mat-radio-button class="option-button" value="i" [checked]="i === 0" readonly><span>{{o}}</span></mat-radio-button>
                                        </mat-radio-group>
                                        </div>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-9">
                      <div class="flex-container" *ngIf="setFillinthBlank==true">
                        <div class="main-block " (click)='navigateToAddQuestionsTyperadio()' style="height:220px">
                          <div class="selection-heading"><span>Fill in the blacks - Text</span></div>
                          <!-- <div class="img-block">
                            <img src="../assets/1.png">
                            <div class="test">
                              <p>Multiple Choice-Standard</p>
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                          </div> -->
                          <div class="question-block">
                            <div class="block-layer"></div>
                          <label id="example-radio-group-label"><input type="text" class="input-fill"> is the largest country in the world?</label>
                          <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="option-group" *ngFor="let country of countries; let i = index">
                            <mat-radio-button class="option-button" [value]="i" [checked]="i === 2" readonly><span>{{country}}</span></mat-radio-button>
                          </mat-radio-group> -->
                          </div>
                        </div>
                        <div class="main-block" (click)='navigateToAddQuestionsTypecheckbox()' style="height:220px">
                          <div class="selection-heading"><span>Fill in the blacks - Dropdown </span>
                          </div>
                          <!-- <div class="img-block">
                                        <img src="../assets/2.png">
                                        <div class="test">
                                          <p>Multiple Choice-Multiple Response</p>
                                          <i class="fa fa-plus" aria-hidden="true"></i>
                                        </div>
                                      </div> -->
                                      <div class="question-block">
                                        <div class="block-layer"></div>
                                        <label id="example-radio-group-label">
                                           <select  class="input-fill">
                                          <option>Coffee</option>
                                          <option selected>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                        </select> of the following are continents?</label>
                                        <!-- <div class="option-group">
                                          <mat-checkbox class="option-button" value="1" [checked]="true"  readonly><span>Australia</span></mat-checkbox>
                                          <mat-checkbox class="option-button" value="2" readonly><span>Austria</span></mat-checkbox>
                                          <mat-checkbox class="option-button" value="3" [checked]="true" 
                                          readonly><span>Europe</span></mat-checkbox>
                                          <mat-checkbox class="option-button" value="4" [checked]="true" 
                                           readonly><span>Asia</span></mat-checkbox>
                                        </div> -->
                                        </div>
                        </div>
                        <!-- <div class="main-block" (click)='navigateToAddQuestionsTypeTruFalse()'>
                          <div class="selection-heading">
                            <span>True Or False</span>
                          </div>
                                      <div class="question-block">
                                        <div class="block-layer"></div>
                                        <label id="example-radio-group-label">About 71 percent of the Earth's surface is covered with water.</label>
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="option-group" *ngFor="let o of yesOrNo; let i = index">
                                          <mat-radio-button class="option-button" value="i" [checked]="i === 0" readonly><span>{{o}}</span></mat-radio-button>
                                        </mat-radio-group>
                                        </div>
                        </div> -->
                      </div>
                    </div>

                    
                </div>
          </div>
        </div>

          <!-- <mat-tab-group>
          <mat-tab label="Items"> -->
            <!-- <div class="item-bank-add-question-container"> -->
          <!-- </mat-tab>
          <mat-tab label="Tags">Content 2</mat-tab>
          <mat-tab label="Assets">Content 1</mat-tab>
          <mat-tab label="Log">Content 2</mat-tab>
        </mat-tab-group> -->
        </div>


<app-question-list *ngIf="backbtnflag"></app-question-list>
<app-item-add-checkbox-questions *ngIf="!flag1"></app-item-add-checkbox-questions>
<app-item-add-question-type *ngIf="!flag"></app-item-add-question-type>
<app-item-add-true-false-questions *ngIf="!flag2"></app-item-add-true-false-questions>

