import { ChangeDetectorRef, Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { debounceTime, finalize, startWith, switchMap, tap } from 'rxjs/operators';
import { ItemService } from 'src/app/shared/item.service';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-item-tags',
  templateUrl: './item-tags.component.html',
  styleUrls: ['./item-tags.component.scss']
})
export class ItemTagsComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['tagtypename', 'tagdescription', 'itemTagId'];
  streets = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  dataSource: any;
  itemId: any;
  itemuuId: any;
  // tags: any;
  // @Input() itemBasicDetails: any;
  tags = new MatTableDataSource<any>([]);
  filteredtags: any[] = [];
  tagsforTable: any[] = [];
  tagsForm: FormGroup;
  isLoading = false;
  isNoTags = false;
  tag: any;
  flatTags: any[] = [];
  flatTagsforTable: any[] = [];
  //flatTagsforTable : Observable<any[]>
  // isPublished: any;
  collectionId: any;
  collectionuuId: any;
  @Input() isPublished;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private itemservice: ItemService,
    private router: Router,
    private routerstate: ActivatedRoute,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef
  ) {


    this.itemservice.updateStatus.subscribe(
      statusdata => {
        console.log(statusdata);

        if (statusdata == 1) {
          this.isPublished = true;

        }
        else {
          this.isPublished = false;
        }
      }
    );
  }


  ngOnInit(): void {

    this.routerstate.paramMap.pipe(
      switchMap((params: any) => {
        this.itemuuId = params.get('id');
        console.log("This which id?", this.itemuuId);
        this.collectionuuId = JSON.parse(localStorage.getItem('defaultcollectionuuid'));
        console.log(":this.collection", this.collectionuuId);
        return this.itemservice.getTags(this.itemuuId)
      }
      )).subscribe(
        resData => {
          // console.log("jsdhfgdshgdjhfdgfhdgfdjhfgdjsdgfjhsdgfjhfgdjhsfgdsjhfgdjhfdgfjhdg",resData);
          this.tagsforTable = resData?.data;
          console.log(this.tagsforTable)
          this.tag = resData?.data;
          if (this.tag?.length == 0) {
            this.isNoTags = true;
          }
          else {
            this.tag = new MatTableDataSource(this.tag);
            this.tag.sort = this.sort;
            this.tag.paginator = this.paginator;
            this.isNoTags = false;
          }
          this.flatTagsforTable = [];
          this.tagsforTable.forEach(t => {
            t?.tags.forEach((element: any) => {
              let a = {
                ...element,
                tagtypename: t.tagtypename
              }
              this.flatTagsforTable.push(a);
            });
            this.tags.data = this.flatTagsforTable;
            this.tags.sort = this.sort;
            this.tags.paginator = this.paginator;
          })
        }

      )
    this.tagsForm = this.fb.group({
      userInput: null
    })

    this.tagsForm.get('userInput')!.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      tap(() => this.isLoading = true),
      switchMap(value => value?.length > 1 ? this.itemservice.getAllTagsForItem(this.collectionuuId, this.itemuuId, value) : EMPTY
        .pipe(
          finalize(() => this.isLoading = false),
        )
      )
    ).subscribe(tags => {
      console.log("Tags data", tags);
      this.isLoading = false
      this.filteredtags = tags?.data;
      this.flatTags = [];
      this.filteredtags.forEach(t => {
        t?.tags.forEach((element: any) => {
          let a = {
            ...element,
            tagtypename: t.tagtypename
          }
          this.flatTags.push(a);
        });


      })

      this.tagsForm.get('userInput')?.valueChanges.subscribe(data => {
        if (data.length == 0) {
          this.flatTags = [];

        }
      });


      // this.itemservice.addTag(); 
    });



  }

  ngOnChanges() {
    console.log(this.isPublished);
  }

  addTagtoItem(data: any) {
    console.log(data);
    this.itemservice.addTag(this.itemuuId, data.taguuId, 0).subscribe(
      (resdata) => {
        this.isNoTags = false;
        this.notificationService.success(resdata?.message); // THis line used to display message
        this.itemservice.getTags(this.itemuuId).subscribe(
          resData => {
            // console.log(data ?.items);
            this.flatTagsforTable = [];
            resData?.data.forEach((t: any) => {
              t?.tags.forEach((element: any) => {
                let a = {
                  ...element,
                  tagtypename: t.tagtypename
                }
                this.flatTagsforTable.push(a);
              });
              this.tags.data = this.flatTagsforTable;
              this.tags.sort = this.sort;
              this.tags.paginator = this.paginator;
              // this.displayFn(undefined);
              this.tagsForm.get('userInput')?.setValue("");
              this.flatTags = [];

            })
            this.cdr.detectChanges();
          },
          error => {
            this.notificationService.warn(error?.message); // THis line used to display error message
          }
        )
      },
      error => {
        this.notificationService.warn(error?.error?.message); // THis line used to display error message
      }
    )
  }

  displayFn(tags: any): string {
    let seprator: string = "-";
    if (tags) {
      console.log('hey');
      return tags.tagtypename + seprator + tags.tagName;
    }
    return '';
  }

  deleteItemTag(itemTagId: any) {
    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to delete this Tag?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    console.log(itemTagId);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.itemservice.deleteTagOfItem(itemTagId, this.itemuuId).subscribe(data => {
          console.log(data);
          console.log('deleted');
          this.itemservice.getTags(this.itemuuId).subscribe(
            resData => {
              console.log(resData);
              this.flatTagsforTable = [];
              if (resData?.data.length == 0) {
                this.tags.data = this.flatTagsforTable;
                this.isNoTags = true;
              }
              resData?.data.forEach((t: any) => {
                t?.tags.forEach((element: any) => {
                  let a = {
                    ...element,
                    tagtypename: t.tagtypename
                  }
                  this.flatTagsforTable.push(a);
                });
                this.tags.data = this.flatTagsforTable;

              })
            }
          )
          this.notificationService.success(data?.message);
        });
      }

      this.routerstate.paramMap.pipe(
        switchMap((params: any) => {
          this.itemId = params.get('id');
          console.log("This which id?", this.itemId);
          this.collectionId = localStorage.getItem('defaultcollectionid');
          console.log(":this.collection", this.collectionId);
          return this.itemservice.getTags(this.itemId)
        }
        )).subscribe(
          resData => {
            // console.log(data);
            this.tagsforTable = resData?.data;
            this.flatTagsforTable = [];
            this.tagsforTable.forEach(t => {
              t?.tags.forEach((element: any) => {
                let a = {
                  ...element,
                  tagtypename: t.tagtypename
                }
                this.flatTagsforTable.push(a);
              });
              this.tags.data = this.flatTagsforTable;
            })
          }

        )
      // this.tagsForm = this.fb.group({
      //   userInput: null
      // });

    });
  }

}
