import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraytonewline'
})
export class ArraytonewlinePipe implements PipeTransform {

  transform(data: []): unknown {
    let dataToArray = data.map(item => item);
    // //convert array to string replacing comma with new line
    return dataToArray.join("\n");
  }

}
