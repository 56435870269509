export class AddNewQuestion {

    active: boolean
    allowCheckAnswer: boolean
    ansRenderCount: number
    autoScoring: boolean
    checkAnswerAttempt: number
    minScoreIfAttempt: number
    options: Options[] = [];
    penaltyPoints: number
    correctPoints:number;
    itemId:number;
    questionOrder: number
    questionSubTypeId: number
    questionTypeId: number
    questiondescription: QuestionDescription
    scoringTypeExactMatch: boolean
    shuffleOption: boolean
    shuffleOptions: boolean

}

export class Options {

    answerDescription: OptionDescription
    answerValid: boolean
    arrangeOrder: 0
    correctPoints: number
    modified: boolean
    optionId: number
    selected: boolean

}
export class OptionDescription {
    description: string
    mediaURL: [
        string
    ]
}
export class QuestionDescription {
    description: string
    mediaURL: [
        string
    ]
}

