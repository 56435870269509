import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CollectionService } from 'src/app/shared/collection.service';


@Component({
    selector: 'app-collection-landing',
    templateUrl: './collection-landing.component.html'
})
export class CollectionLandingComponent implements OnInit {

    collections = [];
    collectionData$: Subscription | undefined;
    displayedColumns: string[] = ['collectionName', 'collectionid'];
    collectionid: any;
    constructor(private router: Router, private collectionService: CollectionService, private routerstate: ActivatedRoute) {
    }
    ngOnInit():void {
        this.routerstate.paramMap.subscribe(data => {
            this.collectionid = data.get('id');
            if (this.collectionid === 0) {
                this.router.navigate(['/login'])
            }
        });
    }
}
