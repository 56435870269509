<div class="main-content-wrapper dashboard-container">
    <div class="dashboard-wrapper">
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-3" *ngFor="let c of collections">
                    <div class="card test-class">
                        <mat-icon>assignment</mat-icon>
                        <div class="test-title">
                            <h1>{{c.collectionName}}</h1>
                            <p>{{c.description}} </p>
                            <p>{{c.LicenseExpire}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>