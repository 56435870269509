import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CollectionService } from '../shared/collection.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  collections = [];
  collectionData$: Subscription | undefined;
  displayedColumns: string[] = ['collectionName', 'collectionid'];
  constructor(private router: Router, private collectionService: CollectionService) {

  }
  ngOnInit(): void {
    this.collectionData$ = this.collectionService.getCollection()?.subscribe((collectionData: any) => {
      this.collections = collectionData?.data;
      console.log(this.collections);
    });
  }

  navigateToItemLanding(collectionId: string): void {
    console.log(collectionId);
    //  this.router.navigate(['/items', collectionId]);
    this.router.navigate(['/items']);
  }

  navigateToCreateNewCollection(): void {
    this.router.navigate(['/addcollection']);
  }

  navigateToEditCollection(collectionId: string): void {
    this.router.navigate(['/editcollection', collectionId]);
  }

}
