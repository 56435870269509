import { Component, Input, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ItemService } from 'src/app/shared/item.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { getItemUuid } from '../item-details/item-details.component';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-create-new-item',
  templateUrl: './create-new-item.component.html',
  styleUrls: ['./create-new-item.component.scss']
})
export class CreateNewItemComponent implements OnInit {


  itemBasicDetails: any;
  @Input() selectLevels: any;
  addItem: any;
  itemId: any;
  action = '';
  selectedTabIndex = 0;
  testId: any;


  constructor(private itemService: ItemService, private router: Router, private routerstate: ActivatedRoute,
    public dialogRef: MatDialogRef<CreateNewItemComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: getItemUuid) {
    this.testId = this.router.getCurrentNavigation()?.extras?.state?.testId;
  }

  ngOnInit(): void {
    console.log('data', this.data)
    this.itemBasicDetails = this.data?.itemBasicDetails;
    //console.log('itemuuid',this.itemuuid)
    if (this.data?.itemuuid == null) {
      this.action = 'createItem';
    } else {
      this.action = 'editItem';
    }
  }

  navigateToItemLanding() {

  }


}
