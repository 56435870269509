import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';
import { userEditUuid } from '../user-landing/user-landing.component';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  userDetailsForm: FormGroup;
  duplicateDataFlag = false;
  action = 'createUser';
  useruuId: number;
  isAddMode: boolean;
  userData: any;
  oldEmail;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<UserEditComponent>, @Inject(MAT_DIALOG_DATA)
    public userUuidData: userEditUuid,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.useruuId = this.userUuidData.useruuId;
    // this.useruuId = this.actRoute.snapshot.params['id'];
    console.log(this.useruuId);
    this.isAddMode = !this.useruuId;
    console.log(this.isAddMode);

    this.userDetailsForm = this.fb.group({
      firstName: [{ value: null }, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/),
      ]],
      lastName: [{ value: null }, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/),
      ]],
      phone: [{ value: null }, [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(24),
        Validators.pattern(/^(?:\+?(\d{0,3}))?[- \(]*(\d{1,9})[-. \/)]*(\d{1,9})[-. \/]*(\d{1,9})*[-. \/]*(\d{1,9})(?: *x(\d))?$/
        ),
      ]],
      emailId: [{ value: '', disabled: true }, [
        Validators.required,
        Validators.minLength(7),
        Validators.pattern(
          /^[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)*\.[A-Za-z]{1,3}$/
        ),
      ]],
      // 'password': [{value: null}, [Validators.required]],
      status: [{ value: 'true' }, [Validators.required]],
    });

    this.usersService.getUsersByID(this.useruuId)?.subscribe((resData) => {
      console.log(resData);
      this.userData = resData?.data;
      this.userDetailsForm.controls['firstName'].setValue(
        this.userData.firstName.trim()
      );
      this.userDetailsForm.controls['lastName'].setValue(
        this.userData.lastName.trim()
      );
      this.userDetailsForm.controls['phone'].setValue(this.userData.phone);
      this.userDetailsForm.controls['emailId'].setValue(this.userData.emailId.trim());
      this.oldEmail = this.userData.emailId.trim();
      this.userDetailsForm.controls['status'].setValue(this.userData.active);
    });

    // this.userDetailsForm.get('emailId')?.disable();
  }

  get f() {
    return this.userDetailsForm.controls;
  }

  changeField(event: KeyboardEvent) {
    this.notificationService.warn('Cannot change Email');
    if (event.key === ' ' && !this.userDetailsForm.value.emailId.length)
      event.preventDefault();
  }

  updateUserDetails() {
    console.log(this.userDetailsForm);
    if (this.userDetailsForm.invalid) {
      return;
    }

    const apiData = {
      // useruuId : this.useruuId,
      firstName: this.userDetailsForm.value.firstName,
      lastName: this.userDetailsForm.value.lastName,
      phone: this.userDetailsForm.value.phone,
      active: this.userDetailsForm.value.status,
    };

    console.log(apiData);

    this.usersService.editUser(apiData, this.useruuId).subscribe(
      (resData) => {
        console.log(resData);
        this.usersService.getusersLanding()?.subscribe(resData => {
          this.usersService.newUserList(resData);
        });
        this.notificationService.success(resData?.message);
        this.router.navigate(['/users']);
        this.dialogRef.close();
        if (resData.userid) {
          // this.router.navigate(["/users", data.testId]);
          // alert("User updated successfully");
        }
      },
      (error) => {
        this.notificationService.warn(error.error.message);
        // if (error.error.error == 'Conflict') {
        //   this.duplicateDataFlag = true;
        // }
      }
    );
  }

  onTestNameControlFocus(): void {
    this.duplicateDataFlag = false;
  }

  navigateToUsersLanding() {
    this.router.navigate(['/users']);
  }
  onKeydown(event: KeyboardEvent) {
    if (event.key === ' ' && !this.userDetailsForm.value.phone.length)
      event.preventDefault();
  }
}
