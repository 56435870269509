import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ExamineesService } from './examinees.service';
import { MessageService } from '../message.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddExamineeComponent } from './add-examinee/add-examinee.component';
import { EditExamineeComponent } from './edit-examinee/edit-examinee.component';


export interface examineeEditUuid {
  examineeuuId: number;
}

@Component({
  selector: 'app-examiness',
  templateUrl: './examiness.component.html',
  styleUrls: ['./examiness.component.scss']
})
export class ExaminessComponent implements OnInit, AfterViewInit {

  
  userServiceSubscription$: Subscription | undefined;
  userFilterServiceSubscription$: Subscription | undefined;

  displayedColumns: string[] = ['firstName', 
  'lastName', 
  'emailId', 
  'status', 
  'updated', 
  // 'action'
];
  
  clientId: number;
  examineesData: any;
  firstName = "";
  lastName = "";
  emailId = "";
  status = "";
  noDataFound = false;
  messages:any;
  isNoExaminee = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private router: Router,
    private examineesService: ExamineesService,
    private messageService:MessageService,
    public dialog: MatDialog

  ) {

  }

  ngOnInit(): void {

    this.userServiceSubscription$ = this.examineesService.getNewExamineeData.subscribe(resData => {
      this.examineesData = resData?.data;
      console.log(this.examineesData);
      if (this.examineesData?.length == 0) {
          this.isNoExaminee = true;
      } 
      else{
        this.examineesData = new MatTableDataSource(this.examineesData);
        this.examineesData.sort = this.sort;
        this.examineesData.paginator = this.paginator;
        this.isNoExaminee = false;
      }
      
    },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

    // Examinee list start
    this.userServiceSubscription$ = this.examineesService.getExaminees()?.subscribe(resData => {
      this.examineesData = resData?.data;
      console.log(this.examineesData);
      if (this.examineesData?.length == 0) {
          this.isNoExaminee = true;
      } 
      else{
        this.examineesData = new MatTableDataSource(this.examineesData);
        this.examineesData.sort = this.sort;
        this.examineesData.paginator = this.paginator;
        this.isNoExaminee = false;
      }
      
    },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

    // users list end

   

  }

  ngAfterViewInit(): void {
    // this.examineesData.sort = this.sort;
    this.examineesData.paginator = this.paginator;

  }


  examineesFilter(): void {
    this.noDataFound = false;
    let a=this.status;
    // const filterObj = {
    //   firstName:this.firstName,
    //   lastName:this.lastName,
    //   emailId:this.emailId,
    //   active:JSON.parse(a)
    // };

    const options = '&firstName=' + this.firstName
      + '&lastName=' + this.lastName
      + '&emailId=' + this.emailId
      + '&active=' + this.status;
    console.log(options);

    this.userFilterServiceSubscription$ = this.examineesService.getExamineesFilter(options)?.subscribe(
      (resData) => {
        console.log(resData);
        this.examineesData = resData?.data;
        console.log(this.examineesData?.length);
        if (this.examineesData.length < 1) {
          this.noDataFound = true;
        }
        console.log(this.examineesData);
        this.examineesData = new MatTableDataSource(this.examineesData);
        this.examineesData.sort = this.sort;
        this.examineesData.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
        this.isNoExaminee = true;

        // this.router.navigate(["/login"]);

      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

  }
  // onChangePage(pageOfItems: Array<any>) {
  //   console.log("function call");
  //   this.totalRecords = this.tests.length;
  //   console.log(this.totalRecords);
  // }

  // dateSort(): void {
  //   this.isItemDescended = !this.isItemDescended;
  //   this.tests.sort(this.sortdate);
  //   console.log(this.tests);
  // }



  // sortdate(a: any, b: any) {
  //   //return new Date(a.updated).getTime() - new Date(b.updated).getTime();
  //   //return new Date(a.updated).getTime() - new Date(b.updated).getTime() ; 
  //   return b.updated > a.updated;
  // }

  resetFilter() {

    this.firstName = "";
    this.lastName = ""
    this.emailId = "";
    this.status = "";
    this.noDataFound = false;
    this.userServiceSubscription$ = this.examineesService.getExaminees()?.subscribe(
      (resData) => {
        //console.log(data);
        this.examineesData = resData?.data;
        console.log(this.examineesData);
        if (this.examineesData.length < 1) {
          this.noDataFound = true;
        }
        this.examineesData = new MatTableDataSource(this.examineesData);
        this.examineesData.sort = this.sort;
        this.examineesData.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
        this.router.navigate(["/login"]);

      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

  }

  navigateToExamineeDetails(examineeuuid: any) {
    // console.log(examineeuuid);
    this.router.navigate(["examinees/editexaminee", examineeuuid]);
  }

  createNewExaminee() {
    this.router.navigate(["examinees/addexaminee"]);
  }
  addMultileExaminee(){
    this.router.navigate(["examinees/uploadexaminee"])

  }

  openNewExamineeDialog(): void {
    const dialogConfig: MatDialogConfig<AddExamineeComponent> = {
      minWidth: '650px',
      maxWidth: '40vw'
    };
    const dialogRef = this.dialog.open(AddExamineeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  
  
  openEditExamineeDialog(examineeuuId: number): void {
    // const dialogConfig: MatDialogConfig<UserEditComponent> = {
      console.log(examineeuuId)
      const dialogRef = this.dialog.open(EditExamineeComponent, {
      minWidth: '650px',
      data: {
        examineeuuId: examineeuuId
      }
    });
    // const dialogRef = this.dialog.open(UserEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  ngOnDestroy() {
    if (this.userServiceSubscription$) {
      this.userServiceSubscription$.unsubscribe();
    }
    if (this.userFilterServiceSubscription$) {
      this.userFilterServiceSubscription$.unsubscribe();
    }
  }

}
