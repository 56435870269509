<!-- <div class="filter-section titles">

    <button class="btn-bg-color" mat-raised-button mat-flat-button (click)="gotoItems()">
        <mat-icon  matTooltip="Back to test items" matTooltipPosition="above">arrow_back</mat-icon>
      </button>
</div>



<div class="item-bank-list-container">
    <div class="">
        <div class="filter-section">
            <div class="">
                <div class="row">
                    <div class="col-md-9">
                        <div class="input-fields-wrapper">
                            <div class="input-wrapper">
                                <input type="text" class="form-control" [(ngModel)]='itemName' placeholder="Find by Item name">

                                <input type="text" class="form-control" [(ngModel)]='itemDescription'
                                    placeholder="Find by Description">

                                <input type="text" class="form-control" [(ngModel)]='tagDescription'
                                    placeholder="Find by Tag">
                            </div>
                            <div class="btn-wrapper">
                                <button (click)='itemFilter()' mat-flat-button mat-raised-button class="btn-bg-color">
                                    <span>Search</span>
                                </button>

                                <button class="btn-green-text" (click)='resetFilter()' mat-flat-button
                                    mat-raised-button>
                                    <span>Reset</span>
                            </button>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="btn-wrapper">
                            <button class="btn-bg-color" (click)="saveItemsToTest()" mat-raised-button mat-flat-button>
                                <span> + Add Items</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="container-fluid">
            <div *ngIf="isNoItems" class="data-not-found-container">
                <span>No Items in this Test</span>
            </div>
            <div *ngIf="!isNoItems" class="row">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-container">
                            <table mat-table [dataSource]="items" matSort class="mat-elevation-z8">
                                <ng-container matColumnDef="itemBankName">
                                    <th mat-header-cell *matHeaderCellDef>Items </th>
                                    <td mat-cell *matCellDef="let row">

                                        <div class="item-box">
                                            <div>
                                                <mat-checkbox (change)="toggleCheckbox($event)" id="{{row.itemuuid}}">
                                                </mat-checkbox>
                                            </div>
                                            <div class="item-desc">
                                                <ng-container
                                                    *ngIf="row?.itemBankName?.length > 40 then sliceTitle else noSliceTitle">
                                                </ng-container>
                                                <ng-template  #sliceTitle>
                                                    <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'
                                                        matTooltip="{{row?.itemBankName}}" matTooltipPosition="above">
                                                        {{row?.itemBankName | slice: 0:50}}...</span>
                                                </ng-template>
                                                <ng-template #noSliceTitle>
                                                    <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'>{{row?.itemBankName
                                                        }}</span>
                                                </ng-template>



                                                <div class="test-desc">
                                                    <ng-container
                                                        *ngIf="row?.itemDescription?.length > 30 then sliceDescription else noSliceDescription">
                                                    </ng-container>
                                                    <ng-template #sliceDescription>
                                                        <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'
                                                            matTooltipPosition="above"
                                                            matTooltip="{{row?.itemDescription}}">
                                                            {{row?.itemDescription | slice: 0:30}}...
                                                        </span>
                                                    </ng-template>
                                                    <ng-template #noSliceDescription>
                                                        <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'>
                                                            {{row?.itemDescription }}
                                                        </span>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="questiontype">
                                    <th mat-header-cell *matHeaderCellDef>Question types</th>
                                    <td mat-cell *matCellDef="let row" >
                                        {{row.questionTypes}}</td>
                                </ng-container>

                                <ng-container matColumnDef="itemStatus">
                                    <th mat-header-cell *matHeaderCellDef>Status</th>
                                    <td mat-cell *matCellDef="let row" >
                                        <i *ngIf="row?.statusName === 'Published'" class="fa fas fa-circle publish"
                                            matTooltip="Published"></i>
                                        <span *ngIf="row?.statusName === 'Unpublished'"
                                            class="glyphicon glyphicon-adjust unpublish"
                                            matTooltip="Unpublished"></span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="updated" >
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
                                    <td mat-cell *matCellDef="let row">
                                        <span class="text-bold"> {{row.modifiedUserName}} </span> <br> {{row.updated | dateAgo}}</td>
                                </ng-container>
                                <ng-container matColumnDef="tags">
                                    <th mat-header-cell *matHeaderCellDef>Tags</th>
                                    <td mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="row?.tagdescription.length > 2; then Slicetag; else noSlicetag" ></ng-container>
                                        <ng-template #Slicetag>
                                           <div class="tag-data">
                                                <table>
                                                    <tr *ngFor="let ele of row.tagdescription">
                                                        <td [innerHtml]="ele | boldTag" matTooltip="{{row.tagdescription}}"></td>
                                                    </tr>
                                                </table>
                                           </div>
                                        </ng-template>
                                        <ng-template #noSlicetag>
                                            <table>
                                                <tr *ngFor="let ele of row.tagdescription">
                                                    <td [innerHtml]="ele | boldTag"></td>
                                                </tr>
                                            </table>
                                        </ng-template>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <div class="data-not-found-container" *ngIf="noDataFound">
                                <p>Data not found</p>
                              </div>

                    </div>
                    <div class="item-paginator">
                        <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>

                    </div>
                </div>
            </div>
    </div>
</div> -->

<div class="module-wrapper content-wrapper">
        <div class="head-container">
            <!-- <button class="btn-bg-color" mat-raised-button mat-flat-button (click)="gotoItems()">
                <mat-icon  matTooltip="Back to test items" matTooltipPosition="above">arrow_back</mat-icon>
            </button> -->
        <!--  <button mat-flat-button color="primary" (click)="gotoItems()" class="btn-blue" aria-label="Back">
            <mat-icon  matTooltip="Back to test items" matTooltipPosition="above">arrow_back</mat-icon>
          </button>
          <h3>Existing Items</h3>-->
          <div class="btn-wrapper">
            <button mat-flat-button color="warn" class="btn-orange" (click)="gotoItems()" aria-label="Back">
            Back</button>
          </div>
          <span class="spacer"></span>
          <div>
            <div class="btn-wrapper">
              <button mat-flat-button color="warn" class="btn-orange" (click)="saveItemsToTest()" aria-label="Add New Item" [disabled]="isNoItems">
                + Add Items</button>
            </div>
          </div>
        </div>
        <div class="input-container">
          <div class="input-fields-wrapper">
            <div class="input-wrapper">
              <input type="text" placeholder="Find by Item name" [(ngModel)]="itemName" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
              <input type="text" placeholder="Find by description"[(ngModel)]="itemDescription" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
              <input type="text" placeholder="Find by Tag" [(ngModel)]="tagDescription" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            </div>
            <div class="btn-wrapper">
              <button mat-flat-button color="primary" (click)="itemFilter()" class="btn-blue" aria-label="Search">Search</button>
              <button mat-flat-button color="basic" (click)="resetFilter()" class="btn-flat" aria-label="Reset">Reset</button>
            </div>
          </div>
        </div>
        <div *ngIf="isNoItems" class="data-not-found-container table-container">
            <span>No Items in this Collection</span>
        </div>
        <div *ngIf="!isNoItems">
        <div class="table-container">
            <table mat-table [dataSource]="items" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="itemBankName">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Item(s) </th>
                    <td mat-cell *matCellDef="let row">
                         <div class="item-box">
                            <div>
                                <mat-checkbox (change)="toggleCheckbox($event)" id="{{row.itemuuid}}">
                                </mat-checkbox>
                            </div>
                            <div class="item-desc">
                                <ng-container
                                    *ngIf="row?.itemBankName?.length > 40 then sliceTitle else noSliceTitle">
                                </ng-container>
                                <ng-template  #sliceTitle>
                                    <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'
                                        matTooltip="{{row?.itemBankName}}" matTooltipPosition="above">
                                        {{row?.itemBankName | slice: 0:50}}...</span>
                                </ng-template>
                                <ng-template #noSliceTitle>
                                    <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'>{{row?.itemBankName
                                        }}</span>
                                </ng-template>
                                <div class="test-desc">
                                    <ng-container
                                        *ngIf="row?.itemDescription?.length > 30 then sliceDescription else noSliceDescription">
                                    </ng-container>
                                    <ng-template #sliceDescription>
                                        <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'
                                            matTooltipPosition="above"
                                            matTooltip="{{row?.itemDescription}}">
                                            {{row?.itemDescription | slice: 0:30}}...
                                        </span>
                                    </ng-template>
                                    <ng-template #noSliceDescription>
                                        <span class="text-bold" (click)='navigateToItemDetails(row.itemBankId)'>
                                            {{row?.itemDescription }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="questiontype">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Question type(s)</th>
                    <td mat-cell *matCellDef="let row" >
                        {{row.questionTypes}}</td>
                </ng-container>

                <ng-container matColumnDef="itemStatus">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
                    <td mat-cell *matCellDef="let row" >
                        <i *ngIf="row?.statusName === 'Published'" class="fa fas fa-circle publish"
                            matTooltip="Published"></i>
                        <span *ngIf="row?.statusName === 'Unpublished'"
                            class="glyphicon glyphicon-adjust unpublish"
                            matTooltip="Unpublished"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="updated" >
                    <th mat-header-cell *matHeaderCellDef  [resizeColumn]="true">Updated</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="text-bold"> {{row.modifiedUserName}} </span>{{row.updated | dateAgo}}</td>
                </ng-container>
                <!-- <ng-container matColumnDef="tags">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag(s)</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="row?.tagdescription.length > 2; then Slicetag; else noSlicetag" ></ng-container>
                        <ng-template #Slicetag>
                           <div class="tag-data">
                                <table>
                                    <tr *ngFor="let ele of row.tagdescription" >
                                        <td [innerHtml]="ele | boldTag" matTooltip="{{row.tagdescription}}"></td>
                                    </tr>
                                </table>
                           </div>
                        </ng-template>
                        <ng-template #noSlicetag>
                            <table>
                                <tr *ngFor="let ele of row.tagdescription">
                                    <td [innerHtml]="ele | boldTag"></td>
                                </tr>
                            </table>
                        </ng-template>
                    </td>
                </ng-container> -->

                <ng-container matColumnDef="tags">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag(s)</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="row.tagdescription.length > 2 then sliceDesc else nosliceDesc" ></ng-container>
                        <ng-template #sliceDesc>
                            <div class="tag-data">
                                <table>
                                    <tr *ngFor="let ele of row.tagdescription" >
                                      <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:44)+'...'"  matTooltipPosition="above" matTooltip="{{ele}}" matTooltipClass="my-custom-tooltip"></td>
                                      <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                                    </tr>
                                </table>
                            </div>
                        </ng-template>
                        <ng-template #nosliceDesc>
                            <table>
                                <tr *ngFor="let ele of row.tagdescription">
                                  <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:44)+'...'"  matTooltip="{{ele}}" matTooltipPosition="above" matTooltipClass="my-custom-tooltip"></td>
                                  <td *ngIf="ele.length <= 40 && ele != 0" [innerHtml]="ele | boldTag"></td>
                                </tr>
                            </table>
                        </ng-template>
    
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          <div class="data-not-found-container" *ngIf="noDataFound">
            <p>Data not found</p>
          </div>
        </div>
        <div class="test-paginator">
            <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
        </div>
  </div>
