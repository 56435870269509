<div class="module-wrapper content-wrapper" >
    <div class="form-wrapper">
       <div class="input-wrapper">
            <form [formGroup]='tagsForm'>
            <input class="form-control" placeholder="Enter minimum two characters for Tag type or Tag name" [matAutocomplete]="auto"
                formControlName='userInput' [readonly]='isPublished'>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngIf="isLoading" class="is-loading">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option (onSelectionChange)="addTagtoItem(tags)" *ngFor="let tags of flatTags" [value]="tags">
                  <span>{{ tags.tagtypename }}</span>
                  <span> - {{tags.tagName}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </form>
          </div>
          <div *ngIf="isNoTags" class="data-not-found-container table-container">
            <span>No Tags in this Test</span>
          </div>
           <div *ngIf="!isNoTags">
      <div class="table-container" *ngIf="tags.data.length!=0">
        <table mat-table [dataSource]="tags" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="tagTypeName">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Type</th>
            <td mat-cell *matCellDef="let row" class="cell-content">
              <ng-container *ngIf="row?.tagtypename?.length > 40 then sliceTitle else noSliceTitle">
              </ng-container>
              <ng-template #sliceTitle>
                <span matTooltipClass="my-custom-tooltip" matTooltip="{{row?.tagtypename}}"
                  matTooltipPosition="above">
                  {{row?.tagtypename | slice: 0:40}}...</span>
              </ng-template>
              <ng-template #noSliceTitle>
                <span>{{row?.tagtypename }}</span>
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="tagDescription">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag</th>
            <td mat-cell *matCellDef="let row" class="cell-content">
              <ng-container *ngIf="row?.tagName?.length > 40 then sliceTitle else noSliceTitle">
              </ng-container>
              <ng-template #sliceTitle>
                <span matTooltipClass="my-custom-tooltip" matTooltip="{{row?.tagName}}"
                  matTooltipPosition="above">
                  {{row?.tagName | slice: 0:40}}...</span>
              </ng-template>
              <ng-template #noSliceTitle>
                <span>{{row?.tagName }}</span>
              </ng-template>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="testTagId">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
              <td mat-cell *matCellDef="let  element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openConfirmationDialog(element.testTagId)"  [disabled]="isPublished">
                      <mat-icon>delete_outline</mat-icon>
                      <span>Delete</span>
                    </button>
                </mat-menu>
              </td>
            </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="text-center" *ngIf="tags.data.length==0">Tags are not added</div>
     </div>
      <div class="item-paginator"  *ngIf="tags.data.length!=0">
      <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
      </div>
      </div>

</div>























<!-- <div class="filter-section title"> Tags</div> -->
<!--<div class="tag-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="input-tag-type">
          <!-- <input type="text" placeholder="Search for a tags" [matAutocomplete]="auto" > -->
<!--         <form [formGroup]='tagsForm'>

              <input class="form-control" placeholder="Enter minimum two characters for Tag type or Tag name" [matAutocomplete]="auto"
                formControlName='userInput' [readonly]='isPublished'>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngIf="isLoading" class="is-loading">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option (onSelectionChange)="addTagtoItem(tags)" *ngFor="let tags of flatTags" [value]="tags">
                  <span>{{ tags.tagtypename }}</span>
                  <span> - {{tags.tagName}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-container">
          <table mat-table [dataSource]="tags" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="tagTypeName">
              <th mat-header-cell *matHeaderCellDef>Types</th>
              <td mat-cell *matCellDef="let row" class="cell-content">
                {{row.tagtypename}}
              </td>
            </ng-container>
            <ng-container matColumnDef="tagDescription">
              <th mat-header-cell *matHeaderCellDef>Tags</th>
              <td mat-cell *matCellDef="let row" class="cell-content">{{row.tagName}}</td>
            </ng-container>

            <ng-container matColumnDef="testTagId">
              <th mat-header-cell *matHeaderCellDef class="icon-td"> </th>
              <td mat-cell *matCellDef="let element">
                <!-- <button (click)="deleteTestTag(element.testTagId)" mat-mini-fab color="accent"
                                aria-label="Example icon button with a home icon" [disabled]="isPublished">
                                <mat-icon>delete</mat-icon>
                              </button> -->
               <!-- <button (click)="openConfirmationDialog(element.testTagId)" mat-mini-fab color="accent"
                  aria-label="Example icon button with a home icon" [disabled]="isPublished">
                  <mat-icon>delete</mat-icon>
                </button>-->
<!--                <button mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon" (click)="openConfirmationDialog(element.testTagId)"  [disabled]="isPublished"><i-feather name="trash-2"></i-feather></button>


              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="text-center" *ngIf="tags.data.length==0">Tags are not added</div>
        </div>
      </div>
    </div>
  </div>
</div>-->
