<div class="dialog-container">
    <div class="dialog-heading">
        <h2 mat-dialog-title>New Schedule</h2>
        <span class="spacer"></span>
        <div class="btn-close">
            <button mat-icon-button mat-dialog-close aria-label="Close Dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="scheduleDetailsForm" novalidate>
            <div class="form-wrapper">
                <div class="input-wrapper">
                    <span class="input-label">
                        <label>Schedule name</label><span class="required">*</span>
                    </span>
                    <span class="input-field">
                        <textarea class="form-control textarea" rows="2" maxlength="121" type="text"
                            formControlName="scheduleTitle"></textarea>
                        <div *ngIf="f.scheduleTitle.invalid && (f.scheduleTitle.dirty || f.scheduleTitle.touched)"
                            class="alert alert-danger">
                            <div *ngIf="f.scheduleTitle.errors?.required"> Schedule name is required </div>
                            <div *ngIf="f.scheduleTitle.errors?.minlength">Minimum 3 characters required </div>
                            <div *ngIf="f.scheduleTitle.errors?.maxlength"> Maximum 120 characters allowed </div>
                        </div>
                    </span>
                </div>
                <div class="input-wrapper">
                    <span class="input-label">
                        <label>Schedule description</label>
                    </span>
                    <span class="input-field">
                        <textarea type="text" rows="8" class="form-control textarea" type="text"
                            formControlName="scheduleDescription"></textarea>
                        <!-- <div *ngIf="f.scheduleDescription.invalid && (f.scheduleDescription.dirty || f.scheduleDescription.touched)"
                            class="alert alert-danger">
                            <div *ngIf="f.scheduleDescription.errors?.minlength"> Minimum 20 characters required. </div>
                            <div *ngIf="f.scheduleDescription.errors?.maxlength"> Maximum 480 characters allowed. </div>
                        </div> -->
                    </span>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-flat-button color="primary" class="btn-blue" aria-label="Save" (click)='saveBasicScheduleDetails()'
            [disabled]="scheduleDetailsForm.invalid">Save</button>
    </mat-dialog-actions>
</div>
