<!-- <div *ngIf="!isNoQuestions"> No questions in this questions</div> -->
<div class="module-wrapper content-wrapper*">
  <!-- <div class="tab-container"> -->
    <!-- <mat-card>
        <mat-card-content> -->
    <!-- <div class="head-container" >
            <div *ngIf="!buttonWasClicked && !isEdit">
              <h1>Item Management</h1>
              <div class="breadcrumb-container">
                <a href="" class="link">Item name : {{itemBankNameDisplay}}</a> / <a href=""
                  class="link-active">Questions</a>
              </div>
            </div>
          </div> -->
    <!-- <div class="item-info-container">
            <div>
              <div class="item-name"><span>Item name : {{itemBankNameDisplay}}</span> <span class="item-active">
                  <i-feather name="disc"></i-feather><span>Active</span>
                </span> </div>
              <div class="item-desc"><span>Item description : {{itemBankNameDiscription}}</span></div>
            </div>
            <span class="spacer"></span>
            <div>
              <div class="btn-wrapper">
                <button mat-icon-button aria-label="Add New Item" (click)="openNewItemDialog()"><span class="menu-icon">
                    <i-feather name="edit"></i-feather>
                  </span></button>
              </div>
            </div>
          </div> -->
    <div class="tab-container">
      <!-- <span class="item-bank-name">Item name : {{itemBankNameDisplay}}</span> -->
      <!-- <mat-tab-group mat-align-tabs="end" [selectedIndex]="selectedTabIndex" animationDuration="0ms">

              <mat-tab>
                <ng-template mat-tab-label>
                  <span class="menu-icon">
                    <i-feather name="tag"></i-feather>
                  </span>
                  <span>Tags</span>
                </ng-template>
                <div class="tab-content">

                </div>
              </mat-tab>
               -->
      <!-- <mat-tab> -->

      <div class="filter-section" *ngIf="dataAvailable">
        <div class="btn-wrapper">
          <button mat-flat-button color="primary" class="btn-blue" (click)="openDialogPreview()"
            [disabled]="isNoQuestions">Preview</button>

          <!-- <button  mat-flat-button color="warn" class="btn-orange" (click)='navigateToSelectQuestionsType()'  [disabled]="isPublished">
                                      <span>+ New Question</span>
                                </button>-->
          <button mat-flat-button color="warn" class="btn-orange" (click)='toggle(true)' [disabled]="isPublished">
            <span>+ New Question</span>
          </button>
        </div>
        <app-choose-question-type *ngIf="flag" class="rem"></app-choose-question-type>
      </div>

      <ng-template mat-tab-label>
        <span class="menu-icon">
          <i-feather name="help-circle"></i-feather>
        </span>
        <span>Questions ({{numberOfQuestions}})</span>
      </ng-template>

      <div *ngIf="!dataAvailable">
        <div *ngIf="!buttonWasClicked && !isEdit">
          <div class="filter-section">
            <div class="btn-wrapper" *ngIf="spinner">
              <button mat-flat-button color="primary" class="btn-blue" (click)="openDialogPreview()"
                [disabled]="isNoQuestions">Preview</button>
              <button mat-flat-button color="warn" class="btn-orange" (click)='toggle(true)' [disabled]="isPublished">
                <span>+ New Question</span>
              </button>
            </div>
          </div>
          <div class="table-container item-details-table">
            <table mat-table [dataSource]="questions" class="mat-elevation-z8 question-details" #table>

              <ng-container matColumnDef="itemBankName">
                <th *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" class="cell-content">
                  <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 2">

                    <app-question-type-checkbox [isReadOnly]='true' [questions]='row'>
                    </app-question-type-checkbox>
                  </div>


                  <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 1">

                    <app-question-type-radio *ngIf="ItemIdQue" [isReadOnly]='true' [questions]='row'>
                    </app-question-type-radio>
                  </div>
                  <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 3">

                    <app-question-type-truefalse [isReadOnly]='true' [questions]='row'>
                    </app-question-type-truefalse>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="itemDescription">
                <th *matHeaderCellDef class="icon-td"> </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <button mat-mini-fab class="btn-bg-color"
                                          aria-label="Example icon button with a home icon" (click)="editQuestion(element)" [disabled]="isPublished">
                                          <mat-icon >edit</mat-icon>
                                        </button>
                                        <button mat-mini-fab class="btn-bg-color"
                                          aria-label="Example icon button with a home icon" (click)="deleteQuestion(element)"[disabled]="isPublished">
                                          <mat-icon>delete</mat-icon>
                                        </button>-->

                  <!--   <button mat-mini-fab color="primary" aria-label="edit icon" class="fab-mini-icon"
                              (click)="editQuestion(element)" [disabled]="isPublished">
                              <i-feather name="edit"></i-feather>
                            </button>
                            <button mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon"
                              (click)="deleteQuestion(element)" [disabled]="isPublished">
                              <i-feather name="trash-2"></i-feather>
                            </button>-->
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editQuestion(element)" [disabled]="isPublished">
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="deleteQuestion(element)" [disabled]="isPublished">
                      <mat-icon>delete_outline</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <app-choose-question-type *ngIf="flag"></app-choose-question-type>
        <app-item-add-question-type *ngIf="isEdit && questionSubtypeId==1" (postCreated)="onPostAdded($event)">
        </app-item-add-question-type>
        <app-item-add-checkbox-questions *ngIf="isEdit && questionSubtypeId==2" (postCreated)="onPostAdded($event)">
        </app-item-add-checkbox-questions>
        <app-item-add-true-false-questions *ngIf="isEdit && questionSubtypeId==3" (postCreated)="onPostAdded($event)">
        </app-item-add-true-false-questions>

      </div>
      <!-- </mat-tab> -->
      <!-- <mat-tab>
                <ng-template mat-tab-label>
                  <span class="menu-icon">
                    <i-feather name="details"></i-feather>
                  </span>
                  <span>Details</span>
                </ng-template>
                <div class="tab-content">
                  <app-item-basic-details [isPublished]='isPublished' [itemBasicDetails]='itemBasicDetails'>
                  </app-item-basic-details>
                </div>
              </mat-tab> -->
      <!-- <mat-tab>
                <ng-template mat-tab-label>
                  <span class="menu-icon">
                    <i-feather name="tag"></i-feather>
                  </span>
                  <span>Tags</span>
                </ng-template>
                <div class="tab-content">
                  <app-item-tags [isPublished]='isPublished'></app-item-tags>
                </div>
              </mat-tab> -->

      <!-- <mat-tab label="Settings">
                <ng-template mat-tab-label>
                  <span class="menu-icon">
                    <i-feather name="settings"></i-feather>
                  </span>
                  <span>Settings</span>
                </ng-template>
                <div class="tab-content">
                  <app-item-settings [isPublished]='isPublished' [itemId]='itemId' [item]='data' [testId]="testId">
                  </app-item-settings>
                </div>
              </mat-tab> -->
      <!-- </mat-tab-group> -->
    </div>
    <div *ngIf="dataAvailable"> No Questions are present in this Item  </div>
    <!-- </mat-card-content>
      </mat-card> -->
  <!-- </div> -->
</div>