import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
@Injectable({
  providedIn: 'root',
})
export class TestService {
  public testID = new Subject<any>();
  public scheduleuuid = new Subject<any>();

  private updateTestStatusSource = new BehaviorSubject<any>('');
  public updateTestStatus = this.updateTestStatusSource.asObservable();

  private newTest = new BehaviorSubject<any>('');
  public getNewtest = this.newTest.asObservable();


  private updatedTest = new BehaviorSubject<any>('');
  public getUpdatedTest = this.updatedTest.asObservable();

  public isTestItemCount = new Subject<any>();
  public selectedSendInMain = new BehaviorSubject<any>(null);

  public listMode = new BehaviorSubject<any>(null);

  scheduleBackBtnobs = new BehaviorSubject<any>(false);



  schedulUuid: any;
  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  updatedTestDetails(test: any) {
    console.log(test);
    this.updatedTest.next(test);
  }

  newTestList(test: any) {
    console.log(test);
    this.newTest.next(test);
  }


  updateStatus(isPublishedStatus: any): void {
    console.log(isPublishedStatus);
    // console.log("selected tag data share service =>", selectedTagData);
    this.updateTestStatusSource.next(isPublishedStatus);
  }

  updateTestId(value: any) {
    console.log('update status', value);
    this.testID.next(value);
  }

  // getItem(itemId: string): Observable<any> | null {
  //   console.log(itemId);
  //   const fetchItemapiUrl = environment.apiUrl + 'tests?collectionId=' + itemId;
  //   if (localStorage.getItem('currentUser')) {
  //     const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
  //     const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
  //     // console.log(accessTokenFromStoarge);
  //     // console.log(fetchItemapiUrl);
  //     const headers = { 'token': accessTokenFromStoarge }
  //     return this.http.get<any>(fetchItemapiUrl, { 'headers': headers }).pipe(
  //       tap((data) => console.log(data)),
  //       catchError(error => {
  //         console.log(error);
  //         if (error.status === 401) {
  //           return throwError(new Error(error.status));
  //         }
  //         // handle here status code error for token expiration
  //         return throwError(error);
  //       })
  //     )
  //   }
  //   else {
  //     console.log('please login in');
  //     console.log('later will navigate from here to login page ');
  //     return null;
  //   }

  // }

  // this function is fetching time zones data from api
  getTimeZone(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'schedule/' + 'gettimezonelist';
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getTests(collectionuuId: string): Observable<any> | null {

    const fetchItemapiUrl =
      environment.apiUrl + 'tests?collectionuuId=' + collectionuuId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchItemapiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }
  getTestStatusList(): Observable<any> | null {
    // console.log(collectionId);
    const fetchTestStatusApiUrl = environment.apiUrl + 'itemstatus';
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http
        .get<any>(fetchTestStatusApiUrl, { headers: headers })
        .pipe(
          tap((data) => console.log(data)),
          catchError((error) => {
            console.log(error);
            if (error.status === 401) {
              return throwError(new Error(error.status));
            }
            // handle here status code error for token expiration
            return throwError(error);
          })
        );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getItemByTestId(testuuId: number): Observable<any> | null {

    const fetchItemapiUrl = environment.apiUrl + 'composetest/' + testuuId;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      console.log(fetchItemapiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchItemapiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getTestItemFilter(item: any): Observable<any> | null {
    const fetchItemapiUrl =
      environment.apiUrl +
      'items?collectionId=' +
      item.itemId +
      '&itemBankName=' +
      item.itemName +
      '&itemDescription=' +
      item.itemDescription +
      '&itemStatus' +
      item.itemStaus;
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      console.log(accessTokenFromStoarge);
      console.log(fetchItemapiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchItemapiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getTestDetails(testuuId: number) {
    const fetchItemDetailsapiUrl = environment.apiUrl + 'tests/' + testuuId;
    // if (localStorage.getItem('currentUser')) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { token: accessTokenFromStoarge };
    return this.http
      .get<any>(fetchItemDetailsapiUrl, { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    // }
    // else {
    //   console.log('please login in');
    //   console.log('later will navigate from here to login page ');
    //    //return Observable<null>;
    //    return null ;
    // }
  }

  getTestTags(testId: number) {
    console.log('Tags TestID', testId);
    const fetchTagsapiUrl = environment.apiUrl + 'tests/' + testId + '/tags';

    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // console.log(accessTokenFromStoarge);
    // console.log(fetchTagsapiUrl);
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTagsapiUrl, { 'headers': headers }).pipe(
      tap((resData) => resData),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }

        return throwError(error);
      })
    );
  }

  getTestFilter(filterData: any): Observable<any> | null {
    console.log(filterData);
    // const fetchItemapiUrl = environment.apiUrl + 'items?collectionId=' + filterData.collectionId + '&itemBankName=' + item.itemName + '&itemDescription=' + item.itemDescription + '&itemStatus' + item.itemStaus;
    const fetchTestApiUrl =
      environment.apiUrl +
      'tests?collectionuuId=' +
      filterData.collectionuuId +
      '&testName=' +
      filterData.testName +
      '&testDescription=' +
      filterData.testDescription +
      '&tag=' +
      filterData.tag +
      '&testStatus=' +
      filterData.testStatus;

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { token: accessTokenFromStoarge };
      return this.http.get<any>(fetchTestApiUrl, { headers: headers }).pipe(
        tap((data) => console.log(data)),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }
  createTest(dataToApi: any) {
    console.log(dataToApi)
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addApiUrl = environment.apiUrl + 'tests';
    return this.http
      .post<any>(addApiUrl, dataToApi, { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        catchError(this.handleError)
      );
  }

  saveTestSettings(dataToApi: any, testuuId: number) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addApiUrl = environment.apiUrl + 'tests/' + testuuId + '/settings';
    console.log(addApiUrl);
    console.log(JSON.stringify(dataToApi));
    return this.http
      .put<any>(addApiUrl, JSON.stringify(dataToApi), { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        catchError(this.handleError)
      );
  }

  updateTest(dataToApi: any, testuuId) {

    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const updateTestApiUrl = environment.apiUrl + 'tests/' + testuuId;
    return this.http
      .put<any>(updateTestApiUrl, JSON.stringify(dataToApi), {
        headers: headers,
      })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }

  getAllTagsForItem(collectionuuId: any, testuuId: any, keyword: string) {
    if (typeof keyword !== 'string') {
      keyword = ' ';
    }
    const fetchTagsapiUrl =
      environment.apiUrl +
      'tags/tests?collectionuuId=' +
      collectionuuId +
      '&testuuId=' +
      testuuId +
      '&keyword=' +
      keyword;

      this.loaderService.sentReqUrl(fetchTagsapiUrl);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // console.log(accessTokenFromStoarge);
    // console.log(fetchTagsapiUrl);
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTagsapiUrl, { 'headers': headers }).pipe(
      tap((resData) => resData?.data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        if (error.status === 0) {
          return throwError(null);
        }

        return throwError(error);
      })
    );
  }

  addTestTag(testId: number, tagId: number, tagOrder: any) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl = environment.apiUrl + 'tests/' + testId + '/tags';
    const dataToApi = {
      tagid: tagId,
    };
    return this.http
      .post<any>(addTagApiUrl, JSON.stringify(dataToApi), { headers: headers })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }

  deleteTagFromTest(testTagId: number) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const deleteItemTagApiUrl = environment.apiUrl + 'tests/tags/' + testTagId;
    console.log(deleteItemTagApiUrl);
    return this.http
      .delete<any>(deleteItemTagApiUrl, { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        // map((data: any) => {
        //   console.log(data);
        // }),
        catchError(this.handleError)
      );
  }

  deleteItemFromTest(testBankuuId: number, itemBankuuId: number) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const deleteItemTagApiUrl =
      environment.apiUrl + 'composetest/' + testBankuuId + '/items/' + itemBankuuId;
    return this.http
      .delete<any>(deleteItemTagApiUrl, { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        catchError(this.handleError)
      );
  }

  addItemToTest(testId: any, itemBankId: any, dataToApi: any) {
    console.log(dataToApi)
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addItemToTestApiUrl =
      environment.apiUrl + 'test/' + testId + '/composetest';

    return this.http
      .post<any>(addItemToTestApiUrl, JSON.stringify(dataToApi), {
        headers: headers,
      })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }

  addItemToTest1(testId: any, dataToApi: any) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addItemToTestApiUrl =
      environment.apiUrl + 'test/' + testId + '/composetest';
    console.log('composeTest', addItemToTestApiUrl);
    console.log('body', dataToApi);
    return this.http
      .post<any>(addItemToTestApiUrl, JSON.stringify(dataToApi), {
        headers: headers,
      })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }

  addTestSchedule(testSchedule) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl = environment.apiUrl + 'schedule/test';
    return this.http
      .post<any>(addTagApiUrl, JSON.stringify(testSchedule), {
        headers: headers,
      })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }


  addTestSchedulePopup(testSchedule, body) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl = environment.apiUrl + 'schedule';

    return this.http
      .post<any>(addTagApiUrl, JSON.stringify(testSchedule, body), {
        headers: headers,
      })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }


  getTestSchedule(collectionuuId, testuuId) {
    // console.log('---------------->collectionId',collectionId);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl =
      environment.apiUrl +
      'schedule/test?collectionuuId=' +
      collectionuuId +
      '&testuuId=' +
      testuuId;

    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }

  getExamineetoSchedule() {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl = environment.apiUrl + 'examinee/schedule';

    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }

  editTestSchedule(scheduleuuId) {
    console.log(scheduleuuId, 'data of shudule');

    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl = environment.apiUrl + 'schedule/' + scheduleuuId;
    // const addTagApiUrl = environment.apiUrl + 'schedule/test?collectionuuId=' + collectionuuId + '&testuuId=' + testuuId + '&scheduleuuId=' + scheduleuuId;

    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }

  updateExamineeRecepient(testScheduleId, testId) {
    console.log("----->testScheduleId", testScheduleId);
    console.log("----->testId", testId);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const addTagApiUrl =
      environment.apiUrl +
      'schedule/recipient?testuuId=' +
      testId +
      '&scheduleuuId=' +
      testScheduleId;

    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }
  editTestScheduleForm(testSchedule) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };

    const addTagApiUrl = environment.apiUrl + 'schedule/test';

    return this.http.put<any>(addTagApiUrl, JSON.stringify(testSchedule), { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }


  scheduleFilter(collectionuuId, testuuId, filterObj) {
    console.log("data service ts filetr", filterObj);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };

    const addTagApiUrl =
      environment.apiUrl + 'schedule/test?collectionuuId=' + collectionuuId + '&testuuId=' + testuuId +
      '&fromDate=' + filterObj.fromDate + '&toDate=' + filterObj.todate +
      '&scheduleStaus=' + filterObj.scheduleStatus + '&type=' + filterObj.scheduleType +
      '&scheduleName=' + filterObj.scheduleName;

    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }

  cancleSchduel(scheduleId) {
    // console.log("service call", environment.apiUrl);
    // environment.apiUrl + 'items/'+ itemId+ '/tags/' +  itemtagId

    const DeletequestionapiUrl = environment.apiUrl + 'schedule/' + scheduleId;
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // const headers = { 'content-type': 'application/json'}
    const headers = { 'token': accessTokenFromStoarge }
    console.log(headers);

    console.log("DeletequestionapiUrl", DeletequestionapiUrl);


    return this.http.delete<any>(DeletequestionapiUrl, { 'headers': headers }).pipe(
      // tap(data=>console.log(data)),
      tap((data: any) => {
        console.log(data);
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration
          return throwError(error);
        })
      }),

    )
  }

  getTestToltalScore(testId) {
    console.log("data service ts filetr", testId);
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };

    const addTagApiUrl =
      environment.apiUrl + 'tests/' + testId + '/marks';

    return this.http.get<any>(addTagApiUrl, { headers: headers }).pipe(
      tap((data) => data),
      catchError(this.handleError)
    );
  }


  deleteRecipient(recipientuuid) {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const deleteItemTagApiUrl = environment.apiUrl + 'schedule/recipient/' + recipientuuid;
    console.log(deleteItemTagApiUrl);
    return this.http
      .delete<any>(deleteItemTagApiUrl, { headers: headers })
      .pipe(
        tap((data) => console.log(data)),
        // map((data: any) => {
        //   console.log(data);
        // }),
        catchError(this.handleError)
      );
  }

  getRecipient(scheduleuuId, testuuid): Observable<any> {
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = {
      'content-type': 'application/json',
      token: accessTokenFromStoarge,
    };
    const getRepicientApiUrl = `${environment.apiUrl}schedule/recipient?scheduleuuId=${scheduleuuId}&testuuId=${testuuid}`;
    return this.http
      .get<any>(getRepicientApiUrl, { headers: headers })
      .pipe(
        tap((data) => data),
        catchError(this.handleError)
      );
  }


  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }
}
