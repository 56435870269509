import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalInterceptorService implements HttpInterceptor {
  subdomain: string;
  deff: any;
  constructor(private router: Router) {

  }

  
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.getSubdomain();
    let req;
    // const d = new Date();
    // this.deff = d?.getTimezoneOffset();
    console.log(this.getTimezoneOffset())
    const currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    req = request.clone({
      setHeaders: {
        subDomainName: this.subdomain,
        TimezoneOffset: this.getTimezoneOffset(),
        "Authorization": `Bearer ${accessTokenFromStoarge}`,
      },

    });
    if (request.url.includes('tdn-api.tanayassessmentdev.com') || request.url.includes('td-api.simpliassess.com')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('examineeUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      req = request.clone({
        setHeaders: {
          subDomainName: this.subdomain,
          TimezoneOffset: this.getTimezoneOffset(),
          "Authorization": `Bearer ${accessTokenFromStoarge}`,
        },

      });
    }
    return next.handle(req).pipe(catchError(x => this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
    ;
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      if (localStorage.getItem('currentUser')) {
        localStorage.removeItem('currentUser');

      }
      this.router.navigate(['/login'], {});
      return of(err.message);
    }
    else if (err.status == 500 || err.status == 404) {
      return EMPTY;
    }
    return throwError(err);
  }

  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www'
    ) {
      this.subdomain = 'devstg';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    return this.subdomain;
  }

  getTimezoneOffset(): string {
    return(String( new Date().getTimezoneOffset()))
  }
}
