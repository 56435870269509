<!-- <div *ngIf="display && isPublished ">
  <app-tests-schedule [testuuId]='currentTestId' [testBankId]='testBankId' [isPublished]='isPublished'>
  </app-tests-schedule>

</div> -->

<div class="main-content-wrapper add-test-schedule-container">
  <div class="content-wrapper">
    <div class="">
      <form [formGroup]='modeForm'>
        <mat-radio-group formControlName="mode">
        <mat-radio-button  value="Public"> Public</mat-radio-button>
        <mat-radio-button  value="Private">Private</mat-radio-button>
        </mat-radio-group>
      </form>

      <div>

        <ng-template #newscheduletemp></ng-template>
    </div>
    </div>
  </div>
</div>