import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {


  disableProperty = new BehaviorSubject<any>(false);
  submitTestObs = new BehaviorSubject<any>(false);
  savedAnswers = {
    testuuId: undefined,
    userTestuuId: undefined,
    answers: [] as any
  };

  // choosenAnswer = {
  //   questionId : undefined,
  //   optionId :[] as any
  // }

  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  createAnswers(testuuId: any, usertestuuId: any, questionuuId: any, optionId: any, type: number) {

    let answerobj = {
      questionuuId: questionuuId,
      optionId: [] as any
    }

    this.savedAnswers.testuuId = testuuId;
    this.savedAnswers.userTestuuId = usertestuuId;
    const qindex = this.savedAnswers.answers.findIndex((element: any) => element['questionuuId'] == questionuuId);
    if (qindex == -1) {
      answerobj.optionId.push(optionId)
      this.savedAnswers.answers.push(answerobj)
    }
    else {
      if (type == 0) {
        let i = this.savedAnswers.answers[qindex]['optionId'].findIndex((t: any) => t == optionId);
        if (i == -1) {
          this.savedAnswers.answers[qindex]['optionId'].push(optionId);
        }
        else {
          this.savedAnswers.answers[qindex]['optionId'].splice(i, 1);
          if (this.savedAnswers.answers[qindex]['optionId'].length == 0) {
            this.savedAnswers.answers.splice(qindex, 1);
          }
        }
      }
      else if (type == 1) {
        this.savedAnswers.answers[qindex]['optionId'].length = 0;
        this.savedAnswers.answers[qindex]['optionId'].push(optionId)
      }
      else {
        this.savedAnswers.answers[qindex]['optionId'].length = 0;
        this.savedAnswers.answers[qindex]['optionId'].push(optionId);
      }

    }
    console.log('saved answer',this.savedAnswers);
    return this.savedAnswers;
  }

  getLocalSavedAnswers() {
    return this.savedAnswers;
  }
  submitTestQuestions(): Observable<any> {
    console.log(this.savedAnswers);
    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }

    const submitTestApiUrl = environment.examineeApiUrl + 'test-delivery/questions/answers';
    const data = JSON.stringify(this.savedAnswers);
    console.log(submitTestApiUrl)
    console.log(data)

    return this.http.post<any>(submitTestApiUrl, data, { 'headers': headers }).pipe(
      tap((data: any) => {
        console.log(data);
      }),

      catchError(this.handleError)

    )



  }

  getTest(userId: any) {

    const fetchTestapiUrl = environment.examineeApiUrl + 'test-delivery/users/' + userId + '/tests';

    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTestapiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })
    )
  }


  getExamineeTest(scheduleUId: any) {

    console.log(typeof (scheduleUId))
    const fetchTestapiUrl = environment.examineeApiUrl + 'test-delivery/schedule/' + scheduleUId;

    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    console.log(fetchTestapiUrl)
    return this.http.get<any>(fetchTestapiUrl, { 'headers': headers }).pipe(
      tap((data) => {
        data = data;
        localStorage.setItem('SchId', scheduleUId)

      }),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })
    )
  }

  getTestResult(userTestId: any) {

    const fetchTestResultapiUrl = environment.examineeApiUrl + 'test-delivery/users/' + userTestId + '/submit-test';

    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTestResultapiUrl, { 'headers': headers }).pipe(
      tap((data) => data),

      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })

    )
  }

  getTestItems(testId: any, userTestId: any) {
    const fetchTestItemsapiUrl = environment.examineeApiUrl + 'test-delivery/test/' + testId + '/items?' + 'userTestuuId=' + userTestId;
    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTestItemsapiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })
    )
  }
  // 

  putUpdateTime(testId: any, userTestId: any): Observable<any> {

    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }

    const submitTestApiUrl = environment.examineeApiUrl +'test-delivery/users/'+userTestId+'/test-timie/'+testId;
    const data = JSON.stringify(this.savedAnswers);
    // console.log(submitTestApiUrl)
    // console.log(data)
    this.loaderService.sentReqUrl(submitTestApiUrl)
    return this.http.get<any>(submitTestApiUrl, { 'headers': headers }).pipe(
      tap((data: any) => {
        console.log(data);
      }),

      catchError(this.handleError)

    )



  }

  getTestQuestions(userTestuuId: any, itemuuId: any) {
    const fetchTestQuestionsapiUrl = environment.examineeApiUrl + 'test-delivery/test/' + userTestuuId + '/items/' + itemuuId + '/questions';
    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge, 'content-type': 'application/json' }
    return this.http.get<any>(fetchTestQuestionsapiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })
    )
  }

  submitTest(usertestuuId: any) {
    const fetchTestapiUrl = environment.examineeApiUrl + 'test-delivery/users/' + usertestuuId + '/test-timie';

    const currentUserFromStorage = JSON.parse(localStorage.getItem('examineeUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge }
    return this.http.get<any>(fetchTestapiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })
    )

  }



  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }

}
