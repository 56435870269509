import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipList, MatChipSelectionChange } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { TestService } from 'src/app/shared/test.service';
import {
  map,
  concatMap,
  catchError,
  startWith,
  debounceTime,
  tap,
  switchMap,
} from 'rxjs/operators';
@Component({
  selector: 'app-add-reciepants',
  templateUrl: './add-reciepants.component.html',
  styleUrls: ['./add-reciepants.component.scss']
})
export class AddReciepantsComponent implements OnInit {
  challenges = [];
  // getSelectedChallenges = [];
  lastFilter = '';
  challengeGroup: FormGroup;
  @Input() selectedExaminee;
  @Output() PrivateInputBoxEmitter = new EventEmitter();

  public get ChallengesArray(): FormArray {
    return this.challengeGroup.get('challenges') as FormArray;
  }
  public chipSelection = new SelectionModel<any>(false, []);
  @ViewChild('chipList')
  public chipList: MatChipList;
  public filteredChallenges: Observable<any[]>;
  public challengeInput: FormControl = new FormControl('');
  testingChallenges = [];
  getOldSelectedExaminee: any;
  public allChallenges: any[];
  clearFilter: any;
  constructor(private testService: TestService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.testService.getExamineetoSchedule().subscribe((resData) => {
      this.challenges = resData?.items;
      if (this.challenges.length > 0) {
        this.buildChallengeForm();
        this.allChallenges = this.challenges;
        // console.log("----------------------->",this.allChallenges);
        this.filteredChallenges = this.challengeInput.valueChanges.pipe(
          startWith<string | any[]>(''),
          debounceTime(350),
          tap(() => {
            this.challenges = this.allChallenges.filter(
              (f) =>
                !this.getSelectedChallenges().some(
                  (x) => x.examineeId === f.examineeId
                )
            );
          }),
          map((value: string | any[]) =>
            typeof value === 'string' ? value : this.lastFilter
          ),
          map((filter) => this.filterChallenges(filter))
        );
      }
      if (this.selectedExaminee) {
        this.selectedExaminee.forEach(element => {
          this.onChallengedefault(element)
        });
      }
    });

  }



  private buildChallengeForm(): void {
    console.log('----->buildChallengeForm');
    this.challengeGroup = this.fb.group({
      challengeInput: [''],
      challenges: this.fb.array([]),
    });
  }

  public addChallenge(event: MatChipInputEvent): void {
    const { input } = event;
    if (input) {
      input.value = '';
      this.lastFilter = '';
    }
  }

  private filterChallenges(filter: string): any[] {
    console.log("eventTreegr");
    console.log("lets data", this.testingChallenges);

    // for validating private redio button input box 

    if (this.testingChallenges.length > 0) {
      console.log(this.testingChallenges.length);
      
      this.PrivateInputBoxEmitter.emit(false);
    } else {
      this.PrivateInputBoxEmitter.emit(true);

    }




    this.clearFilter = filter;
    console.log('-------->calling filter', this.clearFilter);
    console.log('-------->calling filter', this.challengeInput);
    this.lastFilter = filter;
    const filterValue = filter.toLowerCase();
    if (filterValue) {
      console.log('------>filtervalue', filterValue);
      return this.challenges.filter(
        (c) =>
          c.emailId.toLocaleLowerCase().includes(filterValue) ||
          c.firstName.toLocaleLowerCase().includes(filterValue)
      );
    }

    return this.challenges.slice();
  }

  public removeChallenge(challenge: any): void {
    // console.log('ddhjfghfsghj', challenge);
    const selected = this.chipSelection.selected[0];
    if (
      this.chipSelection.hasValue() &&
      selected.examineeId === challenge.examineeId
    ) {
      // const challengeIdx = this.selectExaminee.indexOf(selected);

      // if (challengeIdx > 0) {
      //   this.toggleSelection(this.selectExaminee[challengeIdx - 1]);
      // } else if (this.selectExaminee.length > 0) {
      //   this.toggleSelection(this.selectExaminee[challengeIdx + 1]);
      // } else {
      //   this.resetChallengeForm();
      // }

      this.chipSelection.deselect(challenge);
    }

    this.removeChallengeFromFormArray(challenge);

    this.challengeInput!.setValue('');

    if (this.testingChallenges.length > 0) {
      console.log(this.testingChallenges.length);
      
      this.PrivateInputBoxEmitter.emit(false);
    } else {
      this.PrivateInputBoxEmitter.emit(true);

    }
  }

  private removeChallengeFromFormArray(challenge: any): void {
    console.log('--------->', challenge);

    const i = this.testingChallenges.findIndex(
      (control) => control.examineeId === challenge.examineeId
    );
    //  this.getOldSelectedExamineeData(this.addTestSchedule.testScheduleId);

    if (this.getOldSelectedExaminee) {
      let checkExaminee = this.getOldSelectedExaminee.filter(
        (a) => a.examineeId == challenge.examineeId
      );
      if (checkExaminee) {
        this.challengeInput!.setValue('');

        let rcpuuid = challenge.recipientuuId;
        this.testService.deleteRecipient(rcpuuid).subscribe((resDelData) => { });
      }
    }


    this.ChallengesArray.removeAt(i);
    // console.log('--------------->controls', checkExaminee);

    if (i >= 0) {
      let rcpuuid = challenge.recipientuuId;
      this.testService.deleteRecipient(rcpuuid).subscribe((resDelData) => { });
    }
    this.challengeInput!.setValue('');
    this.testService.selectedSendInMain.next(this.ChallengesArray.value);
    if (this.testingChallenges.length > 0) {
      console.log(this.testingChallenges.length);
      
      this.PrivateInputBoxEmitter.emit(false);
    } else {
      this.PrivateInputBoxEmitter.emit(true);

    }
  }


  public chipListChanged(event: MatChipSelectionChange, challenge: any): void {
    console.log(this.chipList.chips);
    // this.recalculateChipSelection();
  }
  public onChallengedefault(challenge: any): void {
    this.addChallengeToFormArray(challenge);
  }


  public onChallengeSelected(event: Event, challenge: any): void {
    event.stopPropagation();
    this.addChallengeToFormArray(challenge);

    // this.challengeInput.setValue('');
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    // console.log("---------------------->autocomplete",event: MatAutocompleteSelectedEvent);
    const challenge = this.challenges.find(
      (f) => f.emailId === event.option.viewValue
    );

    if (challenge) {
      // alert(challenge);
      this.addChallengeToFormArray(challenge);
      this.challengeInput.setValue('');
    }

    this.lastFilter = '';

    if (this.testingChallenges.length > 0) {
      console.log(this.testingChallenges.length);
      
      this.PrivateInputBoxEmitter.emit(true);
    } else {
      this.PrivateInputBoxEmitter.emit(false);

    }
  }

  public handleCheckboxCheck(event: MatCheckboxChange, challenge: any): void {
    if (event.checked) {
      this.addChallengeToFormArray(challenge);
    } else {
      this.removeChallenge(challenge);
    }

    this.challengeInput!.setValue('');
  }


  private addChallengeToFormArray(challenge: any): void {
    const i = this.ChallengesArray.controls.findIndex(
      (control) => control.value.examineeId === challenge.examineeId
    );
    if (i < 0) {
      // console.log("----challeges get uuid",challenge);
      this.ChallengesArray.push(this.fb.control(challenge));
      this.testService.selectedSendInMain.next(this.ChallengesArray.value);
    }
    // console.log('------------Challenges aarye', this.ChallengesArray);
  }

  getSelectedChallenges() {
    let challenges: any[] = [];
    // challenges = this.abcd;
    // console.log("this.ChallengesArray",this.ChallengesArray);
    this.testingChallenges = [];
    this.ChallengesArray.controls.forEach((c) => {
      if (c && c.value) {
        this.testingChallenges.push(c.value);
      }
    });

    return this.testingChallenges;
  }
}
