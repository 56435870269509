import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { QuestionService } from 'src/app/shared/question.service';

@Component({
  selector: 'app-choose-question-type',
  templateUrl: './choose-question-type.component.html',
  styleUrls: ['./choose-question-type.component.scss']
})
export class ChooseQuestionTypeComponent implements OnInit {
  setQuestionOption = true;
  setFillinthBlank = false;
  flag: boolean = true;
  flag1: boolean = true;
  flag2: boolean = true;
  displaybtn: boolean = true;
  backbtnflag: boolean = false;

  tab: any = 'tab1';
  itemId: any;
  iteBankQueId: any;
  countries:any=['Canada','Australia','Russia','Brazil'];
  yesOrNo:any=['TRUE','FALSE'];
  @Output() backed = new EventEmitter();
  // tab1 : any
  // tab2 : any
  // tab3 : any
  // tab4: any
  // tab5: any
  constructor(private router: Router, private routerstate: ActivatedRoute, private questionService: QuestionService) {

    this.iteBankQueId = this.router.getCurrentNavigation()?.extras?.state?.iteBankQueId;
    console.log("--->this.iteBankQueId id", this.iteBankQueId)
    this.routerstate?.parent?.params?.subscribe(data => {
      this.itemId = data.id;
      // console.log(data);
    });
  }



  ngOnInit(): void {


    console.log("this.itemId", this.itemId);
    // this.routerstate
    // .queryParams
    // .subscribe(params => {
    //   // Defaults to 0 if no query param provided.
    //    console.log(params['yourParamName']);
    // });




    this.tab = 'tab1';
  }

  navigateToAddQuestionsTyperadio() {
    console.log("hey", this.itemId);
    this.flag = false;
    this.displaybtn = false;
    this.flag1 = true;
    this.flag2 = true;

    // state:{itemId:this.itemId,queId:ele.questionid}
    // this.router.navigate(["../addstandardmcquestion"], { relativeTo: this.routerstate, state: { itemId: this.itemId, iteBankQueId: this.iteBankQueId } });
  }

  navigateToAddQuestionsTypecheckbox() {
    console.log("hey", this.iteBankQueId);
    this.flag1 = false;
    this.displaybtn = false;
    this.flag = true;
    this.flag2 = true;


    // this.router.navigate(["../addstandardquestioncheckbox"], { relativeTo: this.routerstate, state: { itemId: this.itemId, iteBankQueId: this.iteBankQueId } });
  }
  navigateToAddQuestionsTypeTruFalse() {
    // console.log("hey");
    this.displaybtn = false;
    this.flag2 = false;
    this.flag1 = true;
    this.flag = true;
    // this.router.navigate(["../addstandardquestiontruefalse"], { relativeTo: this.routerstate, state: { itemId: this.itemId, iteBankQueId: this.iteBankQueId } });
  }

  navigateToItemDetails(showFlag) {
    console.log('trigger back',showFlag);
    //  this.router.navigate(["./"]);
    //this.router.navigate(["../items"],{relativeTo: this.routerstate});
    //this.router.navigate(["/items/selectquestiontype"],{relativeTo: this.routerstate});
    // this.backbtnflag = true;
    //this.localStorage.setItem("backbtnflag","1");
    // localStorage.setItem("backbtnflag", "1");
    this.backed.emit(showFlag);
    this.questionService.backButtonObs.next(showFlag);
  }

  gotoMultipleChoice() {
    this.tab = 'tab1';
    this.setQuestionOption = true;
    this.setFillinthBlank = false;

  }
  gotFillinthBlank() {
    this.tab = 'tab2';
    this.setFillinthBlank = true;
    this.setQuestionOption = false;
  }
  gotoMatchandOrders() {
    this.tab = 'tab3';
    this.setQuestionOption = false;
  }
  gotoFreeText() {
    this.tab = 'tab4';
    this.setQuestionOption = false;
  }
  gotoHotSpot() {
    this.tab = 'tab5';
    this.setQuestionOption = false;
  }


}
