import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ItemsUploadService {

  constructor(private http: HttpClient) { }

  DownloadFile(filePath: string, fileType: string): Observable<any> {

    const fetchDownloadApiUrl = environment.apiUrl + 'item/download/template';
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge };

    let fileExtension = fileType;
    let input = filePath;

    return this.http.get(fetchDownloadApiUrl + "?fileName=" + input, {

      responseType: 'blob',
      observe: 'response',
      'headers': headers
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: fileExtension });
        })
      );
  }

  public upload(formData) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    // const clientId = currentUserFromStorage?.clientId;
    const collectionId = localStorage.getItem('defaultcollectionid');
    const fetchDownloadApiUrl = environment.apiUrl + 'item/upload/template?collectionId='+ collectionId;
    console.log(fetchDownloadApiUrl);
    const headers = { 'token': accessTokenFromStoarge };
    const httpOptions = { 'content-type': 'multipart/form-data; boundary=<calculated when request is sent>', 'headers': headers };
    return this.http.post(fetchDownloadApiUrl, formData, httpOptions).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration 
        return throwError(error);
      })
    )
  }
}
