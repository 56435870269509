
<div class="dialog-container">
  <div class="dialog-heading">
      <h2 mat-dialog-title>Update Collection details</h2>
      <span class="spacer"></span>
      <div class="btn-close">
          <button mat-icon-button mat-dialog-close aria-label="Close Dialog"><mat-icon>close</mat-icon></button>
      </div>
  </div>
  <mat-dialog-content class="mat-typography">
   <form [formGroup]="userForm">
      <div class="form-wrapper">
        <div class="input-wrapper">
            <span class="input-label">
              <label for="">Collection name</label><span class="required">*</span>
            </span>
            <span class="input-field">
              <input type="text" maxlength="121" class="form-control" placeholder="" formControlName="collectionName">
              <div *ngIf="f.collectionName.invalid && (f.collectionName.dirty || f.collectionName.touched)"
                class="alert alert-danger">
                <div *ngIf="f.collectionName.errors?.required"> Collection name is required</div>
                <div *ngIf="f.collectionName.errors?.minlength"> Minimum 1 characters required</div>
                <div *ngIf="f.collectionName.errors?.maxlength"> Maximum 120 characters allowed</div>
                <div *ngIf="f.collectionName.errors?.pattern">Collection name is invalid</div>
              </div>
            </span>
        </div>
        <div class="input-wrapper">
            <span class="input-label">
              <label>Collection description</label>
            </span>
            <span class="input-field">
             <textarea type="text"  class="form-control textarea" maxlength="481" placeholder="" formControlName="description"></textarea>
             <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)" class="alert alert-danger">
               <div *ngIf="f.description.errors?.minlength">Minimum 1 characters required</div>
               <div *ngIf="f.description.errors?.maxlength"> Maximum 480 characters allowed</div>
             </div>
            </span>
        </div>
        <div class="input-wrapper">
          <span class="input-label">
           <label for="">Language</label><span class="required">*</span>
          </span>
          <span class="input-field">
          <!-- <select  type="text" class="form-control" name="language" [(value)]="selectedLanguage" formControlName="langid">
            <option value="">Language</option>
            <option *ngFor="let option of languageArr" [value]="option?.langId">
              {{option?.language}}
            </option>
          </select>   -->
          <select aria-placeholder="language" [(value)]="selectedLanguage" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="" formControlName="langid">
            <!-- <option value="" ng-reflect-value="">Language</option> -->
            <option *ngFor="let option of languageArr" [value]="option?.langId">
              {{option?.language}}
            </option>
          </select>
          <div *ngIf="f.langid.invalid && (f.langid.dirty || f.langid.touched)" class="alert alert-danger">
            <div *ngIf="f.langid.errors?.required"> Language is required</div>
          </div>
         </span>
       </div>
       <div class="input-wrapper">
        <span class="input-label">
         <label for="">Timezone</label><span class="required">*</span>
        </span>
        <span class="input-field">
         <!-- <select  type="text" class="form-control" name="timezone" [(value)]="timeZone" formControlName="timezoneid">
          <option value="">Time zone</option>
           <option *ngFor="let option of timeZoneArr" [value]="option?.timezoneid">
             {{option?.standardname}}
           </option>
         </select> -->
         <select aria-placeholder="Status" [(value)]="timeZone" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="" formControlName="timezoneid">
          <!-- <option value="" ng-reflect-value="">Time zone</option> -->
          <option *ngFor="let option of timeZoneArr" [value]="option?.standardname">
            {{option?.standardname}}
          </option>
        </select>
         <div *ngIf="f.timezoneid.invalid && (f.timezoneid.dirty || f.timezoneid.touched)" class="alert alert-danger">
           <div *ngIf="f.timezoneid.errors?.required"> Time zone is required</div>
         </div>
        </span>
       </div>
      </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" class="btn-blue"
    aria-label="Save" (click)="saveBtnFn()" [disabled]="userForm.invalid" >Save</button>
</mat-dialog-actions>
</div>









<!-- ******************************************************************************************************************* -->

<!-- <div class="module-wrapper content-wrapper">
  <div class="tab-container">
    <mat-card>
      <mat-card-content>
        <div class="head-container">
          <div>
            <h1>Collection Management</h1>
            <div class="breadcrumb-container">
              <a href="" class="link">Collection name : {{collectionNameDisplay}}</a>
            </div>
          </div>
        </div>
        <div class="item-info-container">
          <div>
                  <div class="item-name"><span>Collection name : {{collectionNameDisplay}}</span> <span class="item-active"><i-feather name="disc"></i-feather> <span>Active</span></span> </div>
                  <div class="item-desc">Collection description : {{collectionDescriptionDisplay}} </div>
          </div>
          <span class="spacer"></span>
          <div>
              <div class="btn-wrapper">
                  <button mat-icon-button class="btn-orange" aria-label="Add New Item" (click)="openEditCollectionDialog()"><span class="menu-icon"><i-feather name="edit"></i-feather></span></button>
              </div>
          </div>
      </div>
      <mat-tab-group mat-align-tabs="end" animationDuration="0ms">
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="menu-icon"><i-feather name="help-circle"></i-feather></span>
            <span>Details</span>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="menu-icon"><i-feather name="help-circle"></i-feather></span>
            <span>Users</span>
         </ng-template>
          <div class="tab-content">
            <app-collection-users></app-collection-users>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="menu-icon"><i-feather name="help-circle"></i-feather></span>
            <span>License</span>
         </ng-template>
          <div class="tab-content">

          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="menu-icon"><i-feather name="help-circle"></i-feather></span>
            <span>Other options</span>
         </ng-template>
          <div class="tab-content">

          </div>
        </mat-tab>
      </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>

 -->




<!-- ********************************************************************************************************************************** -->


<!-- <div class=" main-content-wrapper edit-collection">
  <div class="content-wrapper">
    <div class="filter-section">
      <button class="btn-back" mat-raised-button mat-flat-button color="basic" (click)="navigatetoCollection()">
                                                <i class="fa fa-angle-left"></i>
                                                <span>Back</span>
                                            </button>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="tabs">
          <span class="collection-name">Collection name : {{collectionNameDisplay}}</span>
          <mat-tab-group mat-align-tabs="end" animationDuration="0ms">
            <mat-tab label="Details">
              <div class="col-md-12">
                <div class="registration-form">
                  <form [formGroup]="userForm">
                    <div class="form-input save-btn">
                      <button mat-flat-button class="btn-bg-color" [disabled]="userForm.invalid" (click)="saveBtnFn()"> Save</button>
                    </div>
                    <div class="input-class">
                      <label>Collection name<span class="red-asterisk"> *</span></label>
                      <input type="text" maxlength="121" class="form-control" placeholder="" formControlName="collectionName">
                      <div *ngIf="f.collectionName.invalid && (f.collectionName.dirty || f.collectionName.touched)"
                        class="alert alert-danger">
                        <div *ngIf="f.collectionName.errors?.required"> Collection name is required.</div>
                        <div *ngIf="f.collectionName.errors?.minlength"> Minimum 3 characters required.</div>
                        <div *ngIf="f.collectionName.errors?.maxlength"> Maximum 120 characters allowed.</div>
                      </div>
                    </div>
                    <div class="input-class">
                      <label>Collection description</label>
                      <textarea  type="text" maxlength="481" class="form-control textarea"  placeholder="" formControlName="description"></textarea>
                      <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)" class="alert alert-danger">
                        <div *ngIf="f.description.errors?.minlength"> Minimum 20 characters required.</div>
                        <div *ngIf="f.description.errors?.maxlength"> Maximum 480 characters allowed.</div>
                      </div>
                    </div>
                    <div class="input-class">
                      <label>Language<span class="red-asterisk"> *</span></label>
                      <select  type="text" class="form-control" name="language" [(value)]="selectedLanguage" formControlName="langid">
                        <option *ngFor="let option of languageArr" [value]="option?.langId">
                          {{option?.language}}
                        </option>
                      </select>
                      <div *ngIf="f.langid.invalid && (f.langid.dirty || f.langid.touched)" class="alert alert-danger">
                        <div *ngIf="f.langid.errors?.required"> Language is required. </div>
                      </div>
                    </div>
                    <div class="input-class">
                      <label>Time zone<span class="red-asterisk"> *</span></label>
                      <select  type="text" class="form-control" name="timezone" [(value)]="timeZone" formControlName="timezoneid">
                        <option *ngFor="let option of timeZoneArr" [value]="option?.timezoneid">
                          {{option?.standardname}}
                        </option>
                      </select>
                      <div *ngIf="f.timezoneid.invalid && (f.timezoneid.dirty || f.timezoneid.touched)" class="alert alert-danger">
                        <div *ngIf="f.timezoneid.errors?.required"> Time zone is required. </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Users">
                <app-collection-users></app-collection-users>
            </mat-tab>

            <mat-tab label="License">

            </mat-tab>
            <mat-tab label="Other options">

            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

    </div>
  </div> -->



