<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title>Create new User</h2>
    <span class="spacer"></span>
    <div class="btn-close">
        <button mat-icon-button mat-dialog-close aria-label="Close Dialog"><mat-icon>close</mat-icon></button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="userDetailsForm" novalidate>
    <div class="form-wrapper">
      <div class="input-wrapper">
        <span class="input-label">
          <label>First name</label><span class="required">*</span>
        </span>
        <span class="input-field">
          <input type="text" maxlength="31" class="form-control" formControlName="firstName">
          <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
            <div *ngIf="f.firstName.errors?.required"> First name is required</div>
            <div *ngIf="f.firstName.errors?.minlength"> Minimum 2 characters required</div>
            <div *ngIf="f.firstName.errors?.maxlength">Maximum 30 characters allowed</div>
            <div *ngIf="f.firstName.errors?.pattern">Special characters, numbers and blank spaces are not allowed</div>
          </div>
        </span>
      </div>
      <div class="input-wrapper">
        <span class="input-label">
          <label>Last name</label><span class="required">*</span>
        </span>
        <span class="input-field">
          <input type="text" maxlength="31"  class="form-control" formControlName="lastName">
          <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
            <div *ngIf="f.lastName.errors?.required"> Last name is required</div>
            <div *ngIf="f.lastName.errors?.minlength"> Minimum 2 characters required</div>
            <div *ngIf="f.lastName.errors?.maxlength">Maximum 30 characters allowed</div>
            <div *ngIf="f.lastName.errors?.pattern">Special characters, numbers and blank spaces not allowed</div>
          </div>
        </span>
      </div>
      <div class="input-wrapper">
        <span class="input-label">
          <label>Phone</label>
        </span>
        <span class="input-field">
          <input type="text" maxlength="31"  class="form-control" formControlName="phone" (keydown)="onKeydown($event)" onkeypress='return event.charCode>-48 && event.charCode<=57'>
          <div class="col-md-12" *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="alert alert-danger">
            <div *ngIf="f.phone.errors?.required">Phone is required</div>
            <div *ngIf="f.phone.errors?.minlength"> Minimum 5 characters required</div>
            <div *ngIf="f.phone.errors?.maxlength">Maximum 30 characters allowed</div>
            <div *ngIf="f.phone.errors?.pattern?.requiredPattern">Phone is invalid</div>
          </div>
        </span>
      </div>
      <div class="input-wrapper">
        <span class="input-label">
          <label>Email</label><span class="required">*</span>
        </span>
        <span class="input-field">
          <input type="email" maxlength="125" class="form-control" formControlName="emailId">
          <div class="col-md-12" *ngIf="f.emailId.invalid && (f.emailId.touched || f.emailId.dirty)" class="alert alert-danger">
            <div *ngIf="f.emailId.errors?.required">Email is required</div>
            <div *ngIf="f.emailId.errors?.minlength"> Minimum 7 characters required</div>
            <div *ngIf="f.emailId.errors?.maxlength">Maximum 124 characters allowed</div>
            <div *ngIf="f.emailId.errors?.pattern">Email is invalid</div>
          </div>
        </span>
      </div>
      <div class="input-wrapper">
        <span class="input-label">
          <label>Password</label><span class="required">*</span>
        </span>
        <span class="input-field">
          <input type="password" maxlength="21" class="form-control" formControlName="password">
          <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert alert-danger">
            <div *ngIf="f.password.errors?.required">Password is required</div>
            <div *ngIf="f.password.errors?.maxlength">Maximum 20 characters allowed</div>
            <div *ngIf="f.password.errors?.pattern">Password should be minimum 6 characters with at least one special character, one number, one uppercase and one lowercase letter</div>
          </div>
        </span>
      </div>
      <div class="input-wrapper">
        <span class="input-label">
          <label>Status</label><span class="required">*</span>
        </span>
        <span class="input-field">
          <!-- <select class="form-control" formControlName="status">
            <option value="">Status</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select> -->
          <select aria-placeholder="Status" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="" formControlName="status">
            <option value="" ng-reflect-value="">Status</option>
            <option value="true" ng-reflect-value="">Active</option>
            <option value="false" ng-reflect-value="">Inactive</option>
        </select>
                <div *ngIf="f.status.invalid && (f.status.dirty || f.status.touched)" class="alert alert-danger">
                  <div *ngIf="f.status.errors?.required"> Status is required</div>
                </div>
        </span>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" class="btn-blue" aria-label="Save"
    (click)="saveUserDetails()" mat-raised-button [disabled]='userDetailsForm.invalid'>Save</button>
</mat-dialog-actions>
</div>


<!--
<div class="main-content-wrapper item-bank-details-container">
  <div class="content-wrapper"> -->
    <!-- <mat-tab-group>
      <mat-tab label="Add New User"> -->
    <!-- <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="filter-section">
            <button mat-raised-button (click)='navigateToUsersLanding()'>
              <i class="fa fa-angle-left"></i>
              <span> Back</span>
            </button>
          </div> -->
          <!-- <div class="title filter-section">
            <div>User Details</div>
          </div> -->
          <!-- <div class="errorMsg" *ngIf="duplicateDataFlag"> -->
            <!-- <h4>{{ errorMessage }}</h4> -->
          <!-- </div>
          <div class="btn-wrapper">
            <button (click)="saveUserDetails()" mat-raised-button color="accent" class="btn-bg-color"
              [disabled]='userDetailsForm.invalid'>Save</button>
          </div>
          <div class="tabs">
            <form [formGroup]="userDetailsForm" novalidate>
              <div class="input-class">
                <label>First name<span class="red-asterisk">*</span></label>
                <input type="text" maxlength="31" class="form-control" formControlName="firstName">
                <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
                  <div *ngIf="f.firstName.errors?.required"> First name is required. </div>
                  <div *ngIf="f.firstName.errors?.minlength"> Minimum 2 characters required. </div>
                  <div *ngIf="f.firstName.errors?.maxlength">Maximum 30 characters allowed. </div>
                  <div *ngIf="f.firstName.errors?.pattern">Special characters, numbers and blank spaces are not allowed. </div>
                </div>
              </div>
              <div class="input-class">
                <label>Last name<span class="red-asterisk">*</span></label>
                <input type="text" maxlength="31"  class="form-control" formControlName="lastName">
                <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
                  <div *ngIf="f.lastName.errors?.required"> Last name is required. </div>
                  <div *ngIf="f.lastName.errors?.minlength"> Minimum 2 characters required.  </div>
                  <div *ngIf="f.lastName.errors?.maxlength">Maximum 30 characters allowed.</div>
                  <div *ngIf="f.lastName.errors?.pattern">Special characters, numbers and blank spaces not allowed </div>
                </div>
              </div>
              <div class="input-class">
                <label>Phone</label>
                <input type="text" maxlength="25"  class="form-control" formControlName="phone" onkeypress='return event.charCode>-48 && event.charCode<=57'>
                <div class="col-md-12" *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="alert alert-danger">
                  <div *ngIf="f.phone.errors?.required">Phone is reqired.</div>
                  <div *ngIf="f.phone.errors?.minlength"> Minimum 5 characters required. </div>
                  <div *ngIf="f.phone.errors?.maxlength">Maximum 24 characters allowed.</div>
                  <div *ngIf="f.phone.errors?.pattern?.requiredPattern">Phone is invalid.</div>
                </div>
              </div>
              <div class="input-class">
                <label>Email<span class="red-asterisk">*</span></label>
                <input type="text" maxlength="125" class="form-control" formControlName="emailId">
                <div class="col-md-12" *ngIf="f.emailId.invalid && (f.emailId.touched || f.emailId.dirty)" class="alert alert-danger">
                  <div *ngIf="f.emailId.errors?.required">Email is required.</div>
                  <div *ngIf="f.emailId.errors?.minlength"> Minimum 7 characters required.</div>
                  <div *ngIf="f.emailId.errors?.maxlength">Maximum 124 characters allowed.</div>
                  <div *ngIf="f.emailId.errors?.pattern">Email is invalid.</div>
                </div>
              </div>
              <div class="input-class">
                <label>Password<span class="red-asterisk">*</span></label>
                <input type="password" maxlength="21" class="form-control" formControlName="password">
                <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                  class="alert alert-danger">
                  <div *ngIf="f.password.errors?.required">Password is required. </div>
                  <!-- <div *ngIf="f.password.errors?.minlength">Minimum 6 characters required.</div> -->
                  <!-- <div *ngIf="f.password.errors?.maxlength">Maximum 20 characters allowed. </div>
                  <div *ngIf="f.password.errors?.pattern">Password should be minimum 6 characters with at least one special character, one number, one uppercase and one lowercase letter.</div>
                </div>
              </div>
              <div class="input-class">
                <label>Status<span class="red-asterisk">*</span></label>
                <select class="form-control" formControlName="status"> -->
                  <!-- <option *ngFor="let statusList of testStatusList"
                          value="{{statusList.itemstatus}}">{{statusList.itemstatus}}</option> -->
                  <!-- <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
