
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemService } from 'src/app/shared/item.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TestService } from 'src/app/shared/test.service';
import { FlipitemtabService } from '../flipitemtab.service';
import { MessageService } from 'src/app/message.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { SelectionModel } from '@angular/cdk/collections';
import { switchMap } from 'rxjs/operators';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

export interface UserData {
  itemname: string;
  itemdescription: string;
  tags: any;
  isItemTag: boolean;
  // isTestTag: boolean;
  updatedDate: any;
  updatedBy: string;
}

@Component({
  selector: 'app-tests-existing-items',
  templateUrl: './tests-existing-items.component.html',
  styleUrls: ['./tests-existing-items.component.scss']
})
export class TestsExistingItemsComponent implements OnInit, OnDestroy, AfterViewInit {
  itemServiceSubscription$: Subscription | undefined;
  itemFilterServiceSubscription$: Subscription | undefined;
  items: any;
  displayedColumns: string[] = ['itemBankName', 'questiontype', 'itemStatus', 'updated', 'tags'];
  @Output() closeExistingItemEvent = new EventEmitter<boolean>();
  searchText: any;
  page: number = 1;
  itemName = "";
  itemDescription = "";
  tagDescription = "";
  itemStatus = "";
  isItemDescended = true;
  noDataFound = false;
  isNoItems = false;
  totalRecords: any;
  testuuId: any;

  // pageOfItems: any;
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<UserData>(true, []);

  itemIds: any = [];
  itemJsonData: any = [];
  testStatusList: any;
  globalcollectionuuid: any;
  messageFlag = false;
  success = false;
  error = false;
  messages = '';
  selectedItem: any = [];
  constructor(
    private itemService: ItemService,
    private testService: TestService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private flipitemtabService: FlipitemtabService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private routerstate: ActivatedRoute,
    // @Inject(Document) private _document : Document
  ) { }

  ngOnInit(): void {
    this.messageService.clearMessagesNext();
    this.globalcollectionuuid = JSON.parse(localStorage.getItem('defaultcollectionuuid'));

    this.testService.getTestStatusList()?.subscribe(data => {
      this.testStatusList = data?.items;
      console.log(this.testStatusList);
    });

    this.routerstate.paramMap.subscribe(data => {
      this.testuuId = data.get('id');
    })

    const options = '&itemStatus=Published';
    this.itemServiceSubscription$ = this.itemService.getItemByQuery(this.globalcollectionuuid, this.testuuId, options)?.subscribe(
      (data) => {

        console.log('resdatttttttttttttttttttttttttt', data);
        this.items = data?.data;
        console.log(this.items);
        if (this.items.length == 0) {
          this.isNoItems = true;
        }
        this.items = new MatTableDataSource(this.items);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;
      },
      (error) => {
        console.log('dj');
        console.log(error);
        //  this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;
  }

  // Back to Items tab from existing item
  gotoItems() {



    // this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};
    // let currentUrl = this.router.url + '?';
    // this.router.navigateByUrl(currentUrl)
    //   .then(() => {
    //     this.router.navigated = false;
    //     this.router.navigate([this.router.url]);
    //   });
    this.closeExistingItemEvent.emit(true);
  }



  ngAfterViewInit(): void {
    this.items.paginator = this.paginator;
    this.items.sort = this.sort;
  }


  // checkboxLabel(row ?: UserData,): string {
  //     return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.itemname + 5}`;
  //   }

  // This function Used to select checkbox with itemid
  // selectRowToggle(id: any){
  //     console.log('new', id)

  //   if(this.itemIds.some((e: any) => e === id)) {
  //     this.itemIds = this.itemIds.filter((item: any) => item !== id);
  //   } else {
  //     this.itemIds.push(id);
  //   }
  //   console.log(this.itemIds);
  // }


  // getItemId(e:any, id:any){
  //   if(e.target.checked){
  //     console.log(id + 'checked');
  //     this.selectedItem.push(id);
  //   }
  //   else{
  //     console.log(id + 'Unchecked');
  //     this.selectedItem =  this.selectedItem.filter(item => item!=id);

  //   }
  //   console.log( this.selectedItem);
  // }

  toggleCheckbox(event: any) {
    console.log(event);
    if (this.itemIds.some((e: any) => e === event.source.id)) {
      this.itemIds = this.itemIds.filter((item: any) =>
        item !== event.source.id);
    } else {
      this.itemIds.push(event.source.id);
    }
    console.log(this.itemIds);
  }


  saveItemsToTest() {
    this.messageService.getMessageNext().subscribe(data => {
      this.messageFlag = true;
      this.success = false;
      this.error = true;
      this.messages = data?.text;
      // this.notificationService.warn("System doesn't allow to have any modifications to Test whose status is published");
    });

    if (this.actRoute.snapshot.paramMap.get('id') === null) {

    } else {
      this.itemJsonData = [];
      const testuuId = this.actRoute.snapshot.paramMap.get('id');
      const itemBankIds = this.itemIds;
      this.itemIds.forEach((element: any) => {
        this.itemJsonData.push({
          itemBankuuId: element,
          itemRenderOrder: 0
        });
      });
      console.log(this.itemJsonData);
      if (this.itemJsonData.length > 0) {
        this.testService.addItemToTest(testuuId, itemBankIds, this.itemJsonData).subscribe(resData => {
          console.log(resData.data);
          if (resData.data.length > 0) {
            this.notificationService.success(resData?.message);  // This line used to display message
            this.flipitemtabService.emit<boolean>(false);
            this.closeExistingItemEvent.emit(true);
          }
        }, (error) => {
          this.notificationService.warn(error?.error?.message);   // This line used to display message
          // this.messageService.sendMessageNext(error.error.message);
          // console.log(error.error.message);
        });

      }
    }
  }


  itemFilter(): void {
    const testuuId = this.actRoute.snapshot.paramMap.get('id');
    console.log(testuuId);
    const filterObj = {
      // itemId: this.globalcollectionuuid,
      collectionuuid: this.globalcollectionuuid,
      testuuId: testuuId,
      itemName: this.itemName,
      itemDescription: this.itemDescription,
      tag: this.tagDescription,
      itemStatus: this.itemStatus
    };
    this.itemFilterServiceSubscription$ = this.itemService.getItemTestFilter(filterObj)?.subscribe(
      (resData) => {
        this.items = resData?.data;
        console.log(this.items);
        if (this.items.length == 0) {
          this.noDataFound = true;
        }
        this.items = new MatTableDataSource(this.items);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
        this.isNoItems = true;


        // this.router.navigate(["/login"]);

      },
      () => {
        // console.log('item service subscription completed');
      }) || undefined;
    this.noDataFound = false;
  }

  onChangePage(pageOfItems: Array<any>) {
    this.totalRecords = this.items.length;
    // console.log(this.totalRecords);
  }

  // onChangePage(pageOfItems: Array<any>) {
  //   console.log("function call");
  //   this.totalRecords = this.items.length;
  //   console.log(this.totalRecords);
  // }

  // dateSort(): void {
  //   this.isItemDescended = !this.isItemDescended;
  //   this.items.sort(this.sortdate);
  //   console.log(this.items);
  // }



  // sortdate(a: any, b: any) {
  //   //return new Date(a.updated).getTime() - new Date(b.updated).getTime();
  //   //return new Date(a.updated).getTime() - new Date(b.updated).getTime() ;
  //   return b.updated > a.updated;
  // }

  resetFilter() {
    this.itemIds = [];
    this.itemName = "";
    this.itemDescription = ""
    this.itemStatus = "";
    this.tagDescription = "";
    this.noDataFound = false;
    const options = '&itemStatus=Published';
    this.itemServiceSubscription$ = this.itemService.getItemByQuery(this.globalcollectionuuid, this.testuuId, options)?.subscribe(
      (data) => {
        console.log('resdatttttttttttttttttttttttttt', data);
        this.items = data?.data;
        console.log(this.items);
        this.items = new MatTableDataSource(this.items);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;
        if (this.items.paginator.paginator) {
          this.items.paginator.paginator.firstPage();
        }
      },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

  }

  navigateToItemDetails(id: any) {
    this.router.navigate(["/tests-details", id]);
  }

  createNewItem() {
    this.router.navigate(["../../create-new-item"], { relativeTo: this.actRoute });
  }



  ngOnDestroy() {
    if (this.itemServiceSubscription$) {
      this.itemServiceSubscription$.unsubscribe();
    }
    if (this.itemFilterServiceSubscription$) {
      this.itemFilterServiceSubscription$.unsubscribe();
    }
  }

}
