import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlipitemtabService {

  // public subject = new BehaviorSubject<any>('');
  public subject: BehaviorSubject<boolean>;

  constructor() {
    this.subject = new BehaviorSubject<boolean>(false);
  }

  emit<T>(data:boolean) {
    this.subject.next(data);
  }

  on<T>(): Observable<boolean> {
    return this.subject.asObservable();
  }

}
