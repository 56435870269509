import { Component, HostListener, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppService } from '../shared/app.service';
import { CollectionService } from '../shared/collection.service';
import { ShareDataService } from '../shared/share-data.service';

import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { NavigationStart } from "@angular/router";
import { ReportsService } from '../shared/reports.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  isExpanded = true;
  isShowing = false;
  status = false;
  show = false;
  collectionData$: Subscription | undefined;
  collectionData: any;
  selectedCollectionName = "TEJ";
  userDetails: any = {
    parentId: 0,
    userId: 0,
    roleDetails: {
      id: 0,
      name: '',
      description: '',
      privileges: []
    },
    userName: ''
  };
  firstName: string;
  lastName: string;
  collectionIdToNavigate: string;
  collectionuuIdToNavigate: any;
  tagUrl = "/collections/";
  emailId: any;
  searchOptions = [];
  filteredList: any;
  selected: any;
  selectedCollection;

  constructor(private reportService: ReportsService, private router: Router, private appService: AppService, private collectionService: CollectionService,
    private shareDataService: ShareDataService, private routerState: ActivatedRoute) {

    router.events
      .pipe(
        filter(
          (event: NavigationEvent) => {
            return (event instanceof NavigationStart);
          }
        )
      )
      .subscribe(
        (event: NavigationStart) => {
          // console.group("NavigationStart Event");
          // console.log("navigation id:", event.id);
          // console.log("route:", event.url);
          // console.log("trigger:", event.navigationTrigger);
          if (event.restoredState) {
            console.warn("restoring navigation id:", event.restoredState.navigationId);
          }
          console.groupEnd();
        }
      )
      ;
  }
  mouseenter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');

  }
  mouseleave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  ngOnInit(): void {
    // const currentCollection = JSON.parse(localStorage?.getItem('currentCollection'));
    if ((JSON.parse(localStorage.getItem('currentUser') || '{}').firstName) || (JSON.parse(localStorage.getItem('currentUser') || '{}').firstName)) {
      this.firstName = JSON.parse(localStorage.getItem('currentUser') || '{}').firstName;
      this.lastName = JSON.parse(localStorage.getItem('currentUser') || '{}').lastName;
      this.emailId = JSON.parse(localStorage.getItem('currentUser') || '{}').emailId;

    } else {
      this.firstName = '';
      this.lastName = ''
    }

    this.shareDataService.collectionData.subscribe(data => {
      this.selectedCollectionName = data?.collectionName;
    });

    this.collectionService.getNewCollectionData.subscribe(data => {
      this.loadCollections();
    });

    this.shareDataService.globalCollectionuuId$.subscribe(glCollectionUUID => {
      this.collectionuuIdToNavigate = glCollectionUUID;
    });

    this.loadCollections();

  }

  ngOnChanges() {
  }

  navigationRefresh(): void {
    let routeflag = localStorage.getItem('ReportRoute')
    if (routeflag === 'true') {
      localStorage.removeItem('ReportRoute')
      window.location.reload();
    }
  }

  loadCollections(): void {
    const currentCollectionuuId = JSON.parse(localStorage?.getItem('defaultcollectionuuid'));

    this.collectionData$ = this.collectionService.getCollection()?.subscribe((collectionData: any) => {
      this.collectionData = collectionData?.data;
      // Dropdown Search filter
      this.searchOptions = this.collectionData;
      this.selected = this.searchOptions[0];
      this.filteredList = this.searchOptions.slice();
      if (currentCollectionuuId) {
        this.selectedCollection = this.filteredList?.filter(x => x?.collectionuuId == currentCollectionuuId)[0];
        this.selectedCollection = this.selectedCollection?.collectionName;
      }

      this.selectedCollectionName = this.collectionData[0].collectionName;
      if (localStorage.getItem('defaultcollectionuuid')) {
        let data = JSON.parse(localStorage.getItem('defaultcollectionuuid') || '{}');
        this.collectionuuIdToNavigate = data;
        this.tagUrl = this.tagUrl + this.collectionIdToNavigate + '/tags';
        this.selectedCollectionName = this.collectionData.find((d) => d['collectionuuId'] === data)?.collectionName;
      }
      else {
        this.selectedCollectionName = this.collectionData[0].collectionName;
        this.collectionuuIdToNavigate = this.collectionData[0].collectionuuId;
        this.tagUrl = this.tagUrl + this.collectionuuIdToNavigate + '/tags';
      }
    })
  }
  navHide() {

  }
  schedule() {
    this.router.navigate(['/collections/' + this.collectionuuIdToNavigate + '/schedules']);
  }

  navDashboard(): void {
    this.router.navigate(['/collections/' + this.collectionuuIdToNavigate + '/dashboard']);
  }

  navItems(): void {
    this.router.navigate(['/collections/' + this.collectionuuIdToNavigate + '/items']);
  }

  navTestLanding(): void {
    this.router.navigate(['/collections/' + this.collectionuuIdToNavigate + '/test']);
  }
  navTags(): void {

    this.router.navigate(["/collections/" + this.collectionuuIdToNavigate + "/tags"]);
  }

  navUsers() {
    this.router.navigate(['/users']);
  }
  navExamineee() {
    this.router.navigate(['/examinees']);
  }
  navExamineeGroup() {
    this.router.navigate(['/examinee-group']);
  }
  navReports(): void {

    this.reportService.routeToReportObs.next(false);
    this.router.navigate(["/collections/" + this.collectionuuIdToNavigate + '/reports'])

  }
  LogOut() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('defaultcollectionid');
    localStorage.removeItem('defaultcollectionuuid')
    this.appService.changeUserHeader(0);
    this.router.navigate(['/login']);
  }

  changePassword() {
    this.router.navigate(["/change-password"]);
  }

  editCollection(selectedCollection: any) {
    if (selectedCollection) {
      console.log(this.selectedCollection)
      // console.log(selectedCollection)
      // localStorage.setItem('currentCollection', JSON.stringify(selectedCollection));
      this.selectedCollectionName = selectedCollection?.collectionName;
      this.collectionIdToNavigate = selectedCollection?.collectionid;
      this.collectionuuIdToNavigate = selectedCollection?.collectionuuId;
      this.shareDataService.setGlobalCollectionuuId(selectedCollection?.collectionuuId);
      this.shareDataService.setGlobalCollectionId(selectedCollection?.collectionid);
      this.router.navigate(['/collections/' + selectedCollection?.collectionuuId + '/items']);
    }

  }

  addCollection(): void {
    this.shareDataService.addCollection();
    this.router.navigate(['collections/add-collection']);
  }

  viewAllCollection(): void {
    this.router.navigate(['/collections']);
  }

  ngOnDestroy(): void {
    if (this.collectionData$) {
      this.collectionData$?.unsubscribe();
    }
  }

}
