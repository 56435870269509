import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private subject = new Subject<any>();
  private subjectNext = new ReplaySubject();

  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  sendMessageNext(message: string) {
    this.subjectNext.next({ text: message });
  }

  clearMessagesNext() {
    this.subjectNext.next();
  }

  getMessageNext(): Observable<any> {
    return this.subjectNext.asObservable();
  }

}
