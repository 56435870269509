import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeliveryService } from 'src/app/shared/delivery.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { AppService } from '../../shared/app.service';
import { ExamineeloginService } from '../../shared/examineelogin.service';
import { StyleService } from 'src/app/shared/style.service';
import { G } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-examinee-login',
  templateUrl: './examinee-login.component.html',
  styleUrls: ['./examinee-login.component.scss']
})
export class ExamineeLoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted = false;
  loginSpinner = false;
  submitted = false;
  loginErrorMessage: string;
  isLoginError = false;
  userServiceSubscription$: Subscription;
  scheduleDetail$: Subscription;
  publicLogin$: Subscription;
  schuid: any;
  testbankuuId: any;
  testDetails: any;
  schId: any;
  scheduleData: any;
  scheduleType: any;
  queryString: any;
  err: string;
  constructor(private fb: FormBuilder, private appService: AppService,
    private examineeloginservice: ExamineeloginService,
    private routerstate: ActivatedRoute,
    private deliveryService: DeliveryService, private router: Router,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    // this.styleService.addStyle('login', require('../../../scss/login.css'));
    this.routerstate.paramMap.subscribe(data => {
      this.schId = data.get('id');
      console.log('schId', this.schId)
    });

    if (this.schId) {
      this.scheduleDetail$ = this.examineeloginservice
        .scheduleDetail(this.schId).subscribe((data: any) => {
          console.log("Schedule data", data);
          this.scheduleData = data;
          this.scheduleType = this.scheduleData?.scheduleType;
          if (this.scheduleType == "Public") {
            this.routerstate.queryParams
              .subscribe(params => {
                console.log(params);
                this.queryString = params;
              }
              );

            //Call API and pass scheduleid and querystring
            // this.queryString = {"uuid": "5a23-4148-bb48"}
            this.publicLogin$ = this.examineeloginservice.publicLogin(this.schId, this.queryString)
              .subscribe(
                (data: any) => {
                  console.log(this.schId);
                  this.deliveryService.getExamineeTest(this.schId).subscribe(resData => {
                    console.log('resData', resData)
                    this.testDetails = resData;
                    this.testbankuuId = this.testDetails?.testbankuuId;
                    this.router.navigate(['/delivery/start/test', this.testbankuuId]);
                  },
                    error => {
                      this.notificationService.warn(error?.error?.message); // This line used to display error message
                    });
                },
                error => {
                  console.log(error.error.message),
                    // this.err = error.error.message
                    this.err = error?.error?.message;
                }
              );
          }

        })
    }
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.appService.changeUserHeader(0);
  }

  get f(): any {
    return this.loginForm.controls;
  }

  login(): void {
    //this.loginSpinner = true;
    this.submitted = true;
    if (this.loginForm.valid) {
      this.userServiceSubscription$ = this.examineeloginservice
        .login(this.loginForm.value.email, this.loginForm.value.password, this.schId)
        .subscribe(
          (data: any) => {
            this.deliveryService.getExamineeTest(this.schId).subscribe(resData => {
              // console.log('resData', resData)
              this.testDetails = resData;
              this.testbankuuId = this.testDetails?.testbankuuId;
              this.router.navigate(['/delivery/start/test', this.testbankuuId]);
            },
              error => {
                this.notificationService.warn(error?.error?.message); // This line used to display error message
              });
          },
          error => {
            // console.log(error),
            this.loginErrorMessage = "Invalid Username or Password";
          }
        );
    }
  }


  resetPwd(): void {
    this.router.navigate(['./delivery/reset-examinee-password'])
  }
  ngOnDestroy() {
    // this.styleService.removeStyle('login');
  }
}
