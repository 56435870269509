import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationStart, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from './shared/app.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit ,OnDestroy{
  // @HostListener('window:beforeunload', ['$event'])   
  // beforeUnloadHandler(event) {
  //   // after close tab application is logOut
  //   this.LogOut();
  // }
  title = 'assesment';
  userHeader: number;
  isFisrtTimeLoaded = true;
  previousUrl: string;
  currntRout: any
  contentVisible = false;
  constructor(private location: Location, private renderer: Renderer2, private router: Router, private appService: AppService, private activatedRoute: ActivatedRoute) {
  }

  
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  showSubmenu1 = false;
  showSubSubMenu1 = false;
  showSubmenu2 = false;
  showSubSubMenu2 = false;
  showSubmenu3 = false;
  showSubSubMenu3 = false;
  mouseenter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  ngOnInit(): void {

    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.location.onUrlChange(url => {
      if (url.includes('delivery')) {
        this.appService.changeUserHeader(0);
        return
      }
    })

    if (Object.keys(currentUserFromStorage).length === 0 || currentUserFromStorage.roleDetails.privileges.length === 0) {
      this.appService.changeUserHeader(0);
    }
    else {
      this.appService.changeUserHeader(1);
    }

    this.appService.userHeader.subscribe(data => {
      this.userHeader = data;

    });


    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1)
          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug.replace(/[\/ ]/g, '-'));
          }
          this.previousUrl = currentUrlSlug.replace(/[\/ ]/g, '-');
        }

      });
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.contentVisible = true;
    }, 50);
  }

  LogOut(): void {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }


  //  doUnload(){
  //   if (window.event.clientX < 0 && window.event.clientY < 0){
  //     alert("Window closed");
  //   }
  //   else{
  //     alert("Window refreshed");
  //   }
  //  }
ngOnDestroy(): void {
    // this.LogOut();
    alert("logout")
    
}
}
