import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CollectionService } from 'src/app/shared/collection.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared/app.service';
import { ShareDataService } from 'src/app/shared/share-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddCollectionComponent } from '../add-collection/add-collection.component';

export interface CollectionData {
  collectionName: string;
  langid: number;
  modifiedDateTime: string;
}

@Component({
  selector: 'app-collectionhome',
  templateUrl: './collectionhome.component.html',
  styleUrls: ['./collectionhome.component.scss'],
})
export class CollectionhomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  updatedCollectionData = [];
  langData: any;
  collectionData$: Subscription | undefined;
  displayedColumns: string[] = ['collectionName', 
  'language', 
  'timezone', 
  'modifiedDate', 
  // 'action'
];
  dataSource: MatTableDataSource<CollectionData>;
  collectionid: any;
  selectedCollectionName: any;
  collectionIdToNavigate: any;
  filterValue: any;
  globalcollectionid: any;
  searchValue: any;
  collectionData: any
  isNoCollections = false;
  isFilterData = false;
  constructor(
    private router: Router,
    private collectionService: CollectionService,
    private routerstate: ActivatedRoute,
    private appService: AppService,
    private shareDataService: ShareDataService,
    public dialog: MatDialog
  ) { }
  ngOnInit(): void {


    this.collectionData$ = this.collectionService.getNewCollectionData.subscribe(resData => {
      this.dataSource = new MatTableDataSource(resData?.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.appService.changeUserHeader(1);
    this.langData = this.collectionService.getLanguages()?.subscribe((data) => {
      this.shareDataService.getLanguage(data?.items);
    });


    // Collection list start from here
    this.collectionData$ = this.collectionService
      .getCollection()?.subscribe((resData: any) => {
        console.log(resData);
        this.collectionData = resData?.data;
        console.log(this.collectionData?.length);
        if (this.collectionData.length === 0) {
          this.isNoCollections = true;
        }
        else {
          this.dataSource = new MatTableDataSource(resData?.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log('data source', this.dataSource);
          this.isNoCollections = false;
        }
      },
        (error) => {
          console.log(error);
          this.router.navigate(['/login']);
        }) || undefined;
  }

  applyFilter(inputValue: any): any {
    if (!this.isNumber(inputValue)) {
      this.filterValue = inputValue;
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
      console.log(this.dataSource.filteredData);
      if (this.dataSource.filteredData.length > 0) {
        this.isFilterData = false;
      } else {
        this.isFilterData = true;

      }
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  isNumber(n): any { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openNewCollectionDialog(): void {
    const dialogConfig: MatDialogConfig<AddCollectionComponent> = {
      minWidth: '650px',
    };
    const dialogRef = this.dialog.open(AddCollectionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.isFilterData = false;
      this.searchValue = '';

    });
  }

  createCollection(): void {
    this.router.navigate(["/collections/add"]);
  }

  resetFilter(inputValue: any): void {
    this.searchValue = '';
    inputValue = '';
    this.collectionData$ = this.collectionService
      .getCollection()
      ?.subscribe((resData: any) => {
        this.collectionData = resData?.data;
        console.log(this.collectionData);
        this.dataSource = new MatTableDataSource(this.collectionData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isFilterData = false;
      },
        (error) => {
          console.log(error);
          this.isNoCollections = true;
        });
  }

  collectioDetails(selectedCollection: any): void {
    console.log(selectedCollection)
    if (selectedCollection) {
      this.selectedCollectionName = selectedCollection?.collectionName;
      this.collectionIdToNavigate = selectedCollection?.collectionuuId;
      this.shareDataService.editCollection(selectedCollection);
      this.shareDataService.setGlobalCollectionId(selectedCollection?.collectionid);
      this.shareDataService.setGlobalCollectionuuId(
        selectedCollection?.collectionuuId
      );
      // location.href = '/collections', selectedCollection?.collectionuuId
      this.router.navigate(['/collections', selectedCollection?.collectionuuId]);
    }
  }

  ngOnDestroy(): void {
    this.collectionData$.unsubscribe();
  }
}
