import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-collection-parent-landing',
    template: `<router-outlet></router-outlet>`
})
export class CollectionParentLandingComponent implements OnInit {

    constructor() {

    }

    ngOnInit(): void {
        // alert('abc');
    }
}
