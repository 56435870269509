<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title>Assign User(s)</h2>
    <span class="spacer"></span>
    <div class="btn-close">
      <button mat-icon-button mat-dialog-close aria-label="Close Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="module-wrapper content-wrapper">
    <div class="input-container">
      <div class="input-fields-wrapper">
        <div class="input-wrapper">
          <input type="text" class="form-control" [(ngModel)]='searchValue' placeholder="Search">
        </div>
        <div class="btn-wrapper">
          <button mat-flat-button color="primary" class="btn-blue" aria-label="Search" (click)="userFilter()"> Search </button>
          <button mat-flat-button color="basic" class="btn-flat" aria-label="Reset" (click)="resetUserFilter()"> Reset </button>
         <!--<button  mat-flat-button color="primary" cdkFocusInitial class="btn-blue" aria-label="Save" (click)="saveUsers()">  Save </button>-->
        </div>
      </div>
    </div>
    <mat-dialog-content class="mat-typography">
      <div class="table-container">
        <!-- <div *ngIf="isNoUsers" class="row data-not-found-container table-container">
                <span>No clients in this collection</span>
              </div> -->
        <table mat-table [dataSource]="usersData" class="mat-elevation-z8">


          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" class="checkbox-col"></th>
            <td mat-cell *matCellDef="let row">
              <div>
                <mat-checkbox [checked]="row?.checked" (change)="toggleCheckbox($event,row)" id="{{row.userid}}"></mat-checkbox>
                {{row?.flag}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Name</th>
            <td mat-cell *matCellDef="let row">
              <a (click)='navigateToUserDetails(row.userid)'>{{row?.firstName}} {{row.lastName}}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="emailId">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Email </th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.emailId.length > 30 then sliceEmail else noSliceEmail"></ng-container>
              <ng-template #sliceEmail>
                <td matTooltip="{{row?.emailId}}" matTooltipPosition="above" innerHtml="{{row?.emailId | slice:0:15}}">...
                </td>
              </ng-template>
              <ng-template #noSliceEmail>
                <td>{{row.emailId}}</td>
              </ng-template>
            </td>

          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" class="role-col"> Role</th>
            <td mat-cell *matCellDef="let row">

              <mat-select aria-placeholder="Role" class="form-control role"
                (selectionChange)="changeRole(row)"
                [(ngModel)]="row.userAccessLevelId" #role>    

                <mat-option [value]="role.userAccessLevelId" *ngFor="let role of userRoles">{{role.userAccessLevel}}
                </mat-option>
              </mat-select>   
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <div class="data-not-found-container" *ngIf='noDataFound'>
          <p>Data not found</p>
        </div>
      </div>

      <div class="collection-user-paginator">
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button color="primary" class="btn-blue" aria-label="Save" (click)="saveUsers()"> Save </button>
    </mat-dialog-actions>
  </div>
</div>

























<!-- <app-header></app-header> -->
<!-- <div class="main-heading">
  <label>User Role</label>
  <a class="">
    <mat-icon mat-dialog-close>close</mat-icon>
  </a>
</div>
<div class="dialog-container">
  <div class="filter-section">
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-10">
          <div class="input-fields-wrapper">
            <div class="input-wrapper">
              <input type="text" class="form-control" [(ngModel)]='searchValue' placeholder="Search">

            </div>
            <div class="btn-wrapper">

              <button class="btn-bg-color" mat-raised-button (click)="userFilter()">
                <span>Search</span>
              </button>

              <button class="btn-green-text" mat-raised-button (click)="resetUserFilter()">
                                                    <span>Reset</span>
                                                </button>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="btn-wrapper">
            <button class="btn-bg-color" mat-flat-button (click)="saveUsers()">
              <span> Save</span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="table-container ">
          <table mat-table [dataSource]="usersData" matSort class="mat-elevation-z8">


            <ng-container matColumnDef="checkbox">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <div>
                  <mat-checkbox (change)="toggleCheckbox($event)" id="{{row.userid}}"></mat-checkbox>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let row">
                <a (click)='navigateToUserDetails(row.userid)'>{{row?.firstName}} {{row.lastName}}</a>
              </td>
            </ng-container>

            <ng-container matColumnDef="emailId">
              <th mat-header-cell *matHeaderCellDef>Email </th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.emailId.length > 30 then sliceEmail else noSliceEmail"></ng-container>
                <ng-template #sliceEmail>
              <td matTooltip="{{row?.emailId}}" matTooltipPosition="above" innerHtml="{{row?.emailId | slice:0:15}}">...
              </td>
              </ng-template>
              <ng-template #noSliceEmail>
                <td>{{row.emailId}}</td>
              </ng-template>
              </td>

            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Role</th>
              <td mat-cell *matCellDef="let row">

                <mat-select aria-placeholder="Role" class="form-control role"
                  (selectionChange)="changeRole($event.value,row.userid,row.userAccessLevelId)"
                  [(ngModel)]="row.userAccessLevelId" #role>


                  <mat-option [(value)]="role.userAccessLevelId" *ngFor="let role of userRoles">{{role.userAccessLevel}}
                  </mat-option>


                </mat-select>


              </td>
            </ng-container> -->

<!-- <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let row">
                    <i *ngIf="row?.active === true" class="fa fas fa-circle publish"></i>
                    <span *ngIf="row?.active === false" class="glyphicon glyphicon-adjust unpublish"></span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="updated">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
                  <td mat-cell *matCellDef="let row">{{row.modifiedDateTime | dateAgo}}</td>
                </ng-container> -->
<!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>

          <div class="data-not-found-container" *ngIf='noDataFound'>
            <p>Data not found</p>
          </div>
          <div class="collection-user-paginator"> 
            <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>     
          </div>
             
        </div>
      </div>
    </div>
  </div>
</div> -->