<div class="module-wrapper content-wrapper">
    <div class="form-wrapper">
       <div class="input-wrapper">
          <form [formGroup]='tagsForm'>
              <div class="input-class">
                <input type="text" placeholder="Enter minimum two characters for Tag type or Tag name"  [readonly]='isPublished' class="form-control" [matAutocomplete]="auto" formControlName='userInput' >
              </div>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayFn'>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="50"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option (onSelectionChange)="addTagtoItem(tag)" *ngFor="let tag of flatTags" [value]="tag">
                    <span>{{tag?.tagtypename}}</span>
                    <span>-{{tag?.tagName}}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </form>
          </div>

          <div *ngIf="isNoTags" class="data-not-found-container table-container">
            <span>No Tags in this Item</span>
          </div>
           <div *ngIf="!isNoTags">
      <div class="table-container">
          <table mat-table [dataSource]="tags" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="tagtypename">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Type</th>
                <td mat-cell *matCellDef="let row" class="cell-content">
                  <ng-container *ngIf="row?.tagtypename?.length > 40 then sliceTitle else noSliceTitle">
                  </ng-container>
                  <ng-template #sliceTitle>
                    <span matTooltipClass="my-custom-tooltip" matTooltip="{{row?.tagtypename}}"
                      matTooltipPosition="above">
                      {{row?.tagtypename | slice: 0:40}}...</span>
                  </ng-template>
                  <ng-template #noSliceTitle>
                    <span>{{row?.tagtypename }}</span>
                  </ng-template>
                  <!-- {{row.tagtypename}} -->
                </td>
              </ng-container>
              <ng-container matColumnDef="tagdescription">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag</th>
                <td mat-cell *matCellDef="let row" class="cell-content">
                  <ng-container *ngIf="row?.tagName?.length > 40 then sliceTitle else noSliceTitle">
                  </ng-container>
                  <ng-template #sliceTitle>
                    <span matTooltipClass="my-custom-tooltip" matTooltip="{{row?.tagName}}"
                      matTooltipPosition="above">
                      {{row?.tagName | slice: 0:40}}...</span>
                  </ng-template>
                  <ng-template #noSliceTitle>
                    <span>{{row?.tagName }}</span>
                  </ng-template>
                  <!-- {{row.tagName}} -->
                </td>
              </ng-container>

             <!-- <ng-container matColumnDef="itemTagId">
                <th mat-header-cell *matHeaderCellDef class="icon-td">Action</th>
                <td mat-cell *matCellDef="let row">
                 <!-- <button (click)='deleteItemTag(row.itemTagId)' mat-mini-fab color="accent"  [disabled]='isPublished'
                    aria-label="Example icon button with a home icon">
                            <mat-icon>delete</mat-icon>
                  </button>-->
              <!--    <button (click)='deleteItemTag(row.itemTagId)' [disabled]='isPublished' mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon"><i-feather name="trash-2"></i-feather></button>

                </td>
              </ng-container>-->
              <ng-container matColumnDef="itemTagId">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)='deleteItemTag(row.itemTagId)' [disabled]='isPublished'>
                        <mat-icon>delete_outline</mat-icon>
                        <span>Delete</span>
                      </button>
                  </mat-menu>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        <!-- <div class="text-center" *ngIf="tags.data.length==0">Tags are not added</div> -->
     </div>
      <div class="item-paginator">
      <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
  </div>
  </div>
</div>


























<!-- <div class="filter-section title"> Tags</div> -->
<!--<div class="item-tags-container">
  <div class="table-container">
    <div class="input-tag-type">
      <form [formGroup]='tagsForm'>
        <div class="input-class">
          <input type="text" placeholder="Enter minimum two characters for Tag type or Tag name"  [readonly]='isPublished' class="form-control" [matAutocomplete]="auto" formControlName='userInput' >
        </div>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayFn'>
          <mat-option *ngIf="isLoading" class="is-loading">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option (onSelectionChange)="addTagtoItem(tag)" *ngFor="let tag of flatTags" [value]="tag">
              <span>{{tag?.tagtypename}}</span>
              <span>-{{tag?.tagName}}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </form>
    </div>
    <table mat-table [dataSource]="tags" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="tagtypename">
        <th mat-header-cell *matHeaderCellDef>Types</th>
        <td mat-cell *matCellDef="let row" class="cell-content">
          {{row.tagtypename}}
        </td>
      </ng-container>
      <ng-container matColumnDef="tagdescription">
        <th mat-header-cell *matHeaderCellDef>Tags</th>
        <td mat-cell *matCellDef="let row" class="cell-content">
          {{row.tagName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="itemTagId">
        <th mat-header-cell *matHeaderCellDef class="icon-td"> </th>
        <td mat-cell *matCellDef="let row">
         <!-- <button (click)='deleteItemTag(row.itemTagId)' mat-mini-fab color="accent"  [disabled]='isPublished'
            aria-label="Example icon button with a home icon">
                    <mat-icon>delete</mat-icon>
          </button>-->
<!--          <button (click)='deleteItemTag(row.itemTagId)' [disabled]='isPublished' mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon"><i-feather name="trash-2"></i-feather></button>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>-->

<!--!-->
<!--<div class="module-wrapper content-wrapper">
  <div class="form-wrapper">
      <form [formGroup]='tagsForm'>
      <div class="input-wrapper">
          <span class="input-field">
              <input type="text" class="form-control" placeholder="Enter minimum two characters for Tag type or Tag name">
          </span>
      </div>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayFn'>
          <mat-option *ngIf="isLoading" class="is-loading">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option (onSelectionChange)="addTagtoItem(tag)" *ngFor="let tag of flatTags" [value]="tag">
              <span>{{tag?.tagtypename}}</span>
              <span>-{{tag?.tagName}}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </form>
  </div>
  <div class="table-container">
      <table mat-table [dataSource]="tags" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="tagtypename">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Types</th>
            <td mat-cell *matCellDef="let row" class="cell-content">
              {{row.tagtypename}}
            </td>
          </ng-container>
          <ng-container matColumnDef="tagdescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tags</th>
            <td mat-cell *matCellDef="let row" class="cell-content">
              {{row.tagName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="itemTagId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="icon-td"> </th>
            <td mat-cell *matCellDef="let row">
             <!-- <button (click)='deleteItemTag(row.itemTagId)' mat-mini-fab color="accent"  [disabled]='isPublished'
                aria-label="Example icon button with a home icon">
                        <mat-icon>delete</mat-icon>
              </button>-->
 <!--            <button (click)='deleteItemTag(row.itemTagId)' mat-mini-fab color="warn" [disabled]='isPublished' aria-label="delete icon" class="fab-mini-icon"><i-feather name="trash-2"></i-feather></button>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

  </div>
</div>-->
