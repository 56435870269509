import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemsfilter'
})
export class ItemsfilterPipe implements PipeTransform {

  transform(value:any[],args: string): any[] {
    if (!args) {
      return value;
    }
   return value.filter(
      (item => item.itemBankName.toLowerCase().indexOf(args.toLowerCase()) > -1 )
      // (item => item.itemDescription.toLowerCase().indexOf(args.toLowerCase()) > -1 ) ||
      // (item => item.itemStatus.toLowerCase().indexOf(args.toLowerCase()) > -1 ) 
     )
  // return value.some(item => item.itemBankName.toLowerCase().indexOf(args.toLowerCase()) > -1)
  }

}
