import { L } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import {Location} from '@angular/common';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePwdForm: FormGroup
  constructor(private userService :  UserService,
    private router : Router,
    private notificationService : NotificationService,
    private location: Location) { }

  ngOnInit(): void {

    this.changePwdForm = new FormGroup({
      'oldPassword': new FormControl(null,
        [Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)
        ]),
      'newPassword': new FormControl(null,
        [Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{0,20}$/)
        ]),
        'confirmPassword': new FormControl(null,
          [Validators.required,
            Validators.minLength(6),
            Validators.maxLength(20),
            Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{0,20}$/)
          ]),
      })
    }


  get f(){
    return this.changePwdForm.controls;
  }
  submit() {
    const apiData = {
      // collectioId: this.collectionId,
      oldPassword: this.changePwdForm.value.oldPassword,
      newPassword: this.changePwdForm.value.newPassword,
      confirmPassword: this.changePwdForm.value.confirmPassword,

    }


    this.userService.changePwd(apiData).subscribe(resData => {
      console.log('submit',resData);
     this.notificationService.success(resData?.message);
    //  this.location.back();  //It Goes back to last page that user have visited
      if (resData.userid) {
        // this.router.navigate(["/users", data.testId]);

      }
    },
      error => {
        this.notificationService.warn(error?.error?.message);
        // if (error.error.error == 'Conflict') {
        //   this.duplicateDataFlag = true;
        // }
      });
  }
  cancel() {
    this.location.back();  //It Goes back to last page that user have visited
  }



}
