import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient) { }

	// This function is for login user
	login(userName: string, password: string): Observable<any> {
		const headers = { 'content-type': 'application/json' }
		const loginapiUrl = environment.apiUrl + 'login';
		const loginData = {
			"emailId": userName,
			"password": password
		};
		const loginDataToApi = JSON.stringify(loginData);
		return this.http.post<any>(loginapiUrl, loginDataToApi, { 'headers': headers }).pipe(
			tap((user: any) => {
				if (user && user.data) {
					localStorage.setItem('currentUser', JSON.stringify(user.data));
          sessionStorage.setItem('currentUser', JSON.stringify(user.data));
				}
			}),
			catchError(this.handleError)
		)
	}


// This funtion is for change password
	changePwd(dataToApi: any) {
		const currentUserFromStorage = JSON.parse(
			localStorage.getItem('currentUser') || '{}'
		);
		const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
		const headers = {
			'content-type': 'application/json',
			token: accessTokenFromStoarge,
		};
		const changePwdUrl = environment.apiUrl + 'users/change-password';
		return this.http.post<any>(changePwdUrl, JSON.stringify(dataToApi), { 'headers': headers }).pipe(
			tap((user: any) => {
				// if (user && user.data) {
				// 	localStorage.setItem('currentUser', JSON.stringify(user.data))
				// }
			}),
			catchError(this.handleError)
		)
	}


	// This funtion is to reset password
	resetPassword(emailId : any) {
		const headers = { 'content-type': 'application/json' }

		const resetPwdUrl = environment.apiUrl + 'users/reset-password';
		const loginData = {
			"emailId": emailId,
		};
		return this.http.post<any>(resetPwdUrl, JSON.stringify(loginData),  { 'headers': headers }).pipe(
			tap((user: any) => {

			}),
			catchError(this.handleError)
		)

	}

	private handleError(error: any) {
		// console.error(error);
		return throwError(error);
	}
}
