<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title *ngIf="isNewItem==false">Update Item details</h2>
    <h2 mat-dialog-title *ngIf="isNewItem==true">Create new Item {{testId}}</h2>
    <span class="spacer"></span>
    <div class="btn-close">
      <button (click)="closeDialog()" mat-icon-button mat-dialog-close aria-label="Close Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="basicDetailsForm" novalidate>
      <div class="form-wrapper">
        <div class="input-wrapper">
          <span class="input-label">
            <label>Item name</label><span class="required">*</span>
          </span>
          <span class="input-field">
            <input type="text" rows="1" maxlength="121" class="form-control" formControlName="itemTitle"
               [readonly]='isPublished'>
            <!-- <textarea type="text" rows="2" maxlength="121" class="form-control textarea" formControlName="itemTitle" *ngIf="isNewItem==true"
                [(ngModel)]="itemBankName"></textarea> -->
            <div *ngIf="f.itemTitle.invalid && (f.itemTitle.dirty || f.itemTitle.touched)" class="alert alert-danger">
              <div *ngIf="f.itemTitle.errors?.required"> Item name is required</div>
              <div *ngIf="f.itemTitle.errors?.minlength"> Minimum 1 characters required</div>
              <div *ngIf="f.itemTitle.errors?.maxlength"> Maximum 120 characters allowed</div>
              <div *ngIf="f.itemTitle.errors?.pattern">Item name is invalid</div>
            </div>
          </span>
        </div>
        <div class="input-wrapper">
          <span class="input-label">
            <label>Item description</label>
          </span>
          <span class="input-field">
            <textarea type="text" rows="8" maxlength="481" class="form-control textarea" formControlName="itemDes"
              
              [readonly]='isPublished'></textarea>
            <!-- <textarea type="text" rows="8" maxlength="481" class="form-control textarea" formControlName="itemDes"
              *ngIf="isNewItem==true" [(ngModel)]="itemDescription"></textarea> -->
            <div *ngIf="f.itemDes.invalid && (f.itemDes.dirty || f.itemDes.touched)" class="alert alert-danger">
              <div *ngIf="f.itemDes.errors?.minlength">Minimum 1 characters allowed</div>
              <div *ngIf="f.itemDes.errors?.maxlength"> Maximum 480 characters allowed</div>
            </div>
          </span>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button mat-flat-button color="primary" (click)='saveBasicItemDetails()'  mat-raised-button class="btn-bg-color"
    [disabled]='basicDetailsForm.invalid || isPublished'>Save</button> -->
    <button mat-flat-button color="primary" (click)='saveBasicItemDetails()' mat-raised-button class="btn-bg-color"
      [disabled]='f.itemTitle.invalid || isPublished'>Save</button>
  </mat-dialog-actions>
</div>