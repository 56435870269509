import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppService } from 'src/app/shared/app.service';
import { CollectionService } from 'src/app/shared/collection.service';
import { ShareDataService } from 'src/app/shared/share-data.service';
import { TagsService } from 'src/app/shared/tags.service';
import { MatDialog } from '@angular/material/dialog';
import { AddUSerDialog } from 'src/app/collection/collection-users/add-user-dialog';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { UsersService } from 'src/app/users/users.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';

export interface UserData {
  firstName: string;
  lastName: string;
  phone: string;
  emailId: string;
  active: boolean;
  modifiedDateTime: string;
}

export class SelecteRole {
  active: boolean;
  collectionId: number;
  collectionUserId: number;
  collectionUsersuuId: any;
  collectionuuId: any;
  deleted: boolean;
  emailId: string;
  firstName: string;
  lastName: string;
  modifiedDateTime: string;
  phone: number;
  userAccessLevelId: number;
  userAccessLevel: string;
  userId: number;
  status: any;
  roleSelection: any;
  modifiedUserName:any
}

@Component({
  selector: 'app-collection-users',
  templateUrl: './collection-users.component.html',
  styleUrls: ['./collection-users.component.scss'],
})
export class CollectionUsersComponent
  implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'phone',
    'email',
    'role',
    'status',
    'modifiedDate',
    'delete',
  ];
  collectionUsersData$: Subscription | undefined;
  dataSource: MatTableDataSource<UserData>;
  globalcollectionid: any;
  globalcollectionuuId: any;
  isNoUsers = false;
  page: number = 1;
  clientFirstName = '';
  clientLastName = '';
  clientStatus = '';
  userRoleFilter = '';
  selectStatus: any = 'Status';
  statusFilter: any;
  userRoles = [];
  newarray: any;
  statusArray = [
    { status: 'Status' },
    { status: 'Active' },
    { status: 'Inactive' },
  ];
  addnewUsertoCollection = false;
  updateroleValue: any;
  assignUserRole: any;
  collectionUserId: any;
  selectedUserRole: any;
  selecteRole = new SelecteRole();
  newselect: any;
  newDataSourceArray = [];
  isActive = false;
  selectFilterRole: any = 'Role';
  selectedState: any;
  defaultSelectedRole: any;
  currentUserData: any;
  currentUserFlag: boolean;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  userFilterServiceSubscription$: Subscription | undefined;
  usersData: any;
  noDataFound = false;
  collectionUserData: any;

  constructor(
    private collectionService: CollectionService,
    private shareDataService: ShareDataService,
    private router: Router,
    private routerState: ActivatedRoute,
    private appService: AppService,
    public dialog: MatDialog,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private ngzone: NgZone

  ) {
    this.collectionService.addColuser.subscribe((data) => {
      this.collectionService
        .getCollectionUsers(this.globalcollectionuuId)
        .subscribe((resData) => {
          console.log('constructorrr data', resData);

          this.getCollectionUserList(resData?.data);
        });
    });
  }
  ngOnInit(): void {
    this.currentUserData = JSON.parse(localStorage.currentUser);
    this.appService.changeUserHeader(1);
    this.globalcollectionid = JSON.parse(
      localStorage.getItem('defaultcollectionid')
    );

    this.globalcollectionuuId =
      JSON.parse(localStorage.getItem('defaultcollectionuuid'));




    this.collectionService.getUserRoles().subscribe((role) => {
      this.userRoles = role?.items;
    });

    this.collectionUsersData$ = this.collectionService
      .getCollectionUsers(this.globalcollectionuuId)
      .subscribe((assignUsers) => {
        console.log(assignUsers.data)
        this.getCollectionUserList(assignUsers?.data);
        console.log(this.getCollectionUserList);
      });
  }

  getCollectionUserList(data): void {
    console.log(data);
    if (data) {
      if (data?.length > 0) {
        this.isNoUsers = false;
      } else {
        this.isNoUsers = true;
      }
      console.log('Uerrole', this.userRoles);
      this.newarray = [];
      this.selectedUserRole = data;
      console.log(this.selectedUserRole);
      this.selectedUserRole.forEach((element) => {
        console.log(element)
        const selecteRole = new SelecteRole();
        selecteRole.active = element?.active;
        selecteRole.collectionId = element?.collectionId;
        selecteRole.collectionuuId = localStorage.getItem('defaultcollectionuuid');
        selecteRole.collectionUserId = element?.collectionUserId;
        selecteRole.collectionUsersuuId = element?.collectionUsersuuId;
        selecteRole.deleted = element?.deleted;
        selecteRole.emailId = element?.emailId;
        selecteRole.firstName = element?.firstName;
        selecteRole.lastName = element?.lastName;
        selecteRole.modifiedDateTime = element?.modifiedDateTime;
        selecteRole.phone = element?.phone;
        selecteRole.userAccessLevelId = element?.userAccessLevelId;
        selecteRole.userAccessLevel = element?.userAccessLevel;
        selecteRole.userId = element?.userId;
        selecteRole.status = this.userRoles;
        selecteRole.roleSelection = element?.userAccessLevel;
        selecteRole.modifiedUserName = element?.modifiedUserName;

        console.log('SELECTED ROLE TESTdrtetrtrtrtrtr', selecteRole.status);
        console.log('SELECTED ROLE TEST', this.selectedUserRole);
        this.selectedState = element?.userAccessLevel;
        this.defaultSelectedRole = element?.userAccessLevel;
        this.newarray.push(selecteRole);
        console.log(
          '-------------------> this.selectedState',
          this.selectedState
        );
      });


      // this.dataSource = new MatTableDataSource(data ?.items);
      this.dataSource = new MatTableDataSource(this.newarray);
      this.dataSource.paginator = this.paginator;
      // this.newDataSourceArray =   this.newarray;
      console.log(' datasourcee', this.dataSource);

      this.statusFilter = data?.data;
      this.collectionUserId = data.collectionUserId;


    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AddUSerDialog, {
      width: '60%',
      panelClass: 'custom-modalbox',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.noDataFound = false;
    });
  }

  deleteUser(value): void {
    // if (value.userAccessLevelId !== this.currentUserData.userId) {
      console.log(value);
      const dialogRef = this.dialog.open(DelMessageComponent, {
        data: {
          message:
            'Are you sure you want to remove the User from this Collection?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No',
          },
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.collectionService
            .deleteUserfromCollection(this.globalcollectionuuId, value.collectionUsersuuId)
            .subscribe(
              (resData: any) => {
                console.log(resData, "res");
                // this.getCollectionUserList(data);
                this.notificationService.success(resData.message); // This line used to display message
                this.collectionService
                  .getCollectionUsers(this.globalcollectionuuId)
                  .subscribe((resData) => {
                    this.getCollectionUserList(resData?.data);
                  });
              },
              (error) => {
                this.notificationService.warn(error?.error?.message); // This line used to display error message
              }
            );
        }
      });
    // }
    // console.log(value);
  }
  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  onChange(event: any): void {
    if (event === 'status') {
      this.clientStatus = '';
    } else {
      this.clientStatus = event;
    }
  }

  changeRole(value, userData) {


    let findId = this.userRoles.filter(item => item.userAccessLevel === userData.userAccessLevel);
    // Otherwise we want the user to confirm that change


    const dialogRef = this.dialog.open(DelMessageComponent, {

      data: {
        message: 'Are you sure you want to change role for the Collection User?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      const usertoUpdate = {
        active: true,
        collectionuuId: JSON.parse(userData?.collectionuuId),
        userAccessLevelId: findId[0].userAccessLevelId,
        userId: userData?.userId,
      };
      if (confirmed) {
        this.collectionService
          .UpdateUserRole(usertoUpdate, userData.collectionUserId)
          .subscribe((value) => { });
      } else {
        this.newarray.forEach((element) => {
          if (element.collectionUserId === userData.collectionUserId) {
            if (element.roleSelection) {
              element.userAccessLevel = element.roleSelection;
            }
          }

        });
        this.dataSource = this.newarray;
      }
    });
  }

  // Search function using api service
  // itemFilter(): void {

  //   const options = 'clientFirstName='+this.clientFirstName
  //   +'&clientLastName='+this.clientLastName
  //   +'&userRoleFilter='+this.userRoleFilter
  //   +'&selectStatus='+this.selectStatus;
  //   console.log(options);

  //   this.userFilterServiceSubscription$ = this.usersService.getUsersFilter(options)?.subscribe(
  //     (resData) => {
  //       console.log(resData);
  //       this.usersData = resData?.items;
  //       console.log(this.usersData.length);
  //       if (this.usersData.length === 0) {
  //         this.noDataFound = true;
  //       }
  //       console.log(this.usersData);
  //       this.usersData = new MatTableDataSource(this.usersData);
  //       this.usersData.sort = this.sort;
  //       this.usersData.paginator = this.paginator;
  //     },
  //     (error) => {
  //       console.log('dj');
  //       console.log(error);
  //       this.router.navigate(["/login"]);

  //     },
  //     () => {
  //       console.log('item service subscription completed');
  //     }) || undefined;

  // }

  itemFilter(): void {
    let selectedUserAccessLevelId = '';
    if (this.selectStatus === 'Active' || this.selectStatus === 'Status') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    if (this.selectFilterRole != 'Role') {
      selectedUserAccessLevelId = this.userRoles.find(
        (userAccess) => userAccess['userAccessLevel'] === this.selectFilterRole
      )?.userAccessLevelId;
    } else {
      selectedUserAccessLevelId = '';
    }
    const searchQuery = {
      firstName: this.clientFirstName,
      lastName: this.clientLastName,
      status: this.isActive,
      role: selectedUserAccessLevelId,
    };
    this.collectionService.getCollectionUserSearch(searchQuery).subscribe(
      (result: any) => {
        this.collectionUserData = result?.data;
        if (this.collectionUserData?.length === 0) {
          this.noDataFound = true;
        }
        this.dataSource = new MatTableDataSource(result?.data);
        this.dataSource.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
        // this.router.navigate(['/login']);
      },
      () => {
        console.log('item service subscription completed');
      }
    ) || undefined;
    this.noDataFound = false;
  }

  resetFilter(): void {
    this.clientFirstName = '';
    this.clientLastName = '';
    this.selectFilterRole = 'Role';
    this.selectStatus = 'Status';
    this.dataSource = new MatTableDataSource(this.newarray);
    this.dataSource.paginator = this.paginator;
    this.noDataFound = false;
  }

  addNewUser(): void {
    this.addnewUsertoCollection = true;
    // console.log(this.addnewUsertoCollection);
  }

  ngOnDestroy(): void {
    this.collectionUsersData$.unsubscribe();
  }
}
