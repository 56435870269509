import { DatePipe, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import * as StackTrace from "stacktrace-js";
import { ErrorLogService } from "./error-log.service";
import { GlobalErrorEntity } from "./global-error.entity";
// import { GlobalErrorEntity } from "./global-error.entity";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{

    globalErrorEntity : GlobalErrorEntity;
    
    constructor(private injector: Injector) {
      this.globalErrorEntity = {
  
      };
     }

    handleError(error: any): void {
        const loggingService = this.injector.get(ErrorLogService);
        const location = this.injector.get(LocationStrategy);
        const datePipe = this.injector.get(DatePipe);
        const message = error.message ? error.message : error.toString();
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const currentDate = new Date();
    
        // get the stack trace, lets grab the last 10 stacks only
        StackTrace.fromError(error).then(stackframes => {
          const stackString = stackframes
            .splice(0, 20)
            .map(function (sf) {
              return sf.toString();
            }).join('\n');
    
          if(this.globalErrorEntity){
            this.globalErrorEntity.message = message;
            this.globalErrorEntity.logedinuser = "Amruta" // read username
            this.globalErrorEntity.date = datePipe.transform(currentDate, 'yyyy-MM-dd') || undefined ;
            this.globalErrorEntity.stacktrace = stackString; 
            this.globalErrorEntity.path = url; 
            loggingService.logError(this.globalErrorEntity);
          } 
        });
       // throw error;
      }
}