<!-- <mat-dialog-content>
	<p>
		{{message}}
	</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions> -->


<div class="dialog-size">
    <mat-dialog-content class="mat-content">
        <p>
            {{message}}
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button class="btn-bg-color btn-size" (click)="onConfirmClick(true)" tabindex="1">{{confirmButtonText}}</button>
        <button mat-raised-button class="btn-size" (click)="onConfirmClick(false)" tabindex="-1">{{cancelButtonText}}</button>
    </mat-dialog-actions>
</div>