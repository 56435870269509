import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CollectionService } from 'src/app/shared/collection.service';
import { ShareDataService } from 'src/app/shared/share-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/shared/app.service';
import { UsersService } from 'src/app/users/users.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NotificationService } from 'src/app/shared/notification.service';
@Component({
    selector: 'add-user-dialog',
    templateUrl: 'add-user-dialog.html',
    styleUrls: ['./add-user-dialog.scss'],
})
export class AddUSerDialog implements OnInit {
    [x: string]: any;
    usersData: any;
    noDataFound = false;
    sort: any;
    // paginator: any;
    displayedColumns: string[] = ['checkbox', 'firstName', 'emailId', 'role'];
    userStatus: any;
    totalRecords: any;
    searchText: any;
    page: number = 1;
    itemName = "";
    searchValue = "";
    firstName = "";
    lastName = "";
    emailId = "";
    userslist: any;

    testName = "";
    itemDescription = "";
    testDescription = "";
    tagdescription = "";
    itemStatus = "";
    testStatus = "";
    isItemDescended = true;
    itemId = 10;
    userRoles: any;
    updateroleValue: any;
    userServiceSubscription$: Subscription | undefined;
    userFilterServiceSubscription$: Subscription | undefined;

    itemIds: any = [];
    itemJsonData: any = [];
    globalcollectionuuid: any;
    source: any;
    uselevelId: any;
    selectUserArr = [];
    selectedUserRoleArr = [];
    finalUserArr = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    //   @ViewChild(MatSort) sort: MatSort;
    constructor(private collectionService: CollectionService,
        private shareDataService: ShareDataService,
        private router: Router,
        private routerState: ActivatedRoute,
        private appService: AppService,
        private usersService: UsersService,
        public dialogRef: MatDialogRef<AddUSerDialog>,
        private notificationService: NotificationService) { }

    ngOnInit(): void {
        this.globalcollectionuuid =
            JSON.parse(localStorage.getItem('defaultcollectionuuid'
            ));
        this.userServiceSubscription$ = this.usersService.getusers()?.subscribe(resData => {
            this.userslist = resData?.data;
            this.userslist.filter((x: any) => x.checked = false);
            this.usersData = new MatTableDataSource(this.userslist);
            this.usersData.paginator = this.paginator;
            console.log(this.usersData)
        });

        this.collectionService.getUserRoles().subscribe(role => {
            this.userRoles = role?.items;
        });

    }

    userFilter(): void {
        const options = '&keyword=' + this.searchValue;
        this.userFilterServiceSubscription$ = this.usersService.getUsersFilter(options)?.subscribe(
            (resData) => {
                console.log(resData);
                this.usersData = resData?.data;
                console.log(this.usersData.length);
                if (this.usersData.length === 0) {
                    this.noDataFound = true;
                }
                console.log(this.usersData);
                this.usersData = new MatTableDataSource(this.usersData);
                this.usersData.sort = this.sort;
                this.usersData.paginator = this.paginator;
            },
            (error) => {
            },
            () => {
            }) || undefined;
        this.noDataFound = false;

    }

    resetUserFilter(): void {
        this.searchValue = "";
        this.noDataFound = false;

        this.userServiceSubscription$ = this.usersService.getusers()?.subscribe(resData => {
            this.userslist = resData?.data;
            this.userslist.filter((x: any) => x.checked = false);
            this.selectedUserRoleArr = []
            this.selectUserArr = [];
            this.usersData = new MatTableDataSource(this.userslist);
            this.usersData.paginator = this.paginator;
        });
    }

    toggleCheckbox(event, selectedUser: any) {
        selectedUser.checked = event?.checked;
        this.selectUserArr.push(selectedUser);
        this.selectUserArr = this.selectUserArr.filter(x => x.checked === true);
    }

    changeRole(selectedRole: any): void {
        this.selectedUserRoleArr.push(selectedRole);
        this.selectedUserRoleArr = this.selectedUserRoleArr.filter((c, index) => {
            return this.selectedUserRoleArr.indexOf(c) === index;
        });
    }
    saveUsers(): void {
        // console.log('user array', this.selectUserArr)
        // console.log('user role array', this.selectedUserRoleArr)

        this.selectUserArr.filter(x => {
            if (x.checked) {
                if (this.selectedUserRoleArr.length > 0) {
                    this.finalUserArr = this.selectedUserRoleArr.map(x => (
                        {
                            active: x?.active,
                            collectionuuId: this.globalcollectionuuid,
                            userAccessLevelId: x?.userAccessLevelId,
                            userId: x?.userid
                        }));
                } else {
                    this.finalUserArr = this.selectUserArr.map(x => ({
                        active: x?.active,
                        collectionuuId: this.globalcollectionuuid,
                        userAccessLevelId: x?.userAccessLevelId || 3,
                        userId: x?.userid
                    }));
                }
            }
        });
        // console.log('final array', this.finalUserArr)
        this.collectionService.addUsertoCollection(this.finalUserArr).subscribe(resData => {
            console.log(resData);
            this.notificationService.success(resData?.message);  // This line used to display message
            this.collectionService.addCollectionuser(this.itemJsonData);
            this.dialogRef.close();
            this.itemIds = [];
        },
            error => {
                if (this.selectedUserRoleArr.length === 0) {
                    this.notificationService.warn(error?.error?.message);  // This line used to display Select the User to be assigned error message                    
                } else {
                    this.notificationService.warn(error?.error?.message);  // This line used to display  error message
                }
            });
    }
}
