import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgModule, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map } from 'rxjs/operators';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';


export interface Examinee {
  fullName: string;
}

@Component({
  selector: 'app-create-public-schedule',
  templateUrl: './create-public-schedule.component.html',
  styleUrls: ['./create-public-schedule.component.scss']
})
export class CreatePublicScheduleComponent implements OnInit, AfterViewInit, OnChanges {


  // public schedule form
  publicScheduleForm: FormGroup;

  // date initialization
  minDate: Date;
  minDate1: Date;
  d = new Date();
  timeZoneArr = [];
  zone: any;
  convertStartTime: any;
  minutesToAdd: any;
  setDefaultStartTime: any;
  setDefaultEndTime: any;
  convertEndTime: any;

  // editor initialization
  editor: Editor;
  editor1: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  // mat chip and autocomplete 
  public allExaminees: Examinee[] = [];

  public chipSelectedExaminee: Examinee[] = [];

  public filteredExaminee: Observable<String[]>;

  private allowFreeTextAddExaminee = false;

  public examineeControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('examineeInput') examineeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  setPenaltymsg: boolean;
  setTestScoremsg: boolean;
  isExamineeExist = false;
  getMarksDetails: any;
  isStartTimeValid: boolean;
  isEndTimeValid: boolean;

  // search dropdown
  searchOptions = [];
  selected:any;
  filteredList:any;

  constructor(private fb: FormBuilder, private testService: TestService, private notificationService: NotificationService, private datePipe: DatePipe, private cd: ChangeDetectorRef
  ) {

    // /////////////////////////////////////// date logic ////////////////////////////////////////
    // this.minDate = new Date(Date.now() - (3600 * 1000 * 24));
    // this.minDate1 = new Date(Date.now() - (3600 * 1000 * 24));
    // //  convert time to current time to plus 30 minute
    // this.convertStartTime = new Date();
    // this.minutesToAdd = new Date(
    //   this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    // );

    // this.setDefaultStartTime =
    //   this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    // this.convertEndTime = this.convertStartTime.setMinutes(
    //   this.convertStartTime.getMinutes() + 60
    // );
    // this.setDefaultEndTime =
    //   this.convertStartTime.getHours() +
    //   ':' +
    //   this.convertStartTime.getMinutes();
    // ////////////////////////////////////////////////////////////////////////////////////////////

    // editor logic
    this.editor = new Editor();
    this.editor1 = new Editor();
  }

  ngOnInit(): void {
    console.log('public ng oninit')
    /////////////////////////////////////// date logic ////////////////////////////////////////
    this.minDate = new Date(Date.now() - (3600 * 1000 * 24));
    this.minDate1 = new Date(Date.now() - (3600 * 1000 * 24));
    //  convert time to current time to plus 30 minute
    this.convertStartTime = new Date();
    this.minutesToAdd = new Date(
      this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    );

    this.setDefaultStartTime =
      this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    this.convertEndTime = this.convertStartTime.setMinutes(
      this.convertStartTime.getMinutes() + 60
    );
    this.setDefaultEndTime =
      this.convertStartTime.getHours() +
      ':' +
      this.convertStartTime.getMinutes();
    ////////////////////////////////////////////////////////////////////////////////////////////
    this.getTimeZone();
    // this.getPublicExaminees();
    this.buildPublicScheduleForm();
    this.getMarks();
    this.filteredExaminee = this.examineeControl.valueChanges.pipe(
      map(examineeName => this.filterOnValueChange(examineeName))
    );
  }

  ngAfterViewInit(): void {
    console.log('public ng afterview')
    /////////////////////////////////////// date logic ////////////////////////////////////////
    this.minDate = new Date(Date.now() - (3600 * 1000 * 24));
    this.minDate1 = new Date(Date.now() - (3600 * 1000 * 24));
    //  convert time to current time to plus 30 minute
    this.convertStartTime = new Date();
    this.minutesToAdd = new Date(
      this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    );

    this.setDefaultStartTime =
      this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    this.convertEndTime = this.convertStartTime.setMinutes(
      this.convertStartTime.getMinutes() + 60
    );
    // this.setDefaultEndTime =
    //   this.convertStartTime.getHours() +
    //   ':' +
    //   this.convertStartTime.getMinutes();
    ////////////////////////////////////////////////////////////////////////////////////////////
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('public ng onChange')
    /////////////////////////////////////// date logic ////////////////////////////////////////
    this.minDate = new Date(Date.now() - (3600 * 1000 * 24));
    this.minDate1 = new Date(Date.now() - (3600 * 1000 * 24));
    //  convert time to current time to plus 30 minute
    this.convertStartTime = new Date();
    this.minutesToAdd = new Date(
      this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    );

    this.setDefaultStartTime =
      this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    this.convertEndTime = this.convertStartTime.setMinutes(
      this.convertStartTime.getMinutes() + 60
    );
    // this.setDefaultEndTime =
    //   this.convertStartTime.getHours() +
    //   ':' +
    //   this.convertStartTime.getMinutes();
    ////////////////////////////////////////////////////////////////////////////////////////////
    this.cd.detectChanges();
  }

  // form validation
  get f() {
    this.publicScheduleForm.valueChanges.subscribe(valueChange => {
      this.compareDateAndTime(valueChange);
    });
    return this.publicScheduleForm.controls;
  }


  compareDateAndTime(valueChange): any {
    if (valueChange.startTime == '' || valueChange.startTime == '00:00') {
      this.isStartTimeValid = true;
    } else {
      this.isStartTimeValid = false;
    }
    if (valueChange.endTime == '' || valueChange.endTime == '00:00') {
      this.isEndTimeValid = true;
    } else {
      this.isEndTimeValid = false;
    }
  }

  // create public schedule form
  buildPublicScheduleForm() {

    this.publicScheduleForm = this.fb.group({
      schName: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.minLength(3), Validators.maxLength(120)]),
      schDescription: new FormControl({ value: null, disabled: false },
        [
          Validators.minLength(3),
          Validators.maxLength(480),
        ]),
      startDate: new FormControl({ value: new Date(), disabled: false }, Validators.required),
      startTime: new FormControl({ value: this.setDefaultStartTime, disabled: false }, Validators.required),
      endDate: new FormControl({ value: new Date(), disabled: false }, Validators.required),
      endTime: new FormControl({ value: this.setDefaultEndTime, disabled: false }, Validators.required),
      timeZone: new FormControl({ value: '', disabled: false }, Validators.required),
      testDuration: new FormControl({ value: '10', disabled: false }, [Validators.required, Validators.pattern(/^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/)]),
      testTotalScore: new FormControl({ value: '12', disabled: false }, Validators.required),
      testPassingScore: new FormControl({ value: '1', disabled: false }, [Validators.required, Validators.pattern(/^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/)]),
      penaltyPoints: new FormControl({ value: '0', disabled: false }, Validators.required),
      preMessageText: new FormControl({ value: 'Welcome to Assessment Test', disabled: false }, [Validators.required, Validators.minLength(8), Validators.maxLength(1000)]),
      postMessageText: new FormControl({ value: 'Test submitted successfully', disabled: false }, [Validators.required, Validators.minLength(8), Validators.maxLength(1000)]),
      ExamineeRejoinTest: new FormControl({ value: '', disabled: true }, Validators.required),
      showResultAfterTest: new FormControl({ value: '', disabled: true }, Validators.required),
      shuffleItems: new FormControl({ value: '', disabled: true }, Validators.required),
      shuffleQuestions: new FormControl({ value: '', disabled: true }, Validators.required),
      shuffleOptions: new FormControl({ value: '', disabled: true }, Validators.required),
    });
  }

  // merge fname and emailid
  getFnameNemailId(firstName, emailId) {
    return `${firstName} (${emailId})`;
  }


  /////////////////////////////////////// start add and remove recipient logic //////////////////////////////////////////////
  // add new chip
  public addExaminee(event: MatChipInputEvent): void {
    // if (!this.allowFreeTextAddExaminee) {
    //   // only allowed to select from the filtered autocomplete list
    //   // console.log('allowFreeTextAddExaminee is false');
    //   return;
    // }

    // //
    // // Only add when MatAutocomplete is not open
    // // To make sure this does not conflict with OptionSelected Event
    // //
    // if (this.matAutocomplete.isOpen) {
    //   return;
    // }

    // // Add our examinee
    // const value = event.value;
    // if ((value || '').trim()) {
    //   this.selectExamineeByName(value.trim());
    // }

    // this.resetInputs();
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.chipSelectedExaminee.push({ fullName: value });
      this.isExamineeExist = false;
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  // remove chip
  public removeExaminee(examinee: Examinee): void {
    const index = this.chipSelectedExaminee.indexOf(examinee);
    if (index >= 0) {
      this.chipSelectedExaminee.splice(index, 1);
      if (this.chipSelectedExaminee.length === 0) {
        this.isExamineeExist = true;
        return;
      }
      this.resetInputs();
    }
  }

  // selected examinee
  public selectedExaminee(event: MatAutocompleteSelectedEvent): void {
    this.selectExamineeByName(event.option.value);
    this.resetInputs();
  }

  // reset add recipient input box
  private resetInputs() {
    // clear input element
    this.examineeInput.nativeElement.value = '';
    // clear control value and trigger examineeControl.valueChanges event
    this.examineeControl.setValue(null);
  }

  // filter add recipient input box on change value
  private filterOnValueChange(examineeName: string | null): String[] {
    let result: String[] = [];
    //
    // Remove the examinees we have already selected from all examinees to
    // get a starting point for the autocomplete list.
    //
    let allExamineesLessSelected = this.allExaminees.filter(examinee => this.chipSelectedExaminee.indexOf(examinee) < 0);
    if (examineeName) {
      result = this.filterExaminee(allExamineesLessSelected, examineeName);
    } else {
      result = allExamineesLessSelected.map(examinee => examinee.fullName);
    }
    return result;
  }

  // filter examinee after detect any change
  private filterExaminee(examineeList: Examinee[], examineeName: String): String[] {
    let filteredExamineeList: Examinee[] = [];
    const filterValue = examineeName.toLowerCase();
    let examineesMatchingEngineerName = examineeList.filter(examinee => examinee.fullName.toLowerCase().indexOf(filterValue) === 0);
    if (examineesMatchingEngineerName.length || this.allowFreeTextAddExaminee) {
      //
      // either the examinee name matched some autocomplete options 
      // or the name didn't match but we're allowing 
      // non-autocomplete examinee names to be entered
      //
      filteredExamineeList = examineesMatchingEngineerName;
    } else {
      //
      // the examinee name didn't match the autocomplete list 
      // and we're only allowing examinees to be selected from the list
      // so we show the whjole list
      // 
      filteredExamineeList = examineeList;
    }
    //
    // Convert filtered list of examinee objects to list of examinee 
    // name strings and return it
    //
    return filteredExamineeList.map(examinee => examinee.fullName);
  }


  private selectExamineeByName(examineeName) {
    let foundExaminee = this.allExaminees.filter(examinee => examinee.fullName == examineeName);
    if (foundExaminee.length) {
      //
      // We found the examinee name in the allExaminees list
      //
      this.chipSelectedExaminee.push(foundExaminee[0]);
      if (this.chipSelectedExaminee.length > 0) {
        this.isExamineeExist = false;
        return;
      }
    }
  }

  /////////////////////////////////////// end add and remove recipient logic //////////////////////////////////////////////

  ////////////////////////////////////// not used anywhere in html /////////////////////////////////////////////////////////
  testScoreChange() {
    if (
      this.publicScheduleForm?.value?.testPassingScore + 1 >
      // this.scheduleForm.controls['testPassingScore'].value || this.scheduleForm.controls['penaltyPoint'].value >
      this.publicScheduleForm?.value?.testTotalScore
    ) {

      this.setTestScoremsg = true;
      setTimeout(() => {
        this.setTestScoremsg = false;
      }, 1000);
      // this.publicScheduleForm.value.testPassingScore.patchValue({ testPassingScore: '0' });
    } else {
      this.setTestScoremsg = false;
    }

  }


  checkTestValue() {
    // console.log(this.publicScheduleForm.value.penaltyPoints + 1 > this.publicScheduleForm?.value?.testTotalScore)
    if (
      this.publicScheduleForm.value.penaltyPoints + 1 >
      this.publicScheduleForm?.value?.testTotalScore
    ) {
      this.setPenaltymsg = true;
      // this.publicScheduleForm.patchValue({ penaltyPoints: '0' });
    } else {
      this.setPenaltymsg = false;
    }

  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // get all time zones
  getTimeZone(): void {
    this.testService.getTimeZone().subscribe((timezonedata) => {
      this.timeZoneArr = timezonedata?.data;
      // Dropdown Search filter
      this.searchOptions = this.timeZoneArr;
      this.selected = this.searchOptions[0]?.name;
      this.filteredList = this.searchOptions.slice();
    });
  }

  // get all existing public examinees
  getPublicExaminees(): void {
    this.testService.getExamineetoSchedule().subscribe((resData) => {

      const fullInfo = resData.items.map((value) => (
        { fullName: this.getFnameNemailId(value['firstName'], value['emailId']), examineeuuid: value['examineeuuid'], examineeId: value['examineeId'] }
      ));
      this.allExaminees = fullInfo;
      // console.log(this.allExaminees)
    });
  }

  // get marks 
  getMarks(): void {
    const testuuid = localStorage.getItem('testuuid');
    const marks = this.testService.getTestToltalScore(testuuid).subscribe((resData) => {
      this.publicScheduleForm.patchValue({
        testTotalScore: resData?.data?.totalMarks, testPassingScore: resData?.data?.passingCriteria, testDuration: resData?.data?.duration, timeZone: resData?.data?.collectionTimeZone
      });
    });
  }

  // navigate to back
  back() {
    // console.log('back trigger')
    this.testService.scheduleBackBtnobs.next(false);
  }


  // submit public schedule form
  onSubmit(form) {
    if (this.chipSelectedExaminee.length <= 0) {
      this.isExamineeExist = true;
      return;
    }
    const finalPayload = this.getFinalPayload(form);
    console.log(finalPayload)
    this.testService.addTestSchedule(finalPayload).subscribe((resData) => {
      console.log(resData)
      if (resData.success) {
        this.notificationService.success(resData?.message);
        this.back();
      }
    }, handleError => {
      this.notificationService.warn(handleError.error.error[0].message);
    });
  }

  // construct api payload
  getFinalPayload(payload): any {
    // console.log(payload);
    const examinneNames = this.chipSelectedExaminee.map((value) => value.fullName);
    return payload = {
      examinees: [],
      publicScheduleParameters: {
        parameters: examinneNames
      },
      clientId: JSON.parse(localStorage?.getItem('currentUser'))?.clientId,
      collectionId: localStorage.getItem('defaultcollectionid'),
      description: payload?.value?.schDescription?.trim() || null,
      endDate: this.datePipe.transform(payload.value.endDate, 'yyyy-MM-dd'),
      endDateHours: payload.value.endTime.split(':')[0],
      endDateMinutes: payload.value.endTime.split(':')[1],
      postTestMessage: {
        description: payload?.value?.postMessageText?.trim(),
        mediaURL: [
          "String"
        ]
      },
      preTestMessage: {
        description: payload?.value?.preMessageText?.trim(),
        mediaURL: [
          "String"
        ]
      },
      scheduleType: true,
      startDate: this.datePipe.transform(payload.value.startDate, 'yyyy-MM-dd'),
      startDateHours: payload.value.startTime.split(':')[0],
      startDateMinutes: payload.value.startTime.split(':')[1],
      testBankId: localStorage.getItem('testBankId'),
      testDuration: payload?.value?.testDuration,
      testPassingScore: JSON.parse(payload?.value?.testPassingScore),
      testScore: JSON.parse(payload?.value?.testTotalScore),
      penaltyPoint: payload?.value?.penaltyPoints,
      timeZoneName: payload?.value?.timeZone,
      title: payload.value.schName.trim()
    }
  }


}
@NgModule({
  declarations: [
    CreatePublicScheduleComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    NgxEditorModule,
    MatButtonModule,
    MatSelectModule,
    MatSelectFilterModule,
    FormsModule
  ]
})
class CreatePublicScheduleComponentModule {

} 