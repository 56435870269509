import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

 public userHeader : BehaviorSubject<number>; 
  constructor() { 
     this.userHeader = new BehaviorSubject<number>(0); 
  }

  changeUserHeader(value:number){
    this.userHeader.next(value);
  }
}
