<mat-toolbar color="primary" class="example-toolbar primary-color">
  <!-- <button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button> -->
  <!-- <h1 class="example-app-name">TANAY Assessment</h1> -->
  <img src="./../../assets/new_logo.png" alt="" width="200px">
  <div id="bs-example-navbar-collapse-1" style="display: flex;">
    <div class="alg">

      <!-- <button mat-button [matMenuTriggerFor]="menu" *ngIf="collectionData?.length>0" class="marginLeft" >
        <span class="span-width" *ngIf="selectedCollectionName?.length >= 13"  matTooltip="{{selectedCollectionName}}"     matTooltipPosition = "below" >{{selectedCollectionName | slice: 0:10 }} ...</span>
        <span  class="span-width" *ngIf="selectedCollectionName?.length < 13">{{selectedCollectionName | slice: 0:13 }}</span>        
        <mat-icon class="menu-button " >expand_more</mat-icon></button> -->

      <mat-menu #menu="matMenu" class="menu-list">
        <button (click)="viewAllCollection()" mat-menu-item>View all Collections</button>
        <!-- <form action="/action_page.php">
        <input type="text" placeholder="Search.." name="search">
        <button type="submit"><i class="fa fa-search"></i></button>
      </form> -->
        <button matTooltipPosition="above" matTooltip="{{col?.collectionName}}" mat-menu-item
          (click)="editCollection(col)" *ngFor="let col of collectionData" class="collection-item collection-name">
          <span>{{ col?.collectionName | slice: 0:20 }}</span>
          <!-- <span class="tooltiptext">{{col?.collectionName}}</span> -->
        </button>
      </mat-menu>
    </div>

    <!-- search dropdown filter -->
    <button mat-button routerLinkActive="tab-active">
      <!-- <mat-form-field>
        <mat-select [(ngModel)]="selected">
            <mat-select-filter  [array]="searchOptions" [displayMember]="'collectionName'" (filteredReturn)="filteredList =$event"></mat-select-filter>
            <button (click)="viewAllCollection()" mat-menu-item>View all Collections</button>
            <mat-option *ngFor="let item of filteredList" [value]="item" (click)="editCollection(item)">
                {{item?.collectionName}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->

      <mat-form-field >
        <mat-select [(value)]="selectedCollection">
            <mat-select-filter [array]="searchOptions" [displayMember]="'collectionName'" (filteredReturn)="filteredList =$event">
            </mat-select-filter>
              <button (click)="viewAllCollection()" mat-menu-item>View all Collections</button>
              <mat-option [value]="item?.collectionName" *ngFor="let item of filteredList" (click)="editCollection(item)">{{item?.collectionName}}</mat-option>
            </mat-select>
      </mat-form-field>

    </button>

    <!-- <button  mat-button routerLinkActive="tab-active" routerLink="/dashboard" disabled class="text-lightgray">Dashboard</button> -->
    <button mat-button routerLinkActive="tab-active" [routerLinkActiveOptions]="{exact:true}"
      [routerLink]="['/collections/'+collectionuuIdToNavigate+'/items']">Items</button>
    <button mat-button routerLinkActive="tab-active"
      [routerLink]="['/collections/'+collectionuuIdToNavigate+'/test']">Tests</button>
    <button mat-button routerLinkActive="tab-active"
      [routerLink]="['/collections/'+collectionuuIdToNavigate+'/tags']">Tags</button>

    <button mat-button [matMenuTriggerFor]="menu2"><span>Examinees</span>
      <mat-icon class="menu-button">expand_more</mat-icon>
    </button>
    <mat-menu #menu2="matMenu" class="menu-list">
      <button mat-menu-item [routerLink]="['/examinee-group']">
        <span>Examinee Groups</span>
      </button>
      <button mat-menu-item [routerLink]="['/examinees']">
        <span>Examinees</span>
      </button>
    </mat-menu>
    <button mat-button routerLinkActive="tab-active"
      [routerLink]="['/collections/'+collectionuuIdToNavigate+'/reports']"
      (click)="navigationRefresh()">Reports</button>
    <!-- <button  mat-button routerLinkActive="tab-active" [routerLink]="['/schedules']" disabled class="text-lightgray">Schedules</button> -->
    <button mat-button routerLinkActive="tab-active" [routerLink]="['/users']">Users</button>
    <!-- <button  mat-button disabled class="text-lightgray">Analytics</button> -->
  </div>


  <span class="spacer"></span>
  <!-- <button mat-icon-button class="example-icon notifiation-icon text-lightgray rm-btn-underline" aria-label="mail icon" disabled>
            <mat-icon>mail</mat-icon>
        </button>

  <button mat-icon-button class="example-icon notifiation-icon text-lightgray rm-btn-underline" aria-label="notifications icon" disabled>
          <mat-icon>notifications_none</mat-icon>
        </button> -->
  <button mat-icon-button [matMenuTriggerFor]="menuprofile" class="example-icon rm-btn-underline"
    aria-label="user icon">
    <mat-icon>person_outline</mat-icon>
  </button>
  <mat-menu #menuprofile="matMenu">
    <div class="user-details">
      <div class="user-photo">
        <span class="menu-icon">
          <i-feather name="user"></i-feather>
        </span>
      </div>
      <div>
        <p class="user-name">{{ firstName + ' ' + lastName}}</p>
        <p class="user-email">{{emailId}}</p>
      </div>
    </div>
    <button mat-menu-item routerLink="/change-password">
      <mat-icon>edit</mat-icon>
      <span>Change Password</span>
    </button>
    <button mat-menu-item (click)="LogOut()">
      <mat-icon>subdirectory_arrow_right</mat-icon>
      <span>Log Out</span>
    </button>
  </mat-menu>
</mat-toolbar>