// import { NgModule } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
// import { ChooseQuestionTypeComponent } from './items/choose-question-type/choose-question-type.component';
// import { ItemAddQuestionTypeComponent } from './items/item-add-question-type/item-add-question-type.component';
// import { AbcComponent } from './items/item-details/abc.component';
// import { ItemDetailsComponent } from './items/item-details/item-details.component';
// import { ItemLandingComponent } from './items/item-landing/item-landing.component';
// import { AuthguardService } from './shared/authguard.service';
// import { LoginComponent } from './user/login/login.component';
// import { TestsLandingComponent } from 'src/app/tests/tests-landing/tests-landing.component';
// import { TestsDetailsComponent } from 'src/app/tests/tests-details/tests-details.component';
// import { CreateNewItemComponent } from './items/create-new-item/create-new-item.component';
// import { AddTagTypeComponent } from './tags/add-tag-type/add-tag-type.component';
// import { TagsLandingComponent } from './tags/tags-landing/tags-landing.component';
// import { AddTagComponent } from './tags/add-tag/add-tag.component';
// import { EditTagTypeComponent } from './tags/edit-tag-type/edit-tag-type.component';
// import { EditTagComponent } from './tags/edit-tag/edit-tag.component';
// import { CollectionComponent } from './collection/collection.component';
// import { AddClientComponent } from './collection/add-client/add-client.component';
// import { TagsComponent } from './tags/tags.component';
// import { UserLandingComponent } from './users/user-landing/user-landing.component';
// import { UserCreateComponent } from './users/user-create/user-create.component';

// // const routes: Routes = [
// //   { path: '', redirectTo: 'users', pathMatch: 'full' },
// //   {
// //     path: 'login',
// //     component: LoginComponent
// //   },
// // ];
// const routes: Routes = [
//   { path: 'login', component: LoginComponent },
//   {
//     path: '', canActivate: [AuthguardService],
//     children: [
//       {
//         path: 'items', component: ItemLandingComponent, children: [
//         ],
//       },
//       {
//         path: 'tags', component: TagsComponent, children: [
//           {
//             path: '',
//             component: TagsLandingComponent
//           },
//           {
//             path: 'add-tag-type',
//             component: AddTagTypeComponent
//           },
//           {
//             path: 'edit-tag-type',
//             component: EditTagTypeComponent
//           },
//           {
//             path: 'add-tag',
//             component: AddTagComponent
//           },
//           {
//             path: 'edit-tag',
//             component: EditTagComponent
//           }
//         ],
//       },
//       { path: 'create-new-item', component: CreateNewItemComponent },
//       {
//         path: 'items/:id', component: AbcComponent,
//         children: [
//           { path: 'selectquestiontype', component: ChooseQuestionTypeComponent },
//           { path: 'addstandardmcquestion', component: ItemAddQuestionTypeComponent },
//           { path: '', component: ItemDetailsComponent }
//         ]
//       },
//       { path: 'tests', loadChildren: () => import('./delivery/delivery.module').then(m => m.DeliveryModule) },
//       { path: 'add-client', component: AddClientComponent },
//       { path: 'collection', component: CollectionComponent },
//       { path: '', redirectTo: 'items', pathMatch: 'full' },

//     ]
//   },
//   { path: 'tests-landing', component: TestsLandingComponent },
//   { path: 'tests-details', component: TestsDetailsComponent },
//   { path: 'tests-details/:id', component: TestsDetailsComponent },
//   { path: 'users', component: UserLandingComponent },
//   { path: 'examinees', loadChildren: () => import('./examiness/examiness.module').then(m => m.ExaminessModule) },
//   { path: 'schedules', loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule) },
//   { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
//   { path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule) }
// ];
// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }





import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChooseQuestionTypeComponent } from './items/choose-question-type/choose-question-type.component';
import { ItemAddQuestionTypeComponent } from './items/item-add-question-type/item-add-question-type.component';
import { AbcComponent } from './items/item-details/abc.component';
import { ItemDetailsComponent } from './items/item-details/item-details.component';
import { ItemLandingComponent } from './items/item-landing/item-landing.component';
import { AuthguardService } from './shared/authguard.service';
import { LoginComponent } from './user/login/login.component';
import { TestsLandingComponent } from 'src/app/tests/tests-landing/tests-landing.component';
import { TestsDetailsComponent } from 'src/app/tests/tests-details/tests-details.component';
import { CreateNewItemComponent } from './items/create-new-item/create-new-item.component';

import { AddClientComponent } from './collection/add-client/add-client.component';
import { UserLandingComponent } from './users/user-landing/user-landing.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { ItemAddCheckboxQuestionsComponent } from 'src/app/items/item-add-checkbox-questions/item-add-checkbox-questions.component';
import { ItemAddTrueFalseQuestionsComponent } from 'src/app/items/item-add-true-false-questions/item-add-true-false-questions.component';
import { AddCollectionComponent } from './collection/add-collection/add-collection.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CollectionLandingComponent } from './collection/collection-landing/collection-landing.component';
import { CollectionhomeComponent } from './collection/collectionhome/collectionhome.component';
// import { ExamineeloginComponent } from './examineelogin/examineelogin.component';
import { ExamineeComponent } from './examinee/examinee.component';
import { AddTestsScheduleComponent } from './tests/add-tests-schedule/add-tests-schedule.component';
// import { ScheduleDetailsComponent } from './tests/schedule-details/schedule-details.component';
import { ExamineeLoginComponent } from './delivery/examinee-login/examinee-login.component'
import { DeliveryComponent } from './delivery/delivery.component';
import { LandingComponent } from './delivery/landing/landing.component';
import { TestresultComponent } from './delivery/testresult/testresult.component';
import { ChangePasswordComponent } from './header/change-password/change-password.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ExamineeResetPasswordComponent } from './delivery/reset-password/reset-password.component';
import { CollectionDetailsComponent } from './collection/collection-details/collection-details.component';
import { NoAuthguardService } from './shared/no-authguard.service';
import { AddMultipleItemComponent } from './items/item-landing/add-multiple-item/add-multiple-item.component';
let dcid = 0;
const a = () => {
  if (localStorage.getItem('defaultcollectionuuid')) {
    dcid = JSON.parse(localStorage.getItem('defaultcollectionuuid') || '{}');
    console.log(dcid);
  }
};
a();

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NoAuthguardService] },
  { path: 'collections', component: CollectionhomeComponent, canActivate: [AuthguardService] },
  { path: 'collections/add', component: AddCollectionComponent, canActivate: [AuthguardService] },
  {
    path: 'collections/:id', component: CollectionLandingComponent, canActivate: [AuthguardService],
    children: [
      { path: 'items', component: ItemLandingComponent },
      {path : 'items/uploaditems', component: AddMultipleItemComponent},
      { path: 'dashboard', component: DashboardComponent },
      { path: '', component:CollectionDetailsComponent},
      {
        path: 'tags', loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule)
      },

      { path: 'create-new-item', component: CreateNewItemComponent },
      {
        path: 'items/:id', component: AbcComponent,
        children: [
          { path: 'selectquestiontype', component: ChooseQuestionTypeComponent },
          { path: 'addstandardmcquestion', component: ItemAddQuestionTypeComponent },
          { path: 'addstandardquestioncheckbox', component: ItemAddCheckboxQuestionsComponent },
          { path: 'addstandardquestiontruefalse', component: ItemAddTrueFalseQuestionsComponent },
          { path: '', component: ItemDetailsComponent }
        ]
      },
      { path: 'tests', loadChildren: () => import('./delivery/delivery.module').then(m => m.DeliveryModule) },
      { path: 'add-client', component: AddClientComponent },
      { path: 'test', component: TestsLandingComponent },
      { path: 'tests/:id', component: TestsDetailsComponent },
      { path: 'schedules', loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule) },
      {
        path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        //  children: [
        // { path: 'addstandardmcquestion', component: ItemAddQuestionTypeComponent },
        // { path: 'addstandardquestioncheckbox', component: ItemAddCheckboxQuestionsComponent },
        // { path: 'addstandardquestiontruefalse', component: ItemAddTrueFalseQuestionsComponent },
        // { path: '', component: ItemDetailsComponent }
        // ]
      },
      { path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule) },
      { path: 'scheduledetails/:id', component: AddTestsScheduleComponent },
    ]
  },
  { path: 'users', component: UserLandingComponent, canActivate: [AuthguardService] },
  { path: 'users/add', component: UserCreateComponent, canActivate: [AuthguardService] },
  { path: 'users/:id', component: UserEditComponent, canActivate: [AuthguardService] },
  { path: 'examinees', loadChildren: () => import('./examiness/examiness.module').then(m => m.ExaminessModule) ,canActivate: [AuthguardService]},
  { path: 'examinee-group', loadChildren: () => import('./examinees-group/examinee-group.module').then(m => m.ExamineeGroupModule),canActivate: [AuthguardService] },
  {
    path: 'delivery', component: DeliveryComponent,
    children: [
      { path: 'start/test/:id', component: LandingComponent },
      { path: 'test/result/:id', component: TestresultComponent },
      { path: 'reset-examinee-password', component: ExamineeResetPasswordComponent }
    ],
  },

  { path: 'td/login/:id', component: ExamineeLoginComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: '', redirectTo: 'collections/' + dcid, pathMatch: 'full' },
  { path: 'reset-password', component: ResetPasswordComponent },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

