import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CollectionService } from 'src/app/shared/collection.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditCollectionComponent } from '../edit-collection/edit-collection.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss']
})

export class CollectionDetailsComponent implements OnInit, OnChanges, OnDestroy {
  // variable declaration
  timeZone: any;
  language: string;
  languagesData$: Subscription | undefined;
  timeZoneData$: Subscription | undefined;
  collectionPostData$: Subscription | undefined;
  languageArr = [];
  timeZoneArr = [];
  globalcollectionuuId: any;
  userForm: FormGroup;
  collection: any;
  selectedLanguage: any;
  selectedTimeZone: any;
  collectionUpdateData: any;
  collectionNameDisplay: any;
  collectionDescriptionDisplay: any;
  tab: any;
  tab1: boolean = false;

  constructor(
    private collectionService: CollectionService,
    private notificationService: NotificationService,
    private router: Router,
    public dialog: MatDialog
  ) {
    const readUrl = this.router.url;
    const url1 = readUrl.split('/');
    this.globalcollectionuuId = url1[2];
  }

  ngOnInit(): void {
    // this.globalcollectionuuId = JSON.parse(
    //   localStorage.getItem('defaultcollectionid')
    // );

    this.collectionService.getNewCollectionData.subscribe(resData => {
      console.log(resData);
      this.collectionService.getCollectionDetails(this.globalcollectionuuId).subscribe((result) => {
        console.log(result);
        this.collection = result?.data;
        this.collectionNameDisplay = this.collection?.collectionName;
        this.collectionDescriptionDisplay = this.collection?.description;
      });
      //   this.collectionNameDisplay = resData?.data?.collectionName;
      //   this.collectionDescriptionDisplay = resData?.data?.description;
    });

    this.languagesData$ = this.collectionService
      .getLanguages()
      ?.subscribe((languagesData: any) => {
        this.languageArr = languagesData?.items;
        console.log(this.languageArr);
        this.selectedLanguage = 'English';
      });

    this.timeZoneData$ = this.collectionService
      .getTimeZone()
      ?.subscribe((timeZoneData: any) => {
        this.timeZoneArr = timeZoneData?.items;
      });

    this.userForm = new FormGroup({
      collectionName: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(120),
      ]),
      description: new FormControl(null),
      langid: new FormControl(null, [Validators.required]),
      timezoneid: new FormControl(null, [Validators.required]),
    });

    this.collectionService
      .getCollectionDetails(this.globalcollectionuuId)
      .subscribe((result) => {
        console.log(result);
        this.collection = result?.data;
        this.collectionNameDisplay = this.collection?.collectionName;
        this.collectionDescriptionDisplay = this.collection?.description;
        console.log(this.collection);
        this.userForm
          .get('collectionName')
          .setValue(this.collection?.collectionName);
        this.userForm.get('description').setValue(this.collection?.description);
        const selectedLanguage = this.userForm
          .get('langid')
          .setValue(this.collection?.langid);
        const timeZone = this.userForm
          .get('timezoneid')
          .setValue(this.collection?.timezoneid);
        // this.selectedLanguage = this.collection?.language;
        // this.selectedTimeZone = this.collection?.timezone;
        console.log(this.selectedLanguage);
      });

    // this.shareDataService.collectionData.subscribe(collectionData => {
    //   if (collectionData) {
    //     this.userForm.setValue({
    //       collectionName: collectionData?.collectionName,
    //       description: collectionData?.description,
    //       langid: collectionData?.langid,
    //       timezoneid: collectionData?.timezoneid
    //     });
    //   }
    // });
  }

  get f(): any {
    return this.userForm.controls;
  }

  ngOnChanges(): void { }
  saveBtnFn(): void {
    console.log(this.userForm.value);
    if (this.userForm.value.description != null) {
      this.userForm.value.description = this.userForm.value.description.trim();
    } else {
      this.userForm.value.description = this.userForm.value.description;
    }
    const apiData = {
      collectionName: this.userForm.value.collectionName.trim(),
      description: this.userForm.value.description,
      langid: this.userForm.value.langid,
      timezoneid: this.userForm.value.timezoneid,
      timezoneName: "",
    };

    this.collectionService
      .UpdateCollectionDetails(this.globalcollectionuuId, apiData)
      ?.subscribe(
        (data) => {
          console.log('response form server', data);
          this.notificationService.success(data?.message);
          this.router.navigate(['collections']);
        },
        (error) => {
          this.notificationService.warn(error?.error?.message);
        }
      );
  }
  navigatetoCollection(): void {
    this.router.navigate(['/collections']);
  }

  openEditCollectionDialog(): void {
    const dialogConfig: MatDialogConfig<EditCollectionComponent> = {
      minWidth: '650px',
    };
    const dialogRef = this.dialog.open(EditCollectionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onChange(event: MatTabChangeEvent) {
    this.tab1 = true;
    this.tab = event.tab.textLabel;
    console.log(this.tab);
  }


  ngOnDestroy(): void {
    this.languagesData$?.unsubscribe();
    this.timeZoneData$?.unsubscribe();
    this.collectionPostData$?.unsubscribe();
  }
}

