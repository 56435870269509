<div class="module-wrapper content-wrapper">
    <mat-card>
        <mat-card-content>
            <div class="head-container">
                <h1>Examinee Management</h1>
                <span class="spacer"></span>
                <div>
                  <div class="btn-wrapper">
                    <button mat-flat-button color="warn" class="btn-orange" aria-label="Add New Item" (click)="openNewExamineeDialog()"> + New Examinee</button>
                    <button mat-flat-button class="btn-brown" (click)='addMultileExaminee()'> <span>+ Multiple Examinees</span></button>
                </div>
                </div>
            </div>
            <div class="input-container">
                <div class="input-fields-wrapper">
                    <div class="input-wrapper">
                        <input type="text" [(ngModel)]='firstName' placeholder="Find by First name" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
                        <input type="text" [(ngModel)]='lastName'  placeholder="Find by Last name" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
                        <input type="text" [(ngModel)]='emailId'  placeholder="Find by Email" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
                        <select aria-placeholder="Status" [(ngModel)]='status' class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
                            <option value="" ng-reflect-value="">Status</option>
                            <option value="true" ng-reflect-value="">Active</option>
                            <option value="false" ng-reflect-value="">Inactive</option>
                        </select>
                    </div>
                    <div class="btn-wrapper">
                        <button mat-flat-button color="primary" class="btn-blue" aria-label="Search" (click)='examineesFilter()'>Search</button>
                        <button mat-flat-button color="basic" class="btn-flat" aria-label="Reset" (click)='resetFilter()'>Reset</button>
                    </div>
                </div>
              </div>
              <div *ngIf="isNoExaminee" class="data-not-found-container table-container">
                <span>No Examinees for this client</span>
              </div>
            <div *ngIf="!isNoExaminee">
              <div class="table-container">
                <div *ngIf="isNoExaminee" class="data-not-found-container table-container">
                    <span>No Examinees for this client</span>
                </div>
                <table *ngIf="!isNoExaminee" mat-table [dataSource]="examineesData" class="mat-elevation-z8">

                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">First name</th>
                        <td mat-cell *matCellDef="let row"  >
                           {{row?.firstName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Last name </th>
                        <td mat-cell *matCellDef="let row" >
                            {{row.lastName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="emailId">
                        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Email </th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row.emailId.length > 30 then sliceEmail else noSliceEmail"></ng-container>
                            <ng-template #sliceEmail>
                              <td matTooltip="{{row?.emailId}}" matTooltipPosition="above" innerHtml="{{row?.emailId | slice:0:15}} ..."  class="itemrow">...
                              </td>
                            </ng-template>
                            <ng-template #noSliceEmail>
                             <td  class="itemrow">{{row.emailId}}</td>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
                        <td mat-cell *matCellDef="let row" >
                          <!--  <i *ngIf="row?.active === true" class="fa fas fa-circle publish"
                                matTooltip="Active"
                                matTooltipPosition="above">
                            </i>-->
                            <span *ngIf="row?.active === true" class="fa fas fa-circle publish"
                            matTooltip="Active" matTooltipPosition="above">
                            </span>
                            <span *ngIf="row?.active === false" class="glyphicon glyphicon-adjust unpublish"
                                matTooltip="Inactive"
                                matTooltipPosition="above">
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="updated">
                        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Updated</th>
                        <td mat-cell *matCellDef="let row">
                            <span class="text-bold"> {{row.modifiedBy}} </span>
                            <span> {{ row.modifiedDateTime | dateAgo }} </span>

                        </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
                        <td mat-cell *matCellDef="let row"> -->
                          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"> -->
                            <!-- <mat-icon>more_vert</mat-icon> -->
                          <!-- </button> -->
                          <!-- <mat-menu #menu="matMenu">
                            <button mat-menu-item >
                              <mat-icon>edit</mat-icon>
                              <span>Edit</span>
                            </button>
                          </mat-menu>
                        </td>
                      </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                    <tr class="pointer" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openEditExamineeDialog(row.examineeuuid)"></tr>
                </table>
                <div class="data-not-found-container" *ngIf='noDataFound'>
                    <p>Data not found</p>
                </div>
              </div>
              <div class="examinee-paginator">
                <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
              </div>
              </div>
        </mat-card-content>
    </mat-card>
</div>









<!--
<div class="users-container main-content-wrapper">
    <div class="content-wrapper">
        <div class="filter-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="input-fields-wrapper">
                            <div class="input-wrapper">
                                <input type="text" class="form-control" [(ngModel)]='firstName' placeholder="First name">
                                <input type="text" class="form-control" [(ngModel)]='lastName' placeholder="Last name">
                                <input type="text" class="form-control" [(ngModel)]='emailId' placeholder="Email">
                                <select aria-placeholder="Status" class="form-control" [(ngModel)]='status'>
                                    <option value="">Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </select>
                            </div>
                            <div class="btn-wrapper">
                                <button (click)='examineesFilter()' class="btn-bg-color" mat-raised-button>
                                    <span>Search</span>
                                </button>
                                <button (click)='resetFilter()' class="btn-green-text"  mat-raised-button>
                                    <span>Reset</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="btn-wrapper">
                            <button (click)='addMultileExaminee()' class="btn-brown" mat-flat-button>
                                <span>+ Multiple Examinees</span>
                            </button>
                            <button (click)='createNewExaminee()' class="btn-orange" mat-flat-button>
                                <span>+ New Examinee</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="container-fluid">
            <div class="row"> -->
        <!-- <div class="container-fluid">
            <div *ngIf="isNoExaminee" class="data-not-found-container table-container">
                      <span>No Examinees for this client</span>
            </div>
            <div *ngIf="!isNoExaminee" class="row">
                <div class="col-md-12">                     -->
                   <!-- <div class="success">{{messages}}</div> -->
                    <!-- <div class="table-container">
                        <table mat-table [dataSource]="examineesData" matSort class="mat-elevation-z8">
                            <ng-container matColumnDef="firstName">
                                <th mat-header-cell *matHeaderCellDef >First name</th>
                                <td mat-cell *matCellDef="let row">
                                    <span (click)='navigateToExamineeDetails(row.examineeuuid)'>{{row?.firstName}}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="lastName" >
                                <th mat-header-cell *matHeaderCellDef >Last name </th>
                                <td mat-cell *matCellDef="let row" (click)='navigateToExamineeDetails(row.examineeuuid)'>
                                    {{row.lastName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="emailId">
                                <th mat-header-cell *matHeaderCellDef >Email </th>
                                <td mat-cell *matCellDef="let row" (click)='navigateToExamineeDetails(row.examineeuuid)'>
                                    {{row.emailId}}</td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let row" (click)='navigateToExamineeDetails(row.examineeuuid)'>
                                    <i *ngIf="row?.active === true" class="fa fas fa-circle publish"
                                    matTooltip="Active"
                                    matTooltipPosition="right"></i>
                                    <span *ngIf="row?.active === false" class="glyphicon glyphicon-adjust unpublish"
                                    matTooltip="Inactive"
                                    matTooltipPosition="right"></span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="updated">
                                <th mat-header-cell *matHeaderCellDef mat-sort->Updated</th>
                                <td mat-cell *matCellDef="let row" (click)='navigateToExamineeDetails(row.examineeuuid)'>
                                    <span class="modified-user">{{row.modifiedBy}}</span> <br>
                                     {{row.modifiedDateTime | dateAgo}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <div class="data-not-found-container" *ngIf='noDataFound'>
                            <p>Data not found</p>
                        </div>

                    </div>
                    <div class="examinee-paginator">
                        <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->
