import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';
import { UsersService } from '../../users/users.service';
import { ExamineesService } from '../examinees.service';
import { examineeEditUuid } from '../examiness.component';

@Component({
  selector: 'app-edit-examinee',
  templateUrl: './edit-examinee.component.html',
  styleUrls: ['./edit-examinee.component.scss'],
})
export class EditExamineeComponent implements OnInit {
  examineeDetailsForm: FormGroup;
  duplicateDataFlag = false;
  action = 'createUser';
  examineeuuid: number;
  isAddMode: boolean;
  examineeData: any;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private examineesService: ExamineesService,
    private notificationService: NotificationService,
    // private , 
    @Inject(MAT_DIALOG_DATA) private examineeEditUuid: examineeEditUuid
  ) { }

  ngOnInit(): void {

    // this.examineeuuid = this.actRoute.snapshot.params['id'];
    this.examineeuuid = this.examineeEditUuid?.examineeuuId;
    console.log(this.examineeuuid);
    this.isAddMode = !this.examineeuuid;
    console.log(this.isAddMode);

    this.examineeDetailsForm = new FormGroup({
      firstName: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/),
      ]),
      phone: new FormControl(null, [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(24),
        Validators.pattern(/^(?:\+?(\d{0,3}))?[- \(]*(\d{1,9})[-. \/)]*(\d{1,9})[-. \/]*(\d{1,9})*[-. \/]*(\d{1,9})(?: *x(\d))?$/
        ),
      ]),
      emailId: new FormControl(null, [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(124),
        Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)+\.[A-Za-z]{2,4}$/)
      ]),
      status: new FormControl('true', [Validators.required]),
    });

    this.examineesService.getExamineeByID(this.examineeuuid)?.subscribe((resData) => {
      this.examineeData = resData?.data;
      console.log(resData);
      this.examineeDetailsForm.controls['firstName'].setValue(
        this.examineeData.firstName.trim()
      );
      this.examineeDetailsForm.controls['lastName'].setValue(
        this.examineeData.lastName.trim()
      );
      this.examineeDetailsForm.controls['phone'].setValue(
        this.examineeData.phone
      );
      this.examineeDetailsForm.controls['emailId'].setValue(
        this.examineeData.emailId.trim()
      );
      this.examineeDetailsForm.controls['status'].setValue(
        this.examineeData.active
      );
    });

    this.examineeDetailsForm.get('emailId')?.disable();
  }

  get f() {
    return this.examineeDetailsForm.controls;
  }

  changeField(event: KeyboardEvent) { this.notificationService.warn('Cannot change Email'); if (event.key === ' ' && !this.examineeDetailsForm.value.emailId.length) event.preventDefault(); }

  editExamineeDetails() {
    console.log(this.examineeDetailsForm);

    const apiData = {
      // collectioId: this.collectionId,
      firstName: this.examineeDetailsForm.value.firstName.trim(),
      lastName: this.examineeDetailsForm.value.lastName.trim(),
      phone: this.examineeDetailsForm.value.phone,
      active: this.examineeDetailsForm.value.status,
    };

    console.log(apiData);

    this.examineesService.editExaminee(apiData, this.examineeuuid).subscribe(
      (resData) => {
        console.log(resData);
        this.examineesService.getExaminees()?.subscribe(resData => {
          this.examineesService.newExamineeList(resData);
        });
        this.notificationService.success(resData?.message);
        this.router.navigate(['/examinees']);
      },
      (error) => {
        this.notificationService.warn(error.message);
        // if (error.error.error == 'Conflict') {
        //   this.duplicateDataFlag = true;
        // }
      }
    );
  }

  onTestNameControlFocus(): void {
    this.duplicateDataFlag = false;
  }

  navigateToLandingPage() {
    this.router.navigate(['/examinees']);
  }
}
