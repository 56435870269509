import { identifierModuleUrl } from '@angular/compiler';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/shared/app.service';
import { CollectionService } from 'src/app/shared/collection.service';
import { UserService } from 'src/app/shared/user.service';
import { StyleService } from 'src/app/shared/style.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted = false;
  loginSpinner = false;
  submitted = false;
  loginErrorMessage: string;
  userServiceSubscription$: Subscription;
  private formSubmitAttempt: boolean;
  collections = [];
  collectionData$: Subscription | undefined;
  private _returnUrl: any;
  constructor(private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder, private userService: UserService, private collectionService: CollectionService, private router: Router, private appService: AppService) { }

  ngOnInit(): void {
    // this.styleService.addStyle('login', require('../../../scss/login.css'));
    this.appService.changeUserHeader(0);
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // if(localStorage.getItem('currentUser')){
    //   localStorage.removeItem('currentUser');
    // }
    const returnUrl = this._activatedRoute.snapshot.queryParams['returnUrl'];
    this._returnUrl = returnUrl ? returnUrl : '';
  }

  get f() { return this.loginForm.controls; }
  isFieldInvalid(field: string) {
    console.log("Hello", field);
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSubmitAttempt)
    );
  }
  // login() {
  //   this.loginSpinner = true;
  //   this.isSubmitted = true;
  //   if (this.loginForm.valid) {
  //     this.userServiceSubscription$ = this.userService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
  //       (data: any) => {
  //         console.log(data);
  //         if (data?.data.roleDetails.privileges[0] == 'Examinee') {
  //           this.appService.changeUserHeader(1);
  //           this.router.navigate(['/tests']);
  //         }
  //         else {
  //           this.appService.changeUserHeader(2);
  //         //  this.router.navigate(['/items']);
  //          this.router.navigate(['/collection']);
  //         }
  //       },
  //       error => console.log(error),
  //       () => console.log('login service subscribe completed')
  //     );
  //   }
  // }

  login() {
    this.submitted = true;

    if (this.loginForm.valid) {
      console.log("data Exited");
      this.userServiceSubscription$ = this.userService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
        (data: any) => {
          console.log(data);
          // this.appService.changeUserHeader(1);
          this.collectionData$ = this.collectionService.getCollection()?.subscribe((collectionData: any) => {
            this.collections = collectionData?.data;
            console.log(this.collections);

            if (this.collections?.length > 0) {
              // this.router.navigate(["/collections", this.collections[0].collectionuuId]);
              this.router.navigate(['/collections/' + this.collections[0].collectionuuId + '/items']);
              localStorage.setItem('defaultcollectionid', JSON.stringify(this.collections[0].collectionid));
              localStorage.setItem('defaultcollectionuuid', JSON.stringify(this.collections[0].collectionuuId));

            }
            else {
            }

          })
        },
        error => {
          console.log(error),
            this.loginErrorMessage = error.error.message;
          () => console.log('login service subscribe completed');
        }
      );
    }
  }

  resetPwd() {
    this.router.navigate(["./reset-password"])
  }


  // ngOnDestroy() {
  //   this.userServiceSubscription$.unsubscribe();
  // }
  ngOnDestroy() {
    // this.styleService.removeStyle('login');
  }
}
