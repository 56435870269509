<!--<div class="div-content sentence-align" [innerHTML]='questions?.questiondescription?.description'>

</div>

<div class="div-options sentence-align" *ngFor="let o of questions?.options">
  <mat-checkbox [disabled]='isReadOnly' [ngModel]="o?.answerValid">
    <p [innerHtml]="o?.answerDescription?.description"></p>
  </mat-checkbox>
</div>-->


<div class="question-wrapper">
  <div class="div-content sentence-align" >
    <!-- <h3 aria-label="Question"> <span [innerHTML]='questions?.questiondescription?.description'></span></h3> -->
    <form [formGroup]="form">
			<div class="editor">
				<ngx-editor [editor]="editor" [placeholder]="'Enter here...'" formControlName="editorContent">
				</ngx-editor>
			</div>
		</form>
</div>
    <div class="answer-container" *ngFor="let o of questions?.options">
        <!-- <section class="div-options sentence-align checkbox-section" aria-label="Answer options"> -->
            <div class="example-margin">
                <mat-checkbox class="" [disabled]='isReadOnly' [ngModel]="o?.answerValid" class="options">
                    <!-- <p [innerHtml]="o?.answerDescription?.description"></p>
                     -->
                     <div class="editor">
                      <app-option-editor [optionValue]="o?.answerDescription?.description"></app-option-editor>
                      <!-- <ngx-editor [editor]="editor" [value]="o?.answerDescription?.description">
                      </ngx-editor> -->
                    </div>
                </mat-checkbox>
            </div>
        <!-- </section> -->
    </div>
  </div>