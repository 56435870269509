import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { DeliveryService } from 'src/app/shared/delivery.service';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { TestStatusPopupComponent } from '../modal/test-status-popup/test-status-popup.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TestDialogComponent } from '../modal/test-dialog/test-dialog.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  testDetails: any;
  testuuId: any;
  usertestuuId: number;
  isInstructionDisplayed = true;
  schuid: any;
  startEnable = false;
  msg: any;
  form: FormGroup;
  editor: Editor;
  dialogEditor: boolean = false;
  dialogCalc: boolean = false;
  tools = true;
  option: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    // ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    // ['link', 'image'],
    ['text_color', 'background_color'],
    // ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  @ViewChild('quiz', { read: ViewContainerRef }) private quizviewcontainerref: ViewContainerRef;
  constructor(private deliveryService: DeliveryService, private router: Router, private routerstate: ActivatedRoute,
    private cfr: ComponentFactoryResolver, private datePipe: DatePipe, private dialog: MatDialog) { }


  ngOnInit(): void {
    console.log(this.editor);
    this.schuid = localStorage.getItem('SchId')
    this.editor = new Editor();
    this.form = new FormGroup({
      editorContent: new FormControl(
        { value: '', disabled: true },
        Validators.required
      )
    });

    // this.deliveryService.getExamineeTest(this.schuid).subscribe(resData => {
    //   console.log('resData', resData)
    //   this.testDetails = resData;


    // })


    if (localStorage.getItem('currentUser')) {
      this.usertestuuId = JSON.parse(localStorage.getItem('currentUser') || '{}').userId;
    }

    // this.routerstate.paramMap.pipe(
    //   switchMap((params: any) => {
    //     this.testId = params.get('id');
    //     return this.deliveryservice.getTest(this.testId) // this test id is now represents userid which has to be changed
    //   }
    //   )).subscribe(
    //     data => {
    //       this.testDetails = data;
    //     }
    //   )

    this.routerstate.paramMap.pipe(
      switchMap((params: any) => {
        this.testuuId = params.get('id');
        return this.deliveryService.getExamineeTest(this.schuid) // this test id is now represents userid which has to be changed
      }
      )).subscribe(
        data => {
          this.testDetails = data;
          console.log(data);
          // this.editor = new Editor();
          // this.form = new FormGroup({
          //   editorContent: new FormControl(
          //     { value: this.testDetails?.preTestMessage?.description, disabled: true },
          //     Validators.required
          //   )
          // });
          this.form.controls.editorContent.setValue(this.testDetails?.preTestMessage?.description);
          const currDate = new Date();
          console.log(currDate);

          if (!this.testDetails?.examCompleted) {
            
            if (this.testDetails?.scheduleStatus === 'Test Ongoing') {
              this.startEnable = true;
            }
            else if (this.testDetails?.scheduleStatus === 'Test Expire') {

              this.deliveryService.submitTestQuestions().subscribe(data => {
                console.log("formdelivery",data);
                if(data){
                  this.msg = 'already Submitted'
                }
              });

              this.startEnable = false;
              this.msg = "Schedule time ended and Attempted questions submitted";
              
            }
            else if (this.testDetails?.scheduleStatus === 'Future') {
              this.startEnable = false;
              this.msg = ' Test is yet to start';
            }
            else {
              console.log('Test failed');
            }
          } else {
            // this.msg = 'Schedule time ended';
            // text complete popup open
            this.startEnable = false;
            // const dialogRef = this.dialog.open(TestStatusPopupComponent, {
            //   data: {
            //     message:
            //       "Test is successfully submitted",
            //     buttonText: {
            //       ok: 'Yes',
            //       cancel: 'No',
            //     },
            //   },
            // });
            // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            //   if (confirmed) {
            //     this.startEnable = false;
            //   }
            // });

          }




          // if
          //   (this.formatDate(currDate) >= this.formatDate(this.testDetails?.scheduleEndDate) ||

          //   (this.formatDate(currDate) === this.formatDate(this.testDetails?.scheduleEndDate)) &&
          //   (this.formatTime(currDate) > this.formatTime(this.testDetails?.scheduleEndDate))) {


          //   this.startEnable = false
          //   this.msg = ' This Test has already finish'
          // }
          // else
          //   if (this.formatDate(currDate) < this.formatDate(this.testDetails?.scheduleStartDate) ||
          //     (this.formatDate(this.testDetails?.scheduleStartDate) === this.formatDate(this.testDetails?.scheduleEndDate)) &&
          //     (this.formatTime(currDate) < this.formatTime(this.testDetails?.scheduleEndDate))
          //     //   ............  ||
          //     // (this.formatDate(this.testDetails?.scheduleStartDate) <= this.formatDate(currDate) &&
          //     //   this.formatTime(this.testDetails?.scheduleStartDate) > this.formatTime(currDate)
          //     //................ )
          //     ||
          //     (this.formatDate(this.testDetails?.scheduleStartDate) === this.formatDate(this.testDetails?.scheduleEndDate) &&
          //     (this.formatTime(this.testDetails?.scheduleStartDate) > this.formatTime(currDate)) )

          //     ) {

          //     this.startEnable = false
          //     this.msg = ' Test is yet to start'

          //   }

          //   else
          //    if (this.formatDate(currDate) === this.formatDate(this.testDetails?.scheduleStartDate) ||
          //     (this.formatDate(currDate) === this.testDetails?.scheduleStartDate &&
          //       (
          //         (this.formatTime(currDate) >= this.formatTime(this.testDetails?.scheduleStartDate))
          //         &&
          //         (this.formatTime(currDate) <= this.formatTime(this.testDetails?.scheduleEndDate)))
          //     ) ||
          //     (this.formatDate(this.testDetails?.scheduleStartDate) <= this.formatDate(currDate) &&
          //       (this.formatDate(currDate) <= this.formatDate(this.testDetails?.scheduleEndDate) &&
          //         (this.formatTime(currDate) <= this.formatTime(this.testDetails?.scheduleEndDate))))
          //   ) {
          //     this.startEnable = true

          //   }
          //   else {
          //     console.log('Test failed')
          //   }
        }
        );

  }

  openEditor(): void {
   this.dialogEditor = !this.dialogEditor;
   this.tools = false;
    // const dialogRef = this.dialog.open(TestDialogComponent,{
    //   width: '450px',
    //   height: '100vh',
    //   position: { top: '0%', right: '0%', bottom: '0%' },
    //   panelClass: 'mybookmark-dialog-container',
    // });
  }

  openCalculator(){
    this.dialogCalc = !this.dialogCalc;
    this.tools = false;
  }

  closeDialog(){
   this.dialogEditor = false;
   this.dialogCalc = false;
   this.tools = true;
  }

  formatDate(date): any {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  formatTime(dateTime) {
    console.log('datetime', dateTime);
    const date = new Date(dateTime);
    const t = date.getHours() + ':' + date.getMinutes();
    console.log(t);
    return t;
  }
  DateWithoutTime(dateTime) {
    const date = new Date(dateTime);
    const d = this.datePipe.transform(date, 'yyyy-mm-dd');
    console.log(d);
    return d;
  }

  async loadQuiz(): Promise<void> {
    this.isInstructionDisplayed = false;
    this.quizviewcontainerref.clear();
    const { QuizComponent } = await import('../quiz/quiz.component');
    let quizcomp = this.quizviewcontainerref.createComponent(
      this.cfr.resolveComponentFactory(QuizComponent)
    );
    quizcomp.instance.testuuId = this.testDetails?.testbankuuId;
    quizcomp.instance.usertestuuId = this.testDetails?.usertestuuId;
    quizcomp.instance.testName = this.testDetails?.scheduleName;
    quizcomp.instance.testDescription = this.testDetails?.scheduleDescription;
    quizcomp.instance.testDuration = this.testDetails?.duration;
    quizcomp.instance.testId = this.testDetails?.testId;
    quizcomp.instance.usertestId = this.testDetails?.userTestId;
  }

  get doc(): AbstractControl {
    return this.form.get("editorContent");
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}




