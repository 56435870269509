<!-- 

 <table mat-table [dataSource]="collections" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="collectionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row"><a (click)='navigateToItemLanding(row.collectionid)'>{{row.collectionName}}</a></td>
     </ng-container>
     <ng-container matColumnDef="collectionid">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>
  
         </th>
         <td mat-cell *matCellDef="let row">
            <button (click)='navigateToEditCollection(row.collectionid)'>Edit</button>
         </td>
      
     </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

<button (click)='navigateToCreateNewCollection()'> Add New Collection</button>

<div class="main-content-wrapper dashboard-container">
    <div class="dashboard-wrapper">
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-2">
                    <div class="card test-class">
                        <mat-icon>assignment</mat-icon>
                        <div class="test-title">
                            <h1>3008</h1>
                            <p> Test Completed</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card test-class">

                        <mat-icon>schedule</mat-icon>
                        <div class="test-title">
                            <h1>21</h1>
                            <p>Test Scheduled</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card test-class">
                        <mat-icon>live_tv</mat-icon>
                        <div class="test-title">
                            <h1>6</h1>
                            <p>Live</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card test-class">
                        <mat-icon>add_alert</mat-icon>
                        <div class="test-title">
                            <h1>9</h1>
                            <p>Alerts</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="card test-class">
                        <mat-icon>message</mat-icon>
                        <div class="test-title">
                            <h1>2</h1>
                            <p>Product Messages</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="card test-class">
                        <mat-icon>assignment</mat-icon>
                        <div class="test-title">
                            <h1>1</h1>
                            <p>Tickets</p>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <a href="#/collection/add-collection">
                        <div class="collection-class">
                            <div class="top-heading">
                                <h4> Collections</h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a href="/items">
                        <div class="collection-class">
                            <div class="top-heading">

                                <h4> Items </h4>
                            </div>

                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a href="#/tags">
                        <div class="collection-class">
                            <div class="top-heading">
                                <h4> Tags</h4>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <a href="#/tests-landing">
                        <div class="collection-class">
                            <div class="top-heading">
                                <h4> Tests </h4>
                            </div>
                        </div>
                    </a>

                </div>
                <div class="col-md-4">
                    <a href="#/examinees">
                        <div class="collection-class">
                            <div class="top-heading">
                                <h4> Examinees </h4>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <div class="collection-class">
                        <div class="top-heading">
                            <h4>Results</h4>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">

                <div class="bottom-class">

                    <div class="users">
                       
                            <mat-icon>person</mat-icon>
                            <a href="#/users">     <h3>Users</h3>
                        </a>
                    </div>
                    <div class="settings">
                        <mat-icon>settings</mat-icon>
                        <h3>Settings</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

 <router-outlet></router-outlet>
