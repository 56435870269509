<div class="col-md-12 showExaminee">
    <mat-form-field fxFlex appearance="standard">
      <mat-label>Add recipient <span class="red-asterisk">*</span></mat-label>
      <mat-chip-list #chipList aria-label="Challenge selection" [multiple]="false">
        <mat-chip [value]="c" *ngFor="let c of getSelectedChallenges()" [removable]="true"
          [selected]="chipSelection.isSelected(c)" [attr.aria-selected]="chipSelection.isSelected(c)"
          disableRipple="true" (selectionChange)="chipListChanged($event, c)" (removed)="removeChallenge(c)"
          (click)="chipSelection.toggle(c)" (keyup.enter)="chipSelection.toggle(c)">
          {{ c.emailId }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input type="text" aria-label="Challenges" autoActiveFirstOption [formControl]="challengeInput"
          [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addChallenge($event)" value="">
      </mat-chip-list>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let option of filteredChallenges | async" [value]="getSelectedChallenges()">
          <div (click)="onChallengeSelected($event, option)">
            <mat-checkbox [checked]="chipSelection.isSelected(option)"
              (change)="handleCheckboxCheck($event, option)" (click)="$event.stopPropagation()">
              {{ option.firstName }} ({{option.emailId}})
            </mat-checkbox>
          </div>
        </mat-option>
      </mat-autocomplete>
      <!-- <div *ngIf="f.testType.invalid && (f.testType.dirty || f.testType.touched )" class="alert alert-danger">
          <div *ngIf="f.testType.errors?.required">Select Examinee is required.</div>

        </div> -->
    </mat-form-field>

  </div>
