import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { DeliveryService } from 'src/app/shared/delivery.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Editor } from 'ngx-editor';
@Component({
  selector: 'app-testresult',
  templateUrl: './testresult.component.html',
  styleUrls: ['./testresult.component.scss']
})
export class TestresultComponent implements OnInit {

  userTestId: any;
  testResult: any;
  message: any;
  apiMsg: any;
  form:FormGroup;
  editor: Editor;

  constructor(private deliveryservice: DeliveryService, private router: Router, private routerstate: ActivatedRoute) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.form = new FormGroup({
      editorContent: new FormControl(
        { value: '', disabled: true },
        Validators.required
      )
    });
    this.routerstate.paramMap.pipe(
      switchMap((params: any) => {
        this.userTestId = params.get('id');
        return this.deliveryservice.getTestResult(this.userTestId) // this test id is now represents userid which has to be changed
      }
      )).subscribe(
        data => {
          this.testResult = data;
          if (data?.submitStatus) {
            this.deliveryservice.submitTestObs.next(data.submitStatus);
          }
          // this.apiMsg = this.testResult?.postTestMessage?.description
          // this.defaultMsg = "Test Submitted Succesfully";
          if (this.testResult?.postTestMessage?.description === '') {
            this.message = "Test Submitted Succesfully";
          }
          else {
            this.message = this.testResult?.postTestMessage?.description;
            
          }
          this.form.controls.editorContent.setValue(this.message);
        }
      );
  }

}
