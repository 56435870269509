import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentFactoryResolver, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EditPrivateScheduleComponent } from '../schedules/edit-private-schedule/edit-private-schedule.component';
import { EditPublicScheduleComponent } from '../schedules/edit-public-schedule/edit-public-schedule.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-edit-tests-schedule',
  templateUrl: './edit-tests-schedule.component.html',
  styleUrls: ['./edit-tests-schedule.component.scss']
})
export class EditTestsScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('newscheduletemp', { read: ViewContainerRef, static: true }) private newscheduleviewref: ViewContainerRef;
  constructor(private fb: FormBuilder, private cfr: ComponentFactoryResolver) {
    // this.modeForm = fb.group({
    //   mode: ["Public"]
    // })
  }

  ngOnInit(): void {
    const scheduleInfo = JSON.parse(localStorage.getItem('scheduleInfo'));
    if (scheduleInfo?.scheduleType) {
      this.loadPublicScheudle();
    } else {
      this.loadPrivateSchedule();
    }

  }

  loadPublicScheudle() {

    this.newscheduleviewref.clear();
    // const { CreatePublicScheduleComponent } = await import('../../tests/schedules/create-public-schedule/create-public-schedule.component');
    // let greetcomp = this.newscheduleviewref.createComponent(
    //   this.cfr.resolveComponentFactory(CreatePublicScheduleComponent)
    // );
    const factUrl = this.cfr.resolveComponentFactory(EditPublicScheduleComponent);
    const componentRef = this.newscheduleviewref.createComponent(factUrl);
  }

  loadPrivateSchedule() {

    this.newscheduleviewref.clear();
    // const { CreatePrivateScheduleComponent } = await import('../../tests/schedules/create-private-schedule/create-private-schedule.component');
    // let greetcomp = this.newscheduleviewref.createComponent(
    //   this.cfr.resolveComponentFactory(CreatePrivateScheduleComponent)
    // );
    const factUrl = this.cfr.resolveComponentFactory(EditPrivateScheduleComponent);
    const componentRef = this.newscheduleviewref.createComponent(factUrl);

  }

  ngOnDestroy(): void {
    localStorage.removeItem('scheduleInfo');
  }
}
