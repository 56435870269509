<div class="main-heading">
  <label>Item Preview</label>
  <a >
    <mat-icon mat-dialog-close>close</mat-icon>
  </a>
</div>
<div mat-dialog-content>


  <!-- <div class="table-container">
            <table mat-table [dataSource]="questions" class="mat-elevation-z8" #table>

                <ng-container matColumnDef="itemBankName">
                    <th mat-header-cell *matHeaderCellDef>Questions : {{numberOfQuestions}}</th>
                    <td mat-cell *matCellDef="let row" class="cell-content">
                        <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 1">

                            <app-question-type-checkbox [isReadOnly]='true' [questions]='row'>
                            </app-question-type-checkbox>
                        </div>
                        <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 2">

                            <app-question-type-radio [isReadOnly]='true' [questions]='row'>
                            </app-question-type-radio>
                        </div>
                        <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 3">

                            <app-question-type-truefalse [isReadOnly]='true' [questions]='row'>
                            </app-question-type-truefalse>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="itemDescription">
                    <th mat-header-cell *matHeaderCellDef> </th>

                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div> -->




  <div class="table-container" >
    <table mat-table [dataSource]="questions" class="mat-elevation-z8" #table>

      <ng-container matColumnDef="itemBankName">
        <th mat-header-cell *matHeaderCellDef>Questions : {{numberOfQuestions}}</th>
        <td mat-cell *matCellDef="let row" class="cell-content">
          <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 2">

            <app-question-type-checkbox [isReadOnly]='true' [questions]='row'>
            </app-question-type-checkbox>
          </div>
          <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 1">
     
            <app-question-type-radio [isReadOnly]='true' [questions]='row'>
            </app-question-type-radio>
          </div>
          <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 3">

            <app-question-type-truefalse [isReadOnly]='true' [questions]='row'>
            </app-question-type-truefalse>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="itemDescription">
        <th mat-header-cell *matHeaderCellDef class="icon-td"> </th>
        <td mat-cell *matCellDef="row">
          <!-- <button mat-mini-fab class="btn-bg-color"
                    aria-label="Example icon button with a home icon" >
                    <mat-icon >edit</mat-icon>
                  </button>
                  <button mat-mini-fab class="btn-bg-color"
                    aria-label="Example icon button with a home icon">
                    <mat-icon>delete</mat-icon>
                  </button> -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>