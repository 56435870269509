
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemService } from 'src/app/shared/item.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TestService } from 'src/app/shared/test.service';
import { switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TestsCreateComponent } from '../tests-create/tests-create.component';

// export interface UserData {
//   testname: string;
//   testdescription: string;
//   tags: any;
//   istestTag: boolean;
//   // isTestTag: boolean;
//   updatedDate: any;
//   updatedBy: string;
// }

@Component({
  selector: 'app-tests-landing',
  templateUrl: './tests-landing.component.html',
  styleUrls: ['./tests-landing.component.scss']
})
export class TestsLandingComponent implements OnInit, OnDestroy {
  testServiceSubscription$: Subscription | undefined;
  testFilterServiceSubscription$: Subscription | undefined;
  tests: any;
  displayedColumns: string[] = ['itemBankName', 
  'itemCount', 
  'duration', 
  'itemStatus', 
  'updated', 
  'tags', 
  // 'action'
];
  totalRecords: any;
  searchText: any;
  page: number = 1;
  itemName = '';
  testName = '';
  itemDescription = '';
  testDescription = '';
  itemTag = '';
  itemStatus = '';
  testStatus = '';
  isItemDescended = true;
  // itemId = 10;
  public config: any = {
    id: 'custom',
    itemsPerPage: 3,
    currentPage: 1
  };
  isNoTest = true;

  pageOfItems: any;
  noDataFound = false;
  testStatusList: any;
  globalcollectionuuid: any;
  testId: any;
  // dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) set content(paginator: MatPaginator) {
    this.tests.paginator = paginator;
  }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;


  constructor(private testService: TestService, private router: Router, private routerstate: ActivatedRoute, public dialog: MatDialog) {

  }

  ngOnInit(): void {

    console.log("hello there ");
    this.testService.getTestStatusList()?.subscribe(data => {
      this.testStatusList = data?.items.slice(0, 2);
      console.log(this.testStatusList);
    });

    this.testService.getNewtest.subscribe(testData => {
      this.tests = testData?.data;
      console.log(this.tests);
      if (this.tests?.length >= 1) {
        this.isNoTest = false;
      } else {
        this.tests = new MatTableDataSource(this.tests);
        this.tests.sort = this.sort;
        this.tests.paginator = this.paginator;
        this.isNoTest = true;

      }
    },
      (error) => {
        console.log(error);
      },
      () => {
        console.log('test service subscription completed');
      }) || undefined;


    //   this.globalcollectionuuid = JSON.parse(localStorage.getItem('defaultcollectionuuid'));
    this.routerstate.parent.paramMap.pipe(switchMap((params: any) => {
      console.log("--------ppppp---", params);
      this.globalcollectionuuid = params.get('id');
      return this.testService.getTests(this.globalcollectionuuid);
    })).subscribe(
      (testData) => {
        this.tests = testData?.data;
        if (this.tests?.length == 0) {
          // this.noDataFound = true;
          this.isNoTest = true;
        }
        else {

          this.tests = new MatTableDataSource(this.tests);
          this.tests.sort = this.sort;
          this.tests.paginator = this.paginator;
          this.isNoTest = false;
   
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        console.log('test service subscription completed');
      }) || undefined;
  }

  ngAfterViewInit(): void {
    // this.tests.sort = this.sort;
    this.tests.paginator = this.paginator;
  }


  testFilter(): void {

    const filterObj = {
      // itemId: this.itemId,
      collectionuuId: this.globalcollectionuuid,
      testName: this.testName,
      testDescription: this.testDescription,
      tag: this.itemTag,
      testStatus: this.testStatus
    };

    this.testFilterServiceSubscription$ = this.testService.getTestFilter(filterObj)?.subscribe(
      (filterData) => {
        console.log(filterData);
        this.tests = filterData?.data;
        // console.log(this.tests.length);
        if (this.tests.length === 0) {
          this.noDataFound = true;
        }
        console.log(this.tests);
        this.tests = new MatTableDataSource(this.tests);
        this.tests.sort = this.sort;
        this.tests.paginator = this.paginator;
      },
      (error) => {
        // console.log('dj');
        console.log(error);
        this.isNoTest = true;

        // this.router.navigate(["/login"]);

      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;
    this.noDataFound = false;
  }

  onChangePage(pageOfItems: Array<any>) {
    console.log("function call");
    this.totalRecords = this.tests.length;
    console.log(this.totalRecords);
  }

  dateSort(): void {
    this.isItemDescended = !this.isItemDescended;
    this.tests.sort(this.sortdate);
    console.log(this.tests);
  }

  sortdate(a: any, b: any) {
    // return new Date(a.updated).getTime() - new Date(b.updated).getTime();
    // return new Date(a.updated).getTime() - new Date(b.updated).getTime() ;
    return b.updated > a.updated;
  }

  resetFilter() {

    this.testName = '';
    this.testDescription = '';
    this.itemTag = '';
    this.testStatus = '';
    this.noDataFound = false;
    this.testServiceSubscription$ = this.testService.getTests(this.globalcollectionuuid)?.subscribe(
      (resetData) => {
        // console.log(data);
        this.tests = resetData?.data;
        console.log(this.tests);
        if (this.tests.length === 0) {
          this.noDataFound = true;
        }
        this.tests = new MatTableDataSource(this.tests);
        this.tests.sort = this.sort;
        this.tests.paginator = this.paginator;
      },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);

      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;
  }

  navigateToItemDetails(id: any, testId: any) {
    console.log(id, testId, 'its');
    localStorage.setItem('testBankId', testId);
    this.router.navigate(["../tests", id], { relativeTo: this.routerstate });
  }

  createNewTest() {
    const dialogConfig: MatDialogConfig<TestsCreateComponent> = {
      minWidth: '650px',
    };
    const dialogRef = this.dialog.open(TestsCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.resetFilter();
    });
    // this.router.navigate(["../testsdetails"], { relativeTo: this.routerstate });
  }

  ngOnDestroy() {
    if (this.testServiceSubscription$) {
      this.testServiceSubscription$.unsubscribe();
    }
    if (this.testFilterServiceSubscription$) {
      this.testFilterServiceSubscription$.unsubscribe();
    }
  }
}
