<div class="component-container">
  <div class="row">
    <div class="col-md-12">
      <!-- <h2 class="test-title">{{ testDetails?.testTitle }}</h2>
              <p>{{ testDetails?.testDescription }}</p> -->
      <!-- <h2>Test Name</h2>
              <p>Test Description</p> -->

    </div>
    <hr>

    <div class="col-md-12">
      <div class="score">
        <!-- <h2>Your Total Score : {{testResult?.totalScore}}</h2> -->
        <form [formGroup]="form">
          <div class="editor">
            <ngx-editor [editor]="editor" [placeholder]="''" formControlName="editorContent">
            </ngx-editor>
          </div>
        </form>


      </div>
    </div>



  </div>
  <!-- <div class="col-md-12 result">
        <table border="1" cellspacing="1" cellpadding="1"> 
          <tr>
            <td>Total Questions :</td>
            <td>{{testResult?.totalTestQuestions}}</td>
          </tr>
          <tr>
            <td>Total Questions :</td>
            <td>{{testResult?.totalTestQuestions}}</td>
          </tr>
          <tr>
            <td>Total Attempted :</td>
            <td> {{testResult?.totalAttempted}}</td>
          </tr>
          <tr>
            <td>Total UnAttempted : </td>
            <td>{{testResult?.unAttemptedQuestions}}</td>
          </tr>
          <tr>
            <td>Total Correct Answers : </td>
            <td>{{testResult?.totalCorrectAnswer}}</td>
          </tr>
          <tr>
            <td>Total Incorrect Answers :</td>
            <td> {{testResult?.totalIncorrect}}</td>
          </tr>
          <tr>
            <td>Total Positive Score :</td>
            <td> {{testResult?.totalPositiveScore}}</td>
          </tr>
          <tr>
            <td>Total Negative Score :</td>
            <td> {{testResult?.totalNegativeScore}}</td>
          </tr>
        </table>
       
      </div>
      <div class="col-md-12 text-center">
        <a mat-raised-button color="accent">Go to Dashboard</a>
      </div> -->
</div>


<!-- <div class="table-content">
      <div class="example-table-container">
          <table mat-table [dataSource]="testsDataSource"  class="mat-elevation-z8">
              <ng-container matColumnDef="testTitle">
                  <th mat-header-cell *matHeaderCellDef>Test Title</th>
                  <td mat-cell *matCellDef="let row">
                      <a (click)='navigateToTestsDetails(row.testId, row.userTestId)'>{{row?.testTitle}}</a>
                  </td>
              </ng-container>
              <ng-container matColumnDef="examCompleted">
                  <th mat-header-cell *matHeaderCellDef>Exam Competed</th>
                  <td mat-cell *matCellDef="let row">{{row.examCompleted}}</td>
              </ng-container>
      
              <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef>Updated</th>
                  <td mat-cell *matCellDef="let row">{{row.duration}} {{row.durationDisplay}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>
  
  </div> -->