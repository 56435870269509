import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private tagDataSource = new BehaviorSubject<any>('');
  public tagData = this.tagDataSource.asObservable();

  private tagTypeDataSource = new BehaviorSubject<any>('');
  public tagTypeData = this.tagTypeDataSource.asObservable();

  private tagTypeIdSource = new BehaviorSubject<number>(0);
  public tagTypeId = this.tagTypeIdSource.asObservable();

  private collectionDataSource = new BehaviorSubject<any>('');
  public collectionData = this.collectionDataSource.asObservable();

  private langDataSource = new BehaviorSubject<any>('');
  public languageData = this.langDataSource.asObservable();


  globalCollectionId$: BehaviorSubject<any>;
  globalCollectionuuId$: BehaviorSubject<any>;

  // This email validation pattern used as globally
  globalEmailRegexPattern = '^[a-zA-Z0-9]+([.]?[_-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)*\.[A-Za-z]{1,3}$';


  constructor() {
    this.globalCollectionId$ = new BehaviorSubject<any>(10);
    this.globalCollectionuuId$ = new BehaviorSubject<any>("e57336c6-da29-4650-a431-c82b17c8e944");
  }

  // setGlobalCollectionId(cid : any){
  //   this.globalCollectionId$.next(cid);
  // }

  setGlobalCollectionId(cid: any): any {
    console.log(cid);
    localStorage.setItem('defaultcollectionid', JSON.stringify(cid));
    this.globalCollectionId$.next(cid);
  }
  setGlobalCollectionuuId(cid: any): any {
    console.log(cid);
    localStorage.setItem('defaultcollectionuuid', JSON.stringify(cid));
    this.globalCollectionuuId$.next(cid);
  }
  private handleError(error: any): any {
    console.error(error);
    return throwError(error);
  }
  ////////////////////////// tags releted functions ///////////////////////////////////
  addTagTypeId(tagTypeId: number): void {
    // console.log(tagTypeId);
    this.tagTypeIdSource.next(tagTypeId);
  }

  editTagData(selectedTagData: any): void {
    // console.log("selected tag data share service =>", selectedTagData);
    this.tagDataSource.next(selectedTagData);
  }

  editTagTypeData(selectedTagTypeData: any): void {
    // console.log("selected tag-type data share service =>", selectedTagTypeData);
    this.tagTypeDataSource.next(selectedTagTypeData);
  }
  /////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////// collection releted functions ///////////////////////////////////

  addCollection(): void {
    this.collectionDataSource.next(null);
  }

  editCollection(selectedCollectionData: any): void {
    // console.log("selected collection data share service =>", selectedCollectionData);
    this.collectionDataSource.next(selectedCollectionData);
  }

  /////////////////////////////////////////////////////////////////////////////////////


  ///////////////////////// language  functions///////////////////////////////
  getLanguage(languageData: any): void {
    this.langDataSource.next(languageData);
  }


}
