import { validateVerticalPosition } from '@angular/cdk/overlay';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MessageService } from 'src/app/message.service';
import { NotificationService } from 'src/app/shared/notification.service';
// import { ItemService } from 'src/app/shared/item.service';
import { TestService } from 'src/app/shared/test.service';
import { dialogData } from '../tests-details/tests-details.component';

// export function spaceValidator(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/^\s+|\s+$/g, '').length) {
//       control.setValue('');
//       return { required: true }
//   }
//   else {
//       return null;
//   }
//   }
@Component({
  selector: 'app-tests-basic-details',
  templateUrl: './tests-basic-details.component.html',
  styleUrls: ['./tests-basic-details.component.scss'],
})
export class TestsBasicDetailsComponent implements OnInit {
  @Input() itemBasicDetails: any;
  @Input() isPublished: boolean;
  testDetailsForm: FormGroup;
  testBasicdetails: any;
  globalcollectionid: any;
  testuuId: any;
  @Input() tabData: any;
  messageFlag = false;
  success = false;
  error = false;
  messages = '';
  messagesp = '';
  messagesArray: any[] = [];

  constructor(
    private testService: TestService,
    private actRoute: ActivatedRoute,
    private messageService: MessageService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: dialogData,
    public dialogRef: MatDialogRef<TestsBasicDetailsComponent>
  ) {
    // this.testService.updateTestStatus.subscribe( statusData => {
    //   console.log(statusData)
    //   if (statusData == 1) {
    //     this.isPublished = true;
    //   }
    //   else {
    //     this.isPublished = false;
    //   }
    // })
  }

  ngOnInit(): void {
    console.log(this.data);
    this.testuuId = this.data?.testuuId;
    this.isPublished = false;

    this.messageService.getMessageNext().subscribe((data) => {
      this.messageFlag = true;
      this.success = true;
      this.error = false;
      this.messages = data?.text;
    });

    this.testService.getTestDetails(this.testuuId).subscribe(
        (testData) => {
          console.log(testData);
          if(testData?.data?.statusName=="Published"){
            this.isPublished = true;
          }
          this.testDetailsForm.controls['testTitle'].setValue(
            testData?.data?.testTitle
          );
          this.testDetailsForm.controls['testDescription'].setValue(
            testData?.data?.testDescription
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          console.log('test basic detail service subscription completed');
        }
      ) || undefined;

    // Reactive form
    this.testDetailsForm = new FormGroup({
      testTitle: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(120),
        Validators.pattern(/^[ a-zA-Z.-]+([ ]?[a-z0-9 !#|^*()-_@$!%*?&]){0,}$/)
      ]),
      testDescription: new FormControl(
        '',
        [
          Validators.minLength(1),
          Validators.maxLength(480),
        ]
      ),
    });
  }

  ngOnChanges(): void {


    this.testService.updateTestStatus.subscribe(isTestDisabled =>{
      if (isTestDisabled==true) {
        this.isPublished = isTestDisabled;
      }else{
        this.isPublished=false;
      }
    })


    if (this.tabData) {
      this.messageFlag = false;
    }
  }

  get f() {
    return this.testDetailsForm.controls;
    console.log('form', this.testDetailsForm.value);
  }

  editBasicTestDetails() {
    if (this.testDetailsForm.value.testDescription != null) {
      this.testDetailsForm.value.testDescription =
        this.testDetailsForm.value.testDescription.trim();
    } else {
      this.testDetailsForm.value.testDescription =
        this.testDetailsForm.value.testDescription;
    }
    const apiData = {
      testTitle: this.testDetailsForm.value.testTitle.trim(),
      testDescription: this.testDetailsForm.value.testDescription,
    };

    this.testService.updateTest(apiData, this.testuuId).subscribe(
      (resdata) => {
        console.log(resdata);
        this.testService.updatedTestDetails(resdata);
        this.success = true;
        this.notificationService.success(resdata?.message);
        this.dialogRef.close();
      },
      (error) => {
        this.notificationService.warn(error?.error?.message);
      }
    );
  }
  ngOnDestroy(){
    this.isPublished=null;
  }
}
