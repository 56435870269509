import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  constructor(private router: Router, private appService: AppService) { }

  canActivate(next: ActivatedRouteSnapshot,
  ) {
    if (localStorage.getItem('currentUser')) {
      let data = JSON.parse(localStorage.getItem('currentUser') || '{}');

      // if (data?.roleDetails?.privileges[0] == 'Examinee') {
      //   this.appService.changeUserHeader(1);
      // }
      // else {
      this.appService.changeUserHeader(1);
      // }
      return true;
    }

    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: next['_routerState'].url,
      },
    });
    return false;
  }
}
