


import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemService } from 'src/app/shared/item.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ShareDataService } from 'src/app/shared/share-data.service';
import { switchMap } from 'rxjs/operators';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateNewItemComponent } from '../create-new-item/create-new-item.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { QuestionService } from 'src/app/shared/question.service';
export interface UserData {
  itemname: string;
  itemdescription: string;
  tags: any;
  isItemTag: boolean;
  // isTestTag: boolean;
  updatedDate: any;
  updatedBy: string;
}

@Component({
  selector: 'app-item-landing',
  templateUrl: './item-landing.component.html',
  styleUrls: ['./item-landing.component.scss']
})
export class ItemLandingComponent implements OnInit, OnDestroy, AfterViewInit {
  itemServiceSubscription$: Subscription | undefined;
  itemFilterServiceSubscription$: Subscription | undefined;
  items: any;
  displayedColumns: string[] = ['itemBankName', 
  'questionTypes', 
  'itemStatus', 
  'updated', 
  'tags', 
  // 'action'
];
  totalRecords: any;
  searchText: any;
  page: number = 1;
  itemName = "";
  itemDescription = "";
  itemStatus = '';
  noDataFound = false;
  isItemDescended = true;
  itemId: any;
  globalcollectionid: any;
  globalcollectionuuid: any;
  tagInput: string = '';
  isNoItems = false;
  tag = null;
  public config: any = {
    id: 'custom',
    itemsPerPage: 3,
    currentPage: 1
  };

  statusArray = [
    { status: 'Published' },
    { status: 'Unpublished' },
  ];

  filterForm: FormGroup;
  pageOfItems: any;
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) set content(paginator: MatPaginator) {
    this.items.paginator = paginator;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private fb: FormBuilder, private itemService: ItemService, private router: Router,
    private sharedService: ShareDataService, private routerstate: ActivatedRoute,
    public dialog: MatDialog, private cd: ChangeDetectorRef) {

  }


  ngOnInit(): void {
    this.routerstate.parent.paramMap.pipe(
      switchMap((params: any) => {
        this.globalcollectionid = params.get('id');
        return this.itemService.getItem(this.globalcollectionid)
      })).subscribe(
        (resData) => {
          this.items = resData?.data;
          if (this.items?.length < 1) {
            this.isNoItems = true;
            this.itemName = '',
            this.itemDescription = '',
            this.tag = '',
            this.itemStatus = ''
          }
          else {
            this.items = new MatTableDataSource(this.items);
            this.items.sort = this.sort;
            this.items.paginator = this.paginator;
            this.isNoItems = false;
            this.itemId = this.items?.itemBankId;
            this.filterForm = this.fb.group({
              itemName: [''],
              itemDescription: [''],
              tag: [''],
              itemStatus: ['']
            });

            this.itemName = '',
            this.itemDescription = '',
            this.tag = '',
            this.itemStatus = ''
            
            this.noDataFound = false;
            this.globalcollectionuuid = JSON.parse(
              localStorage.getItem('defaultcollectionuuid')
            );
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          console.log('item service subscription completed');
        }) || undefined;

    this.filterForm = this.fb.group({
      itemName: [''],
      itemDescription: [''],
      tag: [''],
      itemStatus: ['']

    });
  }

  ngAfterViewInit(): void {
    //  this.items.paginator = this.paginator;
    // this.items.sort = this.sort;
  }

  getItemsInCollection() {

    this.itemService.getItem(this.globalcollectionid).subscribe(
      (resData) => {
        this.items = resData?.data;
        if (this.items?.length == 0) {
          this.isNoItems = true;
        }
        else {
          this.isNoItems = false;
          this.noDataFound = false;
          this.items = new MatTableDataSource(this.items);
          this.cd.detectChanges();
          this.items.sort = this.sort;
          this.items.paginator = this.paginator;
          this.itemId = this.items?.itemBankId;
          this.filterForm = this.fb.group({
            itemName: [''],
            itemDescription: [''],
            tag: [''],
            itemStatus: ['']
          });

          this.globalcollectionuuid = JSON.parse(
            localStorage.getItem('defaultcollectionuuid')
          );
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        console.log('item service subscription completed');
      });

  }

  openNewItemDialog(): void {
    const dialogConfig: MatDialogConfig<CreateNewItemComponent> = {
      minWidth: '650px',
    };
    const dialogRef = this.dialog.open(CreateNewItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getItemsInCollection();
    });
  }

  addMultipleItems(){
    this.router.navigate(["uploaditems"],{relativeTo : this.routerstate});
  }


  itemFilter(): void {
    const filterObj = {
      collectionuuid: this.globalcollectionuuid,
      itemName: this.itemName ? this.itemName : '',
      itemDescription: this.itemDescription ? this.itemDescription : '',
      tag: this.tag ? this.tag : '',
      itemStatus: this.itemStatus.toLowerCase()
    };
    this.itemFilterServiceSubscription$ = this.itemService.getItemFilter(filterObj)?.subscribe(
      (resData) => {
        this.items = resData?.data;
        if (this.items.length == 0) {
          this.noDataFound = true;
        }

        this.items = new MatTableDataSource(this.items);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;


      },
      (error) => {
        console.log(error);
        this.isNoItems = true;
      },
      () => {
      }) || undefined;
    this.noDataFound = false;


  }
  onChangePage(pageOfItems: Array<any>) {
    this.totalRecords = this.items?.length;
  }

  dateSort(): void {
    this.isItemDescended = !this.isItemDescended;
    this.items.sort(this.sortdate);
  }



  sortdate(a: any, b: any) {
    return b.updated > a.updated;
  }

  resetFilter() {
    this.itemName = '',
      this.itemDescription = '',
      this.tag = '',
      this.itemStatus = ''

    // this.filterForm = this.fb.group({
    //   itemName: [''],
    //   itemDescription: [''],
    //   tag: [''],
    //   itemStatus: ['']
    // });
    this.noDataFound = false;
    this.itemServiceSubscription$ = this.itemService.getItem(this.globalcollectionuuid)?.subscribe(
      (resData) => {
        this.items = resData?.data;
        if (this.items.length == 0) {
          this.noDataFound = true;
        }
        this.items = new MatTableDataSource(this.items);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
        this.router.navigate(["/login"]);

      },
      () => {
      }) || undefined;

  }

  navigateToItemDetails(id: any, itemBankId: any) {
    localStorage.setItem("itemId", itemBankId);
    this.router.navigate(["../items", id], { relativeTo: this.routerstate });
  }
  navigateToCreateNewItem(): void {
    this.router.navigate(["../create-new-item"], { relativeTo: this.routerstate });
  }

  ngOnDestroy() {

    if (this.itemServiceSubscription$) {
      this.itemServiceSubscription$.unsubscribe();
    }
    if (this.itemFilterServiceSubscription$) {
      this.itemFilterServiceSubscription$.unsubscribe();
    }
  }

}




