<div class="">

 <div class="filter-section text-right">
    <button (click)='saveSettings()' mat-flat-button color="primary" class="btn-blue"
      [disabled]='isPublished'>Save</button>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <label for="" class="lableH" > Status</label>
        <div class="autoC">
          <form>
          <mat-form-field appearance="fill">
              <mat-select [(value)]="defaultStatus" [disabled]='isPublished'>
                <mat-option *ngFor="let status of selectStatus" [value]="status?.itemstatus">
                  <p (click)="updateStatus(status)">{{status?.itemstatus}} </p>
                </mat-option>
              </mat-select>

            </mat-form-field>

          </form>
        </div>
      </div>
      <div class="col-md-4">
        <label for="" class="lableH"> Difficulty level</label>
        <div class="autoC" >
          <form >
            <mat-form-field appearance="fill"  >
              <mat-select [(value)]="selectedLevelID" [disabled]='isPublished'>
                <mat-option *ngFor="let level of selectLevels" [value]="level?.difficultyLevelId">
                  <p (click)="updateLevel(level?.difficultyLevelId)">{{level?.difficultyLevelId}}</p>

                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-4">
          <div class="toggle-div">
            <label class="lableH"> Shuffle question</label>
            <mat-slide-toggle ngDefaultControl [(ngModel)]="shuffle" (change)="onChange($event)" [disabled]='true'>
            </mat-slide-toggle>
          </div>
        </div>
      </div> -->
    </div>
</div>

