

<div class="item-bank-list-container" *ngIf="existingItemList==false">
    <div class="filter-section">
        <div class="col-md-8 text-center" [ngClass]="{'text-success': success,'text-danger': error}"
            *ngIf="messageFlag">{{messages}}</div>
        <div class="btn-wrapper">
            <button mat-raised-button color="primary" class="btn-orange" (click)="openDialogPreview()" [disabled]="isNoItem">Preview</button>
            <button color="primary" class="btn-bg-color" (click)='addExistingItem()' mat-raised-button mat-raised-button [disabled]="isPublished">
                <span> + Existing Item</span>
            </button>
            <button class="btn-orange new-item"  mat-raised-button (click)="createNewItem()"
            [disabled]="isPublished">
                <span> + New Item</span>
            </button>
        </div>
    </div>
    <div *ngIf="isNoItem" class="data-not-found-container table-container">
        <span>No existing Items in this Test</span>
    </div>
    <div  class="row" *ngIf="!isNoItem" >
        <div class="col-md-12">
            <div  class="table-container"  >
        <table  mat-table [dataSource]="testItems" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="itemBankName">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Item(s)</th>
                <td mat-cell *matCellDef="let row" (click)='navigateToTestDetails(row.itemBankId)'>
                    <span class="text-bold">{{row?.itemBankName}}</span>

                    <ng-container *ngIf="row?.itemDescription?.length > 20 then sliceDesc else nosliceDesc">
                    </ng-container>
                    <ng-template #sliceDesc>
                        <span matTooltipClass="my-custom-tooltip" matTooltip="{{row?.itemDescription}}"
                        matTooltipPosition="above" > {{row?.itemDescription | slice: 0:20}}... </span>
                    </ng-template>
                    <ng-template #nosliceDesc>
                        <span> {{row?.itemDescription}} </span>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="itemStatus">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
                <td mat-cell *matCellDef="let row">
                        <span *ngIf="row?.itemStatus === 'Published'" class="fa fas fa-circle publish"
                        matTooltip="Published" matTooltipPosition="above">
                    </span>
                    <span *ngIf="row?.itemStatus === 'Unpublished'" class="glyphicon glyphicon-adjust unpublish"
                        matTooltip="Unpublished" matTooltipPosition="above"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Updated</th>
                <td mat-cell *matCellDef="let row">
                    <span class="text-bold">{{row.modifiedByUserName}}</span> {{row.modifiedDate | dateAgo}}</td>
            </ng-container>
            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag(s)</th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="row.tagdescription.length > 2 then sliceDesc else nosliceDesc" ></ng-container>
                    <ng-template #sliceDesc>
                        <div class="tag-data">
                            <table>
                                <tr *ngFor="let ele of row.tagdescription" >
                                  <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:44)+'...'"  matTooltipPosition="above" matTooltip="{{ele}}" matTooltipClass="my-custom-tooltip"></td>
                                  <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                                </tr>
                            </table>
                        </div>
                    </ng-template>
                    <ng-template #nosliceDesc>
                        <table>
                            <tr *ngFor="let ele of row.tagdescription">
                              <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:44)+'...'"  matTooltip="{{ele}}" matTooltipPosition="above" matTooltipClass="my-custom-tooltip"></td>
                              <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                            </tr>
                        </table>
                    </ng-template>

                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openConfirmationDialog(row?.testBankuuId, row?.itemBankuuId, row?.itemBankName )"  [disabled]="isPublished">
                        <mat-icon>delete_outline</mat-icon>
                        <span>Delete</span>
                      </button>
                  </mat-menu>
                  <!-- <button mat-icon-button [disabled]="isPublished"
               (click)="openConfirmationDialog(row?.testBankuuId, row?.itemBankuuId, row?.itemBankName )" matTooltip="Delete">
                <span class="menu-item">
                  <i class="material-icons">delete</i>
                </span>
              </button> -->
                </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div class="no-records-found" *ngIf="testItems?.data?.length === 0">No records found</div>

            </div>

            <div class="item-paginator" [ngClass]="[isNoItem? 'checkedClass' : 'uncheckedClass']">
                <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>

</div>

<div class="item-bank-list-container" *ngIf="existingItemList==true">
    <app-tests-existing-items (closeExistingItemEvent)='closeExistingItem($event)'></app-tests-existing-items>
</div>
