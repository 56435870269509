import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
// import { ResponseContentType, RequestOptions } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class ExamineesService {

  // variable declaration
  private errorResponseSubject: BehaviorSubject<any>;
  errorResponseSubjectObservable: Observable<any>;

  private newExaminee = new BehaviorSubject<any>('');
  public getNewExamineeData = this.newExaminee.asObservable();

  constructor(private http: HttpClient) {
    this.errorResponseSubject = new BehaviorSubject<any>('');
    this.errorResponseSubjectObservable = this.errorResponseSubject.asObservable();
  }

  newExamineeList(examinee: any){
    console.log(examinee);
    this.newExaminee.next(examinee);
  }

  getExaminees(): Observable<any> | null {
    // console.log(collectionId);

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      const fetchUsersApiUrl = environment.apiUrl + 'examinee';
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchUsersApiUrl, { 'headers': headers }).pipe(
        tap((data) => console.log(data)),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration 
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }

  
  getExamineeByID(examineeuuId: number): Observable<any> | null {
    // console.log(examineeuuId);

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      // const clientId = currentUserFromStorage.clientId;
      const fetchUserApiUrl = environment.apiUrl + 'examinee/' + examineeuuId + '/details';
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchUserApiUrl, { 'headers': headers }).pipe(
        tap((data) => console.log(data)),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration 
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }

  getExamineesFilter(options: any): Observable<any> | null {
    console.log(options);
    // const fetchItemapiUrl = environment.apiUrl + 'items?collectionId=' + filterData.collectionId + '&itemBankName=' + item.itemName + '&itemDescription=' + item.itemDescription + '&itemStatus' + item.itemStaus;
    // const fetchTestApiUrl = environment.apiUrl
    //   + 'tests?collectionId=' + filterData.collectionId
    //   + '&testName=' + filterData.testName
    //   + '&testDescription=' + filterData.testDescription
    //   + '&tagdescription=' + filterData.tagdescription
    //   + '&testStatus=' + filterData.testStatus;

    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const clientId = currentUserFromStorage.clientId;
      const fetchUsersApiUrl = environment.apiUrl + 'examinee/' + '?' + options;
      // console.log(accessTokenFromStoarge);
      // console.log(fetchItemapiUrl);
      const headers = { 'token': accessTokenFromStoarge }
      return this.http.get<any>(fetchUsersApiUrl, { 'headers': headers }).pipe(
        tap((data) => console.log(data)),
        catchError(error => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          // handle here status code error for token expiration 
          return throwError(error);
        })
      )
    }
    else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }

  }

  createNewExaminee(dataToApi: any) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    const clientId = currentUserFromStorage.clientId;
    const addUserApiUrl = environment.apiUrl + 'examinee';
    console.log('add-user api url', addUserApiUrl);
    dataToApi.clientId = clientId;
    return this.http.post<any>(addUserApiUrl, JSON.stringify(dataToApi), { 'headers': headers }).pipe(
      tap((data) => data),
      catchError(error => {
        this.errorResponseSubject.next(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        if (error.status === 0) {
          return throwError(null);
        }
        return throwError(error);
      })
    );
  }

  editExaminee(dataToApi: any, examineeuuid: number) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'content-type': 'application/json', 'token': accessTokenFromStoarge }
    // const clientId = currentUserFromStorage.clientId;
    const editUserApiUrl = environment.apiUrl + 'examinee/' + examineeuuid;
    // dataToApi.clientId=clientId;
    return this.http.put<any>(editUserApiUrl, JSON.stringify(dataToApi), { 'headers': headers }).pipe(
      tap(data => console.log(data)),
      catchError(this.handleError)
    )
  }

  //   public downloadFile(docFile: string): Observable < Blob > {  
  //     return this.http.get(this.url + '/GetFile?docFile=' + docFile, {  
  //         responseType: 'blob'  
  //     });  
  // } 






  downloadExamineeTemplate(): Observable<any> | null {

    const fetchDownloadApiUrl = environment.apiUrl + 'examinee/download/template';
    // if (localStorage.getItem('currentUser')) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge };
    // const options = new RequestOptions({ responseType: ResponseContentType.ArrayBuffer });
    // 

    console.log('download0', fetchDownloadApiUrl)
    // return this.http.get(`download_template?template=${type}`, { responseType: 'blob' });

    return this.http.get<any>(fetchDownloadApiUrl, { 'headers': headers }).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration 
        return throwError(error);
      })
    )
  }


  DownloadFile(filePath: string, fileType: string): Observable<any> {

    const fetchDownloadApiUrl = environment.apiUrl + 'examinee/download/template';
    // if (localStorage.getItem('currentUser')) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const headers = { 'token': accessTokenFromStoarge };

    let fileExtension = fileType;
    let input = filePath;

    return this.http.get(fetchDownloadApiUrl + "?fileName=" + input, {

      responseType: 'blob',
      observe: 'response',
      'headers': headers
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: fileExtension });
        })
      );
  }


  UploadExcel(formData: FormData, clientID: any) {
    const fetchDownloadApiUrl = environment.apiUrl + 'examinee/upload/template';
    // if (localStorage.getItem('currentUser')) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const clientId = currentUserFromStorage ?.clientId;
    const headers = { 'token': accessTokenFromStoarge };
    const httpOptions = { 'headers': headers };
    const body = {
      "formData": formData,
      "clientId": clientID
      }

    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');


    // return this.http.post(fetchDownloadApiUrl , formData, httpOptions)


    return this.http.post<any>(fetchDownloadApiUrl ,body , httpOptions).pipe(
      tap((data) => console.log(data)),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration 
        return throwError(error);
      })
    )
  }
  

  public upload(formData) {
    const currentUserFromStorage = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
    const clientId = currentUserFromStorage?.clientId;
    const fetchDownloadApiUrl = environment.apiUrl + 'examinee/upload/template?clientId=' + clientId;
    const headers = { 'token': accessTokenFromStoarge };
    const httpOptions = { 'content-type': 'multipart/form-data; boundary=<calculated when request is sent>', 'headers': headers };
    return this.http.post(fetchDownloadApiUrl, formData, httpOptions).pipe(
      tap((data) => data),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {

          return throwError(new Error(error.status));
        }
        // handle here status code error for token expiration 
        return throwError(error);
      })
    )
  }

  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }

}
