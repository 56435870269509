import {
  Component,
  OnInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { toHTML } from 'ngx-editor';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { QuestionService } from 'src/app/shared/question.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { ItemService } from 'src/app/shared/item.service';
import {
  AddNewQuestion,
  QuestionDescription,
  Options,
} from 'src/app/shared/addnewquestion';
import { NotificationService } from 'src/app/shared/notification.service';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';

export interface DialogData {
  animal: string;
  resData: string;
  html: string;
  editor: Editor;
  editors: Editor[];
  option: any;
  editor1: Editor;
  optionId: any;
}

@Component({
  selector: 'app-item-add-question-type',
  templateUrl: './item-add-question-type.component.html',
  styleUrls: ['./item-add-question-type.component.scss'],
})
export class ItemAddQuestionTypeComponent implements OnInit, OnDestroy {
  listDisplay = false;
  itemId: any;
  deleteOptionId: any;
  display: boolean = false;
  que: any;
  editor1: Editor;
  questionDescription: [];
  set_main_form = false;
  addOptionNew: Options[] = [];
  addedOptions: string[] = [];
  newOptionArray = [];
  moreSetting: [];
  opened = false;
  optionDiv = false;
  openedD = false;
  name = 'Angular 6';
  parentData = 1;
  i = 0;
  userForm: FormGroup;
  tabs = [];
  selected = new FormControl(0);
  editorform: FormGroup;
  editor: Editor;
  editors: Editor[] = [];
  option: [];
  question: [];
  fetchEditQuetion: any;
  fetchEditOption: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  panelOpenState: boolean = false;
  animal: string;
  name1: string;
  html: string;
  questionhtml: any;
  fetchEditQuetion1: any;
  queId: any;
  seteditor = true;
  autoscoreSelectValue: any;
  allowChwckAnsSelectValue: any;
  scoringTypeExactMatchValue: any;
  shuffleSelectValue: any;
  autoscoring: boolean;
  checkansSelectValue: any;
  checkAnsmaintValue: any;

  miniscoretype: any;
  miniscoretypeMainvalue: any;

  penaltypointselectvalue: any;
  penaltypointMainvalue: any;
  scoringselectvalue: any;
  addnewquestion = new AddNewQuestion();
  addnewquestiondescription = new QuestionDescription();
  setAddOption = true;
  editMode = false;

  radioSelected: [];
  checkradiovalue = false;

  getscoringtype: any;
  getAllowCheckAns: any;

  updateOptionText: any;

  submitted = false;

  newArrayforAddOption: any;

  addOptionEditor = false;
  saveDataValidation = false;

  checkSelectionOption = [];
  arrayForScoreadd: any;
  collectionId: any;

  addQuestionScore: any;
  newEvent = 0;
  newEvent1: any;
  scoreArray = [];
  questionscoreF = false;
  questionId12: any;
  defultvalue = 0;
  defultvalueScore = 0;
  correctScorePoint: any;
  itemBankQueId: any;
  @Output() postCreated = new EventEmitter();
  addQuestionForm: FormGroup;
  optionModified: boolean = false;
  saveDisable: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routerstate: ActivatedRoute,
    public dialog: MatDialog,
    public questionService: QuestionService,
    public itemservice: ItemService,
    private notificationServices: NotificationService
  ) {
    // this.itemBankQueId = this.router.getCurrentNavigation()?.extras?.state?.iteBankQueId;
    this.itemBankQueId = localStorage.getItem('itemId');
    console.log('bankid', this.itemBankQueId);

    this.routerstate?.parent?.params?.subscribe((data) => {
      console.log("--->data", data);
      this.itemId = data.id;
      // this.queId = this.router.getCurrentNavigation() ?.extras ?.state ?.queId;
      // console.log(this.queId);
      console.log(this.itemId);
    });

    if (localStorage.getItem('questionuuId')) {
      this.queId = localStorage.getItem('questionuuId');
    } else {
      this.queId = this.router.getCurrentNavigation()?.extras?.state?.queId;
    }

    // this.editMode = this.router.getCurrentNavigation() ?.extras ?.state ?.isEdit;
    this.userForm = fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
      autoscore: new FormControl(true),
      shuffle: new FormControl(false),
      scoringTypeMatch: ['true'],
      allowCheckAnswer: ['false'],
      penaltyPoint: [''],

      minimumScoreIfAttempt: [''],
    });

    this.questionService.updateEditText.subscribe((data) => {
      // console.log("updta enmew one", this.fetchEditOption);
      if (this.editMode == true) {
        if (this.fetchEditOption) {
          this.fetchEditOption.forEach((a) => {
            if (a.optionId == data.optionId) {
              a.answerDescription.description = data.newValue;

              a.modified = true;
              this.optionModified = true;
              console.log('next subscribed value: ' + this.fetchEditOption);
            }
          });
          this.fetchEditOption.forEach((a) => {
            if (a.correctPoints) {
              a.modified = true;

              // console.log('next subscribed value: ' + a.answerDescription.description);
            }
          });
          this.addOptionNew = this.fetchEditOption;
          // this.addedOptions=this.fetchEditOption;
        }
      } else {
        if (this.addOptionNew) {
          this.newArrayforAddOption = this.addOptionNew;
          this.newArrayforAddOption.forEach((a) => {
            if (a.optionId == data.optionId) {
              a.answerDescription.description = data.newValue;
              a.modified = true;

            }
          });
          this.addOptionNew = this.newArrayforAddOption;
          // this.addedOptions=this.fetchEditOption;
        }
      }

      // console.log('next subscribed value: ' + data.newValue);
    });
  }

  createOptionControl(): FormGroup {
    return this.fb.group({
      value: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.saveDisable = false;
    this.editor = new Editor();
    this.editor1 = new Editor();
    this.addQuestionForm = new FormGroup({
      question: new FormControl(
        { value: '', disabled: false },
        Validators.required
      )
    });
    // this.addQuestionForm = this.fb.group({
    //   question: ['', [Validators.required, Validators.minLength(1)]],
    // });
    this.collectionId = localStorage.getItem('defaultcollectionid');
    // this.itemId=JSON.parse(localStorage.getItem('ItemIdQue'));
    if (this.queId) {
      this.seteditor = true;
      this.editMode = true;
      this.questionService
        .getQuestion(this.itemId, this.queId)
        .subscribe((resdata) => {
          console.log('data get questions', resdata?.data?.questiondescription?.description);
          // this.question = resdata?.data?.questiondescription?.description;
          this.addQuestionForm.controls['question'].setValue(resdata?.data?.questiondescription?.description);
          this.fetchEditOption = resdata?.data?.options;
          this.addOptionNew = this.fetchEditOption;
          this.newEvent = resdata?.data?.correctPoints;
          this.autoscoring = resdata.data?.autoScoring;
          // this.fetchEditQuetion1 = data ?.questions[0] ?.questionid;
          this.arrayForScoreadd = this.fetchEditOption;
          this.userForm.setValue({
            hideRequired: this.hideRequiredControl,
            floatLabel: this.floatLabelControl,
            autoscore: resdata?.data.autoScoring,
            shuffle: resdata?.data.shuffleOption,
            scoringTypeMatch: resdata?.data.scoringTypeExactMatch,
            allowCheckAnswer: resdata?.data.allowCheckAnswer,
            penaltyPoint: resdata?.data.penaltyPoint,

            minimumScoreIfAttempt: resdata?.data.minScoreIfAttempt,
          });
          this.getscoringtype = String(resdata?.data.scoringTypeExactMatch);
          this.getAllowCheckAns = String(resdata?.data.allowCheckAnswer);
        });
    }
  }

  get optionrows(): FormArray {
    return <FormArray>this.userForm.get('optionrows');
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      this.notificationServices.warn('Cannot change Total question score');
      return false;
    } else {
      this.notificationServices.warn('Cannot change Total question score');
      return false;
    }
  }


  // get f() { return this.userForm.controls; }
  addOptionRow(): void {
    this.editors.push(new Editor());
    this.optionrows.push(this.createOptionControl());
  }

  removeOptionRow(index: any) {
    (this.userForm.get('optionrows') as FormArray).removeAt(index);
    //this.editors.remove(index);
  }

  getRowFormControls(): AbstractControl[] {
    return (<FormArray>this.userForm.get('DyRow')).controls;
  }

  onCheckRadioOption(value) {
    console.log('va;ue jsgdfhgdfhd', value);
    if (value) {
      this.addOptionNew.forEach((element) => {
        // console.log('element', element);
        if (
          element.answerDescription.description ==
          value.answerDescription.description
        ) {
          // console.log(element.answerValid);
          element.answerValid = true;

          // if (element.answerValid == true) {
          //   element.answerValid = false;
          //   // console.log('ainther false ', element);
          // } else {
          //   element.answerValid = true;
          //   // console.log('ainther true ', element);
          // }
        } else {
          element.answerValid = false;
        }
        this.addScore(value);
      });
      // this.addOptionNew = this.checkSelectionOption;
    }

    // console.log(" this.newArrayforAddOption", this.checkSelectionOption);
  }

  addOption() {
    this.questionscoreF = true;
    var i = this.addOptionNew.length + 1;
    this.html = toHTML(this.option);
    let rmcharHtml = this.html.replace(/(<([^>]+)>)/ig, '');
    let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
    if (this.html != '<p></p>' && rmCharSpace.length !== 0) {
      this.addedOptions.push(this.html);
      this.addOptionNew.push({
        answerDescription: {
          description: this.html,
          mediaURL: [''],
        },
        answerValid: false,
        arrangeOrder: 0,
        correctPoints: 0,
        modified: false,
        optionId: i,
        selected: false,
      });
      this.checkSelectionOption = this.addOptionNew;
      this.newArrayforAddOption = this.addedOptions;
    }
    this.editor1.setContent('');
    console.log("AdddnewOPtion 676767676767", this.addOptionNew);
    this.arrayForScoreadd = this.addOptionNew;
  }
  addScore(valuescore) {
    console.log('valueScore', valuescore);

    if (this.arrayForScoreadd) {
      this.scoreArray = [];
      this.arrayForScoreadd.forEach((element) => {
        if (element.answerValid == true) {
          this.scoreArray.push(element.correctPoints);
        }
      });
    }

    console.log('element.correctPoints', this.scoreArray);
    this.newEvent = this.scoreArray.reduce(add, 0);
    function add(accumulator, a) {
      return accumulator + a;
    }

    // this.scoreArray = [];
    // this.arrayForScoreadd.forEach((element) => {
    //   this.scoreArray.push(element.correctPoints);
    // });
    // console.log("element.correctPoints",this.scoreArray)
    // this.newEvent = this.scoreArray.reduce(add, 0);
    // function add(accumulator, a) {
    //    return a;
    // }
  }

  deleteOption(index: any) {
    this.addOptionNew.splice(index, 1);
    if (this.arrayForScoreadd) {
      this.scoreArray = [];
      this.arrayForScoreadd.forEach((element) => {
        if (element.answerValid == true) {
          this.scoreArray.push(element.correctPoints);
        }
      });
    }

    console.log('element.correctPoints', this.scoreArray);
    this.newEvent = this.scoreArray.reduce(add, 0);
    function add(accumulator, a) {
      return accumulator + a;
    }
  }
  deleteEditOption(index: any, deleteOption: any) {

    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to delete this option?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    // const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.fetchEditOption.splice(index, 1);
        // let id = deleteOption.id;
        this.deleteOptionId = deleteOption.id;
        // this.questionService.deleteOption(this.queId, this.deleteOptionId).subscribe((data) => { });
        if (this.arrayForScoreadd) {
          this.scoreArray = [];
          this.arrayForScoreadd.forEach((element) => {
            if (element.answerValid == true) {
              this.scoreArray.push(element.correctPoints);
            }
          });
        }

        console.log('element.correctPoints', this.scoreArray);
        this.newEvent = this.scoreArray.reduce(add, 0);
        function add(accumulator, a) {
          return accumulator + a;
        }
        this.notificationServices.success("Option deleted successfully");
      }
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  saveQuestion() {
    this.addOptionNew.forEach(element => {
      console.log(element);
      this.correctScorePoint = element.correctPoints
      console.log(this.correctScorePoint)

    });
    if (this.addOptionNew.length <= 1) {

      this.notificationServices.warn('There must be at least two options added');
    }


    else {
      this.addnewquestion = new AddNewQuestion();
      this.addnewquestion.questiondescription = new QuestionDescription();

      let selectetionOptionValue = this.addOptionNew.filter(
        (value) => value.answerValid == true
      );

      let selectioIndex = this.addOptionNew.findIndex(
        (value) => value.answerValid == true
      );
      // this.addnewquestion.itemId =this.itemBankQueId;
      this.addnewquestion.itemId = JSON.parse(localStorage.getItem('itemId'));
      this.questionhtml = this.addQuestionForm.controls['question'].value;
      let rmcharHtml = this.questionhtml.replace(/(<([^>]+)>)/ig, '');
      let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
      if (rmCharSpace.length === 0) {
        this.notificationServices.warn("Question field is mandatory");  // Used to display alert message
      }
      else if (selectioIndex < 0) {
        this.notificationServices.warn('Select at least one correct answer');
      } else {
        if (this.editMode == true) {
          this.questionhtml = this.addQuestionForm.controls['question'].value;
          // this.addnewquestion.autoScoring = this.userForm.controls['autoscore'].value;
          // this.addnewquestion.allowCheckAnswer = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
          this.allowChwckAnsSelectValue = JSON.parse(
            this.userForm.controls['allowCheckAnswer'].value
          );
          this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;

          this.scoringTypeExactMatchValue = JSON.parse(
            this.userForm.controls['scoringTypeMatch'].value
          );
          this.addnewquestion.scoringTypeExactMatch =
            this.scoringTypeExactMatchValue;
          // this.addnewquestion.scoringTypeExactMatch = JSON.parse(this.userForm.controls['scoringTypeMatch'].value);
          this.addnewquestion.autoScoring = this.userForm.controls['autoscore'].value;
          this.shuffleSelectValue = this.userForm.controls['shuffle'].value;
          this.addnewquestion.shuffleOption = this.shuffleSelectValue;
          // this.addnewquestion.allowCheckAnswer = true;
          this.fetchEditOption.forEach((element) => {
            element.modified = true;
          });
          this.addnewquestion.options = this.fetchEditOption;
        } else {
          // this.questionhtml = toHTML(this.question);
          this.questionhtml = this.addQuestionForm.controls['question'].value;
          this.autoscoreSelectValue = JSON.parse(
            this.userForm.controls['autoscore'].value
          );
          this.addnewquestion.autoScoring = this.autoscoreSelectValue;

          // this.allowChwckAnsSelectValue = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
          // this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;

          // this.shuffleSelectValue = JSON.parse(this.userForm.controls['shuffle'].value);
          // this.addnewquestion.shuffleOption = this.shuffleSelectValue;
          // this.checkSelectionOption.forEach(element => {
          //   console.log("gete new array",element.correctPoints);
          //   this.newEvent= ++element.correctPoints;

          // });
          this.shuffleSelectValue = JSON.parse(this.userForm.controls['shuffle'].value);
          this.addnewquestion.shuffleOption = this.shuffleSelectValue;

          this.addnewquestion.options = this.addOptionNew;
        }
        // this.addnewquestion.options = this.addOptionNew;
        // console.log("question", this.addnewquestion.options);
        this.addnewquestion.correctPoints = this.newEvent;
        this.addnewquestion.questiondescription.description = this.questionhtml;
        // this.addnewquestion.scoringTypeExactMatch = true;

        // this.scoringTypeExactMatchValue = JSON.parse(this.userForm.controls['scoringTypeMatch'].value);
        // this.addnewquestion.scoringTypeExactMatch = this.scoringTypeExactMatchValue;

        this.addnewquestion.active = true;
        this.addnewquestion.ansRenderCount = 0;

        this.addnewquestion.questiondescription.mediaURL = ['String'];
        this.addnewquestion.questionOrder = 0;
        this.addnewquestion.questionSubTypeId = 1;
        this.addnewquestion.questionTypeId = 1;
        // this.addnewquestion.shuffleOption = true;
        // this.addnewquestion.shuffleOptions = true;
        // this.autoscoreSelectValue = JSON.parse(this.userForm.controls['autoscore'].value);
        // this.addnewquestion.autoScoring = this.autoscoreSelectValue;
        // console.log("this.userForm.controls['allowCheckAnswer'].value", this.userForm.controls['allowCheckAnswer'].value);
        if (this.userForm.controls['allowCheckAnswer'].value) {
          this.allowChwckAnsSelectValue = JSON.parse(
            this.userForm.controls['allowCheckAnswer'].value
          );
          this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;
        }
        // else{
        //   this.allowChwckAnsSelectValue = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
        //   this.addnewquestion.allowCheckAnswer =this.userForm.controls['allowCheckAnswer'].value;

        // }
        // console.log("----------------------->",this.userForm.controls['scoringTypeMatch'].value);
        if (this.userForm.controls['scoringTypeMatch'].value) {
          this.scoringTypeExactMatchValue = JSON.parse(
            this.userForm.controls['scoringTypeMatch'].value
          );
          this.addnewquestion.scoringTypeExactMatch =
            this.scoringTypeExactMatchValue;
        }
        // else{
        //   // this.scoringTypeExactMatchValue = JSON.parse(this.userForm.controls['scoringTypeMatch'].value);
        //   this.addnewquestion.scoringTypeExactMatch = this.userForm.controls['scoringTypeMatch'].value;
        // }

        // this.checkansSelectValue = this.userForm.controls['checkAnswerAttempt'].value;
        // this.checkAnsmaintValue = +this.checkansSelectValue;
        // this.addnewquestion.checkAnswerAttempt = this.checkAnsmaintValue;

        this.miniscoretype =
          this.userForm.controls['minimumScoreIfAttempt'].value;
        this.miniscoretypeMainvalue = +this.miniscoretype;
        this.addnewquestion.minScoreIfAttempt = this.miniscoretypeMainvalue;

        // this.addnewquestion.options = this.addOptionNew;

        this.penaltypointselectvalue =

          this.userForm.controls['penaltyPoint'].value;
        this.penaltypointMainvalue = +this.penaltypointselectvalue;
        this.addnewquestion.penaltyPoints = this.penaltypointMainvalue;


        // this.addnewquestion.itemId = JSON.parse(localStorage.getItem('ItemIdQue'));
        // console.log("this.editMode", this.addnewquestion);
        if (this.editMode == false) {



          if (this.questionhtml == '<p></p>') {
            this.notificationServices.warn("Question field is mandatory")
          }
          else {
            if (this.correctScorePoint == null) {
              this.notificationServices.warn('Option score cannot be empty')
            }
            else {
              this.questionService
                .addQuestion(this.addnewquestion, this.itemId)
                .subscribe((resData) => {
                  console.log("We are in add question", resData);
                  this.saveDisable = true;
                  this.notificationServices.success(resData?.message); // This line used to display message
                  this.itemservice.questionCountUpdate.next(true);
                  this.listDisplay = true;
                  //  this.router.navigate(['../'], { relativeTo: this.routerstate });
                  this.back();
                },
                  error => {
                    this.notificationServices.warn(error?.error?.message); // This line used to display error message
                  });
            }
          }
        }

        // for Edit Quetion
        if (this.editMode == true) {
          if (this.questionhtml == '<p></p>') {
            this.notificationServices.warn("Question field is mandatory")
          }
          else {
            if (this.correctScorePoint == null) {
              this.notificationServices.warn('Option score cannot be empty')
            }
            else {
              if (this.deleteOptionId) {
                this.questionService.deleteOption(this.queId, this.deleteOptionId).subscribe((data) => { });
              }
              this.questionService
                .updateQuestion(this.addnewquestion, this.itemId, this.queId)
                .subscribe((resData) => {
                  localStorage.removeItem('questionuuId');
                  this.itemservice.editedGetItem(resData.data);
                  this.notificationServices.success(resData?.message); // This line used to display message
                  this.itemservice.questionCountUpdate.next(true);
                  this.listDisplay = true;
                  console.log("----->notification msg", resData);
                  this.back();
                },
                  error => {
                    this.notificationServices.warn(error?.error?.message); // This line used to display error message
                  });
            }
          }
        }
      }
    }
  }

  clickData() {
    this.set_main_form = true;
  }

  openOptionDiv() {
    // this.questionhtml = toHTML(this.question);
    // let rmcharHtml = this.html.replace( /(<([^>]+)>)/ig, '');
    // let rmCharSpace= rmcharHtml.replace(/^\s+/g, '');
    this.questionhtml =
      this.addQuestionForm.controls['question'].value;
    let rmcharHtml = this.questionhtml.replace(/(<([^>]+)>)/ig, '');
    let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
    if (this.questionhtml != '<p></p>' && rmCharSpace.length !== 0) {
      this.setAddOption = false;
      this.optionDiv = true;
      this.addOptionEditor = false;
    } else {
      this.addOptionEditor = true;
    }

  }

  // deleteOption(index1) {
  //   this.addedOptions.splice(index,1);
  // }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.questionhtml = null;
    if (localStorage.getItem('questionId')) {
      this.queId = localStorage.removeItem('questionId');
      localStorage.removeItem('questionuuId');
    }
  }

  changeFromChild(): void {
    this.parentData = +1;
  }

  data: any;

  openDynamic(index: any) {
    this.data = index;
    if (this.data == index) {
      this.openedD = true;
    } else {
      this.openedD = false;
    }
  }

  send(values: any) {
    // console.log(values);
    // console.log("data call send", this.userForm.value);
  }


  back() {
    // if (this.editMode) {
    //   // this.router.navigate(['../'], { relativeTo: this.routerstate });
    //   this.postCreated.emit('false');
    // } else {
    //   this.postCreated.emit('false');
    // }
    this.postCreated.emit('false');
    this.display = true;
    this.editor.destroy();
  }

  openDialog(a: any, index: any, optionId: any): void {
    // let editIndex = this.addOptionNew.filter(item => {
    //   this.addOptionNew[0].optionId == item.optionId
    // })
    console.log(index, '----edit index--------');


    this.updateOptionText = a;
    // console.log("data updste set", this.updateOptionText);
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '950px',
      height: '350px',
      // console.log(a);
      data: { resData: a, index: index, optionId: optionId },
      // data: { name1: this.html ,animal1 :'test'}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
}

export class OptionUpdateText {
  oldValue: any;
  newValue: any;
  optionId: any;
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
  styleUrls: ['dialog-overview-example-dialog.scss'],
})
export class DialogOverviewExampleDialog {
  fetchEditQuetion1: any;
  editor: Editor;
  editors: Editor[] = [];
  option: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  oldOptionName: any;
  optionId: any;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public questionService: QuestionService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) {
    this.editor = new Editor();
  }
  ngOnInit(): void {

    this.option = this.data?.resData;
    this.optionId = this.data?.optionId;
    console.log('oninit', this.optionId)
    this.oldOptionName = this.option;
  }

  updateOption(): void {

    var optionUpdateText = new OptionUpdateText();

    optionUpdateText.newValue = this.option;
    optionUpdateText.optionId = this.data.optionId
    let rmcharHtml = optionUpdateText.newValue.replace(/(<([^>]+)>)/ig, '');
    let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
    if (rmCharSpace.length === 0) {
      this.notificationService.warn("Option field is mandatory")

    } else {
      this.questionService.getUpdateEditValue(optionUpdateText);
      this.notificationService.success("Option updated successfully");
      this.dialogRef.close();

    }


  }
}
