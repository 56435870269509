import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamineeloginService } from 'src/app/shared/examineelogin.service';
import { UserService } from 'src/app/shared/user.service';
import { StyleService } from 'src/app/shared/style.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ExamineeResetPasswordComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted = false;
  loginSpinner = false;
  submitted = false;
  userServiceSubscription$: any;
  msg : any;


  constructor(private fb: FormBuilder, private examineeLoginService: ExamineeloginService) { }

  ngOnInit(): void {
    // this.styleService.addStyle('login', require('../../../scss/login.css'));
    this.loginForm = this.fb.group({
      emailId: [null,
        [Validators.required,
        Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)*\.[A-Za-z]{1,3}$/)
        ]
      ]
    })
  }
  get f() { return this.loginForm.controls; }

  reset() {

    this.submitted = true;
    if (this.loginForm.valid) {
      this.userServiceSubscription$ = this.examineeLoginService.resetPassword(this.loginForm.value.emailId).subscribe(
        (data: any) => {

        }
      )
      this.msg = "New password will be sent to registered email"
      this.loginForm.reset({
        'emailId' : ''
      });
    }
  }
  ngOnDestroy() {
    // this.styleService.removeStyle('login');
  }
}
