import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-collection-users',
  templateUrl: './add-collection-users.component.html',
  styleUrls: ['./add-collection-users.component.scss']
})
export class AddCollectionUsersComponent implements OnInit {
  usersData: any;
  noDataFound: any;
  sort: any;
  paginator: any;
  displayedColumns: string[] = ['checkbox', 'firstName', 'lastName', 'emailId', 'status', 'updated'];
  userStatus: any;
  totalRecords: any;
  searchText: any;
  page = 1;
  itemName = '';
  firstName = '';
  lastName = '';
  emailId = '';
  testName = '';
  itemDescription = '';
  testDescription = '';
  tagdescription = '';
  itemStatus = '';
  testStatus = '';
  isItemDescended = true;
  itemId = 10;
  userServiceSubscription$: Subscription | undefined;
  userFilterServiceSubscription$: Subscription | undefined;

  itemIds: any = [];
  itemJsonData: any = [];
  globalcollectionid: any;
  constructor(private usersService: UsersService) { }

  ngOnInit(): void {
    this.globalcollectionid = JSON.parse(
      localStorage.getItem('defaultcollectionid')
    );
    this.userServiceSubscription$ = this.usersService.getusers()?.subscribe(resData => {
      this.usersData = resData?.items;
      console.log(this.usersData);
      // if (this.usersData.length < 1) {
      //   this.noDataFound = true;
      // }
      // this.usersData = new MatTableDataSource(this.usersData);
      // console.log("this.usersDat",this.usersData);
      // this.usersData.sort = this.sort;
      // this.usersData.paginator = this.paginator;
    });
  }

  navigateToUserDetails(id): void {

  }

  toggleCheckbox(event: any): void {
    console.log(event);
    if (this.itemIds.some((e: any) => e === event.source.id)) {
      this.itemIds = this.itemIds.filter((item: any) => item !== event.source.id);
    } else {
      this.itemIds.push(event.source.id);
    }
  }


  saveItemsToTest() {
    this.itemJsonData = [];
    this.itemIds.forEach((element: any) => {
      this.itemJsonData.push({
        active: true,
        collectionId: this.globalcollectionid,
        userAccessLevelId: 0,
        userId: JSON.parse(element),
      });
    });
    console.log(this.itemJsonData);
    if (this.itemJsonData.length > 0) {
      let addUserserJsonData = JSON.stringify(this.itemJsonData);
      // this.collectionService.addUsertoCollection(this.itemJsonData).subscribe(resData => {
      //   console.log(resData.items.length);
      // });
    }
  }

}
