import { Pipe, PipeTransform } from '@angular/core';
import { ShareDataService } from './share-data.service';

@Pipe({
  name: 'languagePipe'
})
export class LanguagePipe implements PipeTransform {
  result: any;

  constructor(private shareDataService: ShareDataService) { }
  transform(value: any): any {
    this.shareDataService.languageData.subscribe(data => {
        // console.log(lang);
        // console.log("value", value);
        this.result = data.find((lang) => lang['langId'] === value)?.language;
    });
    return this.result;
  }

}
