<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title>Create new Test</h2>
    <span class="spacer"></span>
    <div class="btn-close">
        <button mat-icon-button mat-dialog-close aria-label="Close Dialog"><mat-icon>close</mat-icon></button>
    </div>
  </div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="basicDetailsForm" novalidate>
    <div class="form-wrapper">
      <div class="input-wrapper">
        <span class="input-label">
          <label>Test name</label><span class="required">*</span>
        </span>
        <span class="input-field">   
          <textarea class="form-control textarea" rows="2" maxlength="121" type="text" formControlName="testTitle" (focus)='onTestNameControlFocus()'></textarea>
          <div *ngIf="f.testTitle.invalid && (f.testTitle.dirty || f.testTitle.touched)" class="alert alert-danger">
            <div *ngIf="f.testTitle.errors?.required"> Test name is required</div>
            <div *ngIf="f.testTitle.errors?.minlength"> Minimum 1 characters required</div>
            <div *ngIf="f.testTitle.errors?.maxlength"> Maximum 120 characters allowed</div>
            <div *ngIf="f.testTitle.errors?.pattern">Test name is invalid</div>
          </div>
        </span>
      </div> 
      <div class="input-wrapper">
        <span class="input-label">
          <label>Test description</label>
        </span>
        <span class="input-field">   
          <textarea type="text" rows="8" maxlength="481" class="form-control textarea" type="text" formControlName="testDescription"></textarea>
          <div *ngIf="f.testDescription.invalid  && (f.testDescription.dirty || f.testDescription.touched)" class="alert alert-danger">
            <div *ngIf="f.testDescription.errors?.minlength">Minimum 1 character required</div>
            <div *ngIf="f.testDescription.errors?.maxlength"> Maximum 480 characters allowed</div>
          </div>
        </span>
      </div>        
    </div>    
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-flat-button  color="primary" class="btn-blue" aria-label="Save"  (click)="saveBasicTestDetails()" 
    [disabled]='basicDetailsForm.invalid'>Save</button> -->
    <button mat-flat-button  color="primary" class="btn-blue" aria-label="Save"  (click)="saveBasicTestDetails()" 
    [disabled]='f.testTitle.invalid'>Save</button>
</mat-dialog-actions>
</div>





<!-- <div class="btn-wrapper">
  <button (click)="saveBasicTestDetails()" mat-raised-button class="btn-bg-color"
    [disabled]='basicDetailsForm.invalid'>Save</button>
</div>

<div class="frm-container">
  <form [formGroup]="basicDetailsForm" novalidate>
    <div class="input-class">
      <label>Test name<span class="red-asterisk">*</span></label>
      <input class="form-control" maxlength="121" type="text" formControlName="testTitle" (focus)='onTestNameControlFocus()'>
      <div *ngIf="f.testTitle.invalid && (f.testTitle.dirty || f.testTitle.touched)" class="alert alert-danger">
        <div *ngIf="f.testTitle.errors?.required"> Test name is required. </div>
        <div *ngIf="f.testTitle.errors?.minlength"> Minimum 3 characters required. </div>
        <div *ngIf="f.testTitle.errors?.maxlength"> Maximum 120 characters allowed.  </div>
      </div>
    </div>
    <div class="input-class">
      <label>Test description</label>
      <textarea type="text" maxlength="481"class="form-control textarea" type="text" formControlName="testDescription"></textarea>
      <div *ngIf="f.testDescription.invalid && (f.testDescription.dirty || f.testDescription.touched)" class="alert alert-danger">
        <div *ngIf="f.testDescription.errors?.minlength"> Minimum 20 characters required. </div>
        <div *ngIf="f.testDescription.errors?.maxlength"> Maximum 480 characters allowed.  </div>
      </div>
    </div>
  </form> -->

