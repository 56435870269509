import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification.service';
import { UsersService } from '../users.service';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  userDetailsForm: FormGroup;
  duplicateDataFlag = false;
  errorMessage: string;
  action = 'createUser';
  apiData: any;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<UserCreateComponent>
  ) { }

  ngOnInit(): void {

    this.userDetailsForm = new FormGroup({
      'firstName': new FormControl(null,
        [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/)
        ]),
      'lastName': new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^[ a-zA-Z.-]+([a-zA-Z.-. ])*$/)
      ]),
      'phone': new FormControl(null, [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(24),
        Validators.pattern(/^(?:\+?(\d{0,3}))?[- \(]*(\d{1,9})[-. \/)]*(\d{1,9})[-. \/]*(\d{1,9})*[-. \/]*(\d{1,9})(?: *x(\d))?$/)
      ]),
      'emailId': new FormControl(null,
        [Validators.required,
        Validators.minLength(7),
        Validators.maxLength(124),
        // Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)*\.[A-Za-z]{1,3}$/)
        // Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)\.[A-Za-z]{2,3}$/)
        // Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[a-zA-Z0-9])*@[a-zA-Z0-9]+[A-Za-z]{2,6}$/)
        Validators.pattern(/^[a-zA-Z0-9]+([.]?[_]?[-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+([\-\.]?[a-zA-Z0-9]+)+\.[A-Za-z]{2,4}$/)

        ]),
      'password': new FormControl(null,
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-_=+/\|";:<>,.`~])[A-Za-z\d@$!%*?&#^()-_=+/\|";:<>,.`~]{6,20}$/)
        ]),
      'status': new FormControl('true', [Validators.required]),
    });
    console.log(this.userDetailsForm.controls.phone.errors?.required);
  }

  get f() {
    return this.userDetailsForm.controls;
  }

  saveUserDetails() {

    this.apiData = {
      // collectioId: this.collectionId,
      firstName: this.userDetailsForm.value.firstName.trim(),
      lastName: this.userDetailsForm.value.lastName.trim(),
      phone: this.userDetailsForm.value.phone,
      emailId: this.userDetailsForm.value.emailId.trim(),
      active: this.userDetailsForm.value.status,
      password: this.userDetailsForm.value.password,
    }




    this.usersService.createNewUser(this.apiData).subscribe(resData => {
      console.log(resData);
      this.notificationService.success(resData?.message);
      this.usersService.getusersLanding()?.subscribe(itemListRes => {
        this.usersService.newUserList(itemListRes);
        // this.notificationService.success(itemListRes?.message);
        // this.router.navigate(["/users"]);
        this.dialogRef.close();
      });

      if (resData.userid) {
        // this.router.navigate(["/users", data.testId]);

      }
    },
      error => {
        this.notificationService.warn(error?.error?.message);
        if (error?.status == 409) {
          this.userDetailsForm.patchValue({ "emailId": null });
        }
      });
  }

  onTestNameControlFocus(): void {

    this.duplicateDataFlag = false;

  }

  navigateToUsersLanding() {
    this.router.navigate(["/users"]);
  }
  onKeydown(event: KeyboardEvent) {
    if (event.key === ' ' && !this.userDetailsForm.value.phone.length)
      event.preventDefault();
  }
}
