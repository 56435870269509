<!-- <app-header></app-header> -->

<div *ngIf="!listDisplay">

<div  *ngIf="!display">
  <div class="content-wrapper">
    <div>
      <div>
        <div>
          <div class="btn-wrapper">
            <button mat-flat-button class="btn-back" color="warn" aria-label="Back" (click)='back()'>
              <span>Back</span>
            </button>
            <span class="spacer"></span>
            <button mat-flat-button color="warn" class="btn-red" aria-label="Preview" disabled="disabled">
              <span>Preview</span>
            </button>
            <button [disabled]="saveDisable" mat-flat-button color="primary" class="btn-blue" aria-label="Save" (click)='saveQuestion()'>
              <span>Save</span>
            </button>
          </div>
          <div class="row text-msg" *ngIf="saveDataValidation==true">
            <span>Please fill the form</span>

          </div>
          <div class="filter-section">
            <div class="body-section title">
              <mat-icon>question_answer</mat-icon>
              <span>Compose question (Multiple choice –Multiple response)</span>
            </div>
            <form [formGroup]="addQuestionForm">
            <div class="body-section-2">
              <label class="que-label">Add question</label>
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor [editor]="editor" [placeholder]="'Enter here...'" name="questionBpx" formControlName="question"></ngx-editor>
              <!-- <ngx-editor [editor]="editor" name="questionBpx" [(ngModel)]="question"></ngx-editor> -->
            </div>
          </form>
          </div>
          <div class="btn-container">
            <button mat-flat-button color="warn" class="btn-red" (click)='openOptionDiv()' *ngIf="setAddOption==true">Add options
            </button>
          </div>
          <div *ngIf="addOptionEditor==true" class="text-msg">
            <span>Please add the question first</span>

          </div>
          <div class="filter-section" *ngIf="optionDiv==true">
            <div class="body-section title">
              <div class="">
                <span>Add options</span>
              </div>
            </div>
          </div>
          <div class="body-section-2" *ngIf="optionDiv==true">
            <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor [placeholder]="'Enter here...'" name='optionbox' [(ngModel)]='option' [editor]="editor1">
            </ngx-editor>
          </div>
          <div class="btn-container" *ngIf="optionDiv==true">
            <button mat-flat-button color="warn" class="btn-red" (click)='addOption()' class="btn-bg-color">Add</button>
          </div>
          <div *ngIf="editMode==false">
            <div *ngIf="questionscoreF==true">
              <label class="Questions-score">Total question score</label>
              <input min="0" value="newEvent" [(ngModel)]="newEvent" class="form-control scoreInput" readonly (keypress)="keyPressNumbers($event)">
              <label class="mrg-top">Set option score</label>
            </div>

            <div class="score-wrapper ">

              <div class="add-option" *ngFor="let o of addOptionNew; let i = index">
                <div class="input-class-container">
                  <mat-checkbox (change)="onCheckRadioOption(o,$event)"> </mat-checkbox>
                  <input type="number" min="0" max="9999" onkeydown="if(event.key==='.'){event.preventDefault();}" oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    onkeypress="if(this.value.length == 4 ) return false;" value="" [(ngModel)]="o.correctPoints" (change)="addScore(o)"
                    class="form-control ">
                  <!-- <div class="option-desc sentence-align" [innerHtml]="o?.answerDescription?.description"></div> -->
                  <app-option-editor [optionValue]="o?.answerDescription?.description" [innerHtml]="o?.answerDescription?.description"> </app-option-editor>

                </div>
                <!-- <div class="mat-icon-div">
                  <button mat-mini-fab color="accent" aria-label="Example icon button with a home icon"
                    (click)="openDialog(o?.answerDescription?.description, o?.optionId)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-mini-fab color="accent" aria-label="Example icon button with a home icon"
                    (click)="deleteOption(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>-->
              <!--  <div class="question-icons">
                  <button mat-mini-fab color="primary" aria-label="edit icon" class="fab-mini-icon" (click)="openDialog(o?.answerDescription?.description, o?.optionId)">
                    <i-feather name="edit"></i-feather>
                  </button>
                  <button mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon" (click)="deleteOption(i)">
                    <i-feather name="trash-2"></i-feather>
                  </button>
                </div>-->
                <button mat-icon-button class="action-button" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openDialog(o?.answerDescription?.description, o?.optionId)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="deleteOption(i)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </div>
            </div>

          </div>
          <div *ngIf="editMode==true">
            <div>
              <label class="Questions-score">Total question score</label>
              <input type="number" min="0" value="newEvent" [(ngModel)]="newEvent" class="form-control scoreInput" readonly (keypress)="keyPressNumbers($event)">
            </div>
            <div class="score-wrapper ">
              <label class="mrg-top">Set option score</label>
              <div class="add-option" *ngFor="let o of fetchEditOption; let i = index">
                <div class="input-class-container">
                  <mat-checkbox [value]="o" id="o{{i}}" name="o" (change)="onCheckRadioOption(o,$event)" [checked]="o?.answerValid==true">
                  </mat-checkbox>
                  <input type="number" min="0" max="9999" onkeydown="if(event.key==='.'){event.preventDefault();}" oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    onkeypress="if(this.value.length == 4 ) return false;" value="" [(ngModel)]="o.correctPoints" class="form-control "
                    (change)="addScore(o)">
                  <!-- <div class="option-desc sentence-align" [innerHtml]="o?.answerDescription?.description"></div> -->
                  <app-option-editor [optionValue]="o?.answerDescription?.description" [innerHtml]="o?.answerDescription?.description"> </app-option-editor>

                </div>
                <!--  <div class="mat-icon-div">
                    <button mat-mini-fab color="accent" aria-label="Example icon button with a home icon"
                      (click)="openDialog(o?.answerDescription?.description, o?.optionId)">
                      <mat-icon>edit</mat-icon>
                   </button>
                   <button mat-mini-fab color="accent" aria-label="Example icon button with a home icon"
                     (click)="deleteEditOption(i,o)">
                     <mat-icon>delete</mat-icon>
                   </button>
                </div>-->
              <!--  <div class="question-icons">
                  <button mat-mini-fab color="primary" aria-label="edit icon" class="fab-mini-icon" (click)="openDialog(o?.answerDescription?.description, o?.optionId)">
                    <i-feather name="edit"></i-feather>
                  </button>
                  <button mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon" (click)="deleteEditOption(i,o)">
                    <i-feather name="trash-2"></i-feather>
                  </button>
                </div>-->
                <button mat-icon-button class="action-button" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openDialog(o?.answerDescription?.description, o?.optionId)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="deleteEditOption(i,o)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>

              </div>
            </div>
          </div>
          <div class="exp-panel">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    More options
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="userForm">
                  <div class="container-fluid  body-section-2">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="scoring-check">
                          <mat-checkbox name="autoscoring" formControlName="autoscore" [checked]="autoscoring" [disabled]="true">Auto scoring</mat-checkbox>
                          <!-- <div *ngIf="submitted && f.autoscore.errors" class="invalid-feedback">
                              <div *ngIf="f.autoscore.errors.required">Field is required</div>
                            </div> -->
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="scoring-check">
                          <!-- <mat-checkbox [formControl]="hideRequiredControl" formControlName="shuffle" [disabled]="true">Shuffle options
                          </mat-checkbox> -->
                          
                          <!-- <div *ngIf="submitted && f.shuffle.errors" class="invalid-feedback">
                              <div *ngIf="f.shuffle.errors.required">Field is required</div>
                            </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-label">
                          <label>Scoring type</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field  class="scoring-type" appearance="fill" [hideRequiredMarker]="hideRequiredControl.value" [floatLabel]="floatLabelControl.value">

                          <!-- <mat-label>Select</mat-label> -->
                          <mat-select formControlName="scoringTypeMatch" [(value)]="getscoringtype">
                            <mat-option value="false">Partial match</mat-option>
                            <mat-option value="true">Exact match</mat-option>

                          </mat-select>
                          <!-- <div *ngIf="submitted && f.scoringTypeMatch.errors" class="invalid-feedback">
                              <div *ngIf="f.scoringTypeMatch.errors.required">Field is required</div>
                            </div> -->
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-md-2 answers-label">
                        <div class="form-label">
                          <label>Allow check answers</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field appearance="fill" [hideRequiredMarker]="hideRequiredControl.value" [floatLabel]="floatLabelControl.value"> --> 
                          <!-- <mat-label>Select</mat-label>
                          <!-- <mat-select formControlName="allowCheckAnswer" [(value)]="getAllowCheckAns" [disabled]="true">
                            <mat-option value="false">No</mat-option>
                            <mat-option value="true">Yes</mat-option>
                          </mat-select> -->
                          <!-- <div *ngIf="submitted && f.allowCheckAnswer.errors" class="invalid-feedback">
                              <div *ngIf="f.allowCheckAnswer.errors.required">Field is required</div>
                            </div> -->
                        <!-- </mat-form-field>
                      </div> -->
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-label score-labels">
                          <label>Penalty points</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                      <!-- <input type="number" min="0" max="9999" oninput="validity.valid||(value='');"  [(ngModel)]="defultvalue" formControlName="penaltyPoint" class="form-control penalty-points">-->
                        <input [attr.disabled]="true" type="number" formControlName="penaltyPoint" min="0" max="9999" oninput="validity.valid||(value='');" class="form-control penalty-points">

                        <!-- <div *ngIf="submitted && f.penaltyPoint.errors" class="invalid-feedback">
                            <div *ngIf="f.penaltyPoint.errors.required">Field is required</div>
                          </div> -->
                      </div>
                      <!-- <div class="col-md-3">
                          <div class="form-label">
                            <label>Check Answer Attempt *</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <input  value="" formControlName="checkAnswerAttempt" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.checkAnswerAttempt.errors }">
                          <div *ngIf="submitted && f.checkAnswerAttempt.errors" class="invalid-feedback">
                            <div *ngIf="f.checkAnswerAttempt.errors.required">Field is required</div>
                          </div>
                        </div> -->
                      <div class="col-md-2 answers-label">
                        <div class="form-label score-labels">
                          <label required>Minimum score if attempted </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <!-- <input  type="number" min="0" max="9999" oninput="validity.valid||(value='');" [(ngModel)]="defultvalueScore" formControlName="minimumScoreIfAttempt" class="form-control penalty-points" [ngClass]="{ 'is-invalid': submitted && f.minimumScoreIfAttempt.errors }"> -->
                        <input  type="number" min="0" max="9999" [(ngModel)]="defultvalueScore" oninput="validity.valid||(value='');" formControlName="minimumScoreIfAttempt" class="form-control penalty-points" [ngClass]="{ 'is-invalid': submitted && f.minimumScoreIfAttempt.errors }">
                        <div *ngIf="submitted && f.minimumScoreIfAttempt.errors" class="invalid-feedback">
                          <div *ngIf="f.minimumScoreIfAttempt.errors.required">Field is required</div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-3">
                          <div class="form-label">
                            <label required>Minimum Score If Attempted </label>
                          </div>
                        </div>
                        <div class="col-md-3">

                          <input value="" [(ngModel)]="defultvalueScore" formControlName="minimumScoreIfAttempt" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.minimumScoreIfAttempt.errors }">
                          <div *ngIf="submitted && f.minimumScoreIfAttempt.errors" class="invalid-feedback">
                            <div *ngIf="f.minimumScoreIfAttempt.errors.required">Field is required</div>
                          </div>
                        </div>
                      </div> -->
                  </div>
                </form>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="display">
  <app-choose-question-type></app-choose-question-type>
</div>
</div>




<div *ngIf="listDisplay">
  <app-question-list></app-question-list>
</div>
