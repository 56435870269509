<div class="module-wrapper content-wrapper">
  <mat-card>
    <mat-card-content>
      <div class="head-container">
        <h1>Test Management</h1>
        <span class="spacer"></span>
        <div>
          <div class="btn-wrapper">
            <button mat-flat-button color="warn" class="btn-orange" (click)="createNewTest()" aria-label="Add New Item">
              + New Test</button>
          </div>
        </div>
      </div>
      <div class="input-container">
        <div class="input-fields-wrapper">
          <div class="input-wrapper">
            <input type="text" placeholder="Find by Test name" [(ngModel)]="testName"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <input type="text" placeholder="Find by description" [(ngModel)]="testDescription"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <input type="text" placeholder="Find by Tag" [(ngModel)]="itemTag"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
            <select aria-placeholder="Status" [(ngModel)]="testStatus"
              class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model="">
              <option value="">Status</option>
              <option *ngFor="let statusList of testStatusList" value="{{ statusList.itemstatus }}">
                {{ statusList.itemstatus }}
              </option>
            </select>
          </div>
          <div class="btn-wrapper">
            <button mat-flat-button color="primary" class="btn-blue" (click)="testFilter()"
              aria-label="Search">Search</button>
            <button mat-flat-button color="basic" class="btn-flat" (click)="resetFilter()"
              aria-label="Reset">Reset</button>
          </div>
        </div>
      </div>
      <div *ngIf="isNoTest" class="data-not-found-container table-container">
        <span>No Tests in this Collection</span>
      </div>


      <div *ngIf="!isNoTest">
        <div class="table-container">
          <table mat-table [dataSource]="tests" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="itemBankName">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Test(s)</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.testName?.length > 40; then sliceTitle; else noSlice ">
                </ng-container>
                <ng-template #sliceTitle>
                  <span class="text-bold" matTooltipClass="my-custom-tooltip" matTooltip="{{row?.testName}}"
                    matTooltipPosition="above">
                    {{ row?.testName | slice: 0:40}}...</span>
                </ng-template>
                <ng-template #noSlice>
                  <span class="text-bold">{{ row?.testName }}</span>
                </ng-template>

                <ng-container *ngIf="row?.testDescription?.length > 40; then sliceDesc; else noSliceDesc">
                </ng-container>
                <ng-template #sliceDesc>
                  <span class="test-desc" matTooltip="{{row?.testDescription}}" matTooltipClass="my-custom-tooltip"
                    matTooltipPosition="above">
                    {{ row?.testDescription | slice: 0:40 }}...</span>
                </ng-template>
                <ng-template #noSliceDesc>
                  <span class="test-desc"> {{ row?.testDescription }} </span>
                </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="itemCount">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Item count</th>
              <td mat-cell *matCellDef="let row">{{ row.itemCount }}</td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Duration</th>
              <td mat-cell *matCellDef="let row">{{ row.duration }}</td>
            </ng-container>
            <ng-container matColumnDef="itemStatus">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
              <td mat-cell *matCellDef="let row">
                <!-- <i *ngIf="row?.statusName === 'Published'" class="fa fas fa-circle publish"
                matTooltip="Published">
              </i>-->
                <span *ngIf="row?.statusName === 'Published'" class="fa fas fa-circle publish" matTooltip="Published"
                  matTooltipPosition="above">
                </span>
                <span *ngIf="row?.statusName === 'Unpublished'" class="glyphicon glyphicon-adjust unpublish"
                  matTooltip="Unpublished" matTooltipPosition="above">
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="updated">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Updated </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-bold"> {{row.modifiedByUser}} </span>
                <span> {{ row.updated | dateAgo }} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Tag(s)</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.tagdescription?.length > 2; then Slicetag; else noSlicetag"></ng-container>
                <ng-template #Slicetag>
                  <div class="tag-data">
                    <table class="border-0">
                      <tr *ngFor="let ele of row?.tagdescription">
                        <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:44)+'...'"
                          matTooltipPosition="above" matTooltip="{{ele}}" matTooltipClass="my-custom-tooltip"></td>
                        <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                      </tr>
                    </table>
                  </div>
                </ng-template>
                <ng-template #noSlicetag>
                  <table class="border-0">
                    <tr *ngFor="let ele of row?.tagdescription">
                      <td *ngIf="ele.length > 40" [innerHtml]="(ele | boldTag | slice: 0:44)+'...'" matTooltip="{{ele}}"
                        matTooltipPosition="above" matTooltipClass="my-custom-tooltip"></td>
                      <td *ngIf="ele.length < 40 || ele.length==40" [innerHtml]="ele | boldTag"></td>
                    </tr>
                  </table>
                </ng-template>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr class="pointer" mat-row *matRowDef="let row; columns: displayedColumns"
              (click)="navigateToItemDetails(row?.testuuId,row?.testId)"></tr>
          </table>
          <div class="data-not-found-container" *ngIf="noDataFound">
            <p>Data not found</p>
          </div>
        </div>
        <div class="test-paginator">
          <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>