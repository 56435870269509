<div class="dialog-container">
  <div class="dialog-heading">
    <h2 mat-dialog-title>Test preview</h2>
    <span class="spacer"></span>
    <div class="btn-close">
        <button mat-icon-button mat-dialog-close aria-label="Close Dialog"><mat-icon>close</mat-icon></button>
    </div>
  </div>
<!-- <div class="main-heading">
  <label>Test Preview</label>
  <a>
    <mat-icon mat-dialog-close>close</mat-icon>
  </a>
</div> -->
<div mat-dialog-content>
<br>
<div class="container-fluid">
  <div class="row">

    <div class="col-md-9">

      <div class="table-container">
        <table mat-table [dataSource]="questions" class="mat-elevation-z8" #table>

          <ng-container matColumnDef="itemBankName" >
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="cell-content">
              <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 2">

                <app-question-type-checkbox [isReadOnly]='true' [questions]='row'>
                </app-question-type-checkbox>
              </div>
              <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 1">

                <app-question-type-radio [isReadOnly]='true' [questions]='row'>
                </app-question-type-radio>
              </div>
              <div class="div-content" *ngIf="row?.questionTypeId == 1 && row?.questionSubtypeId == 3">

                <app-question-type-truefalse [isReadOnly]='true' [questions]='row'>
                </app-question-type-truefalse>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="itemDescription">
            <th mat-header-cell *matHeaderCellDef class="icon-td"> </th>
            <td mat-cell *matCellDef="row">
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
    <div class="col-md-3">
      <div class="items-list-class">
        <nav class="nav-sidebar">
          <ul class="nav tabs">
            <li *ngFor="let obj of testItems">
              <a (click)='getQuestions(obj?.itemBankuuId)'
                [ngClass]="obj?.active == isSelected ? 'selectedItem': 'notselectedItem' ">{{obj?.itemBankName}}</a>

            </li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
</div>


</div>
