<!-- <div class="item-bank-list-container" *ngIf="setAddSchedule==false && isPublished" >
  <div class="">
    <div class="filter-section">
      <div class="" >
        <div class="row">
          <div class="col-md-10">
            <div class="input-fields-wrapper" *ngIf="!isNoItems">
              <div class="input-wrapper">
                <mat-form-field class="example-full-width" appearance="fill" >
                  <label>From Date</label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]='fromDate' >
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill" >
                  <label>To Date</label>
                  <input matInput [matDatepicker]="picker1"  [(ngModel)]='toDate' >
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <select aria-placeholder="Type" class="form-control" [(ngModel)]='scheduleType' >
                  <option value="">Schedule Type</option>
                  <option value="true">Public</option>
                  <option value="false">Private</option>
                </select>

                <input type="text" class="form-control" placeholder="Schedule Name" [(ngModel)]="scheduleName"/>

              </div>
              <div class="btn-wrapper">
                <button class="btn-bg-color" mat-raised-button (click)="filterSchedule()" >
                                              <span>Search</span>
                                          </button>
                <button class="btn-green-text" mat-raised-button (click)="resetFilter()" >
                                              <span>Reset</span>
                                          </button>

              </div>
            </div>

          </div>
          <div class="col-md-2">
            <div class="btn-wrapper search">
              <button class="btn-orange" mat-flat-button mat-raised-button color="accent" (click)="newSchedule()"  >
                                        <span> + New Schedule</span>
                                    </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="schedule-tbl"  *ngIf="!isNoItems">
      <div class="row">
        <div class="col-md-12">
          <div class="table-container">
            <table mat-table [dataSource]="schedule" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="schedulename">
                <th mat-header-cell *matHeaderCellDef>Schedule name</th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                  <span >{{row.title}}</span>

                </td>
              </ng-container>
              <ng-container matColumnDef="testType">
                <th mat-header-cell *matHeaderCellDef>Schedule type</th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                 <span  *ngIf="row.scheduleType==true">Public</span>
                 <span  *ngIf="row.scheduleType==false">Private</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="startdate">
                <th mat-header-cell *matHeaderCellDef>Start Date and Time </th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                  <span >
                  {{row.startDate}} {{row.startDateHours}}:{{row.startDateMinutes}} <br>
                  <span>{{row.timeZonesName}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="enddate">
                <th mat-header-cell *matHeaderCellDef>End Date and Time </th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                  <span >
                  {{row.endDate}} {{row.endDateHours}}:{{row.endDateMinutes}} <br>
                  <span>{{row.timeZonesName}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                  <span >{{row.scheduleStatus}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="examineecount">
                <th mat-header-cell *matHeaderCellDef>Examinee count</th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                  <span > {{row.examineeCount}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="examineeattempted">
                <th mat-header-cell *matHeaderCellDef>Examinee(s) attempted </th>
                <td mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row.scheduleuuId)'>
                  <span > {{row.examineeAttempted}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="testurl">
                <th mat-header-cell *matHeaderCellDef>Test URL</th>
                <td mat-cell *matCellDef="let row">
                  <button (click)= "openTestUrlDialog(row?.testUrl)" >
                    <i *ngIf="row?.testUrl" class="fa fa-link"></i>
                  </button>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="data-not-found-container" *ngIf='noDataFound'>
              <p>Data not found</p>
            </div>

          </div>


          <div class="sch-paginator">
            <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
          </div>

        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="item-bank-list-container" *ngIf="!setAddSchedule && isPublished && !isEditEnabled">
  <div class="module-wrapper content-wrapper">
    <mat-card>
      <mat-card-content>
        <div class="head-container">
          <!-- <h2>Test Management</h2>-->
          <span class="spacer"></span>
          <div>
            <!-- <div class="btn-wrapper search">
          <button class="btn-orange" mat-flat-button mat-raised-button color="accent" (click)="newSchedule()"  >
                                    <span> + New Schedule</span>
                                </button>
        </div> -->
            <div class="btn-wrapper">
              <button mat-flat-button color="warn" class="btn-orange" aria-label="Add New Schedule"
                (click)='newSchedule()'> + New Schedule</button>
            </div>

          </div>
        </div>
        <div class="input-container schedule-container">
          <div class="input-fields-wrapper">
            <div class="input-wrapper">
              <!--  <mat-form-field class="example-full-width" appearance="fill" >
              <label>From Date</label>
              <input matInput [matDatepicker]="picker" [(ngModel)]='fromDate' >
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill" >
              <label>To Date</label>
              <input matInput [matDatepicker]="picker1"  [(ngModel)]='toDate' >
              <mat-datepicker-toggle matSuffix [for]="picker1">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>-->
              <mat-form-field class="example-full-width custom-date-picker" appearance="fill">
                <input matInput placeholder="From Date" [matDatepicker]="picker" [(ngModel)]='fromDate'>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="example-full-width custom-date-picker" appearance="fill">
                <input type="text" matInput placeholder="To Date" [matDatepicker]="picker1" [(ngModel)]='toDate'>
                <mat-datepicker-toggle matSuffix [for]="picker1">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>

              <input type="text" [(ngModel)]='scheduleName' placeholder="Find by Schedule name"
                class="form-control ng-untouched ng-pristine ng-valid  sch-wrapper" ng-reflect-model="">
              <select aria-placeholder="Status" [(ngModel)]='scheduleType'
                class="form-control ng-untouched ng-pristine ng-valid  sch-wrapper" ng-reflect-model="">
                <option value="" ng-reflect-value="">Schedule type</option>
                <option value="true" ng-reflect-value="true">Public</option>
                <option value="false" ng-reflect-value="false">Private</option>
              </select>
            </div>
            <div class="btn-wrapper">
              <button mat-flat-button color="primary" class="btn-blue" (click)='filterSchedule()'
                aria-label="Search">Search</button>
              <button mat-flat-button color="basic" class="btn-flat" (click)='resetFilter()'
                aria-label="Reset">Reset</button>
            </div>
          </div>
        </div>

        <div *ngIf="isNoItems" class="data-not-found-container table-container">
          <span>No Schedules in this Test</span>
        </div>
        <div *ngIf="!isNoItems">

          <div class="table-container">
            <table mat-table [dataSource]="schedule" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="schedulename">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Schedule(s)</th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <!--  <span class="text-bold" >{{row.title}}</span>-->
                  <ng-container *ngIf="row?.title?.length > 40 then sliceTitle else noSliceTitle">
                  </ng-container>
                  <ng-template #sliceTitle>
                    <span class="text-bold" matTooltipClass="my-custom-tooltip" matTooltip="{{row?.title}}"
                      matTooltipPosition="above">
                      {{row?.title | slice: 0:50}}...</span>
                  </ng-template>
                  <ng-template #noSliceTitle>
                    <span class="text-bold">{{row?.title }}</span>
                  </ng-template>
                </td>
              </ng-container>


              <ng-container matColumnDef="testType">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Schedule type</th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <span *ngIf="row.scheduleType==true">Public</span>
                  <span *ngIf="row.scheduleType==false">Private</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="startdate">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Start Date and Time </th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <span>
                    {{row.startDate}} {{row.startDateHours}}:{{row.startDateMinutes}} <br>
                    <span>{{row.timeZonesName}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="enddate">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">End Date and Time </th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <span>
                    {{row.endDate}} {{row.endDateHours}}:{{row.endDateMinutes}} <br>
                    <span>{{row.timeZonesName}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Status</th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <span>{{row.scheduleStatus}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="examineecount">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Examinee count</th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <span> {{row.examineeCount}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="examineeattempted">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Examinee attempted count </th>
                <td class="text-focus" mat-cell *matCellDef="let row" (click)='navigateToScheduleDetails(row)'>
                  <span> {{row.examineeAttempted}}</span>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="testurl">
            <th mat-header-cell *matHeaderCellDef>Test URL</th>
            <td mat-cell *matCellDef="let row">
              <button (click)= "openTestUrlDialog(row?.testUrl)" >
                <i *ngIf="row?.testUrl" class="fa fa-link"></i>
              </button>
            </td>
          </ng-container>-->
              <ng-container matColumnDef="testurl">
                <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)='navigateToScheduleDetails(row)'>
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="openTestUrlDialog(row?.testUrl)">
                      <mat-icon>book</mat-icon>
                      <span>Test Url</span>
                    </button>
                  </mat-menu>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="data-not-found-container" *ngIf='noDataFound'>
              <p>Data not found</p>
            </div>
          </div>
          <div class="schedule-paginator">
            <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!--<div *ngIf="isNoItems && isPublished"><h4>No Schedules in this Test !</h4></div>-->
<div *ngIf="isPublished==false">
  <h4>Schedule cannot be created for unpublished Test</h4>
</div>
<div *ngIf="setAddSchedule && !isEditEnabled">
  <!-- <app-add-tests-schedule [currentTestId]="currentTestId"></app-add-tests-schedule> -->
  <!-- <app-add-tests-new-schedule [currentTestId]="currentTestId" (closeNewTestScheduleEvent)='closeNewTestSchedule($event)'></app-add-tests-new-schedule> -->
  <app-add-tests-new-schedule></app-add-tests-new-schedule>
</div>
<div *ngIf="!setAddSchedule && isEditEnabled">
<app-edit-tests-schedule></app-edit-tests-schedule>
</div>