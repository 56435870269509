<div class="private-schedule">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="privateForm">
                    <form [formGroup]="privateScheduleForm">
                        <div class="button-group">
                            <button mat-raised-button color="warn" (click)="back()">Back</button>
                            <button mat-raised-button color="primary"
                                [disabled]="privateScheduleForm.status=='INVALID'? true: false || selectedExaminees.length === 0 ? true : false || isEndTimeValid || isStartTimeValid"
                                (click)="onSubmit(privateScheduleForm)">Save</button>
                        </div>
                        <div class="form-group ptb-padding">
                            <label for="Schedule name">Schedule name<span class="red-asterisk">*</span></label>
                            <input type="text" autocomplete="off" class="form-control" id="schName"
                                aria-describedby="scheduleName" maxlength="121" formControlName="schName">
                            <div *ngIf="f.schName.invalid && (f.schName.dirty || f.schName.touched )"
                                class="alert alert-danger">
                                <div *ngIf="f.schName.errors?.required">Schedule name is required</div>
                                <div *ngIf="f.schName.errors?.minlength"> Minimum 3 characters required</div>
                                <div *ngIf="f.schName.errors?.maxlength"> Maximum 120 characters allowed</div>
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="Schedule description">Schedule description</label>
                            <textarea class="form-control" maxlength="481" type="text" id="schDescription"
                                aria-describedby="scheduledescription" value="" name="schDescription"
                                formControlName="schDescription"></textarea>
                            <div *ngIf="f.schDescription.invalid && (f.schDescription.dirty || f.schDescription.touched )"
                                class="alert alert-danger">
                                <div *ngIf="f.schDescription.errors?.minlength">Minimum 3 characters required</div>
                                <div *ngIf="f.schDescription.errors?.maxlength">Maximum 480 characters allowed</div>
                            </div>
                        </div>
                        <div class="form-group details ptb-padding">
                            <div class="filter-section title mb-margin"> <strong> Details </strong> </div>
                            <!-- <label for="">Add recipient*</label> -->
                            <div class="btn-add-examinee-wrapper">
                                <label for="">Examinees<span class="red-asterisk">*</span></label>
                                <div class="btn-add-examinee">
                                    <button mat-raised-button color="warn" (click)="addRecipientDialogbox()">Add
                                        Examinee(s)</button>
                                </div>
                            </div>
                            <!-- <mat-form-field class="chip-list">
                                <mat-label>Add recipient<span class="red-asterisk">*</span></mat-label>
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let examinee of chipSelectedExaminee" [selectable]=true
                                        [removable]=true (removed)="removeExaminee(examinee)">
                                        {{examinee.fullName}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="" required #examineeInput [formControl]="examineeControl"
                                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]=true (matChipInputTokenEnd)="addExaminee($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedExaminee($event)">
                                    <mat-option *ngFor="let thisExamineeName of filteredExaminee | async"
                                        [value]="thisExamineeName">
                                        {{thisExamineeName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>-->

                            <mat-chip-list aria-label="examinee selection">
                                <mat-chip *ngFor="let chip of selectedExaminees">
                                    {{chip?.fullName}}
                                    <mat-icon (click)="removeExamineeFromArray(chip)">close</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                            <div *ngIf="isExamineeExist" class="alert alert-danger">Please add atleast 1 Examinee
                                recipient
                            </div>

                        </div>
                        <div class="form-group date-n-time pb-padding pt-padding">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="input-class">
                                        <label for="">Start Date<span class="red-asterisk">*</span></label>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label></mat-label>
                                            <input matInput [matDatepicker]="picker" [min]="minDate"
                                                formControlName="startDate" />
                                            <!-- <input matInput [matDatepicker]="picker" formControlName="startDate"> -->
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>

                                            <mat-datepicker #picker></mat-datepicker>

                                        </mat-form-field>
                                        <div *ngIf="f.startDate.invalid && (f.startDate.dirty || f.startDate.touched )"
                                            class="alert alert-danger"> Start Date is Invalid
                                            <div *ngIf="f.startDate.errors?.required">Start Date is required
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-2 date-class">
                                    <div class="input-time">
                                        <label class="timepicker" for="">Start Time<span
                                                class="red-asterisk">*</span></label>
                                        <ngx-timepicker-field [format]="24" aria-disabled="true" [defaultTime]="'00:00'"
                                            formControlName="startTime">
                                        </ngx-timepicker-field>
                                        <div *ngIf="isStartTimeValid" class="alert alert-danger"> Start Time is required
                                        </div>
                                        <!-- <div *ngIf="f.startTime.invalid && (f.startTime.dirty || f.startTime.touched ) "
                                            class="alert alert-danger">
                                            <div *ngIf="f.startTime.errors?.required">Start Time is required</div>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="col-md-2 date-class">
                                    <div class="input-class">
                                        <label for="">End Date<span class="red-asterisk">*</span></label>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <!-- <mat-label>End date</mat-label> -->
                                            <input matInput [matDatepicker]="picker1" [min]="minDate1"
                                                formControlName="endDate" />
                                            <!-- <input matInput [matDatepicker]="picker1" formControlName="endDate"> -->
                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="f.endDate.invalid && (f.endDate.dirty || f.endDate.touched )"
                                            class="alert alert-danger"> End Date is Invalid
                                            <div *ngIf="f.endDate.errors?.required">End Date is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 date-class">
                                    <div class="input-time">
                                        <label class="timepicker" for="">End Time<span
                                                class="red-asterisk">*</span></label>
                                        <ngx-timepicker-field [format]="24" [defaultTime]="'00:00'"
                                            formControlName="endTime">
                                        </ngx-timepicker-field>
                                        <div *ngIf="isEndTimeValid" class="alert alert-danger"> End Time is required
                                        </div>
                                        <!-- <div *ngIf="f.endTime.invalid && (f.endTime.dirty || f.endTime.touched )"
                                            class="alert alert-danger">
                                            <div *ngIf="f.endTime.errors?.required">End Time is required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-3 date-class">
                                    <div class="input-class">
                                        <label class="timepicker" for="">Timezone<span
                                                class="red-asterisk">*</span></label>
                                        <mat-select class="form-control timeZ" name="timezone"
                                            formControlName="timeZone" [value]="zone">
                                            <mat-select-filter  [array]="searchOptions" 
                                            [displayMember]="'name'" (filteredReturn)="filteredList =$event">
                                            </mat-select-filter>
                                            <mat-option value="">Select Timezone</mat-option>
                                            <mat-option *ngFor="let option of filteredList; let i = index"
                                                [value]="option.name">
                                                {{option?.name}}
                                            </mat-option>
                                        </mat-select>
                                        <div *ngIf="f.timeZone.invalid && (f.timeZone.dirty || f.timeZone.touched )"
                                            class="alert alert-danger">
                                            <div *ngIf="f.timeZone.errors?.required">Timezone is required</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group settings pb-padding">
                            <div class="filter-section title"> <strong> Settings </strong> </div>
                            <div class="row">

                                <div class="col-md-2">
                                    <div class="setting-label">
                                        <!-- <label for=""> Total test score<span class="red-asterisk">*</span></label> -->
                                        <label for="">Total test score<span class="red-asterisk">*</span></label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <input type="number" class="settingInput style-non" readonly
                                        formControlName="testTotalScore"><span>mark(s)</span>
                                    <div *ngIf="f.testTotalScore.invalid && (f.testTotalScore.dirty || f.testTotalScore.touched )"
                                        class="alert alert-danger">
                                        <div *ngIf="f.testTotalScore.errors?.required">Test score is required</div>
                                    </div>
                                </div>


                                <div class="col-md-8">
                                    <div class="switch-op">
                                        <label for="">Examinee rejoin the test</label>
                                        <mat-slide-toggle formControlName="ExamineeRejoinTest">
                                        </mat-slide-toggle>

                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="setting-label">
                                        <label for=""> Test duration<span class="red-asterisk">*</span></label>

                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <input type="number" class="settingInput" formControlName="testDuration" min="0"
                                        max="9999"><span>min(s)</span>
                                    <div *ngIf="f.testDuration.invalid && (f.testDuration.dirty || f.testDuration.touched )"
                                        class="alert alert-danger">
                                        <div *ngIf="f.testDuration.errors?.required">Test duration is required</div>
                                        <div *ngIf="f.testDuration.errors?.pattern">Please enter the Test duration with
                                            positive integer</div>

                                    </div>

                                </div>

                                <div class="col-md-8">
                                    <div class="switch-op">
                                        <label for="">Show result after the test</label>
                                        <mat-slide-toggle formControlName="showResultAfterTest">
                                        </mat-slide-toggle>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="setting-label">
                                        <label for="">Test passing score<span class="red-asterisk">*</span></label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <input type="number" class="settingInput" formControlName="testPassingScore" min="0"
                                        (keyup)="testScoreChange()" max="9999"><span>mark(s)</span>
                                    <div *ngIf="f.testPassingScore.invalid && (f.testPassingScore.dirty || f.testPassingScore.touched )"
                                        class="alert alert-danger">
                                        <div *ngIf="f.testPassingScore.errors?.required">Test passing score is
                                            required</div>
                                        <div *ngIf="f.testPassingScore.errors?.pattern">Please enter the Test passing
                                            score with positive integer
                                        </div>
                                    </div>
                                    <div *ngIf="setTestScoremsg" class="alert alert-danger">
                                        <div>Test passing score should be less than Total test score</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="switch-op">
                                        <label for="">Shuffle items</label>
                                        <mat-slide-toggle formControlName="shuffleItems"></mat-slide-toggle>

                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="setting-label">
                                        <label for="">Penalty points<span class="red-asterisk">*</span></label>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <input type="number" class="settingInput" formControlName="penaltyPoints" min="0"
                                        (keyup)="checkTestValue()" max="9999"><span>mark(s)</span>
                                    <div *ngIf="f.penaltyPoints.invalid && (f.penaltyPoints.dirty || f.penaltyPoints.touched )"
                                        class="alert alert-danger">
                                        <div *ngIf="f.penaltyPoints.errors?.required">Penalty points is required
                                        </div>
                                    </div>
                                    <div *ngIf="setPenaltymsg" class="alert alert-danger">
                                        <div>Penalty point should be less than Test passing score and Total test
                                            score</div>
                                    </div>

                                </div>
                                <div class="col-md-8">
                                    <div class="switch-op">
                                        <label for="">Shuffle questions</label>
                                        <mat-slide-toggle formControlName="shuffleQuestions">
                                        </mat-slide-toggle>

                                    </div>
                                    <div class="switch-op">
                                        <label for="">Shuffle options</label>
                                        <mat-slide-toggle formControlName="shuffleOptions">
                                        </mat-slide-toggle>

                                    </div>


                                </div>

                            </div>
                        </div>

                        <div class="messages pb-padding">
                            <div class="title" id="mrg-bottom"> <strong> Message </strong> </div>
                            <div class="filter-section"> <label>Enter pre-test message<span
                                        class="red-asterisk">*</span></label></div>
                            <div class="row">
                                <div class="col-md-12 editor-cls">
                                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                                    </ngx-editor-menu>
                                    <ngx-editor name='optionbox' [placeholder]="'Enter here...'" class="form-control textarea" [editor]="editor"
                                        formControlName="preMessageText">
                                    </ngx-editor>
                                    <div *ngIf="f.preMessageText.invalid && (f.preMessageText.dirty || f.preMessageText.touched )"
                                        class="alert alert-danger">
                                        <div *ngIf="f.preMessageText?.errors?.minlength">Pre-test message is required</div>
                                        <!-- <div *ngIf="f.preMessageText?.errors?.minlength">Minimum 2 characters
                                            required</div> -->
                                        <div *ngIf="f.preMessageText?.errors?.maxlength">Maximum 1000 characters
                                            allowed</div>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-section post-wrapper"><label> Enter post-test message<span class="red-asterisk">*</span> </label></div>
                            <div class="row" class="'mrg-top-msg">
                                <div class=" editor-cls">
                                    <ngx-editor-menu [editor]="editor1" [toolbar]="toolbar">
                                    </ngx-editor-menu>
                                    <ngx-editor [placeholder]="'Enter here...'" name='optionbox' class="form-control" [editor]="editor1"
                                        maxLength="1001" formControlName="postMessageText">
                                    </ngx-editor>
                                    <div *ngIf="f.postMessageText.invalid && (f.postMessageText.dirty || f.postMessageText.touched )"
                                        class="alert alert-danger">
                                        <div *ngIf="f.postMessageText?.errors?.minlength">Post-test message is required</div>
                                        <!-- <div *ngIf="f.postMessageText?.errors?.minlength">Minimum 2 characters
                                            required</div> -->
                                        <div *ngIf="f.postMessageText?.errors?.maxlength">Maximum 1000 characters
                                            allowed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>