import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { ItemService } from 'src/app/shared/item.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TestService } from 'src/app/shared/test.service';

@Component({
  selector: 'app-tests-settings',
  templateUrl: './tests-settings.component.html',
  styleUrls: ['./tests-settings.component.scss'],
})
export class TestsSettingsComponent implements OnInit, OnChanges {
  @Input() tabData: any;
  @Input() isPublished: boolean;
  testSettingsForm: FormGroup;
  duplicateDataFlag = false;
  testuuId: any;
  testDetailData: any;
  testStatusList: any;
  messageFlag = false;
  success = false;
  error = false;
  messages = '';
  itemStatusId = 2;
  defaultStatus: any;

  constructor(
    private testService: TestService,
    private fb: FormBuilder,
    private router: Router,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private itemService: ItemService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.testService.getTestStatusList()?.subscribe((data) => {
      this.testStatusList = data?.items;
      this.defaultStatus = data?.items[1]?.itemstatus;
      console.log('testlist', this.defaultStatus);
    });

    if (this.testuuId != undefined) {
      this.testService.getTestDetails(this.testuuId).subscribe((resData) => {
        console.log(resData);
        this.defaultStatus = resData?.data?.statusName;
      });
    }

    this.actRoute.paramMap
      .pipe(
        switchMap((params: any) => {
          this.testuuId = params.get('id');
          return this.testService.getTestDetails(this.testuuId);
        })
      )
      .subscribe((testData) => {
        this.testDetailData = testData?.data;
        console.log(this.testDetailData?.statusName);
        this.defaultStatus = this.testDetailData?.statusName; // Used to set published value
        if (this.testDetailData?.statusName == 'Published') {
          this.isPublished = true;
        } else {
          this.isPublished = false;
        }

        if (this.tabData) {
          this.messageFlag = false;
        }
        this.testSettingsForm = new FormGroup({
          testDuration: new FormControl(this.testDetailData?.duration,
            [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]
          ),
          passingCriteria: new FormControl(
            this.testDetailData?.passingCriteria,
            [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]
          ),
          shuffle: new FormControl(this.testDetailData?.shuffle),
          calculatorRequired: new FormControl(
            this.testDetailData?.calculatorRequired
          ),
          penaltyPoints: new FormControl(this.testDetailData?.penaltyPoints
          ),
          showItemCount: new FormControl(this.testDetailData?.showItemCount),
          submitredirecturl: new FormControl(
            this.testDetailData?.submitredirecturl
          ),
          cancelRedirecturl: new FormControl(
            this.testDetailData?.cancelRedirecturl
          ),
          testStatus: new FormControl(this.testDetailData?.statusName),
        });
        console.log(this.testSettingsForm);
        this.testService.updateStatus(this.isPublished);
      });
  }

  // This function used for validation purpose
  get f() {
    return this.testSettingsForm.controls;
  }



  openConfirmationDialog(selectedStatus: any) {
    console.log(selectedStatus?.itemstatus);
    if (selectedStatus?.itemstatus == 'Published') {
      const dialogRef = this.dialog.open(DelMessageComponent, {
        data: {
          message: 'Are you sure you want to publish this Test?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No',
          },
        },
      });
      console.log(selectedStatus);
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.itemStatusId = selectedStatus.itemStatusId;
          console.log(selectedStatus?.itemstatus);
        } else {
          this.defaultStatus = "Unpublished";
        }
      });
    } else {
      this.itemStatusId = selectedStatus.itemStatusId;
      console.log(selectedStatus?.itemstatus);
    }
  }

  finalSaveTestSettigs() {
    console.log(this.testSettingsForm);

    const apiData = {
      active: true,
      calculatorRequired: this.testSettingsForm.value.calculatorRequired,
      cancelRedirecturl: this.testSettingsForm.value.cancelRedirecturl,
      duration: this.testSettingsForm.value.testDuration,
      passingCriteria: this.testSettingsForm.value.passingCriteria,
      durationDisplay: '',
      durationapplicable: true,
      penaltyPoints: this.testSettingsForm.value.penaltyPoints,
      showItemCount: this.testSettingsForm.value.showItemCount,
      shuffle: this.testSettingsForm.value.shuffle,
      submitredirecturl: this.testSettingsForm.value.submitredirecturl,
      teststatus: (this.testSettingsForm.value.testStatus = this.itemStatusId),
    };

    this.testService.saveTestSettings(apiData, this.testuuId).subscribe(
      (resData) => {
        if (resData) {
          console.log('data', resData);
          this.notificationService.success(resData?.message);
          this.itemService.getItemtatus().subscribe((getStatus) => {
            getStatus?.items.forEach((statusData: any) => {
              if (resData?.data?.teststatus == statusData?.itemStatusId) {
                this.defaultStatus = statusData?.itemstatus; // this is for selected status
              }
            });

            if (this.defaultStatus == 'Published') {
              this.isPublished = true;
            } else {
              this.isPublished = false;
            }
            console.log(this.isPublished);
            this.testService.updateStatus(this.isPublished);

            console.log(this.defaultStatus);
          });
        }
      },
      (error) => {
        this.notificationService.warn(error?.error?.message);
      }
    );
  }
  ngOnDestroy() {
    this.isPublished = null;
  }
}
