import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamineeloginService {

  public isHeaderVisible$: BehaviorSubject<boolean>;
  constructor(private http: HttpClient) {
    this.isHeaderVisible$ = new BehaviorSubject<boolean>(false);
  }

  login(userName: string, password: string, schId?: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const loginapiUrl = environment.examineeApiUrl + 'login';
    const loginData = {
      emailId: userName,
      password: password,
      testscheduleuuid: schId
    };
    const loginDataToApi = JSON.stringify(loginData);
    return this.http
      .post<any>(loginapiUrl, loginDataToApi, { headers: headers })
      .pipe(
        tap((user: any) => {
          if (user && user.data) {
            localStorage.setItem('examineeUser', JSON.stringify(user.data));
          }
        }),
        catchError(this.handleError)
      );
  }

  publicLogin(scheduleId: string, userJson: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const publicLoginapiUrl = environment.examineeApiUrl + 'publicLogin';
    const publicLoginData = {
      scheduleId: scheduleId,
      userJson: userJson,
    };
    const publicLoginDataToApi = JSON.stringify(publicLoginData);
    return this.http
      .post<any>(publicLoginapiUrl, publicLoginDataToApi, { headers: headers })
      .pipe(
        tap((user: any) => {
          if (user && user.body.data) {
            localStorage.setItem('examineeUser', JSON.stringify(user.body.data));
          }
        }),
        catchError(this.handleError)
      );
  }

  // This funtion is to reset password
  resetPassword(emailId: any) {
    const headers = { 'content-type': 'application/json' }

    const resetPwdUrl = environment.apiUrl + 'examinee/reset-password';
    const loginData = {
      "emailId": emailId,
    };
    return this.http.post<any>(resetPwdUrl, JSON.stringify(loginData), { 'headers': headers }).pipe(
      tap((user: any) => {

      }),
      catchError(this.handleError)
    )

  }

  scheduleDetail(scheduleId: any) {
    const headers = { 'content-type': 'application/json' };
    const scheduleapiUrl = environment.examineeApiUrl + 'loginCheck/' + scheduleId;
    // const scheduleapiUrl = 'https://ta-api.tanayassessmentdev.com/loginCheck/'+ scheduleId;
    return this.http.get<any>(scheduleapiUrl, { 'headers': headers }).pipe(
      tap((user: any) => {
        // if (user && user.data) {
        //   localStorage.setItem('examineeUser', JSON.stringify(user.data));
        // }
      }),
      catchError(error => {
        console.log(error);
        if (error.status === 401) {
          return throwError(new Error(error.status));
        }
        return throwError(error);
      })
    )
  }


  private handleError(error: any): any {
    console.error(error);
    return throwError(error);
  }
}
