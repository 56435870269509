export class NewTestSchedule {
  clientId: number;
  collectionId: number;
  description: string;
  endDate: string;
  endDateHours: number;
  endDateMinutes: number;
  examineeRejoin: boolean;
  examinees: Examinees[] = [];
  postTestMessage: PostTestMessage;
  preTestMessage: PretTestMessage;
  scheduleType: boolean;
  showResultAfterTest: boolean;
  shuffleItem: boolean;
  shuffleOption: boolean;
  shuffleQuestion: boolean;
  startDate: string;
  startDateHours: number;
  startDateMinutes: number;
  testBankId: number;
  testDuration: number;
  testPassingScore: number;
  testScore: number;
   //timeZoneId: number;
  penaltyPoint:number;
   timeZoneName :string
  title: string;
  testScheduleId:any;
  scheduleuuId:any;
  scheduleStatus:any;
  publicScheduleParameters:PublicScheduleParameters={
    parameters: new Parameters
  };

  
}

export class PostTestMessage {
  description: string;
  mediaURL: [string];
}

export class PretTestMessage {
  description: string;
  mediaURL: [string];
}

export class Examinees {
  0;
}

export class PublicScheduleParameters{
  parameters:Parameters;
}

export class Parameters{
  map(arg0: (data: any) => void) {
    throw new Error('Method not implemented.');
  }
  lenght: number;
  every(arg0: (value: any) => boolean): any {
    throw new Error('Method not implemented.');
  }
  uuid:any;
}
// export class Recipients {

//         clientExamineeId: number,
//         recipientId: number

//   }
