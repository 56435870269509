<div class="login-form-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 logo-container">
        <div class="">
          <div class="logo-wrapper">
            <div class="logo">
              <div><img src="../../assets/login.png" alt="Logo Tanay"></div>
            </div>
            <div class="logo-content">
              <h2>Create, update, and publish your tests dynamically across any digital platform or channel.</h2><br>
              <span>
                SimpliAssess is an online test maker that brings high quality assessments within
                everyone’s reach. It’s an easy-to-use headless assessment platform for educators,
                course administrators and training professionals to conduct online tests and quizzes
                across multiple channels.
              </span>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-4 login-form">
        <div id="login" class="form-container">
          <div id="login-row" class="justify-content-center align-items-center">
            <div id="login-box">
              <div id="login-column" class="login-column-c">
                <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
                  <p class="text-center login-title">Login to your account</p>
                  <label for="username" class="text-info">Email<span class="error">*</span></label><br>
                  <input type="text" class="form-control" formControlName="email" required
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                  <label for="password" class="text-info">Password<span class="error">*</span></label><br>
                  <input type="password" class="form-control" formControlName="password" required
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                  <div class="form-group">
                    <div><a routerLink="" (click)="resetPwd()" class="no-underline">Forgot password?</a></div>
                    <div>
                      <button type="submit" class="btn-login btn-bg-color" mat-flat-button>
                        <span *ngIf="!loginSpinner">Login</span>
                        <span *ngIf="loginSpinner"><i class="fa fa-spinner fa-spin fa-1x" aria-hidden="true"></i></span>
                      </button>
                    </div>

                  </div>
                </form>
              </div>
              <span class="error-message invalid-feedback">{{ loginErrorMessage }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-copyrights" id="cp">
  © 2022 SimpliAssess, All Rights Reserved.
</div>