import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { map ,catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  public addColuser = new Subject<any>();
  private newCollection = new BehaviorSubject<any>('');
  public getNewCollectionData = this.newCollection.asObservable();

  addCollectionuser(value: any) {
    console.log('service calling', value);
    this.addColuser.next(value);
  }
  constructor(private http: HttpClient,private router: Router) {}

  newCollectionList(collection: any){
    console.log('dahananjay');
    console.log(collection);
    this.newCollection.next(collection);
  }

  //  this function is fetching languages data from api
  getLanguages(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'languages';
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  // this function is fetching time zones data from api
  getTimeZone(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'timezone';
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  //  this function is posting collection data to api
  postCollection(userForm: any): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl =
        environment.apiUrl + 'clients'  + '/collections';
      const body = userForm;
      return this.http.post<any>(apiUrl, body, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  refershToken(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
        TimezoneOffset: this.getTimezoneOffset(),
      };
      const apiUrl = environment.apiUrl + 'refresh';
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((user: any) => {
          if (user && user.data) {
            localStorage.setItem('currentUser', JSON.stringify(user.data))
          }
        }),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            this.router.navigate(['login']);
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }
          
          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  //  this function is get all collection data from api
  getCollection(): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      //const apiUrl = environment.apiUrl + 'clients/' + clientId + '/collections';
      const apiUrl = environment.apiUrl + 'clients/collections';
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            this.router.navigate(['login']);
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }
  // this function is used registering new client for tanay assessment
  registerClient(clientForm: any): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'mundrisoft/clients';
      const body = clientForm;
      return this.http.post<any>(apiUrl, body, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  // this function is get all collection users data from api
  getCollectionUsers(collectionId: any): Observable<any> | null {
    console.log(collectionId)
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'collectionuser/' + collectionId;
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getCollectionDetails(collectionId: any): Observable<any> | null {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'collections/' + collectionId;
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getUserRoles() {
    // console.log("ca;ling user");
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'usersroles';
      console.log(apiUrl);
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  // this function is used for search collection users
  getCollectionUserSearch(searchParams) {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const collectionId = JSON.parse(
        localStorage.getItem('defaultcollectionuuid') || '{}'
      );
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl =
        environment.apiUrl +
        'collectionuser/' +
        collectionId +
        '?' +
        'firstName=' +
        searchParams.firstName +
        '&' +
        'lastName=' +
        searchParams.lastName +
        '&' +
        'role=' +
        searchParams.role +
        '&' +
        'status=' +
        searchParams.status;
      return this.http.get<any>(apiUrl, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  UpdateCollectionDetails(collectionuuId, formValue) {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'clients/collections/' + collectionuuId;

      console.log(apiUrl);
      const dataToApi = {
        collectionName: formValue.collectionName,
        description: formValue.description,
        langid: formValue.langid,
        // timezoneid: formValue.timezoneid,
        timezoneName: formValue.timezoneName,
      };
      return this.http.put<any>(apiUrl, dataToApi, { headers: headers }).pipe(
        tap((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  UpdateUserRole(usertoUpdate, collectionUserId) {
    console.log(usertoUpdate);
    if (localStorage.getItem('currentUser')) {
      console.log(usertoUpdate.userId);
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      console.log(usertoUpdate[0]?.userId);
      console.log(usertoUpdate?.userId);
      const apiUrl = environment.apiUrl + 'collectionuser/' + collectionUserId;
      console.log(apiUrl);
      return this.http
        .put<any>(apiUrl, usertoUpdate, { headers: headers })
        .pipe(
          tap((data) => data),
          catchError((error) => {
            console.log(error);
            if (error.status === 401) {
              return throwError(new Error(error.status));
            }
            if (error.status === 0) {
              return throwError(null);
            }

            return throwError(error);
          })
        );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  addUsertoCollection(addUsertoCollection) {
    console.log(addUsertoCollection)
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl = environment.apiUrl + 'collectionuser';
      console.log(apiUrl);
      return this.http
        .post<any>(apiUrl, addUsertoCollection, { headers: headers })
        .pipe(
          tap((data) => data),
          catchError((error) => {
            console.log(error);
            if (error.status === 401) {
              return throwError(new Error(error.status));
            }
            if (error.status === 0) {
              return throwError(null);
            }

            return throwError(error);
          })
        );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  deleteUserfromCollection(collectionuuId, collectionUseruuId): Observable<any> {
    if (localStorage.getItem('currentUser')) {
      const currentUserFromStorage = JSON.parse(
        localStorage.getItem('currentUser') || '{}'
      );
      const clientId = currentUserFromStorage?.clientId;
      const accessTokenFromStoarge = currentUserFromStorage['accessToken'];
      const headers = {
        'content-type': 'application/json',
        token: accessTokenFromStoarge,
      };
      const apiUrl =
        environment.apiUrl +
        'collectionuser/' +
        collectionuuId +
        '/' +
        collectionUseruuId;
      // /collectionuser/{collectionId}/{collectionUserId}

      console.log(apiUrl);
      return this.http.delete<any>(apiUrl, { headers: headers }).pipe(
        map((data) => data),
        catchError((error) => {
          console.log(error);
          if (error.status === 401) {
            return throwError(new Error(error.status));
          }
          if (error.status === 0) {
            return throwError(null);
          }

          return throwError(error);
        })
      );
    } else {
      console.log('please login in');
      console.log('later will navigate from here to login page ');
      return null;
    }
  }

  getTimezoneOffset(): string {
    return(String( new Date().getTimezoneOffset()))
  }
}
