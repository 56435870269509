import {
  Component,
  OnInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { toHTML } from 'ngx-editor';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { QuestionService } from 'src/app/shared/question.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { ItemService } from 'src/app/shared/item.service';
import {
  AddNewQuestion,
  QuestionDescription,
  Options,
} from 'src/app/shared/addnewquestion';
import { NotificationService } from 'src/app/shared/notification.service';

export interface DialogData {
  animal: string;
  name1: string;
  html: string;
  editor: Editor;
  editors: Editor[];
  option: any;
  editor1: Editor;
}

@Component({
  selector: 'app-item-add-true-false-questions',
  templateUrl: './item-add-true-false-questions.component.html',
  styleUrls: ['./item-add-true-false-questions.component.scss'],
})
export class ItemAddTrueFalseQuestionsComponent implements OnInit, OnDestroy {
  listDisplay;
  itemId: any;
  display: boolean = false;
  que: any;
  editor1: Editor;
  questionDescription: [];
  set_main_form = false;
  // addOptionNew: Options[] = [];
  addedOptions: Options[] = [
    {
      answerDescription: {
        description: 'TRUE',
        mediaURL: [''],
      },

      answerValid: false,
      arrangeOrder: 0,
      correctPoints: 0,
      modified: false,
      optionId: 1,
      selected: false,
    },
    {
      answerDescription: {
        description: 'FALSE',
        mediaURL: [''],
      },

      answerValid: false,
      arrangeOrder: 0,
      correctPoints: 0,
      modified: false,
      optionId: 2,
      selected: false,
    },
  ];
  shuffleSelectValue: any;
  autoscoring: boolean;
  moreSetting: [];
  opened = false;
  optionDiv = false;
  openedD = false;
  name = 'Angular 6';
  parentData = 1;
  i = 0;
  userForm: FormGroup;
  tabs = [];
  selected = new FormControl(0);
  editorform: FormGroup;
  editor: Editor;
  editors: Editor[] = [];
  option: [];
  question: [];
  fetchEditQuetion: any;
  fetchEditOption: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  panelOpenState: boolean = false;
  animal: string;
  name1: string;
  html: string;
  questionhtml: any;
  fetchEditQuetion1: any;
  queId: any;
  seteditor = true;
  autoscoreSelectValue: any;
  allowChwckAnsSelectValue: any;

  checkansSelectValue: any;
  checkAnsmaintValue: any;

  miniscoretype: any;
  miniscoretypeMainvalue: any;

  penaltypointselectvalue: any = 0;
  penaltypointMainvalue: any;
  scoringselectvalue: any;
  addnewquestion = new AddNewQuestion();
  addnewquestiondescription = new QuestionDescription();
  setAddOption = true;
  editMode = false;
  submitted = false;
  getscoringtype: any;
  getAllowCheckAns: any;
  saveDataValidation = false;
  checkSelectionOption: any;

  addQuestionScore: any;
  newEvent = 0;
  newEvent1: any;
  scoreArray = [];
  questionscoreF = false;
  arrayForScoreadd = [];
  defultvalue = 0;
  defultvalueScore = 0;
  itemBankQueId: any;
  @Input() defValue = '0';
  @Output() postCreated = new EventEmitter();
  addQuestionForm: FormGroup;
  saveDisable: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routerstate: ActivatedRoute,
    public dialog: MatDialog,
    public questionService: QuestionService,
    public itemservice: ItemService,
    private notificationService: NotificationService
  ) {
    this.itemBankQueId = localStorage.getItem('itemId');
    // this.itemBankQueId = this.router.getCurrentNavigation()?.extras?.state?.iteBankQueId;
    // this.itemId = this.router.getCurrentNavigation() ?.extras ?.state ?.itemId;
    // console.log("this.item", this.itemId);
    this.routerstate?.parent?.params?.subscribe((data) => {
      this.itemId = data.id;
      // console.log(data);
    });
    if (localStorage.getItem('questionuuId')) {
      this.queId = localStorage.getItem('questionuuId');
    } else {
      this.queId = this.router.getCurrentNavigation()?.extras?.state?.queId;
    }
    // this.editMode = this.router.getCurrentNavigation() ?.extras ?.state ?.isEdit;
    this.userForm = fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
      autoscore: new FormControl(true),
      shuffle: [''],
      scoringTypeMatch: ['true'],
      allowCheckAnswer: ['false'],
      penaltyPoint: [0],
      minimumScoreIfAttempt: [''],
    });

    // this.userForm = this.fb.group({
    //   optionrows: this.fb.array([
    //     this.createOptionControl()
    //   ],Validators.required)
    // })

    this.editor = new Editor();
    this.editor1 = new Editor();
  }

  createOptionControl(): FormGroup {
    return this.fb.group({
      value: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.saveDisable = false;
    this.editor = new Editor();
    this.editor1 = new Editor();
    this.addQuestionForm = new FormGroup({
      question: new FormControl(
        { value: '', disabled: false },
        Validators.required
      )
    });
    this.checkSelectionOption = this.addedOptions;

    if (this.queId) {
      this.seteditor = true;
      this.editMode = true;
      this.questionService
        .getQuestion(this.itemId, this.queId)
        .subscribe((resdata) => {
          console.log("data get questions", resdata);
          // this.question = resdata.data?.questiondescription?.description;
          this.addQuestionForm.controls['question'].setValue(resdata?.data?.questiondescription?.description);
          this.fetchEditOption = resdata.data?.options;
          this.addedOptions = this.fetchEditOption;
          this.newEvent = resdata.data?.correctPoints;
          console.log('Constructor', this.question);
          this.arrayForScoreadd = this.addedOptions;
          this.autoscoring = resdata.data?.autoScoring;
          // this.fetchEditOption.modified = true;

          // this.arrayForScoreadd = this.addedOptions;
          // this.fetchEditQuetion1 = data ?.questions[0] ?.questionid;
          // console.log
          // console.log("fetchEditQuetion", this.addedOptions);

          this.userForm.setValue({
            hideRequired: this.hideRequiredControl,
            floatLabel: this.floatLabelControl,
            autoscore: resdata.data.autoScoring,
            shuffle: resdata.data.shuffleOption,
            scoringTypeMatch: resdata.data.scoringTypeExactMatch,
            allowCheckAnswer: resdata.data.allowCheckAnswer,
            penaltyPoint: resdata.data.penaltyPoint,
            minimumScoreIfAttempt: resdata.data.minScoreIfAttempt,
          });
          this.getscoringtype = String(resdata.data.scoringTypeExactMatch);
          this.getAllowCheckAns = String(resdata.data.allowCheckAnswer);
        });
    }

    this.arrayForScoreadd = this.addedOptions;
    // console.log('this.itemId', this.itemId);
  }

  get optionrows(): FormArray {
    return <FormArray>this.userForm.get('optionrows');
  }

  get f() {
    return this.userForm.controls;
  }
  addOptionRow(): void {
    this.editors.push(new Editor());
    this.optionrows.push(this.createOptionControl());
  }

  removeOptionRow(index: any) {
    (this.userForm.get('optionrows') as FormArray).removeAt(index);
    //this.editors.remove(index);
  }

  getRowFormControls(): AbstractControl[] {
    return (<FormArray>this.userForm.get('DyRow')).controls;
  }

  onCheckRadioOption(radiovalue, value) {
    console.log('radioSelectied', value);
    this.arrayForScoreadd.forEach((element) => {
      console.log('element', element.answerDescription.description);
      if (element.answerDescription.description == value) {
        element.answerValid = true;
      } else {
        console.log('element answerValid', element.answerValid);
        element.answerValid = false;
      }
      this.addScore(radiovalue);
    });

    this.addedOptions = this.arrayForScoreadd;
    console.log(" this.newArrayforAddOption", this.arrayForScoreadd);
  }

  addScore(valueScore) {
    // with type info
    // this.arrayForScoreadd = this.addedOptions;
    console.log('valueScore', valueScore);
    // this.newEvent = valueScore.correctPoints

    if (this.arrayForScoreadd) {
      this.scoreArray = [];
      this.arrayForScoreadd.forEach((element) => {
        console.log('ele', element);
        if (element.answerValid == true) {
          this.scoreArray.push(element.correctPoints);
        }
      });
    }

    console.log('element.correctPoints', this.scoreArray);
    this.newEvent = this.scoreArray.reduce(add, 0);
    function add(accumulator, a) {
      return accumulator + a;
    }
    // this.scoreArray = [];
    // this.arrayForScoreadd.forEach((element) => {
    //   this.scoreArray.push(element.correctPoints);
    // });
    // this.newEvent = this.scoreArray.reduce(add, 0);
    // function add(accumulator, a) {
    //   // add score of option
    //   return accumulator + a;
    // }
    // console.log('New added value', this.scoreArray);
  }
  deleteOption(index: any) {
    // console.log("Delete data", this.addedOptions);
    this.addedOptions.splice(index, 1);
  }
  deleteEditOption(index: any) {
    // console.log("Delete data", this.fetchEditOption);
    this.fetchEditOption.splice(index, 1);
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      this.notificationService.warn('Cannot change Total question score');
      return false;
    } else {
      this.notificationService.warn('Cannot change Total question score');
      return false;
    }
  }


  saveQuestion() {
    this.submitted = true;
    // console.log(this.submitted);
    // console.log("check", this.userForm.value);
    if (this.userForm.invalid) {
      // console.log("in if", this.submitted);
      // console.log("Plz Fill the form");
      this.saveDataValidation = true;
      return;
    }
    this.addnewquestion = new AddNewQuestion();
    this.addnewquestion.questiondescription = new QuestionDescription();
    let selectetionOptionValue = this.addedOptions.filter(
      (value) => value.answerValid == true
    );

    let selectioIndex = this.addedOptions.findIndex(
      (value) => value.answerValid == true
    );
    this.addnewquestion.itemId = this.itemBankQueId;
    this.questionhtml = this.addQuestionForm.controls['question'].value;
    let rmcharHtml = this.questionhtml.replace(/(<([^>]+)>)/ig, '');
    let rmCharSpace = rmcharHtml.replace(/^\s+/g, '');
    if (rmCharSpace.length === 0) {
      this.notificationService.warn("Question field is mandatory");  // Used to display alert message
    }
    else if (selectioIndex < 0) {
      this.notificationService.warn('Select at least one correct answer');  // Used to display alert message
    } else {
      // console.log("Edit Mode",this.fetchEditOption);
      if (this.editMode == true) {
        this.questionhtml = this.addQuestionForm.controls['question'].value;
        // this.questionhtml = this.question;
        this.addnewquestion.allowCheckAnswer =
          this.userForm.controls['allowCheckAnswer'].value;
        this.addnewquestion.autoScoring = this.userForm.controls['autoscore'].value;
        this.addnewquestion.shuffleOption =
          this.userForm.controls['shuffle'].value;
        // this.addnewquestion.autoScoring = true;
        // this.addnewquestion.allowCheckAnswer = true;
        this.arrayForScoreadd.forEach((element) => {
          element.modified = true;
        });
        this.addedOptions = this.arrayForScoreadd;
        this.addnewquestion.options = this.addedOptions;
        // console.log('this.addnewquestion.options', this.addnewquestion.options);
      } else {
        // this.questionhtml = toHTML(this.question);
        this.questionhtml = this.addQuestionForm.controls['question'].value;
        this.autoscoreSelectValue = JSON.parse(
          this.userForm.controls['autoscore'].value
        );
        this.addnewquestion.autoScoring = this.autoscoreSelectValue;

        if (this.userForm.controls['allowCheckAnswer'].value) {
          this.allowChwckAnsSelectValue = JSON.parse(
            this.userForm.controls['allowCheckAnswer'].value
          );
          this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;
        }

        this.addnewquestion.options = this.addedOptions;
        this.addnewquestion.shuffleOption =
          this.userForm.controls['shuffle'].value;
      }

      this.addnewquestion.correctPoints = this.newEvent;
      // this.addnewquestion.options = this.addedOptions;
      // console.log("question", this.addnewquestion.options);

      this.addnewquestion.questiondescription.description = this.questionhtml;
      this.addnewquestion.scoringTypeExactMatch = true;

      this.addnewquestion.active = true;
      this.addnewquestion.ansRenderCount = 0;

      this.addnewquestion.questiondescription.mediaURL = ['String'];
      this.addnewquestion.questionOrder = 0;
      this.addnewquestion.questionSubTypeId = 3;
      this.addnewquestion.questionTypeId = 1;
      // this.addnewquestion.shuffleOption = true;
      // this.addnewquestion.shuffleOptions = true;
      // this.autoscoreSelectValue = JSON.parse(this.userForm.controls['autoscore'].value);
      // this.addnewquestion.autoScoring = this.autoscoreSelectValue;

      // this.allowChwckAnsSelectValue = JSON.parse(this.userForm.controls['allowCheckAnswer'].value);
      // this.addnewquestion.allowCheckAnswer = this.allowChwckAnsSelectValue;

      // this.checkansSelectValue = this.userForm.controls['checkAnswerAttempt'].value;
      // this.checkAnsmaintValue = +this.checkansSelectValue;
      // this.addnewquestion.checkAnswerAttempt = this.checkAnsmaintValue;

      this.miniscoretype =
        this.userForm.controls['minimumScoreIfAttempt'].value;
      this.miniscoretypeMainvalue = +this.miniscoretype;
      this.addnewquestion.minScoreIfAttempt = this.miniscoretypeMainvalue;

      // this.addnewquestion.options = this.addOptionNew;

      this.penaltypointselectvalue =
        this.userForm.controls['penaltyPoint'].value;

      // console.log("---------> this.penaltypointselectvalue ", this.penaltypointselectvalue );


      // this.penaltypointMainvalue = +this.miniscoretype;
      // console.log("--->penaltypointMainvalue",this.penaltypointMainvalue);
      this.addnewquestion.penaltyPoints = this.penaltypointselectvalue;

      // console.log(this.addnewquestion); /*  */
      if (this.editMode == false) {
        if (this.questionhtml == '<p></p>') {
          this.notificationService.warn("Question field is mandatory")
        }
        else {
          this.questionService
            .addQuestion(this.addnewquestion, this.itemId)
            .subscribe(
              (resData) => {
                // console.log("We are in Edit question",resData);
                this.saveDisable = true;
                this.notificationService.success(resData?.message); // This line used to display message
                this.itemservice.questionCountUpdate.next(true);
                this.listDisplay = true;

                this.back();
              },
              (error) => {
                this.notificationService.warn(error?.error?.message); // This line used to display error message
              }
            );
        }
      }

      // for Edit Quetion
      if (this.editMode == true) {
        if (this.questionhtml == '<p></p>') {
          this.notificationService.warn("Question field is mandatory")
        }
        else {
          this.questionService
            .updateQuestion(this.addnewquestion, this.itemId, this.queId)
            .subscribe(
              (resData) => {
                console.log("We are in Edit question", resData);
                this.itemservice.editedGetItem(resData.data);
                this.notificationService.success(resData?.message); // This line used to display message
                this.itemservice.questionCountUpdate.next(true);
                this.listDisplay = true;

                this.back();
              },
              (error) => {
                this.notificationService.warn(error?.error?.message); // This line used to display error message
              }
            );
        }
      }
    }
  }

  clickData() {
    this.set_main_form = true;
  }

  openOptionDiv() {
    this.setAddOption = false;
    this.optionDiv = true;
  }

  // deleteOption(index1) {
  //   this.addedOptions.splice(index,1);
  // }

  ngOnDestroy(): void {
    if (localStorage.getItem('questionuuId')) {
      this.queId = localStorage.removeItem('questionuuId');
      localStorage.removeItem('questionId');
      localStorage.removeItem('questionuuId');
    }
    // this.editors.forEach(item => {
    //   item.destroy();
    // });
  }

  changeFromChild(): void {
    this.parentData = +1;
  }

  data: any;

  openDynamic(index: any) {
    this.data = index;
    if (this.data == index) {
      this.openedD = true;
    } else {
      this.openedD = false;
    }
  }

  send(values: any) {
    // console.log(values);
    // console.log("data call send", this.userForm.value);
  }

  back() {
    // if (this.editMode) {
    //   // this.router.navigate(['../'], { relativeTo: this.routerstate });
    //   this.postCreated.emit('false');
    // } else {
    //   this.postCreated.emit('false');
    // }
    this.postCreated.emit('false');
    this.display = true;
    this.editor.destroy();
  }

  openDialog(a: any): void {
    // console.log(a);
    const dialogRef = this.dialog.open(DialogTrueFalseQuestions, {
      width: '950px',
      height: '350px',
      // console.log(a);
      data: { name1: a, animal1: 'test' },
      // data: { name1: this.html ,animal1 :'test'}
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      this.animal = result;
    });
  }
}

@Component({
  selector: 'dialog-true-false-questions',
  templateUrl: 'dialog-true-false-questions.html',
  styleUrls: ['dialog-true-false-questions.scss'],
})
export class DialogTrueFalseQuestions {
  fetchEditQuetion1: any;
  editor: Editor;
  editors: Editor[] = [];
  option: any;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogTrueFalseQuestions>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.editor = new Editor();
  }
  ngOnInit(): void {
    // console.log("Option editor", this.data.name1);
    this.option = this.data.name1;
  }

  updateOption(): void {
    // console.log("data show update", this.option);

    this.dialogRef.close();
  }
}
