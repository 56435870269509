import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../shared/app.service';

@Component({
  selector: 'app-userheader',
  templateUrl: './userheader.component.html',
  styleUrls: ['./userheader.component.scss']
})
export class UserheaderComponent implements OnInit {

  userDetails : any ; 
  constructor(private router: Router, private appService : AppService) { }

  ngOnInit(): void {
    if(localStorage.getItem('currentUser')){
      this.userDetails = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }
  }
  LogOut(){
    localStorage.removeItem('currentUser');
    this.appService.changeUserHeader(0);
    this.router.navigate(['/login']);
  }

}
