import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';


@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  getReqUrl: any;
  constructor(public loaderService:LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log(request.headers);
    // console.log(request.body);
    this.loaderService.getReqUrl.subscribe((resData: any) => {
      // console.log(resData)
      this.getReqUrl = resData;
    })
    if(request.url === this.getReqUrl){
      this.loaderService.nextLoading(false)
    }else{
      this.loaderService.nextLoading(true);     
    }
    return next.handle(request).pipe(
      finalize(() => this.loaderService.nextLoading(false))
    );
  }
}

