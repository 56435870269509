import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import {
  Home, Grid, List, FileText, Tag, Edit, LogIn, LogOut, Mail, Link, Info, Bell,
  Trash, Trash2, User, PenTool, Heart, Settings, HelpCircle, Github,
  ArrowLeftCircle, ArrowLeft, ArrowRightCircle, ArrowRight, AlertOctagon, Disc, PlusSquare,
  Star, Target, ShoppingBag, Shield, Smartphone, Activity, Key,
} from 'angular-feather/icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ItemLandingComponent } from './items/item-landing/item-landing.component';
import { UserModule } from './user/user.module';
import { HeaderComponent } from './header/header.component';
import { ItemsfilterPipe } from './shared/itemsfilter.pipe';
import { DialogPreviewItem, ItemDetailsComponent } from './items/item-details/item-details.component';
import { QuestionTypeRadioComponent } from './shared/questionstypes/question-type-radio/question-type-radio.component';
import { ItemAddQuestionTypeComponent, DialogOverviewExampleDialog } from './items/item-add-question-type/item-add-question-type.component';
import { AbcComponent } from './items/item-details/abc.component';
import { QuestionTypeCheckboxComponent } from './shared/questionstypes/question-type-checkbox/question-type-checkbox.component';
import { QuestionTypeTruefalseComponent } from './shared/questionstypes/question-type-truefalse/question-type-truefalse.component';
import { ItemTagsComponent } from './items/item-tags/item-tags.component';
import { TanayMatarialModule } from './material.module';
import { ChooseQuestionTypeComponent } from './items/choose-question-type/choose-question-type.component';
import { ItemBasicDetailsComponent } from './items/item-basic-details/item-basic-details.component';
import { ItemSettingsComponent } from './items/item-settings/item-settings.component';
import { TestsLandingComponent } from './tests/tests-landing/tests-landing.component';
import { TestsDetailsComponent } from './tests/tests-details/tests-details.component';
import { TestsBasicDetailsComponent } from './tests/tests-basic-details/tests-basic-details.component';
import { TestDialogPreview, TestsItemsComponent } from './tests/tests-items/tests-items.component';
import { TestsTagsComponent } from './tests/tests-tags/tests-tags.component';
import { TestsExistingItemsComponent } from './tests/tests-existing-items/tests-existing-items.component';
import { TestsScheduleComponent } from './tests/tests-schedule/tests-schedule.component';
import { TestsSettingsComponent } from 'src/app/tests/tests-settings/tests-settings.component';
import { UserheaderComponent } from './userheader/userheader.component';
import { TestsCreateComponent } from './tests/tests-create/tests-create.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { ArraytonewlinePipe } from './pipes/arraytonewline.pipe';
// import { DialogOverviewExampleDialog }
// from 'src/app/items/item-add-question-type/item-add-question-type/item-add-question-type.component';
import { CreateNewItemComponent } from './items/create-new-item/create-new-item.component';
import { AddTagTypeComponent } from './tags/add-tag-type/add-tag-type.component';
import { DateAgoPipe } from './shared/date-ago.pipe';
import { CollectionComponent } from './collection/collection.component';
import { AddCollectionComponent } from './collection/add-collection/add-collection.component';
import { AddClientComponent } from './collection/add-client/add-client.component';
import { UserLandingComponent } from './users/user-landing/user-landing.component';
import { UserCreateComponent } from './users/user-create/user-create.component';

import { UserEditComponent } from './users/user-edit/user-edit.component';
import { ItemAddCheckboxQuestionsComponent, DialogOverview } from 'src/app/items/item-add-checkbox-questions/item-add-checkbox-questions.component';
import { ItemAddTrueFalseQuestionsComponent, DialogTrueFalseQuestions } from 'src/app/items/item-add-true-false-questions/item-add-true-false-questions.component';
import { BoldTagPipe } from './shared/bold-tag.pipe';
import { EditCollectionComponent } from './collection/edit-collection/edit-collection.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TestsActivitiesComponent } from './tests/tests-activities/tests-activities.component';
import { CollectionLandingComponent } from './collection/collection-landing/collection-landing.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

import { DelMessageComponent } from './shared/del-message/del-message.component';
import { LanguagePipe } from './shared/language.pipe';
import { CollectionhomeComponent } from './collection/collectionhome/collectionhome.component';
import { CollectionUsersComponent } from './collection/collection-users/collection-users.component';
import { CollectionDetailsComponent } from './collection/collection-details/collection-details.component';
import { CollectionParentLandingComponent } from './collection/collectionparentlanding.component';
import { AddCollectionUsersComponent } from './collection/add-collection-users/add-collection-users.component';
import { ExaminessComponent } from './examiness/examiness.component';
import { AddUSerDialog } from 'src/app/collection/collection-users/add-user-dialog';
import { ExamineeloginComponent } from './examineelogin/examineelogin.component';
import { AddTestsScheduleComponent } from './tests/add-tests-schedule/add-tests-schedule.component';
import { GlobalInterceptorService } from './shared/global-interceptor.service';
import { ChangePasswordComponent } from './header/change-password/change-password.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ConfimationMessageComponent } from './shared/confimation-message/confimation-message.component';
import { ExamineesGroupComponent } from './examinees-group/examinees-group.component';
import { ExamineeGroupModule } from './examinees-group/examinee-group.module';
import { ResizeColumnDirective } from './resize-column.directive';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSelectFilterModule } from 'mat-select-filter';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AddNewQuestionPreview, QuestionListComponent } from './items/question-list/question-list.component';
import { NgxEditorModule } from 'ngx-editor';
import { NewSchedulePopupComponent } from './tests/tests-schedule/new-schedule-popup/new-schedule-popup.component';
import { AddTestsNewScheduleComponent } from './tests/add-tests-new-schedule/add-tests-new-schedule.component';
// import { SchedulesModule } from './schedules/schedules.module';
// import { ScheduleDetailsComponent } from './tests/schedule-details/schedule-details.component';
import { AddReciepantsComponent } from './tests/add-reciepants/add-reciepants.component';
import { CountdownModule } from 'ngx-countdown';
import { OptionEditorComponent } from './shared/questionstypes/option-editor/option-editor.component';
import { GlobalErrorHandler } from './shared/global-error-handler.service';
// import { CountdownTimerModule } from 'angular-countdown-timer';
import { EditTestsScheduleComponent } from './tests/edit-tests-schedule/edit-tests-schedule.component';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { AddMultipleItemComponent } from './items/item-landing/add-multiple-item/add-multiple-item.component';

const icons = {
  Home, Grid, List, FileText, Tag, Edit, LogIn, LogOut, Mail,
  Link, Info, Bell, User, Heart, Trash, Trash2, PenTool, Settings, HelpCircle, Github,
  ArrowLeftCircle, ArrowLeft, ArrowRightCircle, ArrowRight, AlertOctagon, Disc, PlusSquare,
  Star, Target, ShoppingBag, Shield, Smartphone, Activity, Key,
};
@NgModule({
  declarations: [
    AppComponent,
    ItemLandingComponent,
    AddMultipleItemComponent,
    ItemDetailsComponent,
    HeaderComponent,
    ItemsfilterPipe,
    ItemAddQuestionTypeComponent,
    QuestionTypeRadioComponent,
    AbcComponent,
    QuestionTypeCheckboxComponent,
    QuestionTypeTruefalseComponent,
    ItemTagsComponent,
    ChooseQuestionTypeComponent,
    ItemBasicDetailsComponent,
    ItemSettingsComponent,
    DialogOverviewExampleDialog,
    TestsLandingComponent,
    TestsDetailsComponent,
    TestsBasicDetailsComponent,
    TestsItemsComponent,
    TestsTagsComponent,
    TestsExistingItemsComponent,
    TestsScheduleComponent,
    TestsSettingsComponent,
    UserheaderComponent,
    TestsCreateComponent,
    CreateNewItemComponent,
    DateAgoPipe,
    Nl2brPipe,
    ArraytonewlinePipe,
    CollectionComponent,
    AddCollectionComponent,
    AddClientComponent,
    UserLandingComponent,
    UserCreateComponent,
    UserEditComponent,
    CollectionLandingComponent,
    ItemAddCheckboxQuestionsComponent,
    DialogOverview,
    ItemAddTrueFalseQuestionsComponent,
    DialogTrueFalseQuestions,
    BoldTagPipe,
    EditCollectionComponent,
    DashboardComponent,
    TestsActivitiesComponent,
    ConfirmationDialogComponent,
    DelMessageComponent,
    LanguagePipe,
    CollectionhomeComponent,
    CollectionUsersComponent,
    CollectionDetailsComponent,
    CollectionParentLandingComponent,
    AddCollectionUsersComponent,
    ExaminessComponent,
    DialogPreviewItem,
    AddUSerDialog,
    TestDialogPreview,
    ExamineeloginComponent,
    AddTestsScheduleComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ConfimationMessageComponent,
    ExamineesGroupComponent,
    ResizeColumnDirective,
    // ScheduleDetailsComponent,
    QuestionListComponent,
    AddNewQuestionPreview,
    NewSchedulePopupComponent,
    AddTestsNewScheduleComponent,
    AddReciepantsComponent,
    OptionEditorComponent,
    EditTestsScheduleComponent,
    LoaderComponent

  ],
  imports: [
    CommonModule,
    BrowserModule,
    UserModule,
    // SchedulesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TanayMatarialModule,
    FeatherModule.pick(icons),
    MatDialogModule,
    NgxEditorModule,
    MatSelectFilterModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    // CountdownTimerModule.forRoot()
    CountdownModule

  ],

  exports: [
    DateAgoPipe
  ],

  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptorService, multi: true },
    {provide:HTTP_INTERCEPTORS, useClass:LoaderInterceptor,multi:true},
    {provide:ErrorHandler,useClass:GlobalErrorHandler},
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

