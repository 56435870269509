import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemService } from 'src/app/shared/item.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TestService } from 'src/app/shared/test.service';
import { UsersService } from '../users.service';
import { UserCreateComponent } from '../user-create/user-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserEditComponent } from '../user-edit/user-edit.component';


export interface userEditUuid {
  useruuId: number;
}


@Component({
  selector: 'app-user-landing',
  templateUrl: './user-landing.component.html',
  styleUrls: ['./user-landing.component.scss']
})


export class UserLandingComponent implements OnInit {

  clientId: number;
  userServiceSubscription$: Subscription | undefined;
  userFilterServiceSubscription$: Subscription | undefined;
  tests: any;
  displayedColumns: string[] = ['firstName', 
  'lastName', 
  'emailId', 
  'status', 
  'updated', 
  // 'action'
];
  //totalRecords: number;
  totalRecords: any;
  searchText: any;
  page: number = 1;
  itemName = "";

  usersData: any;
  firstName = "";
  lastName = "";
  emailId = "";
  userStatus = "";

  testName = "";
  itemDescription = "";
  testDescription = "";
  tagdescription = "";
  itemStatus = "";
  testStatus = "";
  isItemDescended = true;
  itemId = 10;
  public config: any = {
    id: 'custom',
    itemsPerPage: 3,
    currentPage: 1
  };

  userfirstName = "";
  userlastName = "";
  userActiveStatus = "";

  pageOfItems: any;
  noDataFound = false;
  testStatusList: any;
  isNoUser = false;
  statusArray = [
    { status: 'Active' },
    { status: 'Inactive' },
  ];

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.usersData.sort = sort;
  }
  // @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private testService: TestService,
    private router: Router,
    private usersService: UsersService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {

    this.userServiceSubscription$ = this.usersService.getNewData.subscribe(resData => {
      console.log(resData)
      this.usersData = resData?.data;
      console.log(this.usersData);
      if (this.usersData?.length == 0) {
        this.isNoUser = true;
      }
      else {
        this.usersData = new MatTableDataSource(this.usersData);
        this.usersData.paginator = this.paginator;
        this.isNoUser = false;
      }
    },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;



    // Users list start
    this.userServiceSubscription$ = this.usersService.getusersLanding()?.subscribe(resData => {
      this.usersData = resData?.data;
      console.log(this.usersData);
      if (this.usersData?.length == 0) {
        this.isNoUser = true;
      }
      else {
        this.usersData = new MatTableDataSource(this.usersData);
        // this.usersData.sort = this.sort;
        this.usersData.paginator = this.paginator;
        this.isNoUser = false;
      }
    },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

    // users list end

    this.testService.getTestStatusList()?.subscribe(data => {
      this.testStatusList = data?.items;
      console.log(this.testStatusList);
    });

    // this.testServiceSubscription$ = this.testService.getTests('10')?.subscribe(
    //   (data) => {
    //     //console.log(data);
    //     this.tests = data?.items;
    //     console.log(this.tests);
    //     if (this.tests.length < 1) {
    //       this.noDataFound = true;
    //     }
    //     this.tests = new MatTableDataSource(this.tests);
    //     this.tests.sort = this.sort;
    //     this.tests.paginator = this.paginator;
    //   },
    //   (error) => {
    //     console.log('dj');
    //     console.log(error);
    //     this.router.navigate(["/login"]);
    //   },
    //   () => {
    //     console.log('item service subscription completed');
    //   }) || undefined;

  }


  userFilter(): void {

    // const filterObj = {
    //   firstName: this.firstName,
    //   lastName: this.lastName,
    //   emailId: this.emailId,
    //   active: this.userStatus
    // };

    // const filterObj = {
    //   // colledtionid: JSON.parse(this.globalcollectionid),
    //   userfirstName: this.firstName,
    //   userlastName: this.lastName,
    //   userActiveStatus: this.userStatus,

    // };
    let isActive = null;
    if (this.userStatus == 'Active') {
      isActive = true;
    } else if (this.userStatus == 'Inactive') {
      isActive = false;
    } else {
      isActive = '';
    }

    const options = 'firstName=' + this.firstName
      + '&lastName=' + this.lastName
      + '&emailId=' + this.emailId
      + '&active=' + isActive;
    console.log(options);

    this.userFilterServiceSubscription$ = this.usersService.UsersFilter(options)?.subscribe(
      (resData) => {
        console.log(resData);
        this.usersData = resData?.data;
        console.log(this.usersData.length);
        if (this.usersData?.length == 0) {
          this.noDataFound = true;
        }
        console.log(this.usersData);
        this.usersData = new MatTableDataSource(this.usersData);
        // this.usersData.sort = this.sort;
        this.usersData.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
        this.isNoUser = true;

        // this.router.navigate(["/login"]);

      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;
    this.noDataFound = false;

  }
  // onChangePage(pageOfItems: Array<any>) {
  //   console.log("function call");
  //   this.totalRecords = this.tests.length;
  //   console.log(this.totalRecords);
  // }

  // dateSort(): void {
  //   this.isItemDescended = !this.isItemDescended;
  //   this.tests.sort(this.sortdate);
  //   console.log(this.tests);
  // }



  // sortdate(a: any, b: any) {
  //   //return new Date(a.updated).getTime() - new Date(b.updated).getTime();
  //   //return new Date(a.updated).getTime() - new Date(b.updated).getTime() ;
  //   return b.updated > a.updated;
  // }

  resetFilter() {

    this.firstName = "";
    this.lastName = ""
    this.emailId = "";
    this.userStatus = "";
    this.noDataFound = false;

    this.userServiceSubscription$ = this.usersService.getusersLanding()?.subscribe(resData => {
      this.usersData = resData?.data;
      console.log(this.usersData);
      if (this.usersData?.length < 0) {
        this.noDataFound = true;
      }
      else {
        this.usersData = new MatTableDataSource(this.usersData);
        // this.usersData.sort = this.sort;
        this.usersData.paginator = this.paginator;
      }
    },
      (error) => {
        console.log('dj');
        console.log(error);
        this.router.navigate(["/login"]);
      },
      () => {
        console.log('item service subscription completed');
      }) || undefined;

    // this.userServiceSubscription$ = this.usersService.getusers()?.subscribe(
    //   (resData) => {
    //     //console.log(data);
    //     this.usersData = resData?.data;
    //     console.log(this.usersData);
    //     if (this.usersData.length < 0) {
    //       this.noDataFound = true;
    //     }
    //     this.usersData = new MatTableDataSource(this.usersData);
    //     this.usersData.sort = this.sort;
    //     this.usersData.paginator = this.paginator;
    //   },
    //   (error) => {
    //     console.log('dj');
    //     console.log(error);
    //     this.router.navigate(["/login"]);

    //   },
    //   () => {
    //     console.log('item service subscription completed');
    //   }) || undefined;

  }

  // navigateToUserDetails(id: any) {
  //   this.router.navigate(["/users", id]);
  // }

  openEditUserDialog(useruuId: number): void {
    // const dialogConfig: MatDialogConfig<UserEditComponent> = {
    console.log(useruuId)
    const dialogRef = this.dialog.open(UserEditComponent, {
      minWidth: '650px',
      data: {
        useruuId: useruuId
      }
    });
    // const dialogRef = this.dialog.open(UserEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openNewUserDialog(): void {
    const dialogConfig: MatDialogConfig<UserCreateComponent> = {
      minWidth: '650px',
      maxWidth: '40vw'
    };
    const dialogRef = this.dialog.open(UserCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  // createNewUser() {
  //   this.router.navigate(["/users/add"]);
  // }

  ngOnDestroy() {
    if (this.userServiceSubscription$) {
      this.userServiceSubscription$.unsubscribe();
    }
    if (this.userFilterServiceSubscription$) {
      this.userFilterServiceSubscription$.unsubscribe();
    }
  }

}
