<!--<div class="main-heading-close">
    <a class="btn-close">
      <mat-icon mat-dialog-close>close</mat-icon>
    </a>
  </div>
<div class="dialog-size">
 
    <mat-dialog-content>
        <p>
            {{message}}
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button  mat-flat-button color="primary" class="btn-blue" (click)="onConfirmClick(true)" tabindex="1">{{confirmButtonText}}</button>
        <button  mat-flat-button color="warn" class="btn-orange" (click)="onConfirmClick(false)" tabindex="-1">{{cancelButtonText}}</button>
    </mat-dialog-actions>
</div>-->

<div class="dialog-container message-dialog">
        <div class="dialog-heading">
              <span class="spacer"></span>
                <div class="btn-close">
                    <button mat-icon-button mat-dialog-close aria-label="Close Dialog"><mat-icon>close</mat-icon></button>
                </div>
        </div>
        <mat-dialog-content>
            <p class="dialog-message"> {{message}}</p>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <button  mat-flat-button color="primary" (click)="onConfirmClick(true)" tabindex="1">{{confirmButtonText}}</button>
            <button  mat-flat-button color="warn"  (click)="onConfirmClick(false)" tabindex="-1" cdkFocusInitial>{{cancelButtonText}}</button>
          </mat-dialog-actions>
</div>