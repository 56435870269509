import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../shared/app.service';
import { DeliveryService } from '../shared/delivery.service';
import { ExamineeloginService } from '../shared/examineelogin.service';

@Component({
  selector: 'app-examineelogin',
  templateUrl: './examineelogin.component.html',
  styleUrls: ['./examineelogin.component.scss']
})
export class ExamineeloginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  isSubmitted = false;
  loginSpinner = false;
  submitted = false;
  loginErrorMessage: string;
  isLoginError = false;
  userServiceSubscription$: Subscription;
  constructor(private fb: FormBuilder, private appService: AppService,
    private examineeloginservice: ExamineeloginService,
    private deliveryservice: DeliveryService, private router: Router) { }
  userId: number;

  tests: any[] = [];
  testsSubscription$: Subscription;
  testsDataSource: any;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.appService.changeUserHeader(0);

    if (localStorage.getItem('currentUser')) {
      this.userId = JSON.parse(localStorage.getItem('currentUser') || '{}').userId;
      console.log(this.userId);
      this.testsSubscription$ = this.deliveryservice.getTest(this.userId).subscribe(
        (data: any) => {
          console.log(data);
          this.tests.push(data);
          this.testsDataSource = new MatTableDataSource(this.tests);
        },
        error => { console.log(error) },
        () => console.log('complete')
      );
    }
  }

  get f(): any {
    return this.loginForm.controls;
  }

  login(): void {
    let d 
    this.loginSpinner = true;
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.userServiceSubscription$ = this.examineeloginservice
        .login(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe(
          (data: any) => {
            console.log(data)
            // this.router.navigate(['/users'])
            this.appService.changeUserHeader(0);
            // this.router.navigate(["tests/start", this.userId ]);
            console.log(data);
            // this.router.navigate(['/examineework']);
          },
          error => {
            console.log(error),
             d =error.error.message,
             console.log(d);
             
              this.loginErrorMessage = 'Invalid username or password';
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.userServiceSubscription$.unsubscribe();
  }
}
