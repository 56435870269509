<div class="main-heading-close">
    <a class="btn-close">
      <mat-icon mat-dialog-close>close</mat-icon>
    </a>
  </div>
<div class="dialog-size">
 
    <mat-dialog-content>
        <p>
            {{message}}
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button class="btn-bg-color btn-size"  mat-dialog-close  tabindex="-1">{{cancelButtonText}}</button>
        <!-- <button mat-raised-button class="btn-size" (click)="onConfirmClick(false)" tabindex="-1">{{cancelButtonText}}</button> -->
    </mat-dialog-actions>
</div>

<!-- <button mat-raised-button color="primary" tabindex="-1">{{cancelButtonText}}</button> -->