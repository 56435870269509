<div class="add-client">
    <div class="client-registration">
        <form [formGroup]="clientForm">
            <div class="form-group">
                <div class="form-input save-btn">
                    <button mat-raised-button class="btn-bg-color" [disabled]="clientForm.invalid" (click)="registerClient()">Save</button>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>Client Name <span class="red-asterisk">*</span></mat-label>
                        <input matInput placeholder="Enter here" maxlength="30" minlength="2"
                            formControlName="clientName">
                        <mat-error *ngIf="clientFormControl.clientName.errors?.pattern">Numbers and special characters
                            are not allowed</mat-error>
                        <mat-error *ngIf="clientFormControl.clientName.errors?.required">Please enter valid client name
                        </mat-error>
                        <mat-error *ngIf="clientFormControl.clientName.errors?.minlength">Name should be between 2 to 30
                            characters</mat-error>
                        <mat-error *ngIf="clientFormControl.clientName.errors?.maxlength">Name should be between 2 to 30
                            characters</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>Client Url <span class="red-asterisk">*</span></mat-label>
                        <input matInput placeholder="Enter here" maxlength="30" minlength="2"
                            formControlName="clientUrl">
                        <mat-error *ngIf="clientFormControl.clientUrl.errors?.required">Please enter valid client url
                        </mat-error>
                        <mat-error *ngIf="clientFormControl.clientUrl.errors?.minlength">Url should be between 2 to 30
                            characters</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="fill">
                        <mat-label>Time Zone<span class="red-asterisk">*</span></mat-label>
                        <mat-select name="timezone" formControlName="timezone">
                            <mat-option *ngFor="let option of timeZoneArr" [value]="option?.timezoneid">
                                {{option?.standardname}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="clientFormControl.timezone.errors?.required">Please select valid time zone
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>First Name<span class="red-asterisk">*</span></mat-label>
                        <input matInput placeholder="Enter here" maxlength="30" minlength="2"
                            formControlName="firstName">
                        <mat-error *ngIf="clientFormControl.firstName.errors?.pattern">Numbers and special characters
                            are not allowed</mat-error>
                        <mat-error *ngIf="clientFormControl.firstName.errors?.required">Please enter valid first name
                        </mat-error>
                        <mat-error *ngIf="clientFormControl.firstName.errors?.minlength">Name should be between 2 to 30
                            characters</mat-error>
                        <mat-error *ngIf="clientFormControl.firstName.errors?.maxlength">Name should be between 2 to 30
                            characters</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Name<span class="red-asterisk">*</span></mat-label>
                        <input matInput placeholder="Enter here" maxlength="30" minlength="2"
                            formControlName="lastName">
                        <mat-error *ngIf="clientFormControl.lastName.errors?.pattern">Numbers and special characters
                            are not allowed</mat-error>
                        <mat-error *ngIf="clientFormControl.lastName.errors?.required">Please enter valid last name
                        </mat-error>
                        <mat-error *ngIf="clientFormControl.lastName.errors?.minlength">Name should be between 2 to 30
                            characters</mat-error>
                        <mat-error *ngIf="clientFormControl.lastName.errors?.maxlength">Name should be between 2 to 30
                            characters</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>Email ID<span class="red-asterisk">*</span></mat-label>
                        <input matInput placeholder="Enter here" maxlength="30" minlength="2" formControlName="emailId">
                        <mat-error *ngIf="clientFormControl.emailId.errors?.required">Please Enter email ID</mat-error>
                        <mat-error *ngIf="clientFormControl.emailId.errors?.email">Please Enter Valid Email ID
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>Password<span class="red-asterisk">*</span></mat-label>
                        <input matInput placeholder="Enter here" maxlength="50" minlength="6"
                            formControlName="password">
                        <mat-error *ngIf="clientFormControl.password.errors?.required">Please enter valid password
                        </mat-error>
                        <mat-error *ngIf="clientFormControl.password.errors?.minlength">Password should be between 6 to
                            20
                            characters</mat-error>
                        <mat-error *ngIf="clientFormControl.password.errors?.maxlength">Password should be between 6 to
                            20
                            characters</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group">
                <div class="form-input">
                    <mat-form-field appearance="outline">
                        <mat-label>Confirm Password<span class="red-asterisk">*</span></mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" placeholder="Enter here" maxlength="50" minlength="6"
                            formControlName="confirmPassword">
                        <mat-error *ngIf="clientFormControl.confirmPassword.errors?.required">Please enter valid confirm password
                        </mat-error>
                            <mat-error *ngIf="clientFormControl.confirmPassword.errors?.passwordValidator">Password doesn't match
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>