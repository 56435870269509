import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { EMPTY } from 'rxjs';
import { debounceTime, finalize, startWith, switchMap, tap } from 'rxjs/operators';
import { TestService } from 'src/app/shared/test.service';
import { Tags } from './tags';
import { NotificationService } from 'src/app/shared/notification.service';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-tests-tags',
  templateUrl: './tests-tags.component.html',
  styleUrls: ['./tests-tags.component.scss']
})
export class TestsTagsComponent implements OnInit, OnChanges , AfterViewInit {
  @Input() isPublished: boolean;
  displayedColumns: string[] = ['tagTypeName', 'tagDescription', 'testTagId'];
  testId: number;
  tags = new MatTableDataSource<any>([]);
  filteredtags: any[] = [];
  tagsforTable: any[] = [];
  tagsForm: FormGroup;
  isLoading = false;
  flatTags: any[] = [];
  flatTagsforTable: any[] = [];
  globalcollectionuuid: any;
  @Input() tabData: any;
  messageFlag = false;
  success = false;
  error = false;
  messages = '';
  duplicateTag = false;
  isNoTags = false;
  tag: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private testService: TestService,
    private actRoute: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cd : ChangeDetectorRef,
    private notificationService: NotificationService
  ) {
  
   }

   ngAfterViewInit(): void {
       this.tags.paginator = this.paginator; 
       this.tags.sort = this.sort;
   }

  ngOnInit(): void {
    console.log("dhananjay");
    this.isPublished = false;
    this.globalcollectionuuid = JSON.parse(localStorage.getItem('defaultcollectionuuid'));

    this.actRoute.paramMap.pipe(
      switchMap((params: any) => {
        this.testId = params.get('id');
        return this.testService.getTestTags(this.testId)
      }
      )).subscribe(
        resData => {
          console.log('resdata',resData);
         // this.tag = resData?.data;
          if (resData?.data?.length == 0) {
            this.isNoTags = true;
          }
          else {
           this.isNoTags = false;
           this.tag = new MatTableDataSource(resData?.data);
      
           this.isNoTags = false;
           this.tag.sort = this.sort;
           this.tag.paginator = this.paginator;
          this.tagsforTable = resData?.data;
          this.flatTagsforTable = [];
          this.tagsforTable.forEach(t => {
            t?.tags.forEach((element: any) => {

              let a = {
                ...element,
                tagtypename: t.tagtypename
              }
              this.flatTagsforTable.push(a);
            });

            this.tags.data = this.flatTagsforTable;
            this.cd.detectChanges();
            this.tags.sort = this.sort;
            this.tags.paginator = this.paginator;
          })
        }
        }
      );


    this.tagsForm = this.fb.group({
      userInput: null
    });

    this.tagsForm.get('userInput')!.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      tap(() => this.isLoading = true),
      switchMap(value => value.length > 1 ? this.testService.getAllTagsForItem(this.globalcollectionuuid, this.testId, value) : EMPTY
        .pipe(
          finalize(() => this.isLoading = false),
        )
      )
    ).subscribe(tags => {
      this.isLoading = false
      this.filteredtags = tags?.data;
      this.flatTags = [];
      this.filteredtags.forEach(t => {
        t?.tags?.forEach((element: any) => {
          let a = {
            ...element,
            tagtypename: t.tagtypename
          }
          this.flatTags.push(a);
        });
      })
      this.tagsForm.get('userInput')?.valueChanges.subscribe(data => {
        if (data.length == 0) {
          this.flatTags = [];
          this.isLoading = true

        }
      });
    });
  }

  ngOnChanges(): void {
    this.testService.updateTestStatus.subscribe(isTestDisabled => {
      if (isTestDisabled == true) {
        this.isPublished = isTestDisabled;
      } else {
        this.isPublished = false;
      }
    })
    if (this.tabData) {
      this.messageFlag = false;
    }

  }

  openConfirmationDialog(testTagId: any) {
    const dialogRef = this.dialog.open(DelMessageComponent, {
      data: {
        message: 'Are you sure you want to delete this Tag?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {    
        this.testService.deleteTagFromTest(testTagId).subscribe(resData => {
          this.testService.getTestTags(this.testId).subscribe(tagsData => {
            this.flatTagsforTable = [];
            if (tagsData?.data?.length == 0) {
              this.tags.data = this.flatTagsforTable;
              this.isNoTags = true;
            }
            tagsData?.data?.forEach((t: any) => {
              t?.tags.forEach((element: any) => {
                let a = {
                  ...element,
                  tagtypename: t.tagtypename
                }
                this.flatTagsforTable.push(a);
              });
              this.tags.data = this.flatTagsforTable;
            });
          }
          );

          this.notificationService.success(resData?.message);
        });

      }
    });

  }

  addTagtoItem(tagData: Tags) {
    this.testService.addTestTag(this.testId, tagData.tagId, 0).subscribe(
      (resData) => {
        if (resData) {
          this.isNoTags = false;
          this.actRoute.paramMap.pipe(
            switchMap((params: any) => {
              this.testId = params.get('id');
              return this.testService.getTestTags(this.testId)
            }
            )).subscribe(
              resData => {
                this.tag = resData?.data;
                if (this.tag?.length == 0) {
                  this.isNoTags = true;
                }
                else {
                  this.tag = new MatTableDataSource(this.tag);
                  this.tag.sort = this.sort;
                  this.tag.paginator = this.paginator;
                  this.isNoTags = false;
                  if (this.tag.paginator) {
                    this.tag.paginator.firstPage();
                  }
                }
                this.tagsforTable = resData?.data;
                this.flatTagsforTable = [];
                this.tagsforTable.forEach(t => {
                  t?.tags.forEach((element: any) => {
      
                    let a = {
                      ...element,
                      tagtypename: t.tagtypename
                    }
                    this.flatTagsforTable.push(a);
                  });
      
                  this.tags.data = this.flatTagsforTable;
                  this.tags.sort = this.sort;
                  this.tags.paginator = this.paginator;
                })
              }
            );
      
          this.notificationService.success(resData?.message)  
        }

        this.testService.getTestTags(this.testId).subscribe(
          resData => {
            this.flatTagsforTable = [];
            resData?.data.forEach((t: any) => {
              t?.tags.forEach((element: any) => {
                let a = {
                  ...element,
                  tagtypename: t.tagtypename
                }
                this.flatTagsforTable.push(a);
              });
              this.tags.data = this.flatTagsforTable;
              this.tags.sort = this.sort;
              this.tags.paginator = this.paginator;
              this.tagsForm.get('userInput')?.setValue("");
              this.flatTags = [];
            })
          }
        )
      },
      error => {
        this.notificationService.warn(error?.error?.message);
      });
  }


  displayFn(tags: Tags): string {
    console.log(tags)
    let seprator: string = "-";
    if (tags) {
      return tags.tagtypename + seprator + tags.tagName;
    }
    return '';
  }

  deleteTestTag(testTagId: number) {
    this.testService.deleteTagFromTest(testTagId).subscribe(resData => {
      this.notificationService.success(resData?.message)

      this.testService.getTestTags(this.testId).subscribe(data => {
        console.log(data);
        this.flatTagsforTable = [];
        if (data?.length == 0) {
          this.tags.data = this.flatTagsforTable;
        }
        data?.forEach((t: any) => {
          t?.tags.forEach((element: any) => {
            let a = {
              ...element,
              tagtypename: t.tagtypename
            }
            this.flatTagsforTable.push(a);
          });
          this.tags.data = this.flatTagsforTable;
        });
      }
      );
    });
  }

  ngOnDestroy() {
    this.isPublished = null;
  }
}

