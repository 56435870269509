import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { CollectionService } from 'src/app/shared/collection.service';
import {
  NewTestSchedule,
  PostTestMessage,
  PretTestMessage,
} from 'src/app/shared/addtestschedule';
import { DatePipe, Time } from '@angular/common';
import { analyzeAndValidateNgModules } from '@angular/compiler';

import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {
  MatChipInputEvent,
  MatChipList,
  MatChipSelectionChange,
} from '@angular/material/chips';
// import { DatePipe } from '@angular/common';
import { from, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  map,
  concatMap,
  catchError,
  startWith,
  debounceTime,
  tap,
  switchMap,
} from 'rxjs/operators';
import { TestService } from 'src/app/shared/test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NUM_CENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { DelMessageComponent } from 'src/app/shared/del-message/del-message.component';
import { TestsScheduleComponent } from '../tests-schedule/tests-schedule.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { ConfimationMessageComponent } from 'src/app/shared/confimation-message/confimation-message.component';
import { coerceStringArray } from '@angular/cdk/coercion';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-tests-schedule',
  templateUrl: './add-tests-schedule.component.html',
  styleUrls: ['./add-tests-schedule.component.scss'],
})
export class AddTestsScheduleComponent implements OnInit, OnChanges {
  scheduleForm: FormGroup;
  testType = ['Public', 'Private'];
  type: any;
  timeZoneArr = [];
  editor: Editor;
  editor1: Editor;
  NewTestSchedule: any;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  selectedStatus: string;
  addTestSchedule = new NewTestSchedule();
  @Input() currentTestId: any;
  @Input() testId: any;
  // @Input() testBankId:any;
  @Input() isPublished: boolean;
  collectionId: any;
  collectionIdNumber: any;
  currentUserFromStorage = [];
  clientId: any;
  testScheduleUiId: any;

  shuffleRejoin: any;
  // showResultAfterTest: any;
  shuffleItem: any;
  shuffleQuestion: any;
  shuffleOption: any;

  starttime: Time;
  splitStartTime = [];
  startHours: any;
  startHrNumber: any;
  startMinutes: any;
  startMinNumber: any;

  endtime: Time;
  splitEndTime = [];
  endHours: any;
  endHrNumber: any;
  endMinutes: any;
  endMinNumber: any;

  postMessage: any;
  postMessageToHtml: any;
  preMessage: any;
  preMessageToHtml: any;

  startdate: any;
  startlatest_date: any;
  endDate: any;
  endlatest_date: any;

  selectExaminee: any;
  action = false;

  getEndtime: any;
  getStartTime: any;
  getPostMsgvalue: any;
  getPreMsgvalue: any;

  selectedExaminee: any;
  getOldSelectedExaminee: any;
  newselectionexaminee = [];
  zone: any;
  testingChallenges = [];
  selectExamineeSch = [];

  timeZoneIndex: any;

  duplicatePushArray = [];
  loadLandingPage = false;

  showExaminee = true;
  convertStartTime: any;
  setDefaultStartTime: any;
  minutesToAdd: any;

  convertEndTime: any;
  setDefaultEndTime: any;
  submitted = false;
  duplicateDataFlag = false;

  scheduleStatus = false;
  /********************************************************************************************************************* */
  @ViewChild('chipList')
  public chipList: MatChipList;

  public challengeInput: FormControl = new FormControl('');

  public filteredChallenges: Observable<any[]>;

  public allChallenges: any[];

  public lastFilter = '';

  public challengeGroup: FormGroup;

  public chipSelection = new SelectionModel<any>(false, []);
  actRoute: any;
  public challenges = [];
  display = false;
  showrecepients = false;
  minDate: Date;
  minDate1: Date;

  scoreofTest: any;
  clearFilter: any;
  setPenaltymsg = false;
  setTestScoremsg = false;
  testBankId: any;
  collectionuuId: any;
  testuuId: any;
  public get ChallengesArray(): FormArray {
    return this.challengeGroup.get('challenges') as FormArray;
  }
  /********************************************************************************************************* */
  constructor(
    private formBuilder: FormBuilder,
    private collectionService: CollectionService,
    private datePipe: DatePipe,
    private testService: TestService,
    private router: Router,
    private routerstate: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.getTimeZone();
    this.minDate = new Date();
    this.minDate1 = new Date();
    this.editor = new Editor();
    this.editor1 = new Editor();

    //  convert time to current time to plus 30 minute
    this.convertStartTime = new Date();
    this.minutesToAdd = new Date(
      this.convertStartTime.setMinutes(this.convertStartTime.getMinutes() + 30)
    );

    this.setDefaultStartTime =
      this.minutesToAdd.getHours() + ':' + this.minutesToAdd.getMinutes();

    this.convertEndTime = this.convertStartTime.setMinutes(
      this.convertStartTime.getMinutes() + 60
    );
    this.setDefaultEndTime =
      this.convertStartTime.getHours() +
      ':' +
      this.convertStartTime.getMinutes();

    if (this.testService.schedulUuid) {
      this.action = true;
      this.testScheduleUiId = this.testService.schedulUuid;
      this.getScheduleTestData();

      // console.log('-------------------->', this.action);
    } else {
      this.showExaminee = true;
    }
    this.createSchduleForm();
    this.getExamineetoSchedule();
    this.testService.listMode.subscribe(data=>{
      if (data) {
        this.ngOnInit();
      }
    })
  }

  getSelectedChallenges() {
    let challenges: any[] = [];
    // challenges = this.abcd;
    // console.log("this.ChallengesArray",this.ChallengesArray);
    this.testingChallenges = [];
    this.ChallengesArray.controls.forEach((c) => {
      if (c && c.value) {
        this.testingChallenges.push(c.value);
      }
    });

    return this.testingChallenges;
  }

  private buildChallengeForm(): void {
    console.log('----->buildChallengeForm');
    this.challengeGroup = this.formBuilder.group({
      challengeInput: [''],
      challenges: this.formBuilder.array([]),
    });


  }

  ngOnChanges() {
    this.challengeGroup.get('challenges').statusChanges.subscribe(
      status => this.chipList.errorState = status === 'INVALID'
    );
  }

  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }
  selectedExamineeArray(): void {
    //select examinee from dropdown
    this.selectExaminee = this.getSelectedChallenges();
  }

  private filterChallenges(filter: string): any[] {
    this.clearFilter = filter;
    console.log('-------->calling filter', this.clearFilter);
    console.log('-------->calling filter', this.challengeInput);
    this.lastFilter = filter;
    const filterValue = filter.toLowerCase();
    if (filterValue) {
      console.log('------>filtervalue', filterValue);
      return this.challenges.filter(
        (c) =>
          c.emailId.toLocaleLowerCase().includes(filterValue) ||
          c.firstName.toLocaleLowerCase().includes(filterValue)
      );
    }

    return this.challenges.slice();
  }
  public toggleSelection(challenge: any): void {
    this.chipSelection.toggle(challenge);
    this.lastFilter = '';
    this.resetChallengeForm();
  }

  public handleCheckboxCheck(event: MatCheckboxChange, challenge: any): void {
    if (event.checked) {
      this.addChallengeToFormArray(challenge);
    } else {
      this.removeChallenge(challenge);
    }

    this.challengeInput!.setValue('');
  }

  public addChallenge(event: MatChipInputEvent): void {
    const { input } = event;
    if (input) {
      input.value = '';
      this.lastFilter = '';
    }
  }

  public onChallengeSelected(event: Event, challenge: any): void {
    event.stopPropagation();
    this.addChallengeToFormArray(challenge);

    // this.challengeInput.setValue('');
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    // console.log("---------------------->autocomplete",event: MatAutocompleteSelectedEvent);
    const challenge = this.challenges.find(
      (f) => f.emailId === event.option.viewValue
    );

    if (challenge) {
      // alert(challenge);
      this.addChallengeToFormArray(challenge);
      this.challengeInput.setValue('');
    }

    this.lastFilter = '';
  }
  public removeChallenge(challenge: any): void {
    // console.log('ddhjfghfsghj', challenge);
    const selected = this.chipSelection.selected[0];

    if (
      this.chipSelection.hasValue() &&
      selected.examineeId === challenge.examineeId
    ) {
      // const challengeIdx = this.selectExaminee.indexOf(selected);

      // if (challengeIdx > 0) {
      //   this.toggleSelection(this.selectExaminee[challengeIdx - 1]);
      // } else if (this.selectExaminee.length > 0) {
      //   this.toggleSelection(this.selectExaminee[challengeIdx + 1]);
      // } else {
      //   this.resetChallengeForm();
      // }

      this.chipSelection.deselect(challenge);
    }
    this.removeChallengeFromFormArray(challenge);
    this.challengeInput!.setValue('');
  }

  public chipListChanged(event: MatChipSelectionChange, challenge: any): void {
    console.log(this.chipList.chips);
    // this.recalculateChipSelection();
  }

  // Hack/Patch to prevent chip unselect after new chip added or removed.
  private recalculateChipSelection(): void {
    const selectedChip = this.chipSelection.selected[0];
    this.chipList.chips
      .find(
        (c) =>
          selectedChip &&
          c.value &&
          c.value.examineeId === selectedChip.examineeId
      )!
      .selectViaInteraction();
  }
  getaddChallengeToFormArray(examinee: any): void {
    examinee.forEach((item) => {
      this.addChallengeToFormArray(item);
    });
  }
  private addChallengeToFormArray(challenge: any): void {
    const i = this.ChallengesArray.controls.findIndex(
      (control) => control.value.examineeId === challenge.examineeId
    );

    if (i < 0) {
      // console.log("----challeges get uuid",challenge);

      this.ChallengesArray.push(this.formBuilder.control(challenge));
    }
    // console.log('------------Challenges aarye', this.ChallengesArray);
  }
  private removeChallengeFromFormArray(challenge: any): void {
    console.log('--------->', challenge);

    const i = this.testingChallenges.findIndex(
      (control) => control.examineeId === challenge.examineeId
    );
    //  this.getOldSelectedExamineeData(this.addTestSchedule.testScheduleId);

    if (this.getOldSelectedExaminee) {
      let checkExaminee = this.getOldSelectedExaminee.filter(
        (a) => a.examineeId == challenge.examineeId
      );
      if (checkExaminee) {
        this.challengeInput!.setValue('');

        let rcpuuid = challenge.recipientuuId;
        this.testService.deleteRecipient(rcpuuid).subscribe((resDelData) => { });
      }
    }

    this.ChallengesArray.removeAt(i);
    // console.log('--------------->controls', checkExaminee);

    if (i >= 0) {
    }
    this.challengeInput!.setValue('');
  }

  private resetChallengeForm(): void {
    this.challengeInput.reset();
  }

  ngOnInit(): void {
    this.testBankId = localStorage.getItem('testBankId');
    this.collectionId = localStorage.getItem('defaultcollectionid');
    this.currentUserFromStorage = JSON.parse(
      localStorage.getItem('currentUser') || '{}'
    );
    this.clientId = this.currentUserFromStorage['clientId'];
    console.log("------>currentTestId", this.currentTestId);
    if (this.currentTestId) {
      this.testTotalScore();
    }
  }

  getExamineetoSchedule(): void {
    this.testService.getExamineetoSchedule().subscribe((resData) => {
      this.challenges = resData?.items;

      console.log('------------------->testing pub', this.isPublished);
      if (this.challenges.length > 0) {
        this.buildChallengeForm();
        this.allChallenges = this.challenges;
        // console.log("----------------------->",this.allChallenges);
        this.filteredChallenges = this.challengeInput.valueChanges.pipe(
          startWith<string | any[]>(''),
          debounceTime(350),
          tap(() => {
            this.challenges = this.allChallenges.filter(
              (f) =>
                !this.getSelectedChallenges().some(
                  (x) => x.examineeId === f.examineeId
                )
            );
          }),
          map((value: string | any[]) =>
            typeof value === 'string' ? value : this.lastFilter
          ),

          map((filter) => this.filterChallenges(filter))
        );
      }
    });
  }

  // ***************************************************/

  // **********************************************************/
  getTimeZone(): void {
    /* this.collectionService.getTimeZone().subscribe((timezonedata) => {
       this.timeZoneArr = timezonedata?.items;
     });*/
    this.testService.getTimeZone().subscribe((timezonedata) => {
      this.timeZoneArr = timezonedata?.data;
    })
  }

  testRejoinChange(eventValue): void {
    if (eventValue.checked == true) {
      this.shuffleRejoin = true;
    } else {
      this.shuffleRejoin = false;
    }
  }

  // resultAfterTest(eventValue): void {
  //   if (eventValue.checked == true) {
  //     this.showResultAfterTest = true;
  //   } else {
  //     this.showResultAfterTest = false;
  //   }
  // }

  itemShuffle(eventValue): void {
    if (eventValue.checked == true) {
      this.shuffleItem = true;
    } else {
      this.shuffleItem = false;
    }
  }

  questionShuffle(eventValue): void {
    if (eventValue.checked == true) {
      this.shuffleQuestion = true;
    } else {
      this.shuffleQuestion = false;
    }
  }

  optionShuffle(eventValue): void {
    if (eventValue.checked == true) {
      this.shuffleOption = true;
    } else {
      this.shuffleOption = false;
    }
  }
  changeSchduleType(value: any) {
    // alert(value);
    if (value == true) {
      // this.showrecepients = false;
      this.showExaminee = value;
    } else {
      // this.showrecepients = true;
      this.showExaminee = false;
    }
  }

  testTotalScore(): void {
    // get total test score value from server
    if (this.currentTestId) {
      this.testService
        .getTestToltalScore(this.currentTestId)
        .subscribe((scoredata) => {
          if (scoredata) {
            this.scoreofTest = this.scheduleForm.controls[
              'totalTestScore'
            ].setValue(scoredata.data.totalMarks);
          }
        });
    }
  }
  createSchduleForm(): void {
    //to create the schedule form

    this.scheduleForm = this.formBuilder.group({
      scheduleName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(120),
        ],
      ],
      scheduleDesc: ['', [Validators.minLength(20), Validators.maxLength(480)]],
      testType: [this.showExaminee],
      startDate: [new Date()],
      startTime: [this.setDefaultStartTime],
      endDate: [new Date()],
      endTime: [this.setDefaultEndTime],
      timezoneid: ['', Validators.required],
      testDuration: ['', Validators.required],
      rejoinTest: [this.shuffleRejoin],
      totalTestScore: ['', Validators.required],
      // showResultAfterTest: [this.showResultAfterTest],
      totalPassingScore: ['0', Validators.required],
      shuffleItem: [this.shuffleItem],
      penaltyPoint: ['0', Validators.required],
      shuffleQuestion: [this.shuffleQuestion],
      shuffleOption: [this.shuffleOption],
      preMessageText: ['Welcome to Assessment Test', [Validators.required, Validators.minLength(8), Validators.maxLength(1000)]],
      postMessageText: [
        'Test Submitted Successfully',
        [Validators.minLength(3), Validators.maxLength(1000)],
      ],
      completed: [],
    });

    this.scheduleForm.controls.testType.setValue(true);
  }

  checkTypeData(): any {
    if (!this.f.testType.value) {
      return this.testingChallenges.length > 0 ? false : true;
    } else {
      return false;
    }
  }


  testScoreChange() {
    if (
      this.scheduleForm.controls['totalPassingScore'].value >
      // this.scheduleForm.controls['totalPassingScore'].value || this.scheduleForm.controls['penaltyPoint'].value >
      this.scheduleForm.controls['totalTestScore'].value
    ) {

      this.setTestScoremsg = true;
      this.scheduleForm.controls.totalPassingScore.setValue('0');
      console.log('calling chnage');
      console.log(this.setPenaltymsg);
    } else {
      this.setTestScoremsg = false;
      console.log(this.setPenaltymsg);
    }

  }
  checkTestValue() {
    if (
      this.scheduleForm.controls['penaltyPoint'].value >
      this.scheduleForm.controls['totalPassingScore'].value || this.scheduleForm.controls['penaltyPoint'].value >
      this.scheduleForm.controls['totalTestScore'].value
    ) {

      this.setPenaltymsg = true;
      this.scheduleForm.controls.penaltyPoint.setValue('0');
      console.log('calling chnage');
      console.log(this.setPenaltymsg);
    } else {
      this.setPenaltymsg = false;
      console.log(this.setPenaltymsg);
    }

  }
  get f() {
    return this.scheduleForm.controls;
  }

  getScheduleTestData(): void {
    this.testService
      .editTestSchedule(this.testScheduleUiId)
      .subscribe((schedata) => {
        this.addTestSchedule = schedata?.data;
        console.log("---------->addTestSchedule", this.addTestSchedule);

        if (
          this.addTestSchedule.scheduleStatus == 'Completed' ||
          this.addTestSchedule.scheduleStatus == 'Live'
        ) {
          this.scheduleStatus = true;
        } else {
          this.scheduleStatus = false;
        }
        if (this.addTestSchedule.scheduleType == false) {
          this.showExaminee = false;
        } else {
          this.showExaminee = true;
        }
        // this.zone = data.timeZoneName;
        this.getPostMsgvalue = this.addTestSchedule.postTestMessage.description;
        this.getPreMsgvalue = this.addTestSchedule.preTestMessage.description;

        this.getEndtime =
          this.addTestSchedule.endDateHours +
          ':' +
          this.addTestSchedule.endDateMinutes;
        this.getStartTime =
          this.addTestSchedule.startDateHours +
          ':' +
          this.addTestSchedule.startDateMinutes;

        this.scheduleForm = this.formBuilder.group({
          scheduleName: [
            this.addTestSchedule.title,
            [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(120),
            ],
          ],
          scheduleDesc: [
            this.addTestSchedule.description ? this.addTestSchedule.description : null],
          testType: [schedata?.data?.scheduleType],
          startDate: [new Date(schedata?.data?.startDate)],
          startTime: [this.getStartTime],
          endDate: [new Date(schedata?.data?.endDate)],
          endTime: [this.getEndtime],
          timezoneid: [schedata?.data?.timeZoneName, Validators.required],
          testDuration: [schedata?.data?.testDuration, Validators.required],
          rejoinTest: [schedata?.data?.shuffleRejoin],
          totalTestScore: [schedata?.data?.testScore, Validators.required],
          // showResultAfterTest: [schedata?.data?.showResultAfterTest],
          totalPassingScore: [
            schedata?.data?.testPassingScore,
            Validators.required,
          ],
          shuffleItem: [schedata?.data?.shuffleItem],
          penaltyPoint: [schedata?.data?.penaltyPoint, Validators.required],
          shuffleQuestion: [schedata?.data?.shuffleQuestion],
          shuffleOption: [schedata?.data?.shuffleOption],
          postMessageText: [schedata?.data?.postTestMessage.description],
          preMessageText: [schedata?.data?.preTestMessage.description],
          scheduleStatus: [schedata?.data?.scheduleStatus],
        });

        this.getOldSelectedExamineeData(this.addTestSchedule?.scheduleuuId);
      });
  }

  getOldSelectedExamineeData(testScheduleuuId): void {
    this.testService
      .updateExamineeRecepient(testScheduleuuId, this.currentTestId)
      .subscribe((userDAta) => {
        this.selectedExaminee = userDAta?.data;
        // this.editModeaddRecepientToFormArray(userDAta?.data);
        this.getOldSelectedExaminee = userDAta?.data;
        this.getaddChallengeToFormArray(userDAta?.data);
      });
  }
  // Back to Test schedule when we click on back arrow
  gotoTestSchedule() {
    this.display = true;
    this.isPublished = true;
  }

  comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.examineeId == current.examineeId;
        }).length == 0
      );
    };
  }

  saveTestSchedule() {
    // if (this.scheduleForm.controls['startTime'].value=="00:00") {
    //   alert("set time");
    // }
    console.log(this.scheduleForm);
    this.submitted = true;
    this.selectExamineeSch = [];
    console.log('--->form value', this.scheduleForm.value);

    console.log('--->form invalid', !this.scheduleForm.invalid);
    console.log('--->form valid', this.scheduleForm.valid);

    //save the schedule form
    this.selectedExamineeArray(); // getselectedExamineeId

    // this.selectExaminee = this.getSelectedChallenges();
    // this.getOldSelectedExamineeData(this.addTestSchedule.testScheduleId);

    let getRecipient;
    let uniqueResultOne = [];
    if (this.action == true) {
      this.testService
        .updateExamineeRecepient(
          this.addTestSchedule.scheduleuuId,
          this.currentTestId
        )
        .subscribe((userData) => {
          getRecipient = userData?.data;
        });
    }
    setTimeout(() => {
      //used to function call
      if (this.action == true) {
        console.log('--------------------->', getRecipient);
        console.log('--------------------->', this.testingChallenges);
        const uniqueResultOne = this.testingChallenges.filter(function (obj) {
          return !getRecipient.some(function (obj2) {
            return obj.examineeId == obj2.examineeId;
          });
        });
        uniqueResultOne.forEach((Id) => {
          this.selectExamineeSch.push(Id.examineeId);
        });
        console.log('--->uniqueResultOne', uniqueResultOne);
      } else {
        this.selectExaminee.forEach((element) => {
          this.selectExamineeSch.push(element.examineeId);
        });
      }

      if (this.action == true) {
        this.postMessageToHtml =
          this.scheduleForm.controls['postMessageText'].value; //fetch ngx editor text enter value

        this.preMessageToHtml =
          this.scheduleForm.controls['preMessageText'].value; //fetch ngx editor text enter value
      } else {
        this.postMessageToHtml =
          this.scheduleForm.controls['postMessageText'].value; //fetch ngx editor text enter value

        this.preMessageToHtml =
          this.scheduleForm.controls['preMessageText'].value; //fetch ngx editor text enter value
      }

      this.startdate = this.scheduleForm.controls['startDate'].value; //split standard date time only in date

      this.startlatest_date = this.datePipe.transform(
        this.startdate,
        'yyyy-MM-dd'
      );

      this.endDate = this.scheduleForm.controls['endDate'].value; //split standard date time only in date

      this.endlatest_date = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');

      this.splitStartTime =
        this.scheduleForm.controls['startTime'].value.split(':'); //split starttime hour and minute
      this.startHours = this.splitStartTime[0];
      this.startHrNumber = +this.startHours;
      this.startMinutes = this.splitStartTime[1];
      this.startMinNumber = +this.startMinutes;
      if (this.scheduleForm.controls['endTime'].value) {
        this.splitEndTime =
          this.scheduleForm.controls['endTime'].value.split(':'); //split Endtime hour and minute
      }

      this.endHours = this.splitEndTime[0];
      this.endHrNumber = +this.endHours;
      this.endMinutes = this.splitEndTime[1];
      this.endMinNumber = +this.endMinutes;

      /************************************************************************************************************************ */
      this.addTestSchedule.clientId = this.clientId;
      this.collectionIdNumber = +this.collectionId; //convert string to number
      this.addTestSchedule.collectionId = this.collectionIdNumber;

      this.addTestSchedule.description =  this.scheduleForm.value.scheduleDesc ? this.scheduleForm.value.scheduleDesc : null;
      //this.scheduleForm.controls['scheduleDesc'].value.replace(/\s/g, "");

      if (this.addTestSchedule.description && this.addTestSchedule.description.length == 0) {
        this.addTestSchedule.description = null;
      }
      this.addTestSchedule.endDate = this.endlatest_date;

      this.addTestSchedule.endDateHours = this.endHrNumber;

      this.addTestSchedule.endDateMinutes = this.endMinNumber;
      this.addTestSchedule.examineeRejoin =
        this.scheduleForm.controls['rejoinTest'].value;

      this.addTestSchedule.examinees = this.selectExamineeSch;

      console.log('----->this.add', this.addTestSchedule.examinees);

      this.addTestSchedule.postTestMessage = new PostTestMessage();
      this.addTestSchedule.postTestMessage.description = this.postMessageToHtml;
      // this.scheduleForm.controls['postMessageText'].value;
      this.addTestSchedule.postTestMessage.mediaURL = ['String'];

      this.addTestSchedule.preTestMessage = new PretTestMessage();
      this.addTestSchedule.preTestMessage.description = this.preMessageToHtml;
      // this.scheduleForm.controls['preMessageText'].value;
      this.addTestSchedule.preTestMessage.mediaURL = ['String'];

      this.addTestSchedule.scheduleType = JSON.parse(
        this.scheduleForm.controls['testType'].value
      );

      // this.addTestSchedule.showResultAfterTest =
      //   this.scheduleForm.controls['showResultAfterTest'].value;

      this.addTestSchedule.shuffleItem =
        this.scheduleForm.controls['shuffleItem'].value;

      this.addTestSchedule.shuffleOption =
        this.scheduleForm.controls['shuffleOption'].value;

      this.addTestSchedule.shuffleQuestion =
        this.scheduleForm.controls['shuffleQuestion'].value;

      this.addTestSchedule.startDate = this.startlatest_date;

      this.addTestSchedule.startDateHours = this.startHrNumber;

      this.addTestSchedule.startDateMinutes = this.startMinNumber;
      console.log("------>currentTestId", this.currentTestId);
      this.addTestSchedule.testBankId = this.testBankId;
      console.log("------>currentTestId 45454", this.addTestSchedule.testBankId);
      this.addTestSchedule.testDuration =
        this.scheduleForm.controls['testDuration'].value;

      this.addTestSchedule.testPassingScore =
        this.scheduleForm.controls['totalPassingScore'].value;

      this.addTestSchedule.testScore =
        this.scheduleForm.controls['totalTestScore'].value;

      this.addTestSchedule.penaltyPoint =
        this.scheduleForm.controls['penaltyPoint'].value;
      this.addTestSchedule.timeZoneName = this.scheduleForm.controls['timezoneid'].value;

      /*  if (this.scheduleForm.controls['timezoneid'].value) {
          this.timeZoneIndex = this.timeZoneArr.filter(
            (a) =>
              a.standardname == this.scheduleForm.controls['timezoneid'].value
          );

          if (this.timeZoneIndex[0].timezoneid) {
            this.addTestSchedule.timeZoneId = this.timeZoneIndex[0].timezoneid;
            console.log(
              '-------------->timeZoneID',
              this.addTestSchedule.timeZoneId
            );
          }
        }*/

      this.addTestSchedule.title = this.scheduleForm.controls['scheduleName'].value.trim();
      //this.scheduleForm.controls['scheduleName'].value.replace(/\s/g, "");

      // console.log('Data Print--------------------->', this.scheduleForm.controls['scheduleName'].value.replace(/\s/g, ""));
      // console.log('Data Print action--------------------->', this.addTestSchedule.title);
      if (this.action == true) {
        // console.log('edit schedule data');
        this.testService.editTestScheduleForm(this.addTestSchedule).subscribe(
          (editRes) => {
            // console.log('Update data', editRes);
            this.display = true;
             this.isPublished = true;
            this.notificationService.success(editRes?.message); // This line used to display success message
          },
          (error) => {
            console.log(error.error.error);
            //this.notificationService.warn(error.error.message); // This line used to display error message
            this.notificationService.warn(error.error.error[0].message);
          }
        );
      } else {
        this.testService.addTestSchedule(this.addTestSchedule).subscribe(
          (restestData) => {
            // console.log('Hii', restestData);
            // this.router.navigate(['../']);
            // this.loadLandingPage = true;
            // console.log('Hii', this.loadLandingPage);
            // this.testService.loadSchdule( this.loadLandingPage);
            this.display = true;
            this.isPublished = true;
            this.notificationService.success(restestData?.message); // This line used to display success message
          },
          (error) => {
            console.log(error.error.error);
            // this.notificationService.warn(error.error.message); // This line used to display error message
            this.notificationService.warn(error.error.error[0].message);
          }
        );
      }
      // this.display = true;
    }, 1000);
  }

  cancleTestSchedule(): void {
    console.log('---->this.addTestSchedule', this.addTestSchedule);
    if (
      this.addTestSchedule?.scheduleStatus == 'Completed' ||
      this.addTestSchedule?.scheduleStatus == 'Live'
    ) {
      const dialogRef = this.dialog.open(ConfimationMessageComponent, {
        data: {
          message: 'You can not Cancle the Schdule',
          buttonText: {
            cancel: 'Ok',
          },
        },
      });
    } else {
      const dialogRef = this.dialog.open(DelMessageComponent, {
        data: {
          message: 'Are you sure you want to cancel the Schedule?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log('checkvalue', confirmed);
        if (confirmed) {
          console.log("----->this.testScheduleUiId", this.testScheduleUiId);
          this.testService.cancleSchduel(this.testScheduleUiId).subscribe(
            (deldata) => {
              console.log(deldata);

              this.notificationService.success(deldata?.message); // this line used to display error message
            },
            (error) => {
              this.notificationService.warn(error?.error?.message); // this line used to display error message
            }
          );
          this.display = true;
        }
      });
    }
  }
  onTestNameControlFocus(): void {
    this.duplicateDataFlag = false;
  }
}
