<div class="module-wrapper content-wrapper">
    <div class="tab-container">
        <mat-card>
            <mat-card-content>
              <div class="head-container">
                <div>
                <h1>Test Management</h1>
                <div class="breadcrumb-container">
                  <a [routerLink]="[]" class="link-active" (click)="navigatetoTest()">Test Management</a> /
                  <span class="nav-link" *ngIf="tab1">{{tab}}</span>
                  <span class="nav-link" *ngIf="!tab1">Items</span>
              </div>
            </div>
            </div>
                <div class="item-info-container">
                    <div class="itemDetail">
                      <div class="item-name"><span>Test name: {{testTitle}}</span>
                      </div>

                     <div class="item-desc">Test description: {{testDescription}} </div>
                    </div>
                    <span class="spacer"></span>
                    <div  class="edit-btn-wrapper">
                      <span class="item-active" *ngIf="itemBasicDetails.statusName !== ''">
                        <i-feather name="disc"></i-feather>
                        <span>{{itemBasicDetails.statusName}}</span>
                        </span>
                        <div class="btn-wrapper">
                            <button mat-icon-button aria-label="Edit Test" [disabled]="itemBasicDetails?.statusName !== 'Unpublished'" matTooltip="Edit Test" matTooltipPosition="above" (click)="openEditTestDialog()" class="edit">
                              <span class="menu-icon">
                                <i-feather name="edit"></i-feather>
                              </span>
                            </button>
                        </div>
                    </div>
                </div>

                <mat-tab-group mat-align-tabs="end"  (selectedTabChange)="onChange($event)" animationDuration="0ms">
                    <mat-tab label="Items">
                      <ng-template mat-tab-label>
                        <span class="menu-icon">
                              <!-- <i-feather name="help-circle"></i-feather> -->
                          <mat-icon>list</mat-icon>
                        </span>
                        <span>Items ({{isTestItemCount}})</span>
                      </ng-template>
                      <div class="tab-content">
                        <!-- {{loadItemsTab}} -->
                        <!-- <ng-container *ngIf="loadItemsTab"> -->
                          <app-tests-items [tabData]='tabData' [isPublished] = 'isPublished'></app-tests-items>
                        <!-- </ng-container> -->
                      </div>
                    </mat-tab>

                    <mat-tab label="Tags">
                      <ng-template mat-tab-label>
                          <span class="menu-icon"><i-feather name="tag"></i-feather></span>
                          <span>Tags</span>
                      </ng-template>
                      <div class="tab-content">
                        <app-tests-tags [tabData]='tabData' [isPublished] = 'isPublished'></app-tests-tags>
                      </div>
                    </mat-tab>
                    <mat-tab label="Schedule">
                      <ng-template mat-tab-label>
                        <span class="menu-icon">
                          <!-- <i-feather name="tag"></i-feather> -->
                          <mat-icon>event</mat-icon>
                        </span>
                        <span>Schedules</span>
                      </ng-template>
                      <div class="tab-content">
                        <app-tests-schedule *ngIf="testBankId" [testuuId]='testuuId' [testBankId]='testBankId' [isPublished] = 'isPublished'></app-tests-schedule>
                      </div>
                    </mat-tab>
                    <mat-tab label="Settings">
                      <ng-template mat-tab-label>
                        <span class="menu-icon">
                          <!-- <i-feather name="tag"></i-feather> -->
                          <mat-icon>settings</mat-icon>
                        </span>
                        <span>Settings</span>
                      </ng-template>
                      <div class="tab-content">
                        <app-tests-settings [tabData]='tabData'></app-tests-settings>
                      </div>
                    </mat-tab>
                    <!-- <mat-tab label="Activities" disabled>
                      <ng-template mat-tab-label>
                        <span class="menu-icon">
                          <i-feather name="Activity"></i-feather>
                        </span>
                        <span>Activities</span>
                      </ng-template>
                      <div class="tab-content">
                        <app-tests-activities></app-tests-activities>
                      </div>
                    </mat-tab> -->
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
  </div>

