<div class="login-form-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 logo-container">
                <div class="">
                    <div class="logo-wrapper">
                        <div class="logo">
                            <div><img src="../../assets/login.png" alt="Logo Tanay"></div>
                        </div>
                        <div class="logo-content">
                            <h1>Create, update, and publish your tests dynamically across any digital platform or channel.</h1><br>
                            <span>
                                SimpliAssess is an online test maker that brings high quality assessments within
                                everyone’s reach. It’s an easy-to-use headless assessment platform for educators,
                                course administrators and training professionals to conduct online tests and quizzes
                                across multiple channels.
                            </span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-4 login-form">
                <div id="login" class="form-container">
                    <div id="login-row" class="justify-content-center align-items-center">
                        <div id="login-box">
                            <p class="login-title">Forgot password?</p>

                            <div id="login-column" class="login-column-c">

                                <form [formGroup]="loginForm" (ngSubmit)="reset()" novalidate>
                                    <label for="emailId" class="text-info">Enter email address <span
                                            class="error">*</span></label><br>
                                    <input type="text" class="form-control" formControlName="emailId" required
                                        [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">


                                        <div class="col-md-12" *ngIf="submitted && f.emailId.errors" class="alert alert-danger">
                                        <div *ngIf="f.emailId.errors?.required">Enter Email ID</div>
                                        <div *ngIf="f.emailId.errors?.pattern">Please, Enter valid email ID</div>
                                    </div>

                                    <div class="form-group">
                                        <div>
                                            <button type="submit" class="btn-login btn-bg-color" mat-flat-button
                                                (click)="reset()">
                                                <span>Reset password</span>
                                            </button>
                                        </div>
                                        <div class="display-msg">
                                            <P>{{msg}}</P>
                                        </div>


                                    </div>
                                </form>
                            </div>

                            <!-- <span class="error-message invalid-feedback">{{ loginErrorMessage }}</span> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>