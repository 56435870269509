import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'boldTag'
})
export class BoldTagPipe implements PipeTransform {
  transform(value: string): any {
    let a;
    let b;
    let c 
    a = value.split(':');
    if (a[0] && a[1]) {
      for (let i = 1; i < a.length; i++) {
        console.log(a[i][0]);
        
      }
   
      b = a[0] + ': ' + '<strong>' + a[1] + '</strong>';
    } else {
      b = ',' + '<strong>' + a[0] + '</strong>'
    }
    return b;
  }
}
