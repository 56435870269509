<div class="main-content-wrapper ">
    <div class="content-wrapper">
        <div class="">
            <div class="container-fluid">
                <div class="row">
                    <form [formGroup]="changePwdForm" novalidate>
                        <div class="input-class">
                            <label>Enter old password<span class="red-asterisk">*</span></label>
                            <input matInput type="password" maxlength="21" class="form-control" formControlName="oldPassword">
                             <div *ngIf="f.oldPassword.invalid && (f.oldPassword.dirty || f.oldPassword.touched)"
                              class="alert alert-danger">
                              <div *ngIf="f.oldPassword.errors?.required">Password is required </div>
                              <div *ngIf="f.oldPassword.errors?.minlength">Minimum 6 characters required</div>
                              <div *ngIf="f.oldPassword.errors?.maxlength">Maximum 20 characters allowed</div>
                              <div *ngIf="f.oldPassword.errors?.pattern">Password should be minimum 6 characters with at least one special character, one number, one uppercase and one lowercase
                              letter.</div>
                            </div>
                          </div>
                          <div class="input-class">
                            <label>Enter new password<span class="red-asterisk">*</span></label>
                            <input matInput type="password" maxlength="21" class="form-control" formControlName="newPassword">
                            <div *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)"
                              class="alert alert-danger">
                              <div *ngIf="f.newPassword.errors?.required">Password is required </div>
                              <div *ngIf="f.newPassword.errors?.minlength">Minimum 6 characters required</div>
                              <div *ngIf="f.newPassword.errors?.maxlength">Maximum 20 characters allowed</div>
                              <div *ngIf="f.newPassword.errors?.pattern">Password should be minimum 6 characters with at least one special character, one number, one uppercase and one lowercase letter.</div>
                            </div>
                          </div>
                        <div class="input-class">
                            <label>Confirm new password<span class="red-asterisk">*</span></label>
                            <input matInput type="password" maxlength="21" class="form-control" formControlName="confirmPassword">
                            <div *ngIf="f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)"
                              class="alert alert-danger">
                              <div *ngIf="f.confirmPassword.errors?.required">Password is required</div>
                              <div *ngIf="f.confirmPassword.errors?.minlength">Minimum 6 characters required</div>
                              <div *ngIf="f.confirmPassword.errors?.maxlength">Maximum 20 characters allowed</div>
                              <div *ngIf="f.confirmPassword.errors?.pattern">Password should be minimum 6 characters with at least one special character, one number, one uppercase and one lowercase letter.</div>
                            </div>
                          </div>



                        <div class="button-wrapper">
                            <button mat-raised-button class="btn-bg-color firstbtn" (click)="submit()" [disabled]="!f.newPassword.valid || !f.confirmPassword.valid || f.oldPassword.errors?.required">Submit</button>
                            <button mat-raised-button (click)="cancel()">Cancel</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
