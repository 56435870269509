<div mat-dialog-content>
        <div class="main-heading">
        <label>Edit Option</label>
        </div>
        
       
            <ngx-editor-menu  [editor]="editor" [toolbar]="toolbar">
              </ngx-editor-menu>
              <ngx-editor name='optionbox' [placeholder]="'Enter here...'" [(ngModel)]='option' [editor]="editor"></ngx-editor>
    
        
      
    </div>
    <div mat-dialog-actions>
        <button  mat-flat-button color="primary" class="btn-blue" (click)="updateOption()">Save</button>
    </div>
    