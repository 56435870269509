<div class="module-wrapper content-wrapper">
  <!-- <mat-card> -->
  <mat-card-content>
    <div class="head-container">
      <h1></h1>
      <span class="spacer"></span>
      <div>
        <div class="btn-wrapper">
          <button mat-flat-button color="warn" class="btn-orange" aria-label="Add New Item" (click)="openDialog()"> +
            Assign Users</button>
        </div>
      </div>
    </div>
    <div class="input-container">
      <div class="input-fields-wrapper">
        <div class="input-wrapper">
          <input type="text" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model=""
            [(ngModel)]='clientFirstName' placeholder="Find by First name">
          <input type="text" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-model=""
            [(ngModel)]='clientLastName' placeholder="Find by Last name">

          <select placeholder="Role" aria-placeholder="Role" class="form-control role" [(ngModel)]='selectFilterRole'
            #role>
            <option value="Role">Role</option>
            <option [value]="role.userAccessLevel" *ngFor="let role of userRoles">{{role.userAccessLevel}}</option>
          </select>

          <!-- <select aria-placeholder="Status" class="form-control" #status [(ngModel)]='selectStatus'>
                        <option [value]="st.status" *ngFor="let st of statusArray">{{ st.status }}</option>
                      </select> -->
        </div>
        <div class="btn-wrapper">
          <button mat-flat-button color="primary" class="btn-blue" aria-label="Search"
            (click)='itemFilter()'>Search</button>
          <button mat-flat-button color="basic" class="btn-flat" aria-label="Reset"
            (click)='resetFilter()'>Reset</button>
        </div>
      </div>
    </div>

    <div *ngIf="isNoUsers" class="row data-not-found-container table-container">
      <span>No clients in this collection</span>
    </div>
    <div *ngIf="!isNoUsers">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> First name </th>
            <td mat-cell *matCellDef="let row">
              <span class="">{{row?.firstName | titlecase}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Last name </th>
            <td mat-cell *matCellDef="let row">
              {{row?.lastName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Phone</th>
            <td mat-cell *matCellDef="let row">
              {{row?.phone}}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Email </th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.emailId.length > 30 then sliceEmail else noSliceEmail"></ng-container>
              <ng-template #sliceEmail>
            <td matTooltip="{{row?.emailId}}" matTooltipPosition="above" innerHtml="{{row?.emailId | slice:0:15}}">
              ...
            </td>
            </ng-template>
            <ng-template #noSliceEmail>
              <td>{{row.emailId}}</td>
            </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" class="role-col"> Role</th>
            <td mat-cell *matCellDef="let row">
              <mat-select aria-placeholder="Role" class="form-control role"
                (selectionChange)="changeRole($event.value,row)" [(ngModel)]="row.userAccessLevel" #role>

                <mat-option [value]="role.userAccessLevel" *ngFor="let role of userRoles">
                  {{role?.userAccessLevel}}</mat-option>

              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Status </th>
            <td mat-cell *matCellDef="let row">
              <!-- <div *ngIf="row?.active == true">Active</div>
                    <div *ngIf="row?.active == false">Inactive</div> -->
              <i *ngIf="row?.active === true" class="fa fas fa-circle publish" matTooltip="Active"
                matTooltipPosition="above">
                <!-- <mat-icon class="publish">fiber_manual_record</mat-icon>  -->
              </i>
              <span *ngIf="row?.active === false" class="glyphicon glyphicon-adjust unpublish" matTooltip="Inactive"
                matTooltipPosition="above">
              </span>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="modifiedDate">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Updated </th>
            <td mat-cell *matCellDef="let row">
              <span class="text-bold"> {{row?.modifiedUserName}} </span>
              <span> {{row?.modifiedDateTime | dateAgo}} </span>
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> </th>
                  <td mat-cell *matCellDef="let row">
                  <!--  <mat-icon matTooltip="Remove User" matTooltipPosition="above">account_box</mat-icon> -->
          <!--   <div class="question-icons">
                  <button mat-mini-fab color="warn" aria-label="delete icon" class="fab-mini-icon"
                  (click)="deleteUser(row)">
                  <i-feather name="trash-2"></i-feather>
                </button>
                </div>
               </td>
                </ng-container>-->

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true"> Action </th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item  (click)="deleteUser(row)" [disabled]="row.userAccessLevelId === 1 && row.userId === this.currentUserData.userId">
                            <mat-icon>delete_outline</mat-icon>
                            <span>Delete</span>
                          </button>
                      </mat-menu>
              <!-- <button mat-icon-button [disabled]="row.userAccessLevelId === 1 && row.userId === this.currentUserData.userId"
               (click)="deleteUser(row)" matTooltip="Delete">
                <span class="menu-item">
                  <i class="material-icons">delete</i>
                </span>
              </button> -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <div class="data-not-found-container" *ngIf='noDataFound'>
          <p>Data not found</p>
        </div>
      </div>
      <div class="collection-paginator">
        <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </mat-card-content>
  <!-- </mat-card> -->
</div>














<!--
<div class="item-bank-list-container">

  <div class="filter-section">
    <div class="container-fluid">

      <div class="row" *ngIf="addnewUsertoCollection==false">
        <div class="col-md-10">
          <div class="input-fields-wrapper">

            <div class="input-wrapper">
              <input type="text" class="form-control" [(ngModel)]='clientFirstName' placeholder="Search by first name">

              <input type="text" class="form-control" [(ngModel)]='clientLastName' placeholder="Search by last name">

              <select placeholder="Role" aria-placeholder="Role" class="form-control role"
                [(ngModel)]='selectFilterRole' #role>
                <option value="Role">Role</option>
                <option [value]="role.userAccessLevel" *ngFor="let role of userRoles">{{role.userAccessLevel}}</option>
              </select>
              <select aria-placeholder="Status" class="form-control" #status [(ngModel)]='selectStatus'>
                <option [value]="st.status" *ngFor="let st of statusArray">{{ st.status }}</option>
              </select>
            </div>
            <div class="btn-wrapper">

              <button class="btn-bg-color" (click)='itemFilter()' mat-raised-button>
                <span>Search</span>
              </button>
              <button class="btn-green-text" (click)='resetFilter()' mat-flat-button>
                <span>Reset</span>
              </button>
            </div>
          </div>

        </div>
        <div class="col-md-2">
          <div class="search btn-wrapper">
            <button class="btn-orange" (click)="openDialog()" mat-raised-button>
              <span> + Assign users</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid " *ngIf="addnewUsertoCollection==false">
    <div *ngIf="isNoUsers" class="row data-not-found-container table-container">
      <span>No clients in this collection</span>
    </div>


    <div *ngIf="!isNoUsers" class="row">
      <div class="col-md-12">
        <div class="table-container">

          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>


              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> First name </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.firstName | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last name </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.lastName | titlecase}}
                </td>
              </ng-container>

              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone</th>
                <td mat-cell *matCellDef="let row">
                  {{row?.phone}}
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let row">
                  <ng-container *ngIf="row.emailId.length > 30 then sliceEmail else noSliceEmail"></ng-container>
                  <ng-template #sliceEmail>
                <td matTooltip="{{row?.emailId}}" matTooltipPosition="above" innerHtml="{{row?.emailId | slice:0:15}}">
                  ...
                </td>
                </ng-template>
                <ng-template #noSliceEmail>
                  <td>{{row.emailId}}</td>
                </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Role</th>
                <td mat-cell *matCellDef="let row">
                  <mat-select aria-placeholder="Role" class="form-control role"
                    (selectionChange)="changeRole($event.value,row)" [(ngModel)]="row.userAccessLevel" #role>

                    <mat-option [(value)]="role.userAccessLevel" *ngFor="let role of userRoles">
                      {{role?.userAccessLevel}}</mat-option>

                  </mat-select>




                </td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="row?.active == true">Active</div>
                  <div *ngIf="row?.active == false">Inactive</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="modifiedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
                <td mat-cell *matCellDef="let row"> {{row?.modifiedDateTime | dateAgo}} </td>
              </ng-container>

              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let row" (click)="deleteUser(row)">
                  <mat-icon matTooltip="Remove User" matTooltipPosition="above">account_box</mat-icon>


                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-data">
              </tr>

            </table>
            <div class="data-not-found-container" *ngIf='noDataFound'>
              <p>Data not found</p>
            </div>
          </div>

        </div>
        <div class="collection-paginator">
          <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
        </div>

      </div>
    </div>
  </div>

</div> -->